import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../components/CustomButton";
import CustomCheckbox from "../../../components/CustomCheckbox";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CustomTextField from "../../../components/CustomTextField";
import {
  getClinicOtherUsers,
  updateClinicOtherUsers,
} from "../../../redux/reducers/clinicSlice";
import { AppColors } from "../../../util/AppColors";
import { getKey } from "../../../util/function";
import BranchManagementMain from "./BranchManagementMain";
import { accessList } from "../../../util/dropList";
import CustomSelect from "../../../components/Select/Select";

const tableHeaders = ["name", "emailId", "role", "edit"];

const tableNameExpan = {
  name: "Name",
  emailId: "Email ID",
  role: "Role",
  edit: "",
};

const roleExpan = {
  ROLE_CLINICADMIN: "Admin",
  ROLE_RECEPTION: "Front Desk",
  ROLE_PHARMACY: "Pharmacy",
  ROLE_BILLING: "Billing",
};

const roleSrtExpan = {
  ROLE_CLINICADMIN: "AD",
  ROLE_RECEPTION: "RE",
  ROLE_PHARMACY: "PH",
  ROLE_BILLING: "BL",
};

const AccessAndRoles = () => {
  const dispatch = useDispatch();
  const [editModVsble, setEditModVsble] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selctedData, setSelctedData] = useState(null);
  const [checkedList, setCheckedList] = useState([]);
  const otherUsers = useSelector((state) => state?.clinic?.otherUsers);

  useEffect(() => {
    dispatch(getClinicOtherUsers());
  }, []);

  useEffect(() => {
    getTableData();
  }, [otherUsers]);

  const handleEdit = (usr) => {
    setSelctedData(usr);
    setCheckedList(
      usr?.userType?.map((usr) => {
        return roleSrtExpan?.[usr?.name];
      })
    );
    setEditModVsble(true);
  };

  const getTableData = () => {
    const otherUserlist = otherUsers?.filter((vet) => {
      const otherUser = vet?.userType?.find(
        (usrTyp) => usrTyp?.name !== "ROLE_DOCTOR"
      );
      return otherUser;
    });
    const reqTablList = otherUserlist?.map((usr) => ({
      name: usr?.name,
      emailId: usr?.email,
      role: usr?.userType,
      edit: (
        <img
          src={require("../../../assets/images/png/edit2.png")}
          className="img-wh20 cursor"
          onClick={() => handleEdit(usr)}
        />
      ),
    }));
    setTableData(reqTablList);
  };

  const onCloseEditMod = () => {
    setEditModVsble(false);
    setSelctedData(null);
  };

  const onUpdate = () => {
    dispatch(
      updateClinicOtherUsers({
        userId: selctedData?.doctorId,
        access: checkedList,
      })
    ).then((res) => {
      if (res?.payload) {
        dispatch(getClinicOtherUsers());
        onCloseEditMod();
      }
    });
  };

  const handleMultiSelect = (event, name) => {
    const {
      target: { value },
    } = event;
    const reqValue = value?.includes("AD") ? ["AD"] : value;
    setCheckedList(reqValue);
  };

  return (
    <BranchManagementMain active="accessAndRoles">
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="mh20 mv20"
        >
          <TableContainer sx={{ maxHeight: 450 }}>
            <Table
              sx={{ minWidth: 200 }}
              aria-labelledby="tableTitle"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {tableHeaders?.map((hd) => (
                    <TableCell
                      key={getKey()}
                      sortDirection={false}
                      className={`table-header-text capitalize`}
                      style={{
                        background: AppColors.tableGrey,
                        color: AppColors.white,
                      }}
                    >
                      {tableNameExpan?.[hd]}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.map((li) => (
                  <TableRow key={getKey()}>
                    {tableHeaders?.map((hdr) => (
                      <TableCell className={`txt-regular`}>
                        {hdr === "role" ? (
                          li?.[hdr]?.length > 0 ? (
                            <div className="flex-column">
                              {li?.[hdr]?.map((rle) => (
                                <div className="mv5">
                                  {roleExpan?.[rle?.name]
                                    ? roleExpan?.[rle?.name]
                                    : rle?.name}
                                </div>
                              ))}
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          li?.[hdr]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <CustomModal
        open={editModVsble}
        onClose={onCloseEditMod}
        header="Edit"
        rightModal
        modalWidth={30}
      >
        <Grid container spacing={1} className="ph20">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CustomTextField
              label="Name"
              fullWidth
              value={selctedData?.name}
              labelTop
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CustomTextField
              label="Email"
              fullWidth
              value={selctedData?.email}
              labelTop
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CustomSelect
              list={accessList}
              value={checkedList}
              handleChange={(e) => handleMultiSelect(e, "access")}
              multiSelectTagCheck
              label="Roles"
              labelTop
            />
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginLeft: "300px", marginTop: "30px" }}
        >
          <div style={{ width: "20px" }}>
            <CustomButton text="Save" smallBtn={true} onClick={onUpdate} />
          </div>
        </Grid>
      </CustomModal>
    </BranchManagementMain>
  );
};

export default AccessAndRoles;
