// live
export const notes = "https://soap.vetinstant.com/soap_note/";
export const baseURL = "https://api.vetinstant.com/api/v2";
export const socketServer = "https://api.vetinstant.com/ws";

// // dev
// export const notes = "https://soap.vetinstant.com/soap_note";
// export const baseURL = "http://137.184.130.244:1993/api/v2";
// export const socketServer = "http://137.184.130.244:1993/ws";

// // local
// export const socketServer = 'http://localhost:1993/ws';
// export const notes = 'https://soap.vetinstant.com/soap_note';
// export const baseURL = "http://localhost:1993/api/v2";

export const api_key =
  "sk-proj-kO8C64bPVMEKtXUxXl67T3BlbkFJ3uVOdXipHvlXOwTPTMRq";
