import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import {
  Card,
  CardMedia,
  Container,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import { uniq } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../components/CustomButton";
import CustomCheckbox from "../../../components/CustomCheckbox";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CustomTable from "../../../components/CustomTable";
import CustomTextField from "../../../components/CustomTextField";
import Select from "../../../components/Select/Select";
import {
  clinicRescheduleAppointment,
  getClinicAppointemntList,
  getClinicHolidays,
  updateAppointmentStatusById,
} from "../../../redux/reducers/clinicSlice";
import { getVetHolidaysById } from "../../../redux/reducers/doctorSlice";
import { hideLoader, showLoader } from "../../../redux/reducers/loaderSlice";
import { getSlotTime } from "../../../redux/reducers/petSlice";
import { getClinicVets } from "../../../redux/reducers/vetSlice";
import { AppColors } from "../../../util/AppColors";
import { appointmentLaterList } from "../../../util/dropList";
import { getAllDatesFromArray } from "../../../util/function";

const tableHeaders = [
  "petName",
  "gender",
  "breed",
  "userName",
  "vetName",
  "conType",
  "apntmntDteAnTim",
  "approveStatus",
];

const cardSty = {
  borderRadius: 1,
  padding: 2,
  borderTopColor: AppColors.appPrimary,
};

const initErrors = { appointmentDate: "", appointmentTime: "", doctor: "" };

const AppointmentApprove = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [searchType, setSerachType] = useState("all");
  const [filteredList, setFilteredList] = useState([]);
  const [reschModVsble, setReschModVsble] = useState(false);
  const [modData, setModData] = useState(null);
  const [bookValues, setBookValues] = useState(null);
  const [vets, setVets] = useState([]);
  const [selectTimeIdError, setSelectTimeIdError] = useState(false);
  const [errors, setErrors] = useState(initErrors);
  const [holidayDates, setHolidayDates] = useState([]);
  const [selectTime, setSelectTime] = useState(null);
  const [selectTimeId, setSelectTimeId] = useState(null);
  const clinicVets = useSelector((state) => state?.vet?.vets);
  const holidays = useSelector((state) => state?.doctor?.holidays);
  const clinicHolidays = useSelector((state) => state?.clinic?.holidays);
  const appointemnts = useSelector((state) => state?.clinic?.appointmentList);
  const slotList = useSelector((state) => state?.pet?.slotList);
  const activeSlotList = slotList?.filter((status) => status.status === "N");
  const defaultUrl = "?type=appointment";

  useEffect(() => {
    getApppointmentList();
    dispatch(getClinicVets());
    dispatch(getClinicHolidays());
  }, []);

  useEffect(() => {
    const reqVetList = clinicVets
      ?.filter((vl) => {
        if (vl?.userType?.find((ut) => ut?.name === "ROLE_DOCTOR")) {
          return vl;
        }
      })
      ?.map((vet) => {
        return { name: vet?.name, value: vet?.doctorId, id: vet?.doctorId };
      });
    setVets(reqVetList);
  }, [clinicVets]);

  useEffect(() => {
    getTableAppointments();
  }, [appointemnts, searchValue, searchType]);

  useEffect(() => {
    getHolidayDateList();
  }, [holidays, clinicHolidays]);

  useEffect(() => {
    if (
      !bookValues?.vetName?.value ||
      !bookValues?.date ||
      !bookValues?.appointmentOption
    ) {
      return;
    }

    dispatch(getVetHolidaysById(bookValues?.vetName?.value));
    const data = {
      doctorId: bookValues?.vetName?.value,
      contType: bookValues?.consultationMode,
      date: moment(new Date(bookValues?.date)).format("YYYY-MM-DD"),
    };
    dispatch(getSlotTime(data));
  }, [
    bookValues?.vetName?.value,
    bookValues?.date,
    bookValues?.appointmentOption,
    bookValues?.consultationMode,
  ]);

  const getApppointmentList = () => {
    dispatch(getClinicAppointemntList(defaultUrl));
  };

  const getHolidayDateList = async () => {
    if (
      (holidays?.length === 0 || !holidays) &&
      (clinicHolidays?.length === 0 || !clinicHolidays)
    ) {
      return setHolidayDates([]);
    }
    const vetDates = (await getAllDatesFromArray(holidays)) ?? [];

    const cliHolidays =
      clinicHolidays?.map((hl) => {
        return hl?.date;
      }) ?? [];
    const mergedArray = vetDates?.concat(cliHolidays);
    setHolidayDates(uniq(mergedArray));
  };

  const handleReschedule = (apnt) => {
    setReschModVsble(true);
    setModData(apnt);
    setBookValues({
      appoimentTime: apnt?.appoimentTime,
      appoimentId: apnt?.appoimentId,
      appoinmentDate: apnt?.appoinmentDate,
      bookingType: "Later",
      consultationMode: apnt?.appoinmentType,
      serviceType: apnt?.serviceType,
      reason: apnt?.reason ?? "reason",
      vetName: vets?.find((vet) => vet?.value === apnt?.doctorId),
      appointmentOption: "Later",
      date: new Date(apnt?.appoinmentDate),
    });
  };

  const handleReschModClose = () => {
    setReschModVsble(false);
    setModData(null);
    setBookValues(null);
    setSelectTime(false);
    setSelectTimeId(false);
    setSelectTimeIdError(false);
  };

  const getTableAppointments = () => {
    const tblList =
      appointemnts?.length > 0
        ? appointemnts
            ?.filter((apt) => {
              if (searchValue?.length === 0) return apt;
              const doctorMatch = apt?.doctorName
                ?.toLowerCase()
                .includes(searchValue?.toLowerCase());
              const petMatch = apt?.petName
                ?.toLowerCase()
                .includes(searchValue?.toLowerCase());
              const userNameMatch = apt?.userName
                ?.toLowerCase()
                .includes(searchValue?.toLowerCase());
              return searchType === "vetName"
                ? doctorMatch
                : doctorMatch || petMatch || userNameMatch;
            })
            ?.map((apt) => ({
              ...apt,
              vetName: apt?.doctorName,
              apntmntDteAnTim: `${moment(apt?.appoinmentDate)?.format(
                "DD MMM YYYY,"
              )} ${apt?.appoimentTime}`,
              conType: apt?.appoinmentType,
              approveStatus: (
                <div className="flex-row">
                  <div className="flex1-end">
                    <div>
                      <CustomButton
                        text="Approve"
                        smallBtn
                        onClick={() =>
                          updateAppntmentStatusById(apt, "scheduled")
                        }
                      />
                    </div>
                    <div className="ml10">
                      {/* <CustomButton
                      text="Decline"
                      smallBtn
                      redBtn
                      onClick={() => updateAppntmentStatusById(apt, "Canceled")}
                    /> */}
                      <CustomButton
                        text="Reschedule"
                        smallBtn
                        onClick={() => handleReschedule(apt)}
                        redBtn
                      />
                    </div>
                  </div>
                </div>
              ),
            }))
        : [];
    setFilteredList(tblList);
  };

  const updateAppntmentStatusById = (apnt, status) => {
    dispatch(
      updateAppointmentStatusById({ appointmentId: apnt?.appoimentId, status })
    ).then((res) => {
      if (res?.payload) dispatch(getClinicAppointemntList(defaultUrl));
    });
  };

  const handleChangeBookValue = (key, value) => {
    if (key === "appointmentOption" && value === "Immediate") {
      setSelectTimeIdError(false);
    }
    setBookValues({ ...bookValues, [key]: value });
  };

  const handleSelectTime = (time) => {
    setSelectTime(time?.time);
    setSelectTimeId(time?.slotTimeId);
    setSelectTimeIdError(false);
  };

  const validateFields = () => {
    const newErrors = {};
    if (!bookValues?.vetName) newErrors.vetName = "This field is required";
    if (!bookValues?.appointmentOption)
      newErrors.appointmentOption = "Appointment Type is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (!selectTimeId) return setSelectTimeIdError(true);
    if (!validateFields()) return;
    const data = {
      appointmentDate: moment(new Date(bookValues?.date)).format("YYYY-MM-DD"),
      appoimentTime: selectTime,
      doctorId: bookValues?.vetName?.value,
      timeId: selectTimeId,
    };
    dispatch(showLoader());
    dispatch(
      clinicRescheduleAppointment({ appointmentId: modData?.appoimentId, data })
    ).then((res) => {
      if (res?.payload) {
        handleReschModClose();
        getApppointmentList();
        setModData(null);
      }
    });
    dispatch(hideLoader());
  };

  return (
    <>
      <Grid
        container
        // className="topBar-main"
        sx={{ padding: 2, alignItems: "center" }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Container maxWidth="xl">
            <Toolbar variant="regular">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="flex-row">
                  <div className="top-row-cen-con w100Per">
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                      <CustomTextField
                        placeholder="Search"
                        fullWidth
                        handleChange={(e) => setSearchValue(e?.target?.value)}
                        backgroundColor={AppColors.lightPink}
                        value={searchValue}
                      />
                    </Grid>

                    <div className="top-row-right-con w15Per topBar-select">
                      <Select
                        list={[
                          { name: "All", value: "all" },
                          { name: "Vet Name", value: "vetName" },
                        ]}
                        value={searchType}
                        handleChange={(e) => setSerachType(e?.target?.value)}
                        select
                      />
                    </div>
                  </div>
                </div>
              </Grid>
            </Toolbar>
          </Container>
        </Grid>
      </Grid>

      <CustomTable columns={tableHeaders} datas={filteredList} grey approve />

      <CustomModal
        open={reschModVsble}
        onClose={handleReschModClose}
        header="Reschedule"
        rightModal
        modalWidth={30}
        headerBold
      >
        <Grid container spacing={1} className="ph20 scroll-80vh">
          <Grid item xs={12}>
            <Card sx={cardSty} className="CustomCard-back-appointment">
              <div className="maint">
                <div className="flex-row">
                  <Grid item xs={3}>
                    <div className="flex-center">
                      {modData?.petImage ? (
                        <CardMedia
                          image={modData?.petImage}
                          className="CustomCard-img3-bill"
                        />
                      ) : (
                        <div className="flex-center h50img">
                          <Typography className="font-bold fs30 white-color capitalize">
                            {modData?.petName?.[0]}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </Grid>
                  <div className="flex-row">
                    <div className="flex-start">
                      <div className="flex-column">
                        <div className="flex-row">
                          <div className="mb10 txt-semi-bold black fs14 capitalize">
                            {modData?.petName}
                          </div>
                          <Typography
                            className={`ml5 text capitalize ${
                              modData?.gender === "male"
                                ? "card-blue-color"
                                : "card-rose-color"
                            }`}
                          >
                            {`(${modData?.gender})`}
                          </Typography>
                        </div>
                        <Typography className="mb10 txt-regular card-gray-color fs12">
                          {modData?.breed}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div>
                  <div className="flex-row parentcontainer mt10">
                    <div className="flex-row iconcontainer">
                      <AccountCircleOutlinedIcon
                        sx={{ width: 25, height: 25 }}
                      />
                      <div className="txt-semi-bold fs14 capitalize">
                        {modData?.userName}
                      </div>
                    </div>
                    <div className="meeting-doctor">
                      {moment(modData?.dob).fromNow()} ({modData?.dob})
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className="txt-semi-bold fs14 light-grey">
              Booking Type <span className="red-color">*</span>
            </div>
            <Select value="App" selectFixed />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className="txt-semi-bold fs14 light-grey">
              Consultation mode <span className="red-color">*</span>
            </div>
            <Select value={bookValues?.consultationMode} selectFixed />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey">
              Service type <span className="red-color">*</span>
            </div>
            <Select value={bookValues?.serviceType} selectFixed />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey mb10">
              Vet Name <span className="red-color">*</span>
            </div>

            <Select
              list={vets}
              handleChange={(e, value) =>
                handleChangeBookValue("vetName", value)
              }
              name="vetName"
              search
              value={bookValues?.vetName}
              error={!!errors?.vetName}
              helperText={errors?.vetName}
              labelTop
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="mbminus"
          >
            <div className="flex-row">
              <div className="txt-semi-bold fs14 light-grey mt10">
                Appointment <span className="red-color">*</span> :
              </div>
              <div className="ml5">
                <CustomCheckbox
                  radio
                  value={bookValues?.appointmentOption}
                  onChange={(e) =>
                    handleChangeBookValue("appointmentOption", e?.target?.value)
                  }
                  radios={appointmentLaterList}
                  defaultValue={bookValues?.appointmentOption}
                />
              </div>
            </div>

            {!!errors?.appointmentOption && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography color="error" className="mt5 fs14">
                  Appointment is Required
                </Typography>
              </Grid>
            )}
          </Grid>

          {bookValues?.appointmentOption === "Later" && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="txt-mont fs14 ">
                Date <span className="red-color">*</span>
              </div>
              <div className="mb20">
                <CustomTextField
                  name="date"
                  value={bookValues?.date}
                  labelTop
                  fullWidth
                  handleChange={(e) =>
                    handleChangeBookValue("date", e?.target?.value)
                  }
                  mobileDateWithBatch
                  markDateList={holidayDates ?? []}
                />
              </div>
            </Grid>
          )}

          {bookValues?.appointmentOption === "Later" &&
            bookValues?.date &&
            activeSlotList?.length > 0 &&
            activeSlotList?.map((item, i) => {
              return (
                <div className="flex-row">
                  <div
                    onClick={() => handleSelectTime(item)}
                    className={
                      selectTimeId === item?.slotTimeId
                        ? "bgContainerSelected cursor"
                        : "bgContainerUnselected cursor"
                    }
                    key={item?.slotTimeId}
                  >
                    {item?.time}
                  </div>
                </div>
              );
            })}

          {selectTimeIdError && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography color="error" className="mt5 fs14">
                Time is Requried
              </Typography>
            </Grid>
          )}
        </Grid>
        <div className="flex1-end mt20">
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <CustomButton text="Save" onClick={handleSave} />
          </Grid>
        </div>
      </CustomModal>
    </>
  );
};

export default AppointmentApprove;
