import axios from "axios";
import { api_key, baseURL } from "./../../util/server";

function headerConfig(contentType, auth, formData) {
  const header = {};

  if (contentType && typeof contentType === "string") {
    header["Content-Type"] = contentType;
  } else {
    header["Content-Type"] = "application/json";
  }

  if (formData === true) {
    header["Content-Type"] = "multipart/form-data";
  }

  if (auth === true) {
    const token = localStorage.getItem("accessToken");
    header.Authorization = `Bearer ${token}`;
  }

  return header;
}

export default ({ contentType, auth, formData }) =>
  axios.create({
    baseURL,
    headers: headerConfig(contentType, auth, formData),
  });

export const chatgptApi = axios.create({
  baseURL: "https://api.openai.com",
  headers: {
    Authorization: `Bearer ${api_key}`, // Add your API key here
    "Content-Type": "application/json",
  },
});
