import { Container, Grid, Toolbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import CustomCard from "../../../components/CustomCard/CustomCard";
import Checkbox from "../../../components/CustomCheckbox";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CustomTextField from "../../../components/CustomTextField";
import Select from "../../../components/Select/Select";
import TopBar from "../../../components/TopBar/TopBar";
import {
  createClinicDoctors,
  createOthers,
} from "../../../redux/reducers/clinicSlice";
import { showSnackBar } from "../../../redux/reducers/snackSlice";
import {
  getClinicVets,
  getGlobalDays,
  updateVetBlockStatus,
} from "../../../redux/reducers/vetSlice";
import { AppColors } from "../../../util/AppColors";
import { ErrorStrings } from "../../../util/ErrorString";
import { EmailRegex } from "../../../util/Validations";
import { newRoleList } from "../../../util/arrayList";
import {
  accessList,
  clinicRolesList,
  salutationList,
  specialtyList,
  typeList,
} from "../../../util/dropList";
import generatePass from "../../../util/randomPassword";
import CommonPaymentUpdateModal from "../CommonPaymentUpdateModal/CommonPaymentUpdateModal";
import "./Vets.css";

const filterList = [
  { name: "All", value: "all" },
  { name: "Others", value: "other" },
  { name: "Vets", value: "doctor" },
];

const nameExpan = {
  salutation: "Salutation",
  name: "Name",
  speciality: "Speciality",
  conType: "Consultation Type",
  contactNumber: "Contact Number",
  email: "Email",
  password: "Password",
  role: "Role",
  access: "Access",
};

const initialValues = {
  salutation: null,
  name: "",
  speciality: null,
  contactNumber: "",
  conType: [],
  email: "",
  password: "",
  role: null,
  registrationNo: "",
  access: [],
};

const initialError = {
  salutation: false,
  name: false,
  speciality: false,
  conType: false,
  contactNumber: false,
  email: false,
  password: false,
  role: false,
  access: false,
};

const initialHelp = {
  salutation: "",
  name: "",
  speciality: "",
  conType: "",
  contactNumber: "",
  email: "",
  password: "",
  role: "",
  access: "",
};

const vetReqFlds = [
  "name",
  "role",
  "access",
  "speciality",
  "conType",
  "email",
  "password",
];
const othersReqFlds = ["name", "role", "access", "email", "password"];

const Vets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const vets = useSelector((state) => state?.vet?.vets);
  const [conModVisible, setConModVisible] = useState(false);
  const [vetValues, setVetValues] = useState(initialValues);
  const [vetErrors, setVetErrors] = useState(initialError);
  const [vetHelps, setVetHelps] = useState(initialHelp);
  const [showPassword, setShowPassword] = useState(false);
  const [isAutoGenPass, setAutoGenPass] = useState(false);
  const [selectedVet, setSelectedVet] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [createModVisible, setCreateModalVisible] = useState(false);
  const [createUserType, setCreateUserType] = useState("Vet");
  const [vetFilter, setVetFilter] = useState("all");

  useEffect(() => {
    dispatch(getClinicVets(`?type=${vetFilter}`));
    dispatch(getGlobalDays());
  }, []);

  const getAllFilteredList = () => {
    return vets?.filter((vet) => {
      return (vet?.name?.toLowerCase() || "")?.includes(
        searchValue?.toLowerCase()
      );
    });
  };

  const filteredVets = () => {
    return vets?.filter((vet) => {
      const isVet = vet?.userType?.find(
        (usrTyp) => usrTyp?.name === "ROLE_DOCTOR"
      );
      return (
        isVet &&
        (vet?.name?.toLowerCase() ?? "")?.includes(searchValue?.toLowerCase())
      );
    });
  };

  const filteredOthers = () => {
    return vets?.filter((vet) => {
      const otherUsers = vet?.userType?.find(
        (usrTyp) => usrTyp?.name !== "ROLE_DOCTOR"
      );
      return (
        otherUsers &&
        (vet?.name?.toLowerCase() ?? "")?.includes(searchValue?.toLowerCase())
      );
    });
  };

  const conModalOpen = () => {
    setConModVisible(!conModVisible);
  };

  const emailValidation = (value) => {
    if (value === "") {
      setVetErrors({ ...vetErrors, email: true });
      setVetHelps({ ...vetHelps, email: ErrorStrings.emptyEmail });
    }
    if (value !== "") {
      if (!EmailRegex.test(value)) {
        setVetErrors({ ...vetErrors, email: true });
        setVetHelps({ ...vetHelps, email: ErrorStrings.inValidEmail });
      } else {
        setVetErrors({ ...vetErrors, email: false });
        setVetHelps({ ...vetHelps, email: "" });
      }
    }
  };

  const passValidation = (value) => {
    if (value === "") {
      setVetErrors({ ...vetErrors, password: true });
      setVetHelps({ ...vetHelps, password: ErrorStrings.emptyPass });
      return;
    }
    if (value?.length < 6) {
      setVetErrors({ ...vetErrors, password: true });
      setVetHelps({ ...vetHelps, password: ErrorStrings.inValidPass });
      return;
    }
    setVetErrors({ ...vetErrors, password: false });
    setVetHelps({ ...vetHelps, password: "" });
  };

  const mobileValidation = (value) => {
    if (value === "") {
      setVetErrors({ ...vetErrors, contactNumber: true });
      setVetHelps({ ...vetHelps, contactNumber: ErrorStrings.emptyMobile });
      return;
    }
    if (value.length < 10 || value.length > 10) {
      setVetErrors({ ...vetErrors, contactNumber: true });
      setVetHelps({ ...vetHelps, contactNumber: ErrorStrings.inValidMobile });
      return;
    }
    setVetErrors({ ...vetErrors, contactNumber: false });
    setVetHelps({ ...vetHelps, contactNumber: "" });
  };

  const handleChange = (e) => {
    setVetValues({ ...vetValues, [e.target.name]: e.target.value });
    if (e.target.name === "email") return emailValidation(e.target.value);
    if (e.target.name === "password") return passValidation(e.target.value);
    if (e.target.name === "contactNumber") {
      return mobileValidation(e.target.value);
    }
    const { name, value } = e.target;
    if (vetErrors[name]) {
      setVetErrors({ ...vetErrors, [name]: false });
      setVetHelps({ ...vetHelps, [name]: "" });
    }

    setVetValues({
      ...vetValues,
      [name]:
        name === "name"
          ? value?.charAt(0).toUpperCase() + value?.slice(1)
          : value,
    });

    if (e.target.value === "") {
      setVetErrors({ ...vetErrors, [e.target.name]: true });
      setVetHelps({
        ...vetHelps,
        [e.target.name]: `${nameExpan?.[e.target.name]} Required Field`,
      });
    }
    if (e.target.value !== "") {
      setVetErrors({ ...vetErrors, [e.target.name]: false });
      setVetHelps({ ...vetHelps, [e.target.name]: "" });
    }
  };

  const handleMultiSelect = (event, name) => {
    const {
      target: { value },
    } = event;
    const reqValue =
      name === "access" ? (value?.includes("AD") ? ["AD"] : value) : value;
    setVetValues({
      ...vetValues,
      [name]: reqValue,
    });
    setVetErrors({
      ...vetErrors,
      [name]: reqValue?.length > 0 ? false : true,
    });
    setVetHelps({
      ...vetHelps,
      [name]: reqValue?.length > 0 ? "" : `${nameExpan?.[name]} Required Field`,
    });
  };

  const handleSelect = (value, key) => {
    if (vetErrors[key]) {
      setVetErrors({ ...vetErrors, [key]: false });
      setVetHelps({ ...vetHelps, [key]: "" });
    }
    setVetValues({ ...vetValues, [key]: value });
  };

  const handleChangeSwitch = async (e, vet) => {
    const apiRes = await dispatch(
      updateVetBlockStatus({
        vetId: vet?.doctorId,
        active: e.target.value ? "Y" : "N",
      })
    );
    if (apiRes?.payload) {
      dispatch(getClinicVets(`?type=${vetFilter}`));
    }
  };

  const checkRoleDoctor = (vet) => {
    const roleDoctor = vet?.userType?.find(
      (usr) => usr?.name === "ROLE_DOCTOR"
    );
    return roleDoctor ? true : false;
  };

  const handleCardClick = (vet) => {
    if (!checkRoleDoctor(vet)) return;
    navigate("/vet-calendar", { state: { vet } });
  };

  const handleUpdateFeeUpdateSuccess = async () => {
    setSelectedVet(null);
    setConModVisible(!conModVisible);
    dispatch(getClinicVets(`?type=${vetFilter}`));
  };

  const handleAutoGenPassword = () => {
    setAutoGenPass(!isAutoGenPass);
    if (!isAutoGenPass) {
      const pass = generatePass();
      setVetValues({ ...vetValues, password: pass });
    } else {
      setVetValues({ ...vetValues, password: "" });
    }
  };

  const onClickCalendar = (vet) => {
    if (!checkRoleDoctor(vet)) return;
    navigate("/manage-availability", { state: { vetId: vet?.doctorId } });
  };

  const onClickDollar = (vet) => {
    if (!checkRoleDoctor(vet)) return;
    setSelectedVet(vet);
    if (vet?.active === "Y") {
      setConModVisible(!conModVisible);
    }
  };

  const handleCreate = () => {
    setCreateModalVisible(true);
  };

  const createModalOpen = () => {
    setCreateUserType("Vet");
    setCreateModalVisible(!createModVisible);
    setVetValues(initialValues);
    setVetErrors(initialError);
    setVetHelps(initialHelp);
  };

  const handleChangeUserType = (e) => {
    setCreateUserType(e.target.value);
    setVetValues(initialValues);
    setVetErrors(initialError);
    setVetHelps(initialHelp);
  };

  const resetValues = () => {
    setVetValues(initialValues);
    setVetErrors(initialError);
    setVetHelps(initialHelp);
  };

  const handleSuccess = () => {
    dispatch(
      showSnackBar({
        message: "Clinic Vet Registered",
        type: "success",
      })
    );
    setCreateModalVisible(false);
    resetValues();
    dispatch(getClinicVets(`?type=${vetFilter}`));
  };

  const createVet = () => {
    let errs = {};
    let helps = {};
    vetReqFlds?.forEach((fld) => {
      if (
        vetValues?.[fld] === null ||
        vetValues?.[fld] === "" ||
        (Array.isArray(vetValues?.[fld]) && vetValues?.[fld]?.length === 0) ||
        (fld === "password" && vetValues?.[fld]?.length < 6) ||
        (fld === "email" && !EmailRegex.test(vetValues?.[fld]))
      ) {
        errs = { ...errs, [fld]: true };
        helps = {
          ...helps,
          [fld]:
            fld === "email"
              ? vetValues?.[fld] === ""
                ? ErrorStrings.emptyEmail
                : !EmailRegex.test(vetValues?.[fld])
                ? ErrorStrings.inValidEmail
                : ""
              : fld === "password"
              ? vetValues?.[fld] === ""
                ? ErrorStrings.emptyPass
                : vetValues?.[fld]?.length < 6
                ? ErrorStrings.inValidPass
                : ""
              : `${nameExpan?.[fld]} Required Field`,
        };
      } else {
        errs = { ...errs, [fld]: false };
        helps = { ...helps, [fld]: "" };
      }
    });
    setVetErrors({ ...vetErrors, ...errs });
    setVetHelps({ ...vetHelps, ...helps });
    if (Object.keys(errs)?.some((val) => errs?.[val] === true)) return;

    // Proceed with vet creation if all validations pass
    const data = new FormData();
    data.append("name", vetValues?.name);
    // data.append("salutation", "Dr.");
    data.append("speciality", vetValues.speciality?.value);
    data.append("role", vetValues.role?.value);
    data.append("email", vetValues.email);
    data.append("password", vetValues.password);
    data.append("consulationType", vetValues.conType);
    data.append("mobile", vetValues?.contactNumber);
    data.append("access", vetValues?.access);
    if (vetValues?.registrationNo !== "") {
      data.append("registrationNo", vetValues?.registrationNo ?? "");
    }
    dispatch(createClinicDoctors(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          if (res?.payload?.data?.status === 400) {
            dispatch(
              showSnackBar({
                message: res?.payload?.data?.message,
                type: "error",
              })
            );
          }
          if (res?.payload?.data?.status === 200) {
            if (
              res?.payload?.data?.message === "User profile created" ||
              res?.payload?.data?.message ===
                "Doctor profile created successfully"
            ) {
              handleSuccess();
            }
          }
        }
        if (res?.payload?.status === 500) {
          if (
            res?.payload?.response?.data?.error === "Internal Server Error" &&
            res?.payload?.response?.data?.message === "Authentication failed"
          ) {
            handleSuccess();
          }
        }
      })
      .catch((error) => {
        console.error("API call failed:", error);
      });
  };

  const createOther = () => {
    // Validate fields
    let errs = {};
    let helps = {};
    othersReqFlds?.forEach((fld) => {
      if (
        vetValues?.[fld] === null ||
        vetValues?.[fld] === "" ||
        (Array.isArray(vetValues?.[fld]) && vetValues?.[fld]?.length === 0) ||
        (fld === "password" && vetValues?.[fld]?.length < 6) ||
        (fld === "email" && !EmailRegex.test(vetValues?.[fld]))
      ) {
        errs = { ...errs, [fld]: true };
        helps = {
          ...helps,
          [fld]:
            fld === "email"
              ? vetValues?.[fld] === ""
                ? ErrorStrings.emptyEmail
                : !EmailRegex.test(vetValues?.[fld])
                ? ErrorStrings.inValidEmail
                : ""
              : fld === "password"
              ? vetValues?.[fld] === ""
                ? ErrorStrings.emptyPass
                : vetValues?.[fld]?.length < 6
                ? ErrorStrings.inValidPass
                : ""
              : `${nameExpan?.[fld]} Required Field`,
        };
      } else {
        errs = { ...errs, [fld]: false };
        helps = { ...helps, [fld]: "" };
      }
    });
    setVetErrors({ ...vetErrors, ...errs });
    setVetHelps({ ...vetHelps, ...helps });
    if (Object.keys(errs)?.some((val) => errs?.[val] === true)) return;

    // Proceed with vet creation if all validations pass
    const data = new FormData();
    data.append("name", vetValues?.name);
    data.append("role", vetValues?.role?.value);
    data.append("salutation", vetValues?.salutation?.value);
    data.append("registrationNo", vetValues?.registrationNo);
    data.append("email", vetValues?.email);
    data.append("password", vetValues?.password);
    data.append("mobile", vetValues?.contactNumber);
    data.append("access", vetValues?.access);

    dispatch(createOthers(data)).then((res) => {
      if (res?.payload?.data?.status === 200) {
        if (res?.payload?.data?.message === "User Registered Successfully") {
          dispatch(
            showSnackBar({
              message: res?.payload?.data?.message,
              type: "success",
            })
          );
          setCreateModalVisible(false);
          resetValues();
          dispatch(getClinicVets(`?type=${vetFilter}`));
        } else {
          dispatch(
            showSnackBar({
              message: res?.payload?.data?.message,
              type: "error",
            })
          );
        }
      }
    });
  };

  return (
    <>
      <TopBar>
        <Container maxWidth="xl">
          <Toolbar variant="regular">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="flex-row">
                <div className="top-row-cen-con w100Per">
                  <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                    <CustomTextField
                      placeholder="Search"
                      name="name"
                      fullWidth
                      handleChange={(e) => setSearchValue(e.target.value)}
                      value={searchValue}
                      backgroundColor={AppColors.lightPink}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={2}
                    md={2}
                    lg={1}
                    xl={1}
                    className="ml20"
                  >
                    <CustomButton text="Create" onClick={handleCreate} />
                  </Grid>
                  <div className="top-row-right-con w15Per topBar-select">
                    <Select
                      list={filterList}
                      value={vetFilter}
                      handleChange={(e) => {
                        setVetFilter(e?.target?.value);
                        dispatch(getClinicVets(`?type=${e?.target?.value}`));
                      }}
                      name="vet"
                      select
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Toolbar>
        </Container>
      </TopBar>
      <Container maxWidth="xl" className="scroll-80vh">
        {(vetFilter === "all"
          ? getAllFilteredList()
          : vetFilter === "doctor"
          ? filteredVets()
          : filteredOthers()
        )?.length > 0 ? (
          <CustomCard
            list={
              vetFilter === "all"
                ? getAllFilteredList()
                : vetFilter === "doctor"
                ? filteredVets()
                : filteredOthers()
            }
            onClickCalendar={onClickCalendar}
            onClickDollar={onClickDollar}
            onChangeSwitch={handleChangeSwitch}
            onCardClick={handleCardClick}
            users
          />
        ) : (
          <div className="no-record">No records found</div>
        )}
        <div className="pb30" />
      </Container>
      <CommonPaymentUpdateModal
        open={conModVisible}
        onClose={conModalOpen}
        onApiSuccess={handleUpdateFeeUpdateSuccess}
        vet={selectedVet}
      />

      <CustomModal
        open={createModVisible}
        onClose={createModalOpen}
        header="Create"
        rightModal
        modalWidth={30}
      >
        <Grid container spacing={1} className="ph20 scroll-90vh">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-mont fs14 fw-600 ">User Type</div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Checkbox
              radio
              onChange={handleChangeUserType}
              radios={[
                { label: "Vet", value: "Vet" },
                { label: "Other", value: "Other" },
              ]}
              defaultValue={createUserType}
            />
          </Grid>
          {createUserType === "Other" ? (
            <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
              <div className="txt-mont fs14 fw-600 ">Salutation</div>
              <Select
                list={salutationList}
                value={vetValues?.salutation}
                handleChange={(e, value) => handleSelect(value, "salutation")}
                name="salutation"
                search
                error={vetErrors?.salutation}
                helperText={vetHelps?.salutation}
              />
            </Grid>
          ) : null}
          <Grid
            item
            xs={createUserType === "Other" ? 8 : 6}
            sm={createUserType === "Other" ? 8 : 6}
            md={createUserType === "Other" ? 9 : 6}
            lg={createUserType === "Other" ? 9 : 6}
            xl={createUserType === "Other" ? 9 : 6}
          >
            <div className="txt-mont fs14 fw-600 ">
              Name <span className="red-color">*</span>
            </div>
            <CustomTextField
              name="name"
              fullWidth
              handleChange={handleChange}
              value={vetValues?.name}
              helperText={vetHelps?.name}
              error={vetErrors?.name}
            />
          </Grid>
          {createUserType === "Other" || createUserType === "Vet" ? (
            <>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <div className="txt-mont fs14 fw-600 ">
                  Role <span className="red-color">*</span>
                </div>
                <Select
                  list={
                    createUserType === "Vet" ? newRoleList : clinicRolesList
                  }
                  value={vetValues?.role}
                  handleChange={(e, value) => handleSelect(value, "role")}
                  search
                  error={vetErrors?.role}
                  helperText={vetHelps?.role}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <div className="txt-mont fs14 fw-600 ">
                  Access <span className="red-color">*</span>
                </div>
                <Select
                  list={accessList}
                  value={vetValues?.access}
                  handleChange={(e) => handleMultiSelect(e, "access")}
                  multiSelectTagCheck
                  error={vetErrors?.access}
                  helperText={vetHelps?.access}
                />
              </Grid>
            </>
          ) : null}
          {createUserType === "Vet" ? (
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <div className="txt-mont fs14 fw-600 ">
                Speciality <span className="red-color">*</span>
              </div>
              <Select
                list={specialtyList}
                value={vetValues?.speciality}
                handleChange={(e, value) => handleSelect(value, "speciality")}
                name="speciality"
                search
                error={vetErrors?.speciality}
                helperText={vetHelps?.speciality}
              />
            </Grid>
          ) : null}
          {createUserType === "Vet" ? (
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <div className="txt-mont fs14 fw-600">
                Consultation Type <span className="red-color">*</span>
              </div>

              <Select
                list={typeList}
                value={vetValues?.conType}
                handleChange={(e) => handleMultiSelect(e, "conType")}
                multiSelectTagCheck
                error={vetErrors?.conType}
                helperText={vetHelps?.conType}
              />
            </Grid>
          ) : null}

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className="txt-mont fs14 fw-600 ">Phone Number</div>
            <CustomTextField
              value={vetValues?.contactNumber}
              handleChange={(e) =>
                handleSelect(e?.target?.value, "contactNumber")
              }
              name="contactNumber"
              error={vetErrors?.contactNumber}
              fullWidth
              helperText={vetHelps?.contactNumber}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className="txt-mont fs14 fw-600 ">Registration No.</div>
            <CustomTextField
              value={vetValues?.registrationNo}
              handleChange={(e) =>
                handleSelect(e?.target?.value, "registrationNo")
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="txt-mont fs14 fw-600">
              Email <span className="red-color">*</span>
            </div>
            <CustomTextField
              name="email"
              fullWidth
              handleChange={handleChange}
              value={vetValues?.email}
              helperText={vetHelps?.email}
              error={vetErrors?.email}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="txt-mont fs14 fw-600 ">
              Password <span className="red-color">*</span>
            </div>
            <CustomTextField
              name="password"
              fullWidth
              handleChange={handleChange}
              value={vetValues?.password}
              helperText={vetHelps?.password}
              error={vetErrors?.password}
              showPassword={showPassword}
              handleClickShowPassword={() => setShowPassword(!showPassword)}
              password
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Checkbox
              label="Auto-Generate Password"
              checked={isAutoGenPass}
              onChange={handleAutoGenPassword}
            />
          </Grid>
          <div className="flex1-end">
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <CustomButton
                text="Register"
                onClick={createUserType === "Vet" ? createVet : createOther}
              />
            </Grid>
          </div>
        </Grid>
      </CustomModal>
    </>
  );
};

export default Vets;
