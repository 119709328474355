import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Custom3dotString from "../../../components/Custom3dotString";
import CustomButton from "../../../components/CustomButton";
import CustomCheckbox from "../../../components/CustomCheckbox";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CustomSwitch from "../../../components/CustomSwitch";
import CustomTextField from "../../../components/CustomTextField";
import CustomUpload from "../../../components/CustomUpload";
import Select from "../../../components/Select/Select";
import {
  addTaskByPatientId,
  dischargePatientId,
  getClinicDashboardData,
  getClinicInpatients,
  getClinicStocks,
  getServices,
  updatePatientTaskStatusById,
} from "../../../redux/reducers/clinicSlice";
import { storeInpatientScreenDetails } from "../../../redux/reducers/mixedSlice";
import { updateDiagnostics } from "../../../redux/reducers/petSlice";
import { getClinicVets } from "../../../redux/reducers/vetSlice";
import { AppColors } from "../../../util/AppColors";
import { preventiveTypes } from "../../../util/arrayList";
import { taskCategory } from "../../../util/dropList";
import {
  createTimeSlots,
  datePlusOneDay,
  getDateAndTimeList,
  getDateFormat,
  getTimeFormat,
  getTodayWithYMDFormat,
  isTimeBetween,
} from "../../../util/function";
import FilesWithCloseButton from "../ClinicPetDetails/PatientWorkbook/FilesWithCloseButton";

const initialValues = {
  category: null,
  task: "",
  assignTo: null,
  startDate: new Date(),
  startTime: new Date(),
  repeatTask: false,
  endDate: new Date(),
  endTime: new Date(),
  noOfTimes: "",
  serviceId: null,
  productId: null,
};
const initialErrors = {
  category: false,
  task: false,
  assignTo: false,
  startDate: false,
  startTime: false,
  repeatTask: false,
  endDate: false,
  endTime: false,
  noOfTimes: false,
};
const initialHelpers = {
  category: "",
  task: "",
  assignTo: "",
  startDate: "",
  startTime: "",
  endDate: "",
  endTime: "",
  repeatTask: "",
  noOfTimes: "",
};
const nameExpan = {
  category: "Category",
  task: "Task",
  assignTo: "Assign To",
  startDate: "Start Date",
  startTime: "Start Time",
  repeatTask: "Repeat Task",
  endDate: "End Date",
  endTime: "End Time",
  noOfTimes: "Number of Times",
};

const initialPrevValues = {
  actionType: "Administer",
  type: preventiveTypes?.[2],
  name: "",
  stockId: null,
  batchNo: null,
  dueDate: new Date(),
  remarks: "",
  files: [],
  nextDosage: "No",
  nextDueDate: datePlusOneDay,
};

const tableHeaders = ["petDetails", ""];

const Inpatient = ({ setSelectedTab, selectedVet }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEls, setAnchorEls] = useState({});
  const [modOpen, setModOpen] = useState(false);
  const [taskValues, setTaskValues] = useState(initialValues);
  const [taskErrors, setTaskErrors] = useState(initialErrors);
  const [taskHelpers, setTaskHelpers] = useState(initialHelpers);
  const [tableList, setTableList] = useState([]);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [vets, setVets] = useState([]);
  const [timeList, setTimeList] = useState([]);
  const inpatients = useSelector((state) => state?.clinic?.inpatients);
  const clinicVets = useSelector((state) => state?.vet?.vets);
  const [disCofrmModVsble, setDisConfrmModVsble] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [updateRecModVsble, setUpdateRecModVsble] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [files, setFiles] = useState([]);
  const [prevModVsble, setPrevModVsble] = useState(false);
  const [preventiveValues, setPreventiveValues] = useState(initialPrevValues);
  const [batchError, setBatchError] = useState(false);
  const services = useSelector((state) => state?.clinic?.services);
  const stocks = useSelector((state) => state?.clinic?.clinicStocks);
  const defaultUrl = "?type=all";

  useEffect(() => {
    getTimeList();
    dispatch(getClinicStocks());
    dispatch(getClinicVets());
    dispatch(getServices());
  }, []);

  useEffect(() => {
    let url = defaultUrl;
    if (selectedVet !== "All") url = `?type=doctor&doctorId=${selectedVet}`;
    dispatch(getClinicInpatients(url));
    dispatch(storeInpatientScreenDetails({ selectedVet }));
  }, [selectedVet]);

  useEffect(() => {
    getTableList();
  }, [inpatients]);

  useEffect(() => {
    const reqVetList = clinicVets
      ?.filter((vl) => {
        if (vl?.userType?.find((ut) => ut?.name === "ROLE_DOCTOR")) {
          return vl;
        }
      })
      ?.map((vet) => {
        return { name: vet?.name, value: vet?.doctorId, id: vet?.doctorId };
      });
    setVets(reqVetList);
  }, [clinicVets]);

  const getTimeList = async () => {
    const reqTimes = await createTimeSlots("00:00", "23:59", 60);
    setTimeList(reqTimes);
  };

  const getTableList = () => {
    const reqList = inpatients?.data
      ?.filter((inpa) => !inpa?.discharge)
      ?.map((inp) => ({
        ...inp,
        petDetails: {
          petImage: inp?.petImage,
          petName: inp?.petName,
          gender: inp?.gender,
          breed: inp?.breed,
          kennelNumber: inp?.kennelNumber,
        },
        symptoms: inp?.remark,
        vet: {
          doctorName: inp?.doctorName,
          doctorSpeciality: inp?.doctorSpeciality,
        },
        toDoList: inp?.tasks,
      }));
    setTableList(reqList);
  };

  const handleClose = (id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: false }));
  };

  const handleClick = (id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: true }));
  };

  const handleModClose = () => {
    setModOpen(false);
    setSelectedDetails(null);
    setTaskValues(initialValues);
    setTaskErrors(initialErrors);
    setTaskHelpers(initialHelpers);
  };

  const handleChange = (name, value, addObj) => {
    const reqObj = { ...taskValues, [name]: value };
    if (name === "task" && addObj) {
      reqObj.stockId = addObj?.stockId;
      reqObj.productId = addObj?.productId;
      reqObj.serviceId = addObj?.serviceId;
    }
    setTaskValues(reqObj);

    setTaskErrors({
      ...taskErrors,
      [name]: value !== "" && value !== null ? false : true,
    });
    setTaskHelpers({
      ...taskHelpers,
      [name]:
        value !== "" && value !== null
          ? ""
          : `${nameExpan?.[name]} is required`,
    });
  };

  const handleAddTask = (itm, i) => {
    handleClose(i);
    setSelectedDetails(itm);
    setModOpen(true);
  };

  const handleDischarge = (itm, i) => {
    handleClose(i);
    setSelectedPatientId(itm?.patientId);
    setDisConfrmModVsble(true);
  };

  const handleValidation = () => {
    let errs = {};
    let helps = {};
    Object.keys(taskValues).forEach((det) => {
      if (
        taskValues?.[det] === null ||
        taskValues?.[det] === "" ||
        taskValues?.[det]?.length === 0 ||
        !taskValues?.[det]
      ) {
        if (
          det === "stockId" &&
          (taskValues?.category?.value === "Diagnostics" ||
            taskValues?.category?.value === "Others")
        ) {
          return;
        }
        errs = {
          ...errs,
          [det]: true,
        };
        helps = {
          ...helps,
          [det]: `${nameExpan?.[det]} is required field`,
        };
      }
      delete errs.repeatTask;
      delete errs.serviceId;
      delete errs.productId;

      if (!taskValues?.repeatTask) {
        delete errs.noOfTimes;
        helps.noOfTimes = "";
      }
      setTaskErrors({ ...taskErrors, ...errs });
      setTaskHelpers({ ...taskHelpers, ...helps });
    });
    return Object.keys(errs).length > 0 ? true : false;
  };

  const handleSaveTask = async () => {
    const isNotValid = handleValidation();
    if (isNotValid) return;
    let tasks = [];
    if (taskValues?.repeatTask) {
      const reqDateAndTimes = getDateAndTimeList(
        taskValues?.startDate,
        taskValues?.endDate,
        taskValues?.startTime,
        taskValues?.endTime,
        taskValues?.noOfTimes
      );

      reqDateAndTimes?.filter((dt) => {
        const reqTask = {
          category: taskValues?.category?.value,
          task: taskValues?.task,
          startDate: dt?.startDate,
          startTime: dt?.startTime,
          repeatTask: taskValues?.repeatTask,
          doctorId: taskValues?.assignTo?.value,
          endDate: dt?.startDate,
          endTime: dt?.endTime,
          times: taskValues?.noOfTimes,
        };
        if (taskValues?.category?.value !== "Others") {
          if (taskValues?.serviceId) reqTask.serviceId = taskValues?.serviceId;
          if (taskValues?.productId) reqTask.productId = taskValues?.productId;
          if (taskValues?.stockId) reqTask.stockId = taskValues?.stockId;
        }
        tasks = [...tasks, reqTask];
      });
    } else {
      const reqTask = {
        category: taskValues?.category?.value,
        task: taskValues?.task,
        startDate: getDateFormat(taskValues?.startDate),
        startTime: getTimeFormat(taskValues?.startTime),
        repeatTask: taskValues?.repeatTask,
        doctorId: taskValues?.assignTo?.value,
      };
      if (taskValues?.category?.value !== "Others") {
        if (taskValues?.serviceId) reqTask.serviceId = taskValues?.serviceId;
        if (taskValues?.productId) reqTask.productId = taskValues?.productId;
        if (taskValues?.stockId) reqTask.stockId = taskValues?.stockId;
      }
      tasks = [reqTask];
    }
    if (tasks?.length === 0) return;
    dispatch(
      addTaskByPatientId({
        patientId: selectedDetails?.patientId,
        data: { tasks },
      })
    ).then((res) => {
      if (res?.payload) {
        dispatch(getClinicInpatients(defaultUrl));
        handleModClose();
      }
    });
  };

  const updatePatientTaskById = (itm, status, apntId) => {
    if (
      itm?.category === "Deworming" ||
      itm?.category === "Flea & Tick Treatment" ||
      itm?.category === "Vaccines"
    ) {
      setPreventiveValues({
        ...preventiveValues,
        type:
          itm?.category === "Vaccines"
            ? preventiveTypes?.[2]
            : itm?.category === "Flea & Tick Treatment"
            ? preventiveTypes?.[1]
            : preventiveTypes?.[0],
        name: itm?.task,
        stockId: itm?.stockId,
        dueDate: new Date(itm?.startDate),
        remarks: itm?.remark ?? "",
      });
      setSelectedRecord({ ...itm, appointmentId: apntId });
      setPrevModVsble(true);
      return;
    }
    dispatch(
      updatePatientTaskStatusById({ taskId: itm?.id, url: `?status=${status}` })
    ).then((res) => {
      if (res?.payload) {
        if (itm?.category === "Diagnostics") {
          setSelectedRecord({ ...itm, appointmentId: apntId });
          setUpdateRecModVsble(true);
        }
        //to get the updated list
        dispatch(getClinicInpatients(defaultUrl));
      }
    });
  };

  const handleCloseDisCnfrm = () => {
    setSelectedPatientId(null);
    setDisConfrmModVsble(false);
  };

  const handleConfirmDischarge = () => {
    if (selectedPatientId) {
      dispatch(dischargePatientId(selectedPatientId)).then((res) => {
        if (res?.payload) {
          handleCloseDisCnfrm();
          dispatch(getClinicDashboardData({ type: "Outpatient" }));
          setSelectedTab("Outpatient");
        }
      });
    }
  };

  const getPreventiveTypeBasedList = () => {
    const reqList = stocks?.data?.filter((dta) => {
      return (
        dta?.stockBatch?.find((btch) => btch?.quantity > 0) &&
        dta?.category?.toLowerCase() ===
          (preventiveValues?.type?.value === "vaccination"
            ? "vaccines"
            : preventiveValues?.type?.value === "fleaTreatment"
            ? "flea & tick Treatment"
            : preventiveValues?.type?.value?.toLowerCase())
      );
    });
    return reqList?.map((li) => ({
      name: li?.tradeName,
      value: li?.tradeName,
      stockId: li?.stockId,
    }));
  };

  const getTypeBasedList = (typ) => {
    if (typ === "Others") return [];
    if (typ === "Diagnostics") {
      return services?.data
        ?.filter((ser) => ser?.category === typ)
        ?.map((dl) => ({
          ...dl,
          name: dl?.serviceName,
          value: dl?.serviceName,
        }));
    }
    const reqList = stocks?.data?.filter((dta) => {
      return dta?.stockBatch?.find((btch) => btch?.quantity > 0) &&
        typ === "Medication"
        ? dta?.category?.toLowerCase() === typ?.toLowerCase() ||
            dta?.category?.toLowerCase() === "nutrition and supplements"
        : dta?.category?.toLowerCase() === typ?.toLowerCase();
    });
    return reqList?.map((li) => ({
      name: li?.tradeName,
      value: li?.tradeName,
      stockId: li?.stockId,
    }));
    // if (typ === "Medication") {
    //   return products?.data
    //     ?.filter(
    //       (prd) =>
    //         prd?.category === "Nutrition and Supplements" ||
    //         prd?.category === typ
    //     )
    //     ?.map((pd) => ({
    //       ...pd,
    //       value: pd?.name,
    //     }));
    // }
    // return products?.data
    //   ?.filter((prd) => prd?.category === typ)
    //   ?.map((pd) => ({
    //     ...pd,
    //     value: pd?.name,
    //   }));
  };

  const handleImageRemove = (ind) => {
    const reqFiles = files?.filter((fl, i) => i !== ind);
    setFiles(reqFiles);
  };

  const onUploadFile = (e) => {
    const fileList = e?.target?.files;
    let fileLists = files?.length > 0 ? [...files] : [];

    const readFiles = Array.from(fileList).map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          fileLists = [
            ...fileLists,
            {
              file: file,
              previewUrl: reader.result,
            },
          ];
          resolve();
        };
        reader.onerror = () => reject(reader.error);
        reader.readAsDataURL(file);
      });
    });

    Promise.all(readFiles)
      .then(() => setFiles(fileLists))
      .catch((error) => console.error("Error reading files: ", error));
  };

  const onUpdateModClose = () => {
    setUpdateRecModVsble(false);
    setSelectedRecord(null);
    setFiles(null);
    setRemarks("");
  };

  const handleSave = () => {
    const form = new FormData();
    form.append("type", selectedRecord?.task);
    form.append(
      "dueDate",
      moment(new Date(selectedRecord?.startDate)).format("YYYY-MM-DD")
    );
    form.append("remark", remarks);
    form.append("status", "File");
    form.append("inpatient", true);
    form.append("appId", selectedRecord?.appointmentId);
    if (selectedRecord?.serviceId) {
      form.append("serviceId", selectedRecord?.serviceId);
    }
    if (files?.length > 0) {
      files?.forEach((fle) => {
        form.append("file", fle?.file ?? fle?.previewUrl);
      });
    }
    if (selectedRecord?.category === "Diagnostics") {
      const metaUpdate = { diagnosticsId: selectedRecord?.diagonosticId, form };
      dispatch(updateDiagnostics(metaUpdate)).then((res) => onUpdateModClose());
    }
  };

  const getBatchList = () => {
    if (!selectedRecord?.stockId) return [];
    const reqStock = stocks?.data?.find(
      (stc) => stc?.stockId === selectedRecord?.stockId
    );
    const filteredBatches = reqStock?.stockBatch
      ?.filter((btch) => btch?.quantity > 0)
      ?.map((btch) => ({
        name: btch?.batchNo,
        value: btch?.batchNo,
        batchId: btch?.batchId,
      }));
    return filteredBatches;
  };

  const handleChangeValues = (name, value, addObj) => {
    const reqObj = { ...preventiveValues, [name]: value };
    if (name === "type") reqObj.name = null;
    if (name === "name" && addObj) {
      reqObj.stockId = addObj?.stockId;
    }
    if (name === "batchNo" && addObj) {
      reqObj.batchId = addObj?.batchId;
      setBatchError(value ? false : true);
    }
    if (addObj?.productId) reqObj.productId = addObj?.productId;
    if (addObj?.serviceId) reqObj.serviceId = addObj?.serviceId;
    setPreventiveValues(reqObj);
  };

  const handleClosePrevMod = () => {
    setPrevModVsble(false);
    setPreventiveValues(initialPrevValues);
    setBatchError(false);
    setSelectedRecord(null);
  };

  const handleSavePreventive = () => {
    if (!preventiveValues?.batchId) return setBatchError(true);
    dispatch(
      updatePatientTaskStatusById({
        taskId: selectedRecord?.id,
        url: `?status=${true}&stockId=${selectedRecord?.stockId}&batchId=${
          preventiveValues?.batchId
        }`,
      })
    ).then((res) => {
      if (res?.payload) {
        //to get the updated list
        dispatch(getClinicInpatients(defaultUrl));
        handleClosePrevMod();
      }
    });
  };

  return (
    <>
      <Grid container className="ph2">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="mt3 back-white"
        >
          <TableContainer className="inpat-tbl-hgt">
            <Table
              sx={{ minWidth: 200 }}
              aria-labelledby="tableTitle"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {tableHeaders?.map((hl, i) => (
                    <>
                      <TableCell
                        key={i}
                        style={
                          i === 0
                            ? {
                                backgroundColor: AppColors.tableGrey,
                                color: AppColors.white,
                                textAlign: "left",
                                position: "sticky",
                                left: 0,
                                zIndex: 105,
                                paddingLeft: 20,
                              }
                            : {
                                backgroundColor: AppColors.tableGrey,
                                color: AppColors.white,
                                textAlign: "center",
                              }
                        }
                        sortDirection={false}
                        className={`table-header-text capitalize `}
                      >
                        {hl === "petDetails" ? (
                          <div className="flex-center" style={{ width: 450 }}>
                            Pet Details
                          </div>
                        ) : (
                          <div className="flex-row-ali-cen mhMin16">
                            {timeList?.length > 0 &&
                              timeList?.map((tm) => (
                                <div style={{ width: 150 }}>
                                  <Typography className="header fs12">
                                    {tm}
                                  </Typography>
                                </div>
                              ))}
                          </div>
                        )}
                      </TableCell>
                    </>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableList?.map((tl, i) => (
                  <TableRow tabIndex={-1}>
                    <TableCell
                      component="th"
                      className="tbl-brdr"
                      style={{
                        textAlign: "left",
                        position: "sticky",
                        background: AppColors.white,
                        left: 0,
                        zIndex: 100,
                      }}
                    >
                      <div style={{ width: 450 }}>
                        <div className="flex-row-ali-cen">
                          <div
                            className="w60Per cursor"
                            onClick={() => {
                              return navigate("/clinic-pet-details", {
                                state: {
                                  selectedTab: "admissionDetails",
                                  appointment: {
                                    appoinment: {
                                      appoimentId: tl?.appointmentId,
                                      petId: tl?.petId,
                                    },
                                  },
                                  inpatientId: tl?.patientId,
                                },
                              });
                            }}
                          >
                            <div className="flex-row-ali-cen">
                              <div className="flex-start">
                                {tl?.petImage ? (
                                  <img
                                    src={tl?.petImage}
                                    alt=""
                                    className="pet-card-img"
                                  />
                                ) : (
                                  <div className="flex-center tbl-emp-img2">
                                    <Typography className="font-bold fs30 white-color capitalize">
                                      {tl?.petName?.[0]}
                                    </Typography>
                                  </div>
                                )}
                                <div className="ml10 flex-column">
                                  <div className="flex-row">
                                    <Custom3dotString
                                      str={tl?.petName}
                                      className="black6 txt-semi-bold fs16"
                                      length={12}
                                    />
                                    <Typography
                                      className={`capitalize header fs16 ml5 ${
                                        tl?.gender === "male"
                                          ? "card-blue-color"
                                          : "card-rose-color"
                                      }`}
                                    >
                                      ({tl?.gender})
                                    </Typography>
                                  </div>
                                  <Typography className="txt-regular black6 fs14">
                                    {tl?.breed}
                                  </Typography>
                                </div>
                              </div>
                              <div className="flex1-end pr10">
                                <div className="ver-bar h50" />
                              </div>
                            </div>
                          </div>
                          <div className="w40Per">
                            <Typography className="header red6 fs12">
                              Kennel Number: {tl?.kennelNumber}
                            </Typography>
                            <Typography className="black6 txt-semi-bold fs12 mt5">
                              Attended by Dr. {tl?.doctorName}
                            </Typography>
                            <div
                              style={{ position: "absolute", top: 2, right: 7 }}
                            >
                              <MoreVertIcon
                                className="card-3dot-color"
                                onClick={() => {
                                  Boolean(anchorEls?.[i])
                                    ? handleClose(i)
                                    : handleClick(i);
                                }}
                                size="small"
                                sx={{
                                  ml: 2,
                                  cursor: "pointer",
                                  transition: "color 0.3s ease",
                                  ":hover": { color: "#1976d2" },
                                }}
                                aria-controls={
                                  anchorEls?.[i] ? "account-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={
                                  anchorEls?.[i] ? "true" : undefined
                                }
                              />
                              {Boolean(anchorEls?.[i]) ? (
                                <div className="menu-list">
                                  <Typography
                                    className="font-medium fs14 ph10 pv5 cursor"
                                    onClick={() => handleAddTask(tl, i)}
                                  >
                                    Add Task
                                  </Typography>
                                  <Divider />
                                  <Typography
                                    className="font-medium fs14 ph10 pv5 cursor"
                                    onClick={() => handleDischarge(tl, i)}
                                  >
                                    Discharge
                                  </Typography>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="flex-row-ali-cen mt10 ipat-pet-det">
                          <div className="w90Per">
                            <Typography className="gray12 txt-semi-bold fs10">
                              {tl?.remark}
                            </Typography>
                          </div>

                          <div className="flex1-end">
                            {tl?.tasks
                              ?.filter(
                                (tsk) =>
                                  tsk?.startDate === getTodayWithYMDFormat()
                              )
                              ?.reduce((acc, obj) => {
                                return obj.status === false ? acc + 1 : acc;
                              }, 0) > 0 && (
                              <div className="flex-row-ali-cen">
                                <img
                                  src={require("../../../assets/images/png/dangerInfo.png")}
                                  alt=""
                                  className="img-wh20"
                                />
                                <div className="inpat-count">
                                  {tl?.tasks
                                    ?.filter(
                                      (tsk) =>
                                        tsk?.startDate ===
                                        getTodayWithYMDFormat()
                                    )
                                    ?.reduce((acc, obj) => {
                                      return obj.status === false
                                        ? acc + 1
                                        : acc;
                                    }, 0)}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    <div className="flex-row-ali-cen tbl-brdr3">
                      {timeList?.length > 0 &&
                        timeList?.map((tm, timInd) => {
                          const timeMatch = tl?.tasks?.filter(
                            (ap) =>
                              ap?.startDate === getTodayWithYMDFormat() &&
                              isTimeBetween(ap?.startTime, tm)
                          );
                          return (
                            <TableCell
                              component="th"
                              className={`flex-center ${
                                timInd === 0 ? "tbl-brdr4" : "tbl-brdr2 "
                              }`}
                              style={{ width: 150 }}
                            >
                              {timeMatch?.length > 0 ? (
                                <div className="flex-column">
                                  {timeMatch?.map((tmt) => {
                                    const [hour, min] =
                                      tmt?.startTime?.split(":");
                                    const reqDate = new Date(
                                      new Date(tmt?.startDate).setHours(
                                        hour,
                                        min
                                      )
                                    );

                                    const isTimeExceed =
                                      new Date().getTime() > reqDate.getTime();

                                    return (
                                      <div className="flex-row-ali-cen">
                                        <CustomSwitch
                                          value={tmt?.status}
                                          onChange={(e) =>
                                            updatePatientTaskById(
                                              tmt,
                                              !tmt?.status,
                                              tl?.appointmentId
                                            )
                                          }
                                          grayRedGreen
                                          red={isTimeExceed}
                                          disabled={tmt?.status}
                                        />
                                        <Custom3dotString
                                          str={tmt?.task}
                                          className="txt-regular fs14 black6 ml5"
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <div className="flex-row-ali-cen"></div>
                              )}
                            </TableCell>
                          );
                        })}
                    </div>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {tableList?.length === 0 && (
            <div className="flex-center h100">
              <Typography className="txt-regular">
                No records available
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>

      <CustomModal
        open={modOpen}
        onClose={handleModClose}
        header="Add Task"
        rightModal
        modalWidth={30}
      >
        <div className="p20 scroll-80vh">
          <div className="inpat-border">
            <div className="flex-row-ali-cen">
              <div className="w75Per">
                <div className="flex-row-ali-cen">
                  {selectedDetails?.petImage ? (
                    <img
                      src={selectedDetails?.petImage}
                      alt=""
                      className="pet-card-img"
                    />
                  ) : (
                    <div className="flex-center tbl-emp-img2">
                      <Typography className="font-bold fs30 white-color capitalize">
                        {selectedDetails?.petName?.[0]}
                      </Typography>
                    </div>
                  )}
                  <div className="ml10">
                    <div className="flex-column">
                      <div className="flex-row-ali-cen">
                        <Typography className="txt-semi-bold card-black fs14">
                          {selectedDetails?.petName}
                        </Typography>
                        <Typography
                          className={`capitalize header fs14 ml5 ${
                            selectedDetails?.gender === "male"
                              ? "card-blue-color"
                              : "card-rose-color"
                          }`}
                        >
                          ({selectedDetails?.gender})
                        </Typography>
                      </div>
                      <Typography className="card-black1 fs12 txt-regular">
                        {selectedDetails?.breed}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w2Per">
                <div className="ver-bar h50" />
              </div>
              <div className="flex1-end">
                <div className="flex1-end">
                  <div className="flex-column">
                    <div className="flex1-end">
                      <Typography className="txt-semi-bold fs12 black7">
                        {moment(selectedDetails?.dob).fromNow()}
                      </Typography>
                    </div>
                    <div className="flex1-end">
                      <div className="flex-row-ali-cen mt5">
                        <img
                          src={require("../../../assets/images/png/weight.png")}
                          alt=""
                          className="img-wh15"
                        />
                        <Typography className="txt-semi-bold fs12 card-black ml5">
                          {selectedDetails?.weight} kg
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-row-ali-cen mt10">
              <div className="flex-row-ali-cen">
                <AccountCircleOutlinedIcon sx={{ width: 20, height: 20 }} />
                <Typography className="fs12 txt-semi-bold card-black ml5">
                  {selectedDetails?.userName}
                </Typography>
              </div>
              <div className="flex1-end">
                <Typography className="card-black1 fs12 txt-semi-bold">
                  Dr. {selectedDetails?.doctorName}
                </Typography>
              </div>
            </div>
          </div>
          <div className="inpat-blue-back mt20">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="txt-mont fs14">
                  Category <span className="red-color">*</span>
                </div>
                <Select
                  list={taskCategory}
                  value={taskValues?.category}
                  handleChange={(e, value) => handleChange("category", value)}
                  search
                  error={taskErrors?.category}
                  helperText={taskHelpers?.category}
                  backgroundColor={AppColors.white}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="txt-mont fs14">
                  Task <span className="red-color">*</span>
                </div>
                {taskValues?.category?.value === "Others" ? (
                  <CustomTextField
                    fullWidth
                    handleChange={(e) => {
                      handleChange("task", e?.target?.value);
                    }}
                    value={taskValues?.task}
                    helperText={taskHelpers?.task}
                    error={taskErrors?.task}
                  />
                ) : (
                  <Select
                    list={getTypeBasedList(taskValues?.category?.value)}
                    value={taskValues?.task}
                    error={taskErrors?.task}
                    helperText={taskHelpers?.task}
                    addNewSelect
                    handleChange={(e) => {
                      handleChange("task", e?.value ?? e, {
                        stockId: e?.stockId ?? null,
                        productId: e?.productId ?? null,
                        serviceId: e?.serviceId ?? null,
                      });
                    }}
                    backgroundColor={AppColors.white}
                  />
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="txt-mont fs14 mt10">
                Assign to <span className="red-color">*</span>
              </div>
              <Select
                list={vets}
                value={taskValues?.assignTo}
                handleChange={(e, value) => handleChange("assignTo", value)}
                search
                error={taskErrors?.assignTo}
                helperText={taskHelpers?.assignTo}
                backgroundColor={AppColors.white}
              />
            </Grid>

            <div className="flex-row-ali-cen mt10">
              <div className="w50Per pr10">
                <div className="txt-mont fs14">
                  Start Date <span className="red-color">*</span>
                </div>
                <CustomTextField
                  fullWidth
                  handleChange={(e) => {
                    handleChange("startDate", e?.target?.value);
                  }}
                  value={taskValues?.startDate}
                  helperText={taskHelpers?.startDate}
                  error={taskErrors?.startDate}
                  mobileDate
                  placeholder={nameExpan?.startDate}
                />
              </div>
              <div className="w50Per pl10">
                <div className="txt-mont fs14">
                  Start Time <span className="red-color">*</span>
                </div>
                <CustomTextField
                  fullWidth
                  handleChange={(e) =>
                    handleChange("startTime", e?.target?.value)
                  }
                  value={taskValues?.startTime}
                  helperText={taskHelpers?.startTime}
                  error={taskErrors?.startTime}
                  mobileTime
                  is12HourFomat={false}
                  placeholder={nameExpan?.startTime}
                  backgroundColor={AppColors.white}
                />
              </div>
            </div>
            <div className="flex-row-ali-cen mt10">
              <div className="w50Per pr10">
                <div className="flex-row-ali-cen mt10">
                  <Typography className="txt-semi-bold fs12 mark-balck">
                    Repeat Task?
                  </Typography>
                  <div className="ml15 mt5">
                    <CustomSwitch
                      value={taskValues?.repeatTask}
                      onChange={(e) =>
                        handleChange("repeatTask", !taskValues?.repeatTask)
                      }
                      greenToGray
                    />
                  </div>
                </div>
              </div>
              {taskValues?.repeatTask && (
                <div className="w50Per pl10">
                  <div className="txt-mont fs14">
                    Repeat Interval (in hrs){" "}
                    <span className="red-color">*</span>
                  </div>
                  <CustomTextField
                    fullWidth
                    handleChange={(e) =>
                      handleChange("noOfTimes", e?.target?.value)
                    }
                    value={taskValues?.noOfTimes}
                    helperText={taskHelpers?.noOfTimes}
                    error={taskErrors?.noOfTimes}
                  />
                </div>
              )}
            </div>

            {taskValues?.repeatTask ? (
              <>
                <div className="flex-row-ali-cen mt10">
                  <div className="w50Per pr10">
                    <div className="txt-mont fs14">
                      End Date <span className="red-color">*</span>
                    </div>
                    <CustomTextField
                      fullWidth
                      handleChange={(e) => {
                        handleChange("endDate", e?.target?.value);
                      }}
                      value={taskValues?.endDate}
                      helperText={taskHelpers?.endDate}
                      error={taskErrors?.endDate}
                      mobileDate
                      placeholder={nameExpan?.endDate}
                    />
                  </div>
                  <div className="w50Per pl10">
                    <div className="txt-mont fs14">
                      End Time <span className="red-color">*</span>
                    </div>
                    <CustomTextField
                      fullWidth
                      handleChange={(e) =>
                        handleChange("endTime", e?.target?.value)
                      }
                      value={taskValues?.endTime}
                      helperText={taskHelpers?.endTime}
                      error={taskErrors?.endTime}
                      mobileTime
                      is12HourFomat={false}
                      placeholder={nameExpan?.endTime}
                      backgroundColor={AppColors.white}
                    />
                  </div>
                </div>
                <div className="flex-row-ali-cen mt10"></div>
              </>
            ) : null}
            <div className="flex-row-ali-cen mt20">
              <div className="flex1-end">
                <div>
                  <CustomButton smallBtn text="Save" onClick={handleSaveTask} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={disCofrmModVsble}
        onClose={handleCloseDisCnfrm}
        header="Discharge Pet"
        modal
        modalWidth={40}
      >
        <Typography className="txt-regular fs16 ml10">
          Are you sure you want to Discharge the patient?
        </Typography>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className="clinic-mod-btn-pos mb10 mt15">
            <div className="mr10">
              <CustomButton
                text="Yes"
                redBtn
                onClick={handleConfirmDischarge}
              />
            </div>
            <div className="ml10">
              <CustomButton text="No" onClick={handleCloseDisCnfrm} />
            </div>
          </div>
        </Grid>
      </CustomModal>
      <CustomModal
        open={updateRecModVsble}
        onClose={onUpdateModClose}
        header="Update"
        rightModal
        modalWidth={30}
      >
        <div className="scroll-80vh">
          <Grid container spacing={2} className="ph20 mt10">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomTextField
                label="Select Type"
                fullWidth
                value={selectedRecord?.category}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomTextField
                label="Name"
                fullWidth
                value={selectedRecord?.task}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomTextField
                label="Due Date"
                fullWidth
                value={selectedRecord?.startDate}
                mobileDate
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomTextField
                label="Remarks"
                placeholder="Remarks"
                name="remarks"
                fullWidth
                handleChange={(e) => setRemarks(e?.target?.value)}
                value={remarks}
                multiline
              />
            </Grid>

            {files?.length > 0 ? (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FilesWithCloseButton
                  files={files}
                  onClickFile={(i, file) => handleImageRemove(i)}
                />
              </Grid>
            ) : null}

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomUpload
                onUploadFile={onUploadFile}
                multipleNew
                multiFiles
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="flex1-center">
                <div>
                  <CustomButton
                    text="Mark as complete"
                    onClick={handleSave}
                    tabSelectdBtn
                    noBgbtn
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </CustomModal>

      <CustomModal
        open={prevModVsble}
        onClose={handleClosePrevMod}
        header="Update"
        rightModal
        modalWidth={30}
      >
        <div className="scroll-80vh ph20">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="flex-row-ali-cen">
                <Typography className="font-medium fs14">
                  Select your action
                </Typography>
                <span className="red-color ml2"> *</span>
              </div>
              <CustomCheckbox
                radio
                name="actionType"
                radios={[
                  { label: "Schedule", value: "Schedule" },
                  { label: "Administer", value: "Administer" },
                ]}
                defaultValue={preventiveValues?.actionType}
                disabled
              />
              <div className="mbminus" />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Select
                list={preventiveTypes}
                value={preventiveValues?.type}
                name="type"
                label={nameExpan?.["type"]}
                select
                mandatoryField
                handleChange={(e, value) => handleChangeValues("type", value)}
                disabled
                search
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Select
                list={getPreventiveTypeBasedList()}
                value={preventiveValues?.name ? preventiveValues?.name : null}
                name="name"
                label={
                  preventiveValues?.type?.value === "vaccination"
                    ? "Vaccine Name"
                    : preventiveValues?.type?.value === "deworming"
                    ? "Deworming Name"
                    : "Treatment Name"
                }
                mandatoryField
                newSelect
                disabled
              />
            </Grid>
            {preventiveValues?.actionType === "Administer" ? (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Select
                  list={getBatchList()}
                  value={preventiveValues?.batchNo}
                  name="batchNo"
                  label="Batch Number"
                  mandatoryField
                  newSelect
                  handleChange={(e) => {
                    handleChangeValues("batchNo", e?.value, {
                      productId: e?.productId ?? null,
                      serviceId: e?.serviceId ?? null,
                      batchId: e?.batchId,
                    });
                  }}
                  error={batchError}
                  helperText={
                    batchError ? "Batch Number is required field" : ""
                  }
                />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomTextField
                name="dueDate"
                label={nameExpan?.["dueDate"]}
                fullWidth
                handleChange={(e) =>
                  handleChangeValues("dueDate", e?.target?.value)
                }
                value={preventiveValues?.dueDate}
                mobileDate
                mandatoryField
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomTextField
                label={nameExpan?.["remarks"]}
                placeholder="Remarks"
                name="remarks"
                fullWidth
                handleChange={(e) =>
                  handleChangeValues("remarks", e?.target?.value)
                }
                value={preventiveValues?.remarks}
                multiline
                // error={preventiveErrors?.remarks}
                // helperText={preventiveHelpers?.remarks}
              />
            </Grid>
            {preventiveValues?.actionType === "Administer" ? (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="flex-row-ali-cen">
                  <Typography className="font-medium fs14">
                    Schedule for the next dosage?
                  </Typography>
                  <span className="red-color ml2"> *</span>
                </div>
                <CustomCheckbox
                  radio
                  name="nextDosage"
                  onChange={(e) =>
                    handleChangeValues("nextDosage", e?.target?.value)
                  }
                  radios={[
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                  ]}
                  defaultValue={preventiveValues?.nextDosage}
                  disabled
                />
                <div className="mbminus" />
              </Grid>
            ) : null}
            {preventiveValues?.actionType === "Administer" &&
            preventiveValues?.nextDosage === "Yes" ? (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomTextField
                  name="nextDueDate"
                  label={nameExpan?.["nextDueDate"]}
                  fullWidth
                  handleChange={(e) =>
                    handleChangeValues("nextDueDate", e?.target?.value)
                  }
                  value={preventiveValues?.nextDueDate}
                  mobileDate
                  mandatoryField
                  labelTop
                  minDate={datePlusOneDay}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="flex-center">
                <div>
                  <CustomButton
                    text="Save"
                    smallBtn
                    onClick={handleSavePreventive}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
};

export default Inpatient;
