import { Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/CustomButton";
import CustomModal from "../../../components/CustomModal/CustomModal";
import Table from "../../../components/CustomTable";
import CustomTextField from "../../../components/CustomTextField";
import { default as Select } from "../../../components/Select/Select";

import { useDispatch, useSelector } from "react-redux";
import {
  createClinicService,
  editClinicService,
  getServices,
} from "../../../redux/reducers/clinicSlice";
import { hideLoader, showLoader } from "../../../redux/reducers/loaderSlice";
import { AppColors } from "../../../util/AppColors";
import { serviceCategoryList, yesNoValueList } from "../../../util/dropList";

const tableHeaders = [
  "serviceName",
  "category",
  "subCategory",
  "sellingPrice",
  "tax",
  "editButton",
];

const initialValues = {
  category: null,
  subCategory: "",
  serviceName: "",
  taxApplicability: "",
  servicePrice: "",
  tax: "",
  description: "",
};
const initialError = {
  category: false,
  subCategory: false,
  serviceName: false,
  taxApplicability: false,
  servicePrice: false,
  tax: false,
  description: false,
};

const initialHelp = {
  category: "",
  subCategory: "",
  serviceName: "",
  taxApplicability: "",
  servicePrice: "",
  tax: "",
  description: "",
};

const requiredFieldList = [
  "category",
  "subCategory",
  "serviceName",
  "taxApplicability",
  "servicePrice",
  "tax",
];

const Service = () => {
  const dispatch = useDispatch();
  const services = useSelector((state) => state.clinic.services);
  const [serviceValues, setServiceValues] = useState(initialValues);
  const [serviceErrors, setServiceErrors] = useState(initialError);
  const [serviceHelps, setServiceHelps] = useState(initialHelp);
  const [serviceId, setServiceId] = useState(null);
  const [modVisible, setModVisible] = useState(false);
  const [tableSservices, setTableServices] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const defaultUrl = `?page=1&itemSize=${rowsPerPage}&search=${searchValue}`;

  useEffect(() => {
    dispatch(getServices(defaultUrl));
  }, [searchValue]);

  useEffect(() => {
    getSupplierTable();
  }, [services?.data]);

  const getSupplierTable = () => {
    dispatch(showLoader());
    const requiredStock = services?.data?.map((stock, i) => {
      return {
        serviceName: stock?.serviceName,
        sellingPrice: stock?.servicePrice,
        description: stock?.description,
        category: stock?.category,
        subCategory: stock?.subCategory,
        tax: stock?.tax,
        editButton: (
          <div
            onClick={() => handleEditModal(stock?.serviceId)}
            className="blue-color txt-semi-bold cursor "
          >
            <img
              src={require("../../../assets/images/png/edit.png")}
              alt=""
              className="h30"
            />
          </div>
        ),
      };
    });
    dispatch(hideLoader());
    setTableServices(requiredStock);
  };

  const handleOpen = () => {
    setModVisible(true);
  };

  const handleClose = () => {
    setModVisible(false);
    setServiceValues(initialValues);
    setServiceErrors(initialError);
    setServiceHelps(initialHelp);
  };

  const handleSubmit = async () => {
    let errObj = {};
    let hlpObj = {};
    requiredFieldList?.forEach((fld) => {
      if (serviceValues?.[fld]?.length === 0 || !serviceValues?.[fld]) {
        errObj = {
          ...errObj,
          [fld]:
            fld === "tax"
              ? serviceValues?.taxApplicability === "No"
                ? false
                : true
              : true,
        };
        hlpObj = {
          ...hlpObj,
          [fld]:
            fld === "tax"
              ? serviceValues?.taxApplicability === "No"
                ? ""
                : "This field is required"
              : "This field is required",
        };
      } else {
        errObj = { ...errObj, [fld]: false };
        hlpObj = { ...hlpObj, [fld]: "" };
      }
    });
    setServiceErrors({ ...serviceErrors, ...errObj });
    setServiceHelps({ ...serviceHelps, ...hlpObj });
    if (Object.keys(errObj)?.some((val) => errObj?.[val] === true)) return;

    const serviceData = {
      category: serviceValues?.category?.value,
      subCategory: serviceValues?.subCategory,
      serviceName: serviceValues?.serviceName,
      taxApplicability: serviceValues?.taxApplicability,
      servicePrice: serviceValues?.servicePrice,
      tax:
        serviceValues?.taxApplicability === "No"
          ? 0
          : parseFloat(serviceValues?.tax)
          ? parseFloat(serviceValues?.tax)
          : 0,
      description: serviceValues?.description,
    };

    try {
      let apiRes = null;
      let reqUrl = defaultUrl;
      if (serviceId) {
        apiRes = await dispatch(
          editClinicService({ id: serviceId, data: serviceData })
        );
        // reqUrl = `?page=${page}&itemSize=${rowsPerPage}`;
        reqUrl = `?page=${page}&itemSize=${rowsPerPage}`;
      } else {
        apiRes = await dispatch(createClinicService(serviceData));
      }
      if (apiRes.payload) {
        dispatch(getServices());
        setModVisible(!modVisible);
        setServiceId(null);
        setServiceValues(initialValues);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChangePage = (e, selectedPage) => {
    setPage(selectedPage);
    setTableServices([]);
    dispatch(
      getServices(
        `?page=${selectedPage}&itemSize=${rowsPerPage}${
          searchValue?.length > 0 ? `&search=${searchValue}` : ""
        }`
      )
    );
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setPage(1);
    // const url = `?page=1&itemSize=${rowsPerPage}`;
    setTableServices([]);
    // dispatch(getStocksByClinic(`?search=${searchValue}&page=1`));
  };

  const handleChange = (name, value) => {
    setServiceValues({ ...serviceValues, [name]: value });
    if (value?.length > 0) {
      setServiceErrors({ ...serviceErrors, [name]: false });
      setServiceHelps({ ...serviceHelps, [name]: "" });
    } else {
      setServiceErrors({
        ...serviceErrors,
        [name]: requiredFieldList?.includes(name) ? true : false,
      });
      setServiceHelps({
        ...serviceHelps,
        [name]: requiredFieldList?.includes(name)
          ? "This field is required"
          : "",
      });
    }
  };

  const handleEditModal = (id) => {
    const selectedProduct = services?.data?.find(
      (product) => product?.serviceId === id
    );

    if (selectedProduct) {
      setServiceValues({
        ...serviceValues,
        category: serviceCategoryList?.find(
          (cat) => cat?.value === selectedProduct?.category
        ),
        subCategory: selectedProduct?.subCategory,
        serviceName: selectedProduct?.serviceName,
        taxApplicability: selectedProduct?.taxApplicability,
        servicePrice: selectedProduct?.servicePrice,
        tax: parseFloat(selectedProduct?.tax),
        description: selectedProduct?.description,
      });
      setServiceId(id);
      setModVisible(!modVisible);
    }
  };

  return (
    <Container maxWidth="xl">
      <div className="com-mar back-white pv10">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <div className="flex-row">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ width: "600px" }}
            >
              <CustomTextField
                placeholder="Search"
                fullWidth
                backgroundColor={AppColors.lightPink}
                value={searchValue}
                handleChange={handleSearch}
              />
            </Grid>
          </div>
          <div className="w5Per ml20">
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
              <div className="">
                <CustomButton text={"Create"} smallBtn onClick={handleOpen} />
              </div>
            </Grid>
          </div>
          <div className="pos-absolute r80">
            <Typography className="font-medium fs16">
              Total number of services: {services?.totalRecords}
            </Typography>
          </div>
        </Grid>

        <Table
          columns={tableHeaders}
          datas={tableSservices}
          page={page}
          rowsPerPage={rowsPerPage}
          totalRecords={services?.totalRecords}
          handleChangePage={handleChangePage}
          grey
          product={true}
        />

        <CustomModal
          open={modVisible}
          onClose={handleClose}
          header={serviceId ? "Edit" : "Create"}
          modal
          modalWidth={80}
        >
          <Grid container spacing={2} className="ph20">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className="font-bold fs16 blue-color">
                Service Details
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
              <div className="txt-semi-bold fs14 light-grey">
                Category <span className="red-color">*</span>
              </div>
              <Select
                list={serviceCategoryList}
                value={serviceValues?.category}
                handleChange={(e, value) => handleChange("category", value)}
                name="category"
                search
                error={serviceErrors?.category}
                helperText={serviceHelps?.category}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
              <div className="txt-semi-bold fs14 light-grey">
                Sub-Category <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="subCategory"
                fullWidth
                helperText={serviceHelps?.subCategory}
                error={serviceErrors?.subCategory}
                value={serviceValues?.subCategory}
                handleChange={(e) =>
                  handleChange("subCategory", e?.target?.value)
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
              <div className="txt-semi-bold fs14 light-grey">
                Procedure name <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="serviceName"
                fullWidth
                helperText={serviceHelps?.serviceName}
                error={serviceErrors?.serviceName}
                value={serviceValues?.serviceName}
                handleChange={(e) =>
                  handleChange("serviceName", e?.target?.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className="font-bold fs16 blue-color">
                Price & Taxes
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
              <div className="txt-semi-bold fs14 light-grey">
                Tax applicability <span className="red-color">*</span>
              </div>
              <Select
                list={yesNoValueList}
                value={serviceValues?.taxApplicability}
                handleChange={(e) =>
                  handleChange("taxApplicability", e?.target?.value)
                }
                name="taxApplicability"
                select
                error={serviceErrors?.taxApplicability}
                helperText={serviceHelps?.taxApplicability}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <div className="txt-semi-bold fs14 light-grey">
                Selling Price <span className="red-color">*</span>
              </div>

              <CustomTextField
                fullWidth
                value={serviceValues?.servicePrice}
                helperText={serviceHelps?.servicePrice}
                error={serviceErrors?.servicePrice}
                handleChange={(e) =>
                  handleChange("servicePrice", e?.target?.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <div className="txt-semi-bold fs14 light-grey">
                Tax(%) <span className="red-color">*</span>
              </div>
              <CustomTextField
                fullWidth
                value={
                  serviceValues?.taxApplicability === "No"
                    ? "-"
                    : serviceValues?.tax
                }
                helperText={
                  serviceValues?.taxApplicability === "No"
                    ? ""
                    : serviceHelps?.tax
                }
                error={
                  serviceValues?.taxApplicability === "No"
                    ? false
                    : serviceErrors?.tax
                }
                handleChange={(e) => handleChange("tax", e?.target?.value)}
                disabled={serviceValues?.taxApplicability === "No"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="txt-semi-bold fs14 light-grey">Remarks</div>
              <CustomTextField
                name="description"
                fullWidth
                value={serviceValues?.description}
                error={serviceErrors?.description}
                helperText={serviceHelps?.description}
                handleChange={(e) =>
                  handleChange("description", e?.target?.value)
                }
                multiline
              />
            </Grid>
          </Grid>

          <div className="mt30 mb20">
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <div className="flex1-end">
                  <div className="flex-row-ali-cen">
                    <div className="mr10">
                      <CustomButton
                        text="Cancel"
                        textBtn
                        onClick={handleClose}
                        color={AppColors.red}
                      />
                    </div>
                    <div className="ml10">
                      <CustomButton text="Submit" onClick={handleSubmit} />
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </CustomModal>
      </div>
    </Container>
  );
};

export default Service;
