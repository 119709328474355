import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Card, CardMedia, Grid, Typography } from "@mui/material";
import { uniq } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import CustomCard from "../../../components/CustomCard/CustomCard";
import CustomCheckbox from "../../../components/CustomCheckbox";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CustomTextField from "../../../components/CustomTextField";
import Select from "../../../components/Select/Select";
import {
  clinicReassignAppointment,
  clinicRescheduleAppointment,
  getClinicDashboardData,
  updateAppointmentStatusById,
  updateCancel,
  updateCompleted,
} from "../../../redux/reducers/clinicSlice";
import { getVetHolidaysById } from "../../../redux/reducers/doctorSlice";
import { hideLoader, showLoader } from "../../../redux/reducers/loaderSlice";
import {
  admitPet,
  getSlotTime,
  petAppointment,
} from "../../../redux/reducers/petSlice";
import { AppColors } from "../../../util/AppColors";
import {
  appointmentLaterList,
  appointmentList,
  serviceType,
  serviceTypeWOSurgery,
  typeAppointemnt,
  typeList,
  typeListNew,
} from "../../../util/dropList";
import { getAllDatesFromArray } from "../../../util/function";
import "./Home.css";

const cardSty = {
  borderRadius: 1,
  padding: 2,
  borderTopColor: AppColors.appPrimary,
};

const initBookValues = {
  bookingType: "Phone",
  consultationMode: "Virtual",
  serviceType: null,
  reason: "",
  vetName: null,
  appointmentOption: "",
  date: new Date(),
};

const initAdmitValues = { kennelNumber: "", remark: "", vet: null };
const initAdmitErrors = { kennelNumber: false, remark: false, vet: false };
const initErrors = { appointmentDate: "", appointmentTime: "", doctor: "" };
const initTimeObj = { times: [], ids: [] };

const HomeVisit = ({ selectedVet, selectedTab, vets }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalData, setModalData] = useState(null);
  const [selectTime, setSelectTime] = useState(null);
  const [selectTimeId, setSelectTimeId] = useState(null);
  const [delModVisible, setDelModVisible] = useState(false);
  const [rescheduleModVsble, setRescheduleModVsble] = useState(false);
  const [followUpModVsble, setFollowUpModVsble] = useState(false);
  const [selectTimeIdError, setSelectTimeIdError] = useState(false);
  const [admitModVisible, setAdmitModVisible] = useState(false);
  const [admitValues, setAdmitValues] = useState(initAdmitValues);
  const [admitErrors, setAdmitErrors] = useState(initAdmitErrors);
  const [holidayDates, setHolidayDates] = useState([]);
  const [errors, setErrors] = useState(initErrors);
  const [bookValues, setBookValues] = useState(initBookValues);
  const [isReassign, setIsReassign] = useState(true);
  const [timeObject, setTimeObject] = useState(initTimeObj);
  const holidays = useSelector((state) => state?.doctor?.holidays);
  const clinicHolidays = useSelector((state) => state?.clinic?.holidays);
  const slotList = useSelector((state) => state.pet.slotList);
  const dashboard = useSelector((state) => state.clinic.dashboard);
  const activeSlotList = slotList?.filter((status) => status.status === "N");

  //   useEffect(() => {
  //     checkLocalStorageforReload();
  //     getProfile(); // get and store user details
  //     dispatch(getClinicVets());
  //     dispatch(getClinicHolidays());
  //   }, []);

  useEffect(() => {
    if (
      isReassign ||
      !bookValues?.vetName?.value ||
      !bookValues?.date ||
      !bookValues?.appointmentOption
    ) {
      return;
    }
    if (bookValues?.appointmentOption === "Later") {
      dispatch(getVetHolidaysById(bookValues?.vetName?.value));
      const data = {
        doctorId: bookValues?.vetName?.value,
        contType: bookValues?.consultationMode,
        date: moment(new Date(bookValues?.date)).format("YYYY-MM-DD"),
      };
      dispatch(getSlotTime(data));
    }
  }, [
    bookValues?.vetName?.value,
    bookValues?.date,
    bookValues?.appointmentOption,
    bookValues?.consultationMode,
  ]);

  useEffect(() => {
    // Refetch the dashboard data whenever the selected veterinarian changes
    if (selectedTab === "Home") {
      const reqObj = { type: selectedTab };
      if (selectedVet?.value !== "All")
        reqObj.url = `&doctorId=${selectedVet?.value}`;
      dispatch(getClinicDashboardData(reqObj));
    }
  }, [selectedVet]);

  useEffect(() => {
    getHolidayDateList();
  }, [holidays, clinicHolidays]);

  const getHolidayDateList = async () => {
    if (
      (holidays?.length === 0 || !holidays) &&
      (clinicHolidays?.length === 0 || !clinicHolidays)
    ) {
      return setHolidayDates([]);
    }
    const vetDates = (await getAllDatesFromArray(holidays)) ?? [];

    const cliHolidays =
      clinicHolidays?.map((hl) => {
        return hl?.date;
      }) ?? [];
    const mergedArray = vetDates?.concat(cliHolidays);
    setHolidayDates(uniq(mergedArray));
  };

  const createModalOpen = (li, reAssign = false) => {
    setIsReassign(reAssign);
    setRescheduleModVsble(!rescheduleModVsble);
    setModalData(li);
    const apnt = li?.appoinment;
    setBookValues({
      ...bookValues,
      appoimentTime: apnt?.appoimentTime,
      appoimentId: apnt?.appoimentId,
      appoinmentDate: apnt?.appoinmentDate,
      bookingType: "Walk-in",
      consultationMode: apnt?.appoinmentType,
      serviceType: apnt?.serviceType,
      reason: apnt?.reason,
      vetName: vets?.find((vet) => vet?.value === apnt?.doctorId) ?? null,
      appointmentOption: apnt?.immediate ? null : "Later",
      date: new Date(apnt?.appoinmentDate),
    });
  };

  const rescheduleModClose = () => {
    setIsReassign(false);
    setRescheduleModVsble(false);
    setModalData(null);
    setBookValues(initBookValues);
    setSelectTimeIdError(false);
    setErrors(initErrors);
    setSelectTimeId(null);
  };

  const createModalOpenFollowUp = (li) => {
    setFollowUpModVsble(!followUpModVsble);
    setModalData(li);
    setIsReassign(false);
    setBookValues(initBookValues);
  };

  const onCloseFollowUpMod = () => {
    setFollowUpModVsble(false);
    setRescheduleModVsble(false);
    setBookValues(initBookValues);
    setModalData(null);
    setSelectTimeId(null);
    setSelectTime(null);
    setSelectTimeIdError(false);
    setErrors(initErrors);
  };

  const handleDelModClose = (li) => {
    setDelModVisible(!delModVisible);
    setModalData(li);
  };

  const handleSelectTime = (time) => {
    setSelectTime(time?.time);
    setSelectTimeId(time?.slotTimeId);
    setSelectTimeIdError(false);
  };

  const validateFields = () => {
    const newErrors = {};
    if (!bookValues?.vetName?.value)
      newErrors.vetName = "This field is required";
    if (!bookValues?.appointmentOption)
      newErrors.appointmentOption = "Appointment Type is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const navigatePetDetailsScreen = (appointment) => {
    navigate(`/clinic-pet-details`, { state: { appointment } });
  };

  const filterAppointmentsByVet = (typ) => {
    return dashboard?.[typ]?.filter((itm) => !itm?.appoinment?.inpatient);
  };

  const handleAdmit = (item) => {
    setModalData(item);
    setAdmitModVisible(true);
  };

  const renderSection = (
    title,
    data,
    colorClass,
    sectionType,
    checkIn,
    threedots
  ) => (
    <div className="w20Per borderRight">
      <div className="flex-row">
        <div className="p10MinWid99Per ">
          <Typography
            className={`card-head-${colorClass}-color card-head-border-bottom-${colorClass} font-bold fs20`}
          >
            {title} ({data?.length ?? 0})
          </Typography>
          <CustomCard
            list={data ?? []}
            dashboard
            sectionType={sectionType}
            onClickResch={(li) => createModalOpen(li, false)}
            onReassign={(li) => createModalOpen(li, true)}
            handleAction={(li, action) => handleAction(li, action)}
            handleCancel={(li) => handleDelModClose(li)}
            handleCompleted={(li) => handleCompleted(li)}
            onFollowUp={(li) => {
              createModalOpenFollowUp(li);
            }}
            onClick={(li) => navigatePetDetailsScreen(li)}
            checkIn={false} //checkIn
            threedots={threedots}
            handleConsult={(li) => handleConsult(li)}
            handleCheckout={(li) => handleCheckout(li)}
            handleAdmit={(itm) => handleAdmit(itm)}
          />
        </div>
      </div>
    </div>
  );

  const handleAction = (li, action) => {
    if (action === "In Transit") {
      dispatch(
        updateAppointmentStatusById({
          appointmentId: li?.appoinment?.appoimentId,
          status: "intransit",
          successMsg: "Patient has been notified!",
        })
      ).then((res) => {
        if (res?.payload) {
          dispatch(getClinicDashboardData({ type: selectedTab }));
        }
      });
    }
  };

  const handleCancel = () => {
    dispatch(updateCancel(modalData?.appoinment?.appoimentId)).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        setDelModVisible(false);
        setRescheduleModVsble(false);
        dispatch(getClinicDashboardData({ type: selectedTab }));
      }
    });
  };

  const handleConsult = (li) => {
    dispatch(
      updateAppointmentStatusById({
        appointmentId: li?.appoinment?.appoimentId,
        status: "consultation",
        successMsg: "Status updated successfully!",
      })
    ).then((res) => {
      if (res?.payload) {
        dispatch(getClinicDashboardData({ type: selectedTab }));
      }
    });
  };

  const handleCompleted = (li) => {
    dispatch(
      updateAppointmentStatusById({
        appointmentId: li?.appoinment?.appoimentId,
        status: "completed",
        successMsg: "Status updated successfully!",
      })
    ).then((res) => {
      if (res?.payload) {
        dispatch(getClinicDashboardData({ type: selectedTab }));
      }
    });
  };

  const handleCheckout = (li, action) => {
    dispatch(updateCompleted(li?.appoinment?.appoimentId)).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        dispatch(getClinicDashboardData({ type: selectedTab }));
      }
    });
  };

  const handleReschedule = () => {
    if (
      !isReassign &&
      !selectTimeId &&
      bookValues?.appointmentOption !== "Immediate"
    ) {
      return setSelectTimeIdError(true);
    }
    if (!validateFields()) return;

    const apntId = modalData?.appoinment?.appoimentId;
    if (isReassign) {
      const form = new FormData();
      form.append("doctorId", bookValues?.vetName?.value);
      dispatch(showLoader());
      dispatch(clinicReassignAppointment({ appointmentId: apntId, form })).then(
        (res) => {
          if (res?.payload) {
            onCloseFollowUpMod();
            setModalData(null);
          }
        }
      );
      dispatch(hideLoader());
    } else {
      const data = {
        appointmentDate: moment(new Date(bookValues?.date)).format(
          "YYYY-MM-DD"
        ),
        appoimentTime: selectTime,
        doctorId: bookValues?.vetName?.value,
        timeId: selectTimeId,
      };
      dispatch(showLoader());
      dispatch(
        clinicRescheduleAppointment({ appointmentId: apntId, data })
      ).then((res) => {
        if (res?.payload) {
          onCloseFollowUpMod();
          setModalData(null);
        }
      });
      dispatch(hideLoader());
    }
  };

  const onCloseAdmitModal = () => {
    setAdmitModVisible(false);
    setAdmitValues(initAdmitValues);
    setAdmitErrors(initAdmitErrors);
    setModalData(null);
  };

  const handleAdmitChange = (name, value) => {
    setAdmitValues({ ...admitValues, [name]: value });
    setAdmitErrors({
      ...admitErrors,
      [name]: value !== "" && value !== null ? false : true,
    });
  };

  const handleAdmitPatient = () => {
    if (
      admitValues?.kennelNumber === "" ||
      admitValues?.remark === "" ||
      admitValues?.vet === null
    ) {
      const reqObj = { kennelNumber: false, remark: false, vet: false };
      if (admitValues?.kennelNumber === "") reqObj.kennelNumber = true;
      if (admitValues?.remark === "") reqObj.remark = true;
      if (admitValues?.vet === null) reqObj.vet = true;
      return setAdmitErrors(reqObj);
    }
    const data = {
      appointmentId: modalData?.appoinment?.appoimentId,
      kennelNumber: admitValues?.kennelNumber,
      remark: admitValues?.remark,
    };

    dispatch(
      admitPet({
        petId: modalData?.appoinment?.petId,
        vetId: admitValues?.vet?.value,
        data,
      })
    ).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        dispatch(getClinicDashboardData({ type: selectedTab }));
        onCloseAdmitModal();
      }
    });
  };

  const handleChangeBookValue = (key, value) => {
    if (key === "appointmentOption" && value === "Immediate") {
      setSelectTimeIdError(false);
    }
    setBookValues({ ...bookValues, [key]: value });
    setErrors({
      ...errors,
      [key]: value?.length > 0 ? false : "Required Field",
    });
  };

  const handleValidation = () => {
    const errorList = [];
    const woErrorList = [];
    Object.keys(bookValues).forEach(function (key, index) {
      if (bookValues?.appointmentOption !== "Immediate" || key !== "date") {
        if (
          bookValues?.[key]?.length === 0 ||
          bookValues?.[key] === "" ||
          bookValues?.[key] === null
        ) {
          return errorList?.push(key);
        }
        woErrorList.push(key);
      }
    });
    let errorObj = {};
    let errHelperObj = {};
    let correctObj = {};
    let helperObj = {};
    //set Error
    if (errorList?.length > 0) {
      errorList?.forEach((key) => {
        errorObj = { ...errorObj, [key]: true };
        errHelperObj = { ...errHelperObj, [key]: `Required Field` };
      });
    }
    //Remove Error
    if (woErrorList?.length > 0) {
      woErrorList?.forEach((key) => {
        correctObj = { ...correctObj, [key]: false };
        helperObj = { ...helperObj, [key]: "" };
      });
    }
    setErrors({ ...errors, ...errHelperObj, ...correctObj });
    return { errorList, woErrorList };
  };

  const handleFollowUp = () => {
    const validation = handleValidation();
    if (validation?.errorList?.length > 0) return;
    if (
      bookValues?.serviceType?.value === "Surgery"
        ? timeObject?.times?.length !== 2
        : selectTimeId === null ||
          selectTimeId === undefined ||
          selectTimeId === ""
    ) {
      setSelectTimeIdError(true);
    } else {
      const data = {
        bookingType: bookValues?.bookingType,
        consultationMode: bookValues?.consultationMode,
        serviceType: bookValues?.serviceType?.value,
        reason: bookValues?.reason,
        doctorId: bookValues?.vetName?.value,
        appointmentDate: moment(new Date(bookValues?.date)).format(
          "YYYY-MM-DD"
        ),
        slotId: activeSlotList?.[0]?.slotdayId,
      };
      if (bookValues?.serviceType?.value === "Surgery") {
        const reqSmlId = timeObject?.times?.[0] <= timeObject?.times?.[1];
        const reqTimeObj = activeSlotList?.find(
          (slt) => slt?.slotTimeId === timeObject?.times?.[reqSmlId ? 0 : 1]
        );
        data.surgeryTimes = timeObject?.ids?.map((id) => {
          const timObj = activeSlotList?.find((slt) => slt?.slotTimeId === id);
          return timObj?.time;
        });
        data.appoimentTime = reqTimeObj?.time;
        data.timeIds = timeObject?.ids;
      } else {
        data.appoimentTime = selectTime;
        data.timeId = selectTimeId;
      }
      dispatch(showLoader());
      dispatch(
        petAppointment({ petId: modalData?.appoinment?.petId, data })
      ).then((res) => {
        if (res?.payload?.status === 200) {
          onCloseFollowUpMod();
        }
        dispatch(hideLoader());
      });
    }
  };

  const getSlotIdsBetween = (slots, id1, id2) => {
    const startId = Math.min(id1, id2);
    const endId = Math.max(id1, id2);
    return slots
      .filter(
        (slot) => slot?.slotTimeId >= startId && slot?.slotTimeId <= endId
      )
      .map((slot) => slot?.slotTimeId);
  };

  const handleSelectectedTimeIds = (time) => {
    const reqTimeIds = [...timeObject?.times, time?.slotTimeId];
    let ids = [];
    const reqList = {
      times: reqTimeIds?.length > 2 ? [time?.slotTimeId] : reqTimeIds,
    };
    if (reqList?.times?.length === 2) {
      ids = getSlotIdsBetween(activeSlotList, reqTimeIds?.[0], reqTimeIds?.[1]);
      reqList.ids = ids;
    } else {
      ids.push(time?.slotTimeId);
      reqList.ids = ids;
    }
    setTimeObject(reqList);
    setSelectTimeIdError(reqTimeIds?.length === 2 ? false : true);
  };

  return (
    <>
      <div className="flex-center">
        {renderSection(
          "Upcoming",
          filterAppointmentsByVet("upcomming"),
          "orange",
          "Upcoming",
          true,
          true
        )}
        {renderSection(
          "In Transit",
          filterAppointmentsByVet("intransit"),
          "rose",
          "Checked-in",
          true,
          true
        )}
        {renderSection(
          "Consultation",
          filterAppointmentsByVet("consultation"),
          "green",
          "Consultation",
          true,
          true
        )}
        {/* {renderSection(
              "Billing",
              filterAppointmentsByVet("billings"),
              "yellow",
              "Billing",
              true,
              true
            )} */}
        {renderSection(
          "Completed",
          filterAppointmentsByVet("completed"),
          "paleGreen",
          "Completed",
          true,
          true
        )}
      </div>

      <CustomModal
        open={rescheduleModVsble}
        onClose={rescheduleModClose}
        header="Reschedule / Reassign"
        rightModal
        modalWidth={30}
        headerBold
      >
        <Grid container spacing={1} className="ph20 scroll-80vh">
          <Grid item xs={12}>
            <Card sx={cardSty} className="CustomCard-back-appointment">
              <div className="maint">
                <div className="flex-row">
                  <Grid item xs={3}>
                    <div className="flex-center">
                      {modalData?.appoinment?.petImage ? (
                        <CardMedia
                          image={modalData?.appoinment?.petImage}
                          className="CustomCard-img3-bill"
                        />
                      ) : (
                        <div className="flex-center h50img">
                          <Typography className="font-bold fs30 white-color capitalize">
                            {modalData?.appoinment?.petName?.[0]}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </Grid>
                  <div className="flex-row">
                    <div className="flex-start">
                      <div className="flex-column">
                        <div className="flex-row">
                          <div className="mb10 txt-semi-bold black fs14 capitalize">
                            {modalData?.appoinment?.petName}
                          </div>
                          <Typography
                            className={`ml5 text capitalize ${
                              modalData?.appoinment?.gender === "male"
                                ? "card-blue-color"
                                : "card-rose-color"
                            }`}
                          >
                            {`(${modalData?.appoinment?.gender})`}
                          </Typography>
                        </div>
                        <Typography className="mb10 txt-regular card-gray-color fs12">
                          {modalData?.appoinment?.breed}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div>
                  <div className="flex-row parentcontainer mt10">
                    <div className="flex-row iconcontainer">
                      <AccountCircleOutlinedIcon
                        sx={{ width: 25, height: 25 }}
                      />
                      <div className="txt-semi-bold fs14 capitalize">
                        {modalData?.appoinment?.userName}
                      </div>
                    </div>
                    <div className="meeting-doctor">
                      {moment(modalData?.appoinment?.appoinmentDate).fromNow()}{" "}
                      ({modalData?.appoinment?.appoinmentDate})
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className="txt-semi-bold fs14 light-grey">
              Booking Type <span className="red-color">*</span>
            </div>
            <Select value={bookValues?.bookingType} selectFixed />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className="txt-semi-bold fs14 light-grey">
              Consultation Mode <span className="red-color">*</span>
            </div>
            <Select value={bookValues?.consultationMode} selectFixed />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey">
              Service Type <span className="red-color">*</span>
            </div>
            <Select value={bookValues?.serviceType} selectFixed />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey">
              Reason for visit <span className="red-color">*</span>
            </div>
            <Select value={bookValues?.reason} selectFixed />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey mb10">
              Vet Name <span className="red-color">*</span>
            </div>

            <Select
              list={vets}
              handleChange={(e, value) =>
                handleChangeBookValue("vetName", value)
              }
              name="vetName"
              search
              value={bookValues?.vetName}
              error={!!errors?.vetName}
              helperText={errors?.vetName}
              labelTop
            />
          </Grid>
          {isReassign ? null : (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="txt-semi-bold fs14 light-grey">
                Appointment Time:{" "}
                {moment(new Date(modalData?.appoinment?.appoinmentDate)).format(
                  "DD MMM YYYY"
                )}{" "}
                {modalData?.appoinment?.appoimentTime}
              </div>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="mbminus"
          >
            <div className="flex-row">
              <div className="txt-semi-bold fs14 light-grey mt10">
                Appointment <span className="red-color">*</span> :
              </div>
              <div className="ml5">
                <CustomCheckbox
                  radio
                  value={bookValues?.appointmentOption}
                  onChange={(e) =>
                    handleChangeBookValue("appointmentOption", e?.target?.value)
                  }
                  radios={isReassign ? appointmentList : appointmentLaterList}
                  disabled={isReassign}
                  defaultValue={bookValues?.appointmentOption}
                />
              </div>
            </div>

            {!!errors?.appointmentOption && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography color="error" className="mt5 fs14">
                  Appointment is Required
                </Typography>
              </Grid>
            )}
          </Grid>

          {!isReassign && bookValues?.appointmentOption === "Later" && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="txt-mont fs14 ">
                Date <span className="red-color">*</span>
              </div>
              <div className="mb20">
                <CustomTextField
                  name="date"
                  value={bookValues?.date}
                  labelTop
                  fullWidth
                  handleChange={(e) =>
                    handleChangeBookValue("date", e?.target?.value)
                  }
                  mobileDateWithBatch
                  markDateList={holidayDates ?? []}
                />
              </div>
            </Grid>
          )}

          {!isReassign &&
            bookValues?.appointmentOption === "Later" &&
            bookValues?.date &&
            activeSlotList?.length > 0 &&
            activeSlotList?.map((item, i) => {
              return (
                <div className="flex-row">
                  <div
                    onClick={() => handleSelectTime(item)}
                    className={
                      selectTimeId === item?.slotTimeId
                        ? "bgContainerSelected cursor"
                        : "bgContainerUnselected cursor"
                    }
                  >
                    {item?.time}
                  </div>
                </div>
              );
            })}

          {selectTimeIdError && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography color="error" className="mt5 fs14">
                Time is Requried
              </Typography>
            </Grid>
          )}
        </Grid>
        <div className="flex1-end mt20">
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <CustomButton text="Save" onClick={handleReschedule} />
          </Grid>
        </div>
      </CustomModal>
      <CustomModal
        open={followUpModVsble}
        onClose={onCloseFollowUpMod}
        header="Book an appointment"
        rightModal
        modalWidth={30}
        headerBold
      >
        <Grid container spacing={1} className="ph20 scroll-80vh">
          <Grid item xs={12}>
            <Card sx={cardSty} className="CustomCard-back-appointment">
              <div className="maint">
                <div className="flex-row">
                  <Grid item xs={3}>
                    <div className="flex-center">
                      {modalData?.appoinment?.petImage ? (
                        <CardMedia
                          image={modalData?.appoinment?.petImage}
                          className="CustomCard-img3-bill"
                        />
                      ) : (
                        <div className="flex-center h50img">
                          <Typography className="font-bold fs30 white-color capitalize">
                            {modalData?.appoinment?.petName?.[0]}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </Grid>
                  <div className="flex-row">
                    <div className="flex-start">
                      <div className="flex-column">
                        <div className="flex-row">
                          <div className="mb10 txt-semi-bold black fs14 capitalize">
                            {modalData?.appoinment?.petName}
                          </div>
                          <Typography
                            className={`ml5 text capitalize ${
                              modalData?.appoinment?.gender === "male"
                                ? "card-blue-color"
                                : "card-rose-color"
                            }`}
                          >
                            {`(${modalData?.appoinment?.gender})`}
                          </Typography>
                        </div>
                        <Typography className="mb10 txt-regular card-gray-color fs12">
                          {modalData?.appoinment?.breed}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div>
                  <div className="flex-row parentcontainer mt10">
                    <div className="flex-row iconcontainer">
                      <AccountCircleOutlinedIcon
                        sx={{ width: 25, height: 25 }}
                      />
                      <div className="txt-semi-bold fs14 capitalize">
                        {modalData?.appoinment?.userName}
                      </div>
                    </div>
                    <div className="meeting-doctor">
                      {moment(modalData?.appoinment?.dob).fromNow()} (
                      {modalData?.appoinment?.dob})
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className="txt-semi-bold fs14 light-grey">
              Booking Type <span className="red-color">*</span>
            </div>
            <Select
              list={typeAppointemnt}
              value={bookValues?.bookingType}
              handleChange={(e) => {
                handleChangeBookValue("bookingType", e?.target?.value);
              }}
              select
              labelTop
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className="txt-semi-bold fs14 light-grey">
              Consultation Mode <span className="red-color">*</span>
            </div>
            <Select
              list={
                bookValues?.bookingType === "Walk-in" ? typeListNew : typeList
              }
              value={
                bookValues?.bookingType === "Walk-in"
                  ? "Physical"
                  : bookValues?.consultationMode
              }
              handleChange={(e) => {
                handleChangeBookValue("consultationMode", e?.target?.value);
              }}
              select
              labelTop
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey">
              Service Type <span className="red-color">*</span>
            </div>
            <Select
              list={
                bookValues?.consultationMode === "Virtual"
                  ? serviceTypeWOSurgery
                  : serviceType
              }
              value={bookValues?.serviceType}
              handleChange={(e, value) =>
                handleChangeBookValue("serviceType", value)
              }
              search
              labelTop
              error={!!errors?.serviceType}
              helperText={errors?.serviceType}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey">
              Reason for visit <span className="red-color">*</span>
            </div>
            <CustomTextField
              name="reason"
              fullWidth
              handleChange={(e) =>
                handleChangeBookValue("reason", e?.target?.value)
              }
              value={bookValues?.reason}
              error={!!errors?.reason}
              helperText={errors?.reason}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey mb10">
              Vet Name <span className="red-color">*</span>
            </div>
            <Select
              list={vets}
              handleChange={(e, value) =>
                handleChangeBookValue("vetName", value)
              }
              name="vetName"
              search
              value={bookValues?.vetName}
              error={!!errors?.vetName}
              helperText={errors?.vetName}
              labelTop
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="mbminus"
          >
            <div className="flex-row">
              <div className="txt-semi-bold fs14 light-grey mt10">
                Appointment <span className="red-color">*</span> :
              </div>
              <div className="ml5">
                <CustomCheckbox
                  radio
                  value={bookValues?.appointmentOption}
                  onChange={(e) =>
                    handleChangeBookValue("appointmentOption", e?.target?.value)
                  }
                  radios={appointmentLaterList}
                />
              </div>
            </div>

            {errors?.appointmentOption && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography color="error" className="mt5 fs14">
                  Appointment is Required
                </Typography>
              </Grid>
            )}
          </Grid>

          {bookValues?.appointmentOption === "Later" && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="txt-mont fs14 ">
                Date <span className="red-color">*</span>
              </div>
              <div className="mb20">
                <CustomTextField
                  name="date"
                  value={bookValues?.date}
                  labelTop
                  fullWidth
                  handleChange={(e) =>
                    handleChangeBookValue("date", e?.target?.value)
                  }
                  mobileDateWithBatch
                  markDateList={holidayDates ?? []}
                />
              </div>
            </Grid>
          )}

          {bookValues?.appointmentOption === "Later" &&
            bookValues?.date &&
            activeSlotList?.length > 0 && (
              <div className="flex-row-wrap1">
                {activeSlotList?.length > 0 &&
                  activeSlotList?.map((item, i) => {
                    return (
                      <div
                        key={item?.time + i}
                        onClick={() => {
                          bookValues?.serviceType?.value === "Surgery"
                            ? handleSelectectedTimeIds(item)
                            : handleSelectTime(item);
                        }}
                        className={
                          (
                            bookValues?.serviceType?.value === "Surgery"
                              ? timeObject?.ids?.includes(item?.slotTimeId)
                              : selectTimeId === item?.slotTimeId
                          )
                            ? "bgContainerSelected cursor"
                            : "bgContainerUnselected cursor"
                        }
                      >
                        {item?.time}
                      </div>
                    );
                  })}
              </div>
            )}

          {selectTimeIdError && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography color="error" className="mt5 fs14">
                Time is Requried
              </Typography>
            </Grid>
          )}
        </Grid>
        <div className="flex1-end mt20">
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <CustomButton text="Save" onClick={handleFollowUp} />
          </Grid>
        </div>
      </CustomModal>
      <CustomModal
        open={admitModVisible}
        onClose={onCloseAdmitModal}
        header="Admit Patient"
        rightModal
        modalWidth={30}
        headerBold
      >
        <Grid container spacing={1} className="ph20">
          <Grid item xs={12}>
            <Card sx={cardSty} className="CustomCard-back-appointment">
              <div className="maint">
                <div className="flex-row">
                  <Grid item xs={3}>
                    <div className="flex-center">
                      {modalData?.appoinment?.petImage ? (
                        <CardMedia
                          image={modalData?.appoinment?.petImage}
                          className="CustomCard-img3-bill"
                        />
                      ) : (
                        <div className="flex-center h50img">
                          <Typography className="font-bold fs30 white-color capitalize">
                            {modalData?.appoinment?.petName?.[0]}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </Grid>
                  <div className="flex-row">
                    <div className="flex-start">
                      <div className="flex-column">
                        <div className="flex-row">
                          <div className="mb10 txt-semi-bold black fs14 capitalize">
                            {modalData?.appoinment?.petName}
                          </div>
                          <Typography
                            className={`ml5 text capitalize ${
                              modalData?.appoinment?.gender === "male"
                                ? "card-blue-color"
                                : "card-rose-color"
                            }`}
                          >
                            {`(${modalData?.appoinment?.gender})`}
                          </Typography>
                        </div>
                        <Typography className="mb10 txt-regular card-gray-color fs12">
                          {modalData?.appoinment?.breed}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div>
                  <div className="flex-row parentcontainer mt10">
                    <div className="flex-row iconcontainer">
                      <AccountCircleOutlinedIcon
                        sx={{ width: 25, height: 25 }}
                      />
                      <div className="txt-semi-bold fs14 capitalize">
                        {modalData?.appoinment?.userName}
                      </div>
                    </div>
                    <div className="meeting-doctor">
                      {moment(modalData?.appoinment?.appoinmentDate).fromNow()}{" "}
                      ({modalData?.appoinment?.appoinmentDate})
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey">
              Kennel Number <span className="red-color">*</span>
            </div>
            <CustomTextField
              name="kennelNumber"
              value={admitValues?.kennelNumber}
              fullWidth
              handleChange={(e) =>
                handleAdmitChange("kennelNumber", e?.target?.value)
              }
              error={admitErrors?.kennelNumber}
              helperText={
                admitErrors?.kennelNumber
                  ? "Kennel Number is required field"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey">
              Reason <span className="red-color">*</span>
            </div>
            <CustomTextField
              fullWidth
              multiline
              rows={3}
              handleChange={(e) =>
                handleAdmitChange("remark", e?.target?.value)
              }
              value={admitValues?.remark ?? ""}
              error={admitErrors?.remark}
              helperText={admitErrors?.remark ? "Reason is required field" : ""}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey mb10">
              Vet Name <span className="red-color">*</span>
            </div>
            <Select
              list={vets}
              handleChange={(e, value) => handleAdmitChange("vet", value)}
              name="vet"
              search
              value={admitValues?.vet}
              error={admitErrors?.vet}
              helperText={admitErrors?.vet ? "Vet Name is required field" : ""}
            />
          </Grid>
        </Grid>
        <div className="flex1-end mt20">
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <CustomButton text="Save" onClick={handleAdmitPatient} />
          </Grid>
        </div>
      </CustomModal>

      <CustomModal
        open={delModVisible}
        onClose={handleDelModClose}
        header="Cancel"
        modal
        modalWidth={40}
      >
        <Typography className="txt-regular fs16 ml10">
          Are you sure you want to cancel the appointment?
        </Typography>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className="clinic-mod-btn-pos mb10 mt15">
            <div className="mr10">
              <CustomButton text="Yes" redBtn onClick={handleCancel} />
            </div>
            <div className="ml10">
              <CustomButton
                text={"No"}
                onClick={() => setDelModVisible(false)}
              />
            </div>
          </div>
        </Grid>
      </CustomModal>
    </>
  );
};

export default HomeVisit;
