export const productHeaders = [
  "productName",
  "tradeName",
  "category",
  "subCategory",
  "ingredient",
  "nature",
  "code",
  "brand",
  "measurementUnit",
  "subMeasurementUnit",
  "formula",
  "batchNumber",
  "manufacturingDate",
  "expiryDate",
  "location",
  "unitSellingPrice",
  "subUnitSellingPrice",
  "administeringCharges",
  "taxApplicability",
  "tax",
  "dosageRecommendations",
  "composition",
  "sideEffects",
  "instructions",
  "description",
];

export const serviceHeaders = [
  "serviceName",
  "category",
  "subCategory",
  "sellingPrice",
  "taxApplicability",
  "tax",
  "description",
];

export const petsHeaders = [
  "name",
  "parentName",
  "species",
  "breed",
  "color",
  "gender",
  "petAge",
  "reproductiveStatus",
  "weight",
  "rfid",
];

export const appointmentHeaders = [
  "apntDateAndTime",
  "apntPetName",
  "parentName",
  "vetName",
  "consultationType",
  "category",
  "serviceType",
];

export const paymentsHeaders = [
  "trDate",
  "payPetName",
  "vetName",
  "balanceDue",
  "consultationType",
  "paymentStatus",
];

export const stockSummaryHeaders = [
  "product",
  "category",
  "hsnSacCode",
  "batch",
  "mfgDate",
  "expiryDate",
  "quantity",
  "location",
];

export const stockAdjustHeaders = [
  "date",
  "by",
  "type",
  "customer",
  "supplier",
  "invoiceNo",
  "product",
  "batchNo",
  "quantity",
  "remarks",
];

export const stockSupplierHeaders = [
  "supplierName",
  "gst",
  "billDate",
  "invoiceNumber",
  "totalTaxableValue",
  "totalTaxAmount",
  "totalAdditionalCharges",
  "grandTotal",
];

export const stockConsumptionHeaders = [
  "date",
  "vetName",
  "customer",
  "type",
  "product",
  "batchNo",
  "quantity",
];

export const nameExpan = {
  productName: "Product Name",
  tradeName: "Trade Name",
  category: "Category",
  subCategory: "Sub Category",
  tax: "Tax(%)",
  unitSellingPrice: "Unit Selling Price",
  subUnitSellingPrice: "Subunit Selling Price",

  serviceName: "Service Name",
  sellingPrice: "Selling Price",

  name: "Name",
  parentName: "Parent Name",
  breed: "Breed",
  gender: "Gender",
  reproductiveStatus: "Reproductive Status",
  species: "Species",
  petAge: "Age",
  color: "Color",
  weight: "Weight",
  rfid: "RFID",

  apntPetName: "Pet Name",
  apntDateAndTime: "Appointment Date/Time",
  serviceType: "Service Type",

  payPetName: "Pet Name",
  vetName: "Vet Name",
  balanceDue: "Balance Due",
  consultationType: "Consultation Type",
  paymentStatus: "Payment Status",
  trDate: "Tr. Date",

  supplierName: "Supplier Name",
  quantity: "Quantity",
  price: "Price",
  batchNo: "Batch No",

  subCategory: "Sub-Category",
  ingredient: "Major Active Ingredients",

  taxApplicability: "Tax applicability",
  description: "Description",
  nature: "Nature",
  code: "Bar Code number",
  brand: "Brand",
  measurementUnit: "Measurement Unit",
  subMeasurementUnit: "Sub-Measurement Unit",
  formula: "Formula",
  batchNumber: "Batch Number",
  manufacturingDate: "Mfg Date",
  expiryDate: "Expiry Date",
  location: "Location",
  administeringCharges: "Administering Charges",
  dosageRecommendations: "Dosage Recommendations",
  composition: "Composition",
  sideEffects: "Side Effects",
  instructions: "Instructions",

  product: "Product",
  batch: "Batch",
  mfgDate: "Mfg Date",

  hsnSacCode: "HSN/SAC Code",

  date: "Date",
  by: "By",
  type: "Type",
  customer: "Customer",
  supplier: "Supplier",
  invoiceNo: "Invoice No",
  remarks: "Remarks",

  gst: "GST",
  billDate: "Bill Date",
  invoiceNumber: "Invoice Number",
  totalTaxableValue: "Total Taxable Value",
  totalTaxAmount: "Total Tax Amount",
  totalAdditionalCharges: "Total Additional Charges",
  grandTotal: "Grand Total",
};
