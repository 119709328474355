import EventNoteIcon from "@mui/icons-material/EventNote";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import PetsIcon from "@mui/icons-material/Pets";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import { ReactComponent as AppointmentsIcon } from "../../assets/images/svg/calendar.svg";
import { ReactComponent as CircleinfoIcon } from "../../assets/images/svg/circle-information.svg";
import { ReactComponent as DeliveryIcon } from "../../assets/images/svg/delivery-fast.svg";
import { ReactComponent as DialIcon } from "../../assets/images/svg/dial-max.svg";
import { ReactComponent as HeartIcon } from "../../assets/images/svg/heart.svg";
import { ReactComponent as HomeIcon } from "../../assets/images/svg/home.svg";
import { ReactComponent as InventoryIcon } from "../../assets/images/svg/inventory-management.svg";
import { ReactComponent as PaymentIcon } from "../../assets/images/svg/invoice.svg";
import { ReactComponent as PetIcon } from "../../assets/images/svg/pet.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/svg/service.svg";
import { ReactComponent as VetIcon } from "../../assets/images/svg/stethescope.svg";

const navAdminConfig = [
  { title: "dashboard", path: "/home", icon: <HomeOutlinedIcon /> },
  { title: "clinics", path: "/clinic", icon: <LocalHospitalIcon /> },
  { title: "pets", path: "/pet", icon: <PetsIcon /> },
  { title: "payments", path: "/payment", icon: <ReceiptLongIcon /> },
];

const navClinicConfig = [
  { title: "Dashboard", path: "/dashboard", icon: <HomeIcon /> },
  { title: "Vets", path: "/vets", icon: <VetIcon /> },
  { title: "Pets", path: "/pets", icon: <PetIcon /> },
  { title: "Appointments", path: "/appointments", icon: <AppointmentsIcon /> },
  { title: "Billing", path: "/payments", icon: <PaymentIcon /> },
  { title: "Inventory", path: "/inventory", icon: <InventoryIcon /> },
  { title: "Reports", path: "/reports", icon: <PaymentIcon /> },
  { title: "Coming soon", path: "/coming-soon", icon: <DeliveryIcon /> },
  { title: "Coming soon", path: "/coming-soon1", icon: <HeartIcon /> },
  { title: "Coming soon", path: "/coming-soon2", icon: <DialIcon /> },
  { title: "Coming soon", path: "/coming-soon3", icon: <SettingsIcon /> },
  { title: "Coming soon", path: "/coming-soon4", icon: <CircleinfoIcon /> },
];

const navVetConfig = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <HomeIcon />,
  },
  {
    title: "Pets",
    path: "/pets",
    icon: <PetIcon />,
  },
  {
    title: "Appointments",
    path: "/appointments",
    icon: <AppointmentsIcon />,
  },
  {
    title: "Inventory",
    path: "/inventory",
    icon: <InventoryIcon />,
  },
  {
    title: "Coming soon",
    path: "/coming-soon",
    icon: <DeliveryIcon />,
  },
  {
    title: "Coming soon",
    path: "/coming-soon1",
    icon: <HeartIcon />,
  },
  {
    title: "Coming soon",
    path: "/coming-soon2",
    icon: <DialIcon />,
  },
  {
    title: "Coming soon",
    path: "/coming-soon3",
    icon: <SettingsIcon />,
  },
  {
    title: "Coming soon",
    path: "/coming-soon4",
    icon: <CircleinfoIcon />,
  },
];

const navBillingConfig = [
  { title: "Dashboard", path: "/dashboard", icon: <HomeIcon /> },
  { title: "Pets", path: "/pets", icon: <PetIcon /> },
  { title: "Billing", path: "/payments", icon: <PaymentIcon /> },
];

const navManagementConfig = [
  { title: "dashboard", path: "/home", icon: <HomeOutlinedIcon /> },
  { title: "vets", path: "/vets", icon: <LocalHospitalIcon /> },
  { title: "pets", path: "/pets", icon: <PetsIcon /> },
  { title: "appointments", path: "/appointments", icon: <EventNoteIcon /> },
  { title: "payments", path: "/payments", icon: <ReceiptLongIcon /> },
  {
    title: "pharma delivery",
    path: "/pharma-delivery",
    icon: <LocalShippingIcon />,
  },
  { title: "settings", path: "/settings", icon: <RoomPreferencesIcon /> },
  { title: "inventory", path: "/inventory", icon: <MedicalServicesIcon /> },
];

const navFrontDeskConfig = [
  { title: "Dashboard", path: "/dashboard", icon: <HomeIcon /> },
  { title: "Vets", path: "/vets", icon: <VetIcon /> },
  { title: "Pets", path: "/pets", icon: <PetIcon /> },
  { title: "Appointments", path: "/appointments", icon: <AppointmentsIcon /> },
];
const navPharmacyConfig = [
  { title: "Dashboard", path: "/dashboard", icon: <HomeIcon /> },
  { title: "Pets", path: "/pets", icon: <PetIcon /> },
  { title: "Billing", path: "/payments", icon: <PaymentIcon /> },
  { title: "Inventory", path: "/inventory", icon: <InventoryIcon /> },
];
const navVeterinaryConfig = [
  { title: "dashboard", path: "/dashboard", icon: <HomeOutlinedIcon /> },
];
export {
  navAdminConfig,
  navBillingConfig,
  navClinicConfig,
  navFrontDeskConfig,
  navManagementConfig,
  navPharmacyConfig,
  navVetConfig,
  navVeterinaryConfig,
};
