import {
  Box,
  Card,
  CardMedia,
  Container,
  FormHelperText,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CustomButton from "../../../../components/CustomButton";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import CustomTextField from "../../../../components/CustomTextField";
import Select from "../../../../components/Select/Select";
import { getClinicStocks } from "../../../../redux/reducers/clinicSlice";
import {
  createPrescription,
  getPetPrescriptions,
  getPetsCompliantSummary,
  updatePrescriptionById,
} from "../../../../redux/reducers/petSlice";
import { AppColors } from "../../../../util/AppColors";
import { frequncyCheckList } from "../../../../util/arrayList";
import { routeList } from "../../../../util/dropList";
import { CardCss } from "../../../../util/object";

const tableNameExpan = {
  name: "Name",
  dosage: "Dosage",
  qty: "Qty",
  duration: "Duration",
  frequency: "Frequency",
  meal: "",
  route: "Route",
  batchNumber: "batchNumber",
};

const tableHeaders = ["name", "qty", "dosage", "duration", "frequency", "meal"];
const adminstTableHeaders = ["name", "batchNumber", "dosage", "route"];
const initialPres = {
  name: null,
  dosage: "1",
  duration: "1",
  frequency: [],
  meal: "",
  customFrequency: false,
  route: routeList?.[0],
};

const mealTypes = [
  { name: "", value: "" },
  { name: "After Meal", value: "afterMeal" },
  { name: "Before Meal", value: "beforeMeal" },
];

const Prescription = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { appointment } = location.state || {};
  const [prescriptionList, setPrescriptionList] = useState([
    { ...initialPres, i: 0 },
  ]);
  const [adminsterPresList, setAdminsterPresList] = useState([
    { ...initialPres, i: 0 },
  ]);
  const [remark, setRemark] = useState("");
  const [prevModVisible, setPrevModVisible] = useState(false);
  const [isPreUploaded, setPreUploaded] = useState(false);
  const [viewPresValue, setViewPresValue] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectedPrescription, setSelectedPrescrision] = useState(null);
  const [page, setPage] = useState(1);
  const [tblVisible, setTblVisible] = useState(false);
  const [notValErr, setNotValErr] = useState(false);
  const [admNotValErr, setAdmNotValErr] = useState(false);
  const [tableType, setTableType] = useState("Administered");
  const itemsPerLoad = 3;
  const defaultUrl = `?page=1&itemSize=${itemsPerLoad}`;
  const user = localStorage.getItem("user");
  const profile = JSON.parse(user);
  const products = useSelector((state) => state?.clinic?.products);
  const metHisDet = useSelector(
    (state) => state?.pet?.complaintSummary?.data?.medicalHistory
  );
  const prescriptions = useSelector((state) => state?.pet?.prescriptions);
  const petDet = useSelector((state) => state?.pet?.complaintSummary?.data);
  const stocks = useSelector((state) => state?.clinic?.clinicStocks);
  const apntmnt = petDet?.appointment;

  useEffect(() => {
    dispatch(
      getPetPrescriptions({
        petId: appointment?.appoinment?.petId,
        url: defaultUrl,
      })
    );
    dispatch(getClinicStocks());
    // dispatch(getProductsByClinic());
  }, []);

  // const getListBySelectedType = () => {
  //   return products?.data?.map((pd) => ({ ...pd, value: pd?.name }));
  // };

  const getListBySelectedType = () => {
    const reqList = stocks?.data?.filter((dta) => {
      return dta?.stockBatch?.find((btch) => btch?.quantity > 0);
    });
    return reqList?.map((li) => ({
      name: li?.tradeName,
      value: li?.tradeName,
      stockId: li?.stockId,
      type: li?.category,
    }));
  };

  const handleAfterUpdate = () => {
    dispatch(
      getPetPrescriptions({
        petId: appointment?.appoinment?.petId,
        url: defaultUrl,
      })
    );
    dispatch(getPetsCompliantSummary(appointment?.appoinment?.appoimentId));
    setPage(1);
    setPreUploaded(true);
    setSelectedPrescrision(null);
    setPrevModVisible(false);
  };

  const handleAddAnUpdatePrescription = async () => {
    if (prescriptionList?.length + adminsterPresList?.length === 0) {
      setNotValErr(true);
      return setAdmNotValErr(true);
    }
    if (
      prescriptionList?.filter(
        (pl) => pl?.name === "" || pl?.name === null || pl?.name === undefined
      )?.length > 0 &&
      adminsterPresList?.filter(
        (pl) => pl?.name === "" || pl?.name === null || pl?.name === undefined
      )?.length > 0
    ) {
      setNotValErr(true);
      setAdmNotValErr(true);
      return;
    }
    let prescription = [];
    if (
      prescriptionList?.length > 0 &&
      prescriptionList?.filter(
        (pl) => pl?.name === "" || pl?.name === null || pl?.name === undefined
      )?.length === 0
    ) {
      const reqPres = prescriptionList?.map((pre) => {
        const selStock = stocks?.data?.find(
          (stc) => stc?.stockId === pre?.stockId
        );
        const reqObj = {
          administered: false,
          type: pre?.type,
          stockId: pre?.stockId,
          batchId: selStock
            ? selStock?.stockBatch?.find((stck) => stck?.quantity > 0)?.batchId
            : null,
          name: pre?.name,
          does: pre?.dosage,
          duration: pre?.duration,
          meal: pre?.meal,
          quantity: Number(pre?.qty),
          subUnit: pre?.unitType ?? "Unit",
          morning:
            (pre?.frequency?.includes("morning") &&
              !pre?.frequency?.includes("Custom")) ??
            false,
          afternoon:
            (pre?.frequency?.includes("afternoon") &&
              !pre?.frequency?.includes("Custom")) ??
            false,
          night:
            (pre?.frequency?.includes("night") &&
              !pre?.frequency?.includes("Custom")) ??
            false,
        };
        if (typeof pre?.frequency === "string") {
          reqObj.frequencyDuration = pre?.frequency;
        }
        if (pre?.productId) reqObj.productId = pre?.productId;
        if (pre?.serviceId) reqObj.serviceId = pre?.serviceId;
        if (selectedPrescription && pre?.id) reqObj.detailId = pre?.id;
        return reqObj;
      });
      prescription = [...prescription, ...reqPres];
    }
    if (
      adminsterPresList?.length > 0 &&
      adminsterPresList?.filter(
        (pl) => pl?.name === "" || pl?.name === null || pl?.name === undefined
      )?.length === 0
    ) {
      const reqPres = adminsterPresList?.map((pre) => {
        const reqObj = {
          administered: true,
          type: pre?.type ?? null,
          name: pre?.name ?? null,
          does: pre?.dosage,
          quantity: 1,
          vaccineType: pre?.route?.value,
          duration: "1",
          stockId: pre?.stockId ?? null,
          batchId: pre?.batchId ?? null,
        };
        if (pre?.productId) reqObj.productId = pre?.productId;
        if (pre?.serviceId) reqObj.serviceId = pre?.serviceId;
        if (selectedPrescription && pre?.id) reqObj.detailId = pre?.id;
        return reqObj;
      });
      prescription = [...prescription, ...reqPres];
    }
    const data = {
      status: selectedPrescription?.status ?? "Pending",
      remark,
      prescription,
      issue: metHisDet?.[0]?.problemType ?? "",
    };
    if (selectedPrescription) {
      dispatch(
        updatePrescriptionById({
          prescrisionId: selectedPrescription?.prescrisionId,
          data,
        })
      ).then((res) => {
        if (res?.payload?.status === 200) {
          handleAfterUpdate();
        }
      });
    } else {
      dispatch(
        createPrescription({
          appointmentId: appointment?.appoinment?.appoimentId,
          data,
        })
      ).then((res) => {
        if (res?.payload?.status === 200) {
          handleAfterUpdate();
        }
      });
    }
  };

  const handleAddNewPres = () => {
    setPrescriptionList([
      ...prescriptionList,
      { ...initialPres, i: prescriptionList?.length },
    ]);
  };

  const handleAddNewAdminsterPres = () => {
    setAdminsterPresList([
      ...adminsterPresList,
      { ...initialPres, i: adminsterPresList?.length },
    ]);
  };

  const handleChangePresValue = (name, value, ind, addObj) => {
    const reqList = prescriptionList?.map((pl, i) => {
      if (i === ind) {
        const reqObj = { ...pl, [name]: value };
        if (name === "frequency") {
          if (value?.includes("Custom")) {
            reqObj.frequency = "";
            reqObj.customFrequency = true;
          }
        }
        if (name === "name" && addObj) {
          reqObj.type = addObj?.type;
          reqObj.stockId = addObj?.stockId;
        }
        if (name === "type") reqObj.name = null;
        if (addObj?.productId) {
          reqObj.productId = addObj?.productId;
          if (addObj.unitType) reqObj.unitType = addObj.unitType;
        }
        if (addObj?.serviceId) reqObj.serviceId = addObj?.serviceId;

        return reqObj;
      }
      return pl;
    });

    setPrescriptionList(reqList);
    if (
      reqList?.filter(
        (pl) => pl?.name === "" || pl?.name === null || pl?.name === undefined
      )?.length > 0
    ) {
      return setNotValErr(true);
    }
    setNotValErr(false);
  };

  const handleChangeAdminsterPresValue = (name, value, ind, addObj) => {
    const reqList = adminsterPresList?.map((pl, i) => {
      if (i === ind) {
        const reqObj = { ...pl, [name]: value };
        if (name === "frequency") {
          if (value?.includes("Custom")) {
            reqObj.frequency = "";
            reqObj.customFrequency = true;
          }
        }
        if (name === "name" && addObj) {
          reqObj.type = addObj?.type;
          reqObj.stockId = addObj?.stockId;
        }
        if (name === "batchNumber" && addObj) {
          reqObj.batchId = addObj?.batchId;
        }
        if (name === "type") reqObj.name = null;
        if (addObj?.productId) {
          reqObj.productId = addObj?.productId;
          if (addObj.unitType) reqObj.unitType = addObj.unitType;
        }
        if (addObj?.serviceId) reqObj.serviceId = addObj?.serviceId;
        return reqObj;
      }
      return pl;
    });
    setAdminsterPresList(reqList);
    if (
      reqList?.filter(
        (pl) => pl?.name === "" || pl?.name === null || pl?.name === undefined
      )?.length > 0
    ) {
      return setNotValErr(true);
    }
    setNotValErr(false);
  };

  const handlePreModClose = () => {
    setViewPresValue(null);
    setPrevModVisible(false);
  };

  const handleViewPres = (presc) => {
    setViewPresValue(presc);
    setPrevModVisible(true);
  };

  const handleChangePage = (e, selectedPage) => {
    dispatch(
      getPetPrescriptions({
        petId: appointment?.appoinment?.petId,
        url: `?page=${selectedPage}&itemSize=${itemsPerLoad}`,
      })
    );
    setPage(selectedPage);
  };

  const deletePresByIndex = (ind) => {
    const reqList = prescriptionList
      .filter((pre, i) => {
        return i !== ind;
      })
      ?.map((rl, i) => ({ ...rl, i }));
    setPrescriptionList(reqList);
  };

  const deleteAdministerPresByIndex = (ind) => {
    const reqList = adminsterPresList
      .filter((pre, i) => {
        return i !== ind;
      })
      ?.map((rl, i) => ({ ...rl, i }));
    setAdminsterPresList(reqList);
  };

  const handleEditPrescription = (pres) => {
    setSelectedPrescrision(pres);
    setRemark(pres?.remark);
    const prescbList = pres?.prescriptionDetails
      ?.filter((pre) => pre?.administered?.toLowerCase() === "prescribed")
      ?.map((pd, i) => {
        // const reqName = products?.data?.find(
        //   (prd) => prd?.category === pd?.type && prd?.name === pd?.name
        // );
        const customFrequency = !pd?.morning && !pd?.afternoon && !pd?.night;
        const reqfreq = [];
        if (pd?.morning) reqfreq.push("morning");
        if (pd?.afternoon) reqfreq.push("afternoon");
        if (pd?.night) reqfreq.push("night");
        return {
          stockId: pd?.stockId,
          batchId: pd?.batchId,
          type: pd?.type,
          name: pd?.name,
          qty: pd?.quantity,
          dosage: pd?.does,
          unitType: pd?.subUnit,
          duration: pd?.duration,
          frequency:
            !pd?.morning && !pd?.afternoon && !pd?.night
              ? pd?.frequencyDuration
              : reqfreq,
          meal: pd?.meal,
          i,
          id: pd?.id,
          customFrequency,
        };
      });
    const admPrescbList = pres?.prescriptionDetails
      ?.filter((pre) => pre?.administered?.toLowerCase() === "administered")
      ?.map((pd, i) => {
        // const reqName = products?.data?.find(
        //   (prd) => prd?.category === pd?.type && prd?.name === pd?.name
        // );
        const batchList = getBatchList(pd?.stockId);
        const reqBatch = batchList?.find((bat) => bat?.batchId === pd?.batchId);
        return {
          ...initialPres,
          stockId: pd?.stockId,
          batchId: pd?.batchId,
          batchNumber: reqBatch?.value,
          type: pd?.type,
          name: pd?.name,
          qty: pd?.quantity,
          dosage: pd?.does,
          i,
          id: pd?.id,
          route: routeList?.find((rut) => rut?.value === pd?.vaccineType),
        };
      });
    setPrescriptionList(prescbList);
    setAdminsterPresList(admPrescbList);
    setTblVisible(true);
    setPreUploaded(false);
  };

  const getListByType = (adminstered = false) => {
    return viewPresValue
      ? viewPresValue?.prescriptionDetails?.filter((pd) => {
          const filterValue = adminstered ? "admininstered" : "prescribed";
          const currAdmin =
            pd?.administered?.toLowerCase() === "admininstered" ||
            pd?.administered?.toLowerCase() === "administered"
              ? "admininstered"
              : "prescribed";
          return currAdmin === filterValue;
        })
      : adminstered
      ? adminsterPresList
      : prescriptionList;
  };

  const renderData = (li, currPrd, administerd = false) => {
    return (
      <div className="gray-back-con" key={Date.now() + li?.id}>
        <div className="flex-row flex-row-wrap">
          <div className="w85Per">
            <div className="flex-row flex-row-wrap">
              <div className="font-bold fs12 black">{li?.name}</div>
              <div className="flex-row flex-center ml10">
                <div className="gray-dot" />
                <div className="font-medium fs12 black4 ml5">Quantity:</div>
                <div className="txt-regular fs12 black4 ml3">
                  {li?.quantity ?? li?.qty ?? "1"}{" "}
                  {li?.subUnit === "Unit" || li?.unitType === "Unit"
                    ? currPrd?.measurementUnit
                    : currPrd?.subMeasurementUnit}
                </div>
              </div>
              <div className="flex-row flex-center ml10">
                <div className="gray-dot" />
                <div className="font-medium fs12 black4 ml5">Dosage:</div>
                <div className="txt-regular fs12 black4 ml3">
                  {li?.dosage ?? li?.does}
                </div>
              </div>
              <div className="flex-row flex-center ml10">
                <div className="gray-dot" />
                <div className="font-medium fs12 black4 ml5">Duration:</div>
                <div className="txt-regular fs12 black4 ml3">
                  {li?.duration ?? "1"} days
                </div>
              </div>
              {li?.meal?.length > 0 ? (
                <div className="flex-row flex-center ml10">
                  <div className="gray-dot" />
                  <div className="txt-regular fs12 black4 ml5">
                    {li?.meal === "afterMeal"
                      ? "After Meal"
                      : li?.meal === "beforeMeal"
                      ? "Before Meal"
                      : ""}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {administerd ? null : (
              viewPresValue
                ? li?.morning ||
                  li?.afternoon ||
                  li?.night ||
                  li?.frequencyDuration?.length > 0
                : (typeof li?.frequency === "string" &&
                    li?.frequency?.length > 0) ||
                  li?.frequency?.includes("morning") ||
                  li?.frequency?.includes("afternoon") ||
                  li?.frequency?.includes("night")
            ) ? (
            <div className="flex-center">
              <div className="blue-back-con fs12 font-medium">
                {viewPresValue
                  ? !li?.morning && !li?.afternoon && !li?.night
                    ? li?.frequencyDuration
                    : `${li?.morning ? "1" : "0"}-${
                        li?.afternoon ? "1" : "0"
                      }-${li?.night ? "1" : "0"}`
                  : typeof li?.frequency === "string"
                  ? li?.frequency
                  : `${li?.frequency?.includes("morning") ? "1" : "0"}-${
                      li?.frequency?.includes("afternoon") ? "1" : "0"
                    }-${li?.frequency?.includes("night") ? "1" : "0"}`}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const getListDataToShow = () => {
    return (
      <div>
        {getListByType(true)?.length > 0 ? (
          <div>
            <div className="font-bold fs14 blue-color mv10">Administered</div>
            {getListByType(true)?.map((li) => {
              const currPrd = stocks?.data?.find(
                (dta) => dta?.stockId === li?.stockId
              );
              // const currPrd = products?.data?.find(
              //   (prd) => prd?.name === li?.name
              // );
              return renderData(li, currPrd, true);
            })}
          </div>
        ) : null}
        {getListByType(false)?.length > 0 ? (
          <div>
            <div className="font-bold fs14 blue-color mv10">Prescribed</div>
            {getListByType(false)?.map((li) => {
              const currPrd = stocks?.data?.find(
                (dta) => dta?.stockId === li?.stockId
              );
              // const currPrd = products?.data?.find(
              //   (prd) => prd?.name === li?.name
              // );
              return renderData(li, currPrd, false);
            })}
          </div>
        ) : null}
      </div>
    );
  };

  const getBatchList = (stockId = null) => {
    if (!stockId) return [];
    const reqStock = stocks?.data?.find((stc) => stc?.stockId === stockId);
    const filteredBatches = reqStock?.stockBatch
      ?.filter((btch) => btch?.quantity > 0)
      ?.map((btch) => ({
        name: btch?.batchNo,
        value: btch?.batchNo,
        batchId: btch?.batchId,
      }));
    return filteredBatches;
  };

  return (
    <>
      <div className="scroll-80vh w100Per">
        <Grid container className="back-white">
          {!tblVisible ? (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {apntmnt?.prescriptionStatus !== "Y" && (
                <div className="mt20 flex-center">
                  <div className="no-rec">
                    No records yet. Create a new prescription now
                  </div>
                </div>
              )}
              <div
                className={`flex-center mb20 ${
                  apntmnt?.prescriptionStatus === "Y" && "mt30"
                }`}
              >
                <div className="w15Per">
                  <CustomButton
                    text="Create"
                    onClick={() => setTblVisible(true)}
                    smallBtn
                  />
                </div>
              </div>
            </Grid>
          ) : tblVisible || apntmnt?.prescriptionStatus !== "Y" ? (
            <Container maxWidth="xl" className="ph10">
              <div className="flex-row-ali-cen mt20">
                <Typography
                  className={`txt-semi-bold fs12 gray4 ph10 pv5 br5 cursor ${
                    tableType === "Administered" ? "green-back3" : "gray-back2"
                  }`}
                  onClick={() => setTableType("Administered")}
                >
                  Administered
                </Typography>
                <div className="ml10" />
                <Typography
                  className={`txt-semi-bold fs12 gray4 ph10 pv5 br5 cursor ${
                    tableType === "Prescribed" ? "green-back3" : "gray-back2"
                  }`}
                  onClick={() => setTableType("Prescribed")}
                >
                  Prescribed
                </Typography>
              </div>
              <Box className="pv20">
                <TableContainer sx={{ maxHeight: 400 }}>
                  {tableType === "Prescribed" ? (
                    <Table
                      sx={{ minWidth: 200, tableLayout: "fixed" }}
                      aria-labelledby="tableTitle"
                      stickyHeader
                    >
                      <TableHead>
                        <TableRow>
                          {tableHeaders?.map((hl, i) => (
                            <TableCell
                              key={hl + i}
                              style={{
                                backgroundColor: AppColors.tableGrey,
                                color: AppColors.white,
                                textAlign: "left",
                                width:
                                  hl === "type" || hl === "meal"
                                    ? 150
                                    : hl === "name" || hl === "qty"
                                    ? 180
                                    : hl === "dosage"
                                    ? 100
                                    : hl === "duration"
                                    ? 130
                                    : hl === "frequency"
                                    ? 140
                                    : "auto",
                              }}
                              sortDirection={false}
                              className={`table-header-text ${
                                hl === "name" ? "w30Per" : "w15Per"
                              }`}
                            >
                              {tableNameExpan?.[hl]}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {prescriptionList?.length > 0 &&
                          prescriptionList?.map((pr, ind) => {
                            const reqProduct = products?.data?.find((prd) => {
                              return pr?.productId
                                ? prd?.productId === pr?.productId
                                : prd?.name === pr?.name;
                            });

                            const reqList = [
                              {
                                name: reqProduct?.measurementUnit,
                                value: "Unit",
                              },
                              {
                                name: reqProduct?.subMeasurementUnit,
                                value: "subUnit",
                              },
                            ];

                            return (
                              <TableRow
                                tabIndex={-1}
                                key={ind + pr?.name + pr?.i}
                              >
                                {tableHeaders?.map((th, index) => (
                                  <TableCell
                                    key={index + th + "tr"}
                                    component="th"
                                  >
                                    <div>
                                      {th === "name" ? (
                                        <Select
                                          list={getListBySelectedType()}
                                          value={pr?.name}
                                          addNewSelect
                                          mandatoryField
                                          handleChange={(e) => {
                                            handleChangePresValue(
                                              "name",
                                              e?.value ?? e,
                                              ind,
                                              {
                                                stockId: e?.stockId ?? null,
                                                productId: e?.productId ?? null,
                                                serviceId: e?.serviceId ?? null,
                                                unitType: reqList?.[0]?.value,
                                                type: e?.type,
                                              }
                                            );
                                          }}
                                          disabled={isPreUploaded}
                                        />
                                      ) : th === "qty" ? (
                                        <div className="flex-row-ali-cen">
                                          <div style={{ width: 50 }}>
                                            <CustomTextField
                                              value={pr?.qty}
                                              handleChange={(e) =>
                                                handleChangePresValue(
                                                  "qty",
                                                  e?.target?.value,
                                                  ind
                                                )
                                              }
                                              fullWidth
                                              type="number"
                                              disabled={isPreUploaded}
                                            />
                                          </div>

                                          <div
                                            style={{
                                              width: 80,
                                              paddingLeft: "5%",
                                            }}
                                          >
                                            <Select
                                              list={reqList}
                                              value={pr?.unitType}
                                              handleChange={(e) => {
                                                handleChangePresValue(
                                                  "unitType",
                                                  e?.target?.value,
                                                  ind
                                                );
                                              }}
                                              name="unitType"
                                              select
                                              disabled={isPreUploaded}
                                            />
                                          </div>
                                        </div>
                                      ) : th === "dosage" ? (
                                        <CustomTextField
                                          fullWidth
                                          handleChange={(e) =>
                                            handleChangePresValue(
                                              "dosage",
                                              e?.target?.value,
                                              ind
                                            )
                                          }
                                          value={pr?.dosage}
                                          disabled={isPreUploaded}
                                        />
                                      ) : th === "duration" ? (
                                        <CustomTextField
                                          fullWidth
                                          handleChange={(e) =>
                                            handleChangePresValue(
                                              "duration",
                                              e?.target?.value,
                                              ind
                                            )
                                          }
                                          value={pr?.duration}
                                          endIcon
                                          inputIcon="days"
                                          disabled={isPreUploaded}
                                        />
                                      ) : th === "frequency" ? (
                                        pr?.customFrequency ? (
                                          <CustomTextField
                                            fullWidth
                                            handleChange={(e) =>
                                              handleChangePresValue(
                                                "frequency",
                                                e?.target?.value,
                                                ind
                                              )
                                            }
                                            value={pr?.frequency}
                                            disabled={isPreUploaded}
                                          />
                                        ) : (
                                          <Select
                                            list={frequncyCheckList}
                                            value={pr?.frequency ?? []}
                                            handleChange={(e) => {
                                              handleChangePresValue(
                                                "frequency",
                                                e?.target?.value,
                                                ind
                                              );
                                            }}
                                            multiSelectTagCheck
                                            disabled={isPreUploaded}
                                          />
                                        )
                                      ) : (
                                        <div className="flex-row flex-center">
                                          <Select
                                            list={mealTypes}
                                            value={pr?.meal}
                                            select
                                            handleChange={(e) => {
                                              handleChangePresValue(
                                                "meal",
                                                e?.target?.value,
                                                ind
                                              );
                                            }}
                                            disabled={isPreUploaded}
                                          />
                                          {!isPreUploaded &&
                                          !selectedPrescription ? (
                                            <img
                                              src={require("../../../../assets/images/png/delete.png")}
                                              alt=""
                                              className="imghw30 ml10 cursor"
                                              onClick={() =>
                                                deletePresByIndex(ind)
                                              }
                                            />
                                          ) : null}
                                        </div>
                                      )}
                                    </div>
                                  </TableCell>
                                ))}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  ) : (
                    <Table
                      sx={{ minWidth: 200, tableLayout: "fixed" }}
                      aria-labelledby="tableTitle"
                      stickyHeader
                    >
                      <TableHead>
                        <TableRow>
                          {adminstTableHeaders?.map((hl, i) => (
                            <TableCell
                              key={hl + i}
                              style={{
                                backgroundColor: AppColors.tableGrey,
                                color: AppColors.white,
                                textAlign: "left",
                                width:
                                  hl === "name"
                                    ? 170
                                    : hl === "dosage"
                                    ? 100
                                    : 200,
                              }}
                              sortDirection={false}
                              className={`table-header-text ${
                                hl === "name" ? "w30Per" : "w15Per"
                              }`}
                            >
                              {tableNameExpan?.[hl]}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {adminsterPresList?.length > 0 &&
                          adminsterPresList?.map((pr, ind) => {
                            const reqProduct = products?.data?.find((prd) => {
                              return pr?.productId
                                ? prd?.productId === pr?.productId
                                : prd?.name === pr?.name;
                            });

                            const reqList = [
                              {
                                name: reqProduct?.measurementUnit,
                                value: "Unit",
                              },
                              {
                                name: reqProduct?.subMeasurementUnit,
                                value: "subUnit",
                              },
                            ];

                            return (
                              <TableRow
                                tabIndex={-1}
                                key={ind + pr?.name + pr?.i}
                              >
                                {adminstTableHeaders?.map((th, index) => (
                                  <TableCell
                                    key={index + th + "tr"}
                                    component="th"
                                  >
                                    <div>
                                      {th === "name" ? (
                                        <Select
                                          list={getListBySelectedType()}
                                          value={pr?.name}
                                          addNewSelect
                                          mandatoryField
                                          handleChange={(e) => {
                                            handleChangeAdminsterPresValue(
                                              "name",
                                              e?.value ?? e,
                                              ind,
                                              {
                                                stockId: e?.stockId ?? null,
                                                productId: e?.productId ?? null,
                                                serviceId: e?.serviceId ?? null,
                                                unitType: reqList?.[0]?.value,
                                                type: e?.type,
                                              }
                                            );
                                          }}
                                          disabled={isPreUploaded}
                                        />
                                      ) : th === "batchNumber" ? (
                                        <Select
                                          list={getBatchList(pr?.stockId)}
                                          value={pr?.batchNumber}
                                          addNewSelect
                                          mandatoryField
                                          handleChange={(e) => {
                                            handleChangeAdminsterPresValue(
                                              "batchNumber",
                                              e?.value ?? e,
                                              ind,
                                              {
                                                batchId: e?.batchId ?? null,
                                              }
                                            );
                                          }}
                                          disabled={isPreUploaded}
                                        />
                                      ) : th === "dosage" ? (
                                        <CustomTextField
                                          fullWidth
                                          handleChange={(e) =>
                                            handleChangeAdminsterPresValue(
                                              "dosage",
                                              e?.target?.value,
                                              ind
                                            )
                                          }
                                          value={pr?.dosage}
                                          disabled={isPreUploaded}
                                        />
                                      ) : (
                                        <div className="flex-row flex-center">
                                          <Select
                                            list={routeList}
                                            value={pr?.route}
                                            search
                                            handleChange={(e, value) => {
                                              handleChangeAdminsterPresValue(
                                                "route",
                                                value,
                                                ind
                                              );
                                            }}
                                            disabled={isPreUploaded}
                                          />
                                          {!isPreUploaded &&
                                          !selectedPrescription ? (
                                            <img
                                              src={require("../../../../assets/images/png/delete.png")}
                                              alt=""
                                              className="imghw30 ml10 cursor"
                                              onClick={() =>
                                                deleteAdministerPresByIndex(ind)
                                              }
                                            />
                                          ) : null}
                                        </div>
                                      )}
                                    </div>
                                  </TableCell>
                                ))}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              </Box>
              {notValErr && admNotValErr ? (
                <div className="flex-center">
                  <FormHelperText error>
                    {Number(
                      prescriptionList?.length + adminsterPresList?.length
                    ) === 0 ||
                    (!prescriptionList?.length && !adminsterPresList?.length)
                      ? "Please add at least 1 record"
                      : ""}
                  </FormHelperText>
                </div>
              ) : null}

              {!isPreUploaded && !selectedPrescription ? (
                <div className="flex-end">
                  <div className="w15Per">
                    <CustomButton
                      text="Add New"
                      smallBtn
                      onClick={
                        tableType === "Prescribed"
                          ? handleAddNewPres
                          : handleAddNewAdminsterPres
                      }
                    />
                  </div>
                </div>
              ) : null}

              <div className="mv20">
                <CustomTextField
                  fullWidth
                  multiline
                  placeholder="Advice"
                  rows={3}
                  handleChange={(e) => setRemark(e?.target?.value)}
                  value={remark}
                  disabled={isPreUploaded}
                />
              </div>
              <div className="flex-end">
                <div className="w15Per">
                  <CustomButton
                    text="Preview"
                    smallBtn
                    onClick={() => setPrevModVisible(true)}
                  />
                </div>
                {!isPreUploaded && tblVisible && selectedPrescription ? (
                  <div className="w15Per ml15">
                    <CustomButton
                      text="Update"
                      smallBtn
                      onClick={handleAddAnUpdatePrescription}
                    />
                  </div>
                ) : null}
              </div>
            </Container>
          ) : null}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="ph20">
            <div className="box-hor-split mv20" />
            <div className="font-bold fs14 mv20 blue-color">
              Prescription History
            </div>
            <div className="flex-center">
              <div className="w75Per">
                <CustomTextField
                  placeholder="Search"
                  fullWidth
                  backgroundColor={AppColors.lightPink}
                  value={searchValue}
                  handleChange={(e) => setSearchValue(e?.target?.value)}
                />
              </div>
            </div>
            <div className="pb100 w100Per">
              {prescriptions?.data?.filter(
                (pr) =>
                  pr?.doctorName
                    ?.toLowerCase()
                    ?.includes(searchValue.toLowerCase()) ||
                  pr?.issue?.toLowerCase()?.includes(searchValue.toLowerCase())
              )?.length > 0 ? (
                prescriptions?.data
                  ?.filter(
                    (pr) =>
                      pr?.doctorName
                        ?.toLowerCase()
                        ?.includes(searchValue.toLowerCase()) ||
                      pr?.issue
                        ?.toLowerCase()
                        ?.includes(searchValue.toLowerCase())
                  )
                  ?.map((pres, ind) => (
                    <div
                      className="flex-row-ali-cen mv10"
                      key={Date.now() + ind + "pres"}
                    >
                      <div className="w20Per">
                        <div className="back-img">
                          <div className="flex-center h100">
                            <div className="flex-column flex-center">
                              <Typography className="black2 fs10 font-bold">
                                {moment(new Date(pres?.createdDate)).format(
                                  "DD MMM"
                                )}
                              </Typography>
                              <Typography className="black2 fs10 font-bold mt5">
                                {moment(new Date(pres?.createdDate)).format(
                                  "YYYY"
                                )}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w60Per">
                        <Card sx={CardCss} className="inner-cards h110">
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className="mt10"
                            >
                              <div className="card-top-color card-top-blue-color" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <div className="ph10">
                                <div className="flex-row">
                                  <div className="w90Per">
                                    <div className="flex-start">
                                      <div className="flex-column w10Per">
                                        <div className="h50">
                                          {apntmnt?.doctorImage ? (
                                            <CardMedia
                                              image={apntmnt?.doctorImage}
                                              className="img-h40"
                                            />
                                          ) : (
                                            <div className="flex-center img-hw40 back-gray2">
                                              <Typography className="font-bold fs30 white-color capitalize">
                                                {pres?.doctorName?.[0]}
                                              </Typography>
                                            </div>
                                          )}
                                        </div>
                                        <div className="card-light-blue-back card-time flex-center">
                                          <Typography className="txt-regular card-blue2 fs12">
                                            {pres?.appoimentTime ??
                                              apntmnt?.appoimentTime}
                                          </Typography>
                                        </div>
                                      </div>
                                      <div className="flex-column ml15 jus-con-spa-bet">
                                        <div className="h50">
                                          <Typography className="font-bold fs14 black capitalize">
                                            Dr. {pres?.doctorName}
                                          </Typography>
                                          {/* <Typography className="gray7 fs14 font-medium mt5 capitalize">
                                            {pres?.issue}
                                          </Typography> */}
                                        </div>
                                        <div className="card-con-blue-back card-time flex-center w100Px">
                                          <Typography className="txt-regular white-color fs12">
                                            {pres?.appoinmentType}
                                          </Typography>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w10Per">
                                    <div className="flex-center h90">
                                      <img
                                        src={require("../../../../assets/images/png/edit2.png")}
                                        alt=""
                                        className="img-wh25 cursor"
                                        onClick={() =>
                                          handleEditPrescription(pres)
                                        }
                                      />

                                      <img
                                        src={require("../../../../assets/images/png/view.png")}
                                        alt=""
                                        className="img-hw40 ml10 cursor"
                                        onClick={() => handleViewPres(pres)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Card>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="no-rec">No records available</div>
              )}
              {prescriptions?.data?.filter(
                (pr) =>
                  pr?.doctorName
                    ?.toLowerCase()
                    ?.includes(searchValue.toLowerCase()) ||
                  pr?.issue?.toLowerCase()?.includes(searchValue.toLowerCase())
              )?.length > 0 ? (
                Math.ceil(prescriptions?.totalRecords / itemsPerLoad) > 1 ? (
                  <div className="flex-end">
                    <Pagination
                      count={Math.ceil(
                        prescriptions?.totalRecords / itemsPerLoad
                      )}
                      variant="outlined"
                      color="primary"
                      page={page}
                      onChange={handleChangePage}
                    />
                  </div>
                ) : null
              ) : null}
            </div>
          </Grid>
        </Grid>
      </div>
      <CustomModal
        open={prevModVisible}
        onClose={handlePreModClose}
        header=""
        modal
        modalWidth={50}
      >
        <div className="scroll-80vh">
          <div className="p5">
            <div className="blue-box-examD">
              <div className="flex-row">
                <div className="w30Per">
                  <div className="flex-column">
                    <div className="flex-row mt10">
                      <div className="font-bold fs14 blue-color">Pet Name:</div>
                      <div className="font-bold fs14 black capitalize ml5">
                        {apntmnt?.petName}
                      </div>
                    </div>
                    <div className="flex-row mv5">
                      <div className="font-bold fs14 blue-color">Breed:</div>
                      <div className="txt-regular fs14 black capitalize ml5">
                        {apntmnt?.breed}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w45Per">
                  <div className="flex-column">
                    <div className="flex-row mt10">
                      <div className="font-bold fs14 blue-color">Vet Name:</div>
                      <div className="font-bold fs14 black ml5 capitalize">
                        {`Dr. ${
                          viewPresValue?.doctorName ?? apntmnt?.doctorName ?? ""
                        }`}
                      </div>
                      {viewPresValue?.registrationNo?.length > 0 ||
                      apntmnt?.registrationNo?.length > 0 ? (
                        <div className="flex-row-ali-cen">
                          <div className="font-regular fs14 black ml5">(</div>
                          <div className="font-bold fs14 blue-color ml2">
                            RegNo:
                          </div>
                          <div className="font-regular fs14 black ml5 capitalize">
                            {viewPresValue?.registrationNo ??
                              apntmnt?.registrationNo}{" "}
                            )
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="flex-row mv5">
                      <div className="font-bold fs14 blue-color">Date:</div>
                      <div className="txt-regular fs14 black ml5">
                        {viewPresValue
                          ? moment(viewPresValue?.createdDate).format(
                              "DD MMM YYYY"
                            )
                          : moment().format("DD MMM YYYY")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w25Per">
                  <div className="flex-end">
                    <img
                      alt=""
                      src={
                        profile?.image ??
                        require("../../../../assets/images/png/applologo.png")
                      }
                      className="img-h50"
                    />
                  </div>
                </div>
              </div>
              <div className="box-hor-split mv10" />
              {getListDataToShow()}
              <div className="font-bold fs14 blue-color mv10">Advice</div>
              <CustomTextField
                fullWidth
                multiline
                placeholder="Advice"
                rows={3}
                value={viewPresValue ? viewPresValue?.remark : remark}
                disabled
              />
              <div className="flex-row mv20">
                <img
                  src={require("../../../../assets/images/jpg/WoofQrCode.jpeg")}
                  alt=""
                  className="img-h50"
                />
                <div className="blu-back wh50 flex-center">
                  <img
                    src={require("../../../../assets/images/png/VetInstantLogo.png")}
                    alt=""
                    className="img-h40"
                  />
                </div>
                <div className="flex-center">
                  <div className="font-bold fs14 ml20">
                    Scan to download our app
                  </div>
                </div>
              </div>
            </div>
            {!isPreUploaded && tblVisible && !selectedPrescription ? (
              <div className="flex-center mv20">
                <div className="w15Per">
                  <CustomButton
                    text="Submit"
                    smallBtn
                    onClick={handleAddAnUpdatePrescription}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default Prescription;
