import { Container, Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../components/CustomButton";
import CustomModal from "../../../components/CustomModal/CustomModal";
import Table from "../../../components/CustomTable";
import CustomTextField from "../../../components/CustomTextField";
import Select from "../../../components/Select/Select";
import {
  createNewProduct,
  deleteProducts,
  editClinicProduct,
  getProductsByClinic,
} from "../../../redux/reducers/clinicSlice";
import { hideLoader, showLoader } from "../../../redux/reducers/loaderSlice";
import { AppColors } from "../../../util/AppColors";
import {
  locationList,
  natureList,
  productCategoryList,
  productMeasurementUnit,
  productSubMeasurementUnit,
  yesNoValueList,
} from "../../../util/dropList";

const tableHeaders = [
  "tradeName",
  "category",
  "subCategory",
  "nature",
  "sellingPrice",
  "editButton",
];

const requiredFieldList = [
  "category",
  "subCategory",
  "name",
  "tradeName",
  "measurementUnit",
];

const initialValues = {
  category: null,
  subCategory: "",
  name: "",
  tradeName: "",
  ingredient: "",
  nature: null,
  code: "",
  brand: "",
  measurementUnit: null,
  subMeasurementUnit: null,
  formula: "",
  description: "",
};

const initialError = {
  category: false,
  subCategory: false,
  name: false,
  tradeName: false,
  ingredient: false,
  nature: false,
  code: false,
  brand: false,
  measurementUnit: false,
  subMeasurementUnit: false,
  formula: false,
  location: false,

  description: false,
};

const initialHelp = {
  category: "",
  subCategory: "",
  name: "",
  tradeName: "",
  ingredient: "",
  nature: "",
  code: "",
  brand: "",
  measurementUnit: "",
  subMeasurementUnit: "",
  formula: "",
  description: "",
};

const ProductComponent = () => {
  const dispatch = useDispatch();
  const productsData = useSelector((state) => state?.clinic?.products);
  const [modVisible, setModVisible] = useState(false);
  const [productValues, setProductValues] = useState(initialValues);
  const [productErrors, setProductErrors] = useState(initialError);
  const [productHelps, setProductHelps] = useState(initialHelp);
  const [delModVisible, setDelModVisible] = useState(false);
  const [tableProducts, setTableProducts] = useState([]);
  const [productId, setProductId] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const defaultUrl = `?page=1&itemSize=${rowsPerPage}&search=${searchValue}`;

  useEffect(() => {
    dispatch(getProductsByClinic(defaultUrl));
  }, [searchValue]);

  useEffect(() => {
    getProductsTable();
  }, [productsData]);

  const getProductsTable = () => {
    dispatch(showLoader());
    const requiredSupplier = productsData?.data?.map((products, i) => {
      return {
        productCode: products?.code,
        tradeName: products?.tradeName,
        unitType: products?.unitType,
        sellingPrice: products?.sellPrice,
        recorderLevel: products?.reoder,
        category: products?.category,
        subCategory: products?.subCategory,
        description: products?.description,
        nature: products?.nature,
        deleteButton: (
          <div
            onClick={() => handleDeleteModal(products?.productId)}
            className="red-color txt-semi-bold cursor "
          >
            <img
              src={require("../../../assets/images/png/del.png")}
              alt=""
              className="h30"
            />
          </div>
        ),
        editButton: (
          <div
            onClick={() => handleEditModal(products?.productId)}
            className="blue-color txt-semi-bold cursor "
          >
            <img
              src={require("../../../assets/images/png/edit.png")}
              alt=""
              className="h30"
            />
          </div>
        ),
      };
    });
    dispatch(hideLoader());
    setTableProducts(requiredSupplier);
  };

  const handleModalClose = () => {
    setProductId(null);
    setProductValues(initialValues);
    setProductErrors(initialError);
    setProductHelps(initialHelp);
    setModVisible(false);
  };

  const handleSubmit = async () => {
    let errObj = {};
    let hlpObj = {};
    requiredFieldList?.forEach((fld) => {
      const value =
        typeof productValues?.[fld] === "object"
          ? productValues?.[fld]?.value
          : productValues?.[fld];
      // fld === "category" ||
      // fld === "nature" ||
      // fld === "measurementUnit" ||
      // fld === "subMeasurementUnit"
      //   ? productValues?.[fld]?.value
      //   : productValues?.[fld]?.length === 0 || !productValues?.[fld];
      if (value?.length === 0 || !value) {
        errObj = {
          ...errObj,
          [fld]:
            fld === "tax"
              ? productValues?.taxApplicability === "No"
                ? false
                : true
              : true,
        };
        hlpObj = {
          ...hlpObj,
          [fld]:
            fld === "tax"
              ? productValues?.taxApplicability === "No"
                ? ""
                : "This field is required"
              : "This field is required",
        };
      } else {
        errObj = { ...errObj, [fld]: false };
        hlpObj = { ...hlpObj, [fld]: "" };
      }
    });
    setProductErrors({ ...productErrors, ...errObj });
    setProductHelps({ ...productHelps, ...hlpObj });
    if (Object.keys(errObj)?.some((val) => errObj?.[val] === true)) return;

    const productData = {
      category: productValues?.category?.value,
      subCategory: productValues?.subCategory,
      hsnSacCode: productValues?.hsnSacCode,
      name: productValues?.name,
      tradeName: productValues?.tradeName,
      ingredient: productValues?.ingredient,
      nature: productValues?.nature?.value,
      code: productValues?.code,
      brand: productValues?.brand,
      measurementUnit: productValues?.measurementUnit?.value,
      subMeasurementUnit: productValues?.subMeasurementUnit?.value ?? "",
      formula: productValues?.formula,
      description: productValues?.description,
    };

    try {
      let apiRes = null;
      let reqUrl = defaultUrl;
      if (productId) {
        apiRes = await dispatch(
          editClinicProduct({ id: productId, data: productData })
        );
        reqUrl = `?page=${page}&itemSize=${rowsPerPage}`;
      } else {
        apiRes = await dispatch(createNewProduct(productData));
      }
      if (apiRes?.payload) {
        dispatch(getProductsByClinic(reqUrl));
        setModVisible(!modVisible);
        setProductId(null);
        setProductValues(initialValues);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDeleteModal = async (id) => {
    setProductId(id);
    setDelModVisible(true);
  };

  const handleDelete = async () => {
    const apiRes = await dispatch(deleteProducts(productId));
    if (apiRes?.payload) {
      dispatch(getProductsByClinic(defaultUrl));
      setDelModVisible(false);
      setProductId(null);
    }
  };

  function formatDate(dateStr) {
    if (dateStr?.includes("-")) {
      const parts = dateStr?.split("-");

      // Check if the first part is year or day
      if (parts?.[0]?.length === 4) {
        // It's already in YYYY-MM-DD format
        return dateStr;
      } else {
        // It's in DD-MM-YYYY format, convert it
        const [day, month, year] = parts;
        return `${year}-${month}-${day}`;
      }
    }
    return null;
  }

  const handleEditModal = (id) => {
    const selectedProduct = productsData?.data?.find(
      (product) => product.productId === id
    );
    if (selectedProduct) {
      setProductValues({
        ...productValues,
        category: productCategoryList?.find(
          (cat) => cat?.value === selectedProduct?.category
        ),
        subCategory: selectedProduct?.subCategory,
        hsnSacCode: selectedProduct?.hsnSacCode,
        name: selectedProduct?.name,
        tradeName: selectedProduct?.tradeName,
        ingredient: selectedProduct?.ingredient,
        nature: natureList?.find(
          (nat) => nat?.value === selectedProduct?.nature
        ),
        code: selectedProduct?.code,
        brand: selectedProduct?.brand,
        measurementUnit: productMeasurementUnit?.find(
          (unit) => unit?.value === selectedProduct?.measurementUnit
        ),
        subMeasurementUnit: productSubMeasurementUnit?.find(
          (sub) => sub?.value === selectedProduct?.subMeasurementUnit
        ),
        formula: selectedProduct?.formula,
        description: selectedProduct?.description,
      });
      setProductId(id);
      setModVisible(!modVisible);
    }
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setPage(1);
    setTableProducts([]);
    // dispatch(getProductsByClinic(`?search=${searchValue}&page=1`));
  };

  const handleChangePage = (e, selectedPage) => {
    setPage(selectedPage);
    setTableProducts([]);
    dispatch(
      getProductsByClinic(
        `?page=${selectedPage}&itemSize=${rowsPerPage}${
          searchValue?.length > 0 ? `&search=${searchValue}` : ""
        }`
      )
    );
  };

  const handleChange = (name, value) => {
    setProductValues({ ...productValues, [name]: value });
    if (value?.length > 0) {
      setProductErrors({ ...productErrors, [name]: false });
      setProductHelps({ ...productHelps, [name]: "" });
    } else {
      setProductErrors({
        ...productErrors,
        [name]: requiredFieldList?.includes(name) ? true : false,
      });
      setProductHelps({
        ...productHelps,
        [name]: requiredFieldList?.includes(name)
          ? "This field is required"
          : "",
      });
    }
  };

  const handleDelModClose = () => {
    setDelModVisible(false);
    setProductId(null);
  };

  return (
    <Container maxWidth="xl">
      <div className="com-mar back-white pv10">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <div className="flex-row">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ width: "600px" }}
            >
              <CustomTextField
                placeholder="Search"
                fullWidth
                backgroundColor={AppColors.lightPink}
                value={searchValue}
                handleChange={handleSearch}
              />
            </Grid>
          </div>
          {/* <div className="w5Per ml20">
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
              <div className="">
                <CustomButton
                  text="Create"
                  smallBtn
                  onClick={() => setModVisible(true)}
                />
              </div>
            </Grid>
          </div> */}
          <div className="pos-absolute r80">
            <Typography className="font-medium fs16">
              Total number of products: {productsData?.totalRecords}
            </Typography>
          </div>
        </Grid>
        <Table
          columns={tableHeaders}
          datas={tableProducts}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          totalRecords={productsData?.totalRecords}
          grey
          product={true}
          onEdit={() => handleEditModal(productId)}
        />
        <CustomModal
          open={modVisible}
          onClose={handleModalClose}
          header={productId ? "Edit" : "Create"}
          modalWidth={100}
          modal
        >
          <div className="ph20 scroll-80vh">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography className="font-bold fs16 blue-color">
                  Product Details
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Category <span className="red-color">*</span>
                </div>
                <Select
                  list={productCategoryList}
                  value={productValues?.category}
                  handleChange={(e, value) => handleChange("category", value)}
                  name="category"
                  search
                  error={productErrors?.category}
                  helperText={productHelps?.category}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Sub-Category <span className="red-color">*</span>
                </div>
                <CustomTextField
                  name="subCategory"
                  fullWidth
                  value={productValues?.subCategory}
                  error={productErrors?.subCategory}
                  helperText={productHelps?.subCategory}
                  handleChange={(e) =>
                    handleChange("subCategory", e?.target?.value)
                  }
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  HSN/SAC Code
                </div>
                <CustomTextField
                  name="hsnSacCode"
                  fullWidth
                  value={productValues?.hsnSacCode}
                  error={productErrors?.hsnSacCode}
                  helperText={productHelps?.hsnSacCode}
                  handleChange={(e) =>
                    handleChange("hsnSacCode", e?.target?.value)
                  }
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Name <span className="red-color">*</span>
                </div>
                <CustomTextField
                  name="name"
                  fullWidth
                  value={productValues?.name}
                  error={productErrors?.name}
                  helperText={productHelps?.name}
                  handleChange={(e) => handleChange("name", e?.target?.value)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Trade Name <span className="red-color">*</span>
                </div>
                <CustomTextField
                  name="tradeName"
                  fullWidth
                  value={productValues?.tradeName}
                  error={productErrors?.tradeName}
                  helperText={productHelps?.tradeName}
                  handleChange={(e) =>
                    handleChange("tradeName", e?.target?.value)
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Major Active Ingredients
                </div>
                <CustomTextField
                  name="ingredient"
                  fullWidth
                  value={productValues?.ingredient}
                  error={productErrors?.ingredient}
                  helperText={productHelps?.ingredient}
                  handleChange={(e) =>
                    handleChange("ingredient", e?.target?.value)
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">Nature</div>
                <Select
                  list={natureList}
                  value={productValues?.nature}
                  handleChange={(e, value) => handleChange("nature", value)}
                  name="nature"
                  search
                  error={productErrors?.nature}
                  helperText={productHelps?.nature}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Bar Code number
                </div>
                <CustomTextField
                  name="code"
                  fullWidth
                  value={productValues?.code}
                  error={productErrors?.code}
                  helperText={productHelps?.code}
                  handleChange={(e) => handleChange("code", e?.target?.value)}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">Brand</div>
                <CustomTextField
                  name="brand"
                  fullWidth
                  value={productValues?.brand}
                  error={productErrors?.brand}
                  helperText={productHelps?.brand}
                  handleChange={(e) => handleChange("brand", e?.target?.value)}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Measurement Unit <span className="red-color">*</span>
                </div>
                <Select
                  list={productMeasurementUnit}
                  value={productValues?.measurementUnit}
                  handleChange={(e, value) =>
                    handleChange("measurementUnit", value)
                  }
                  name="measurementUnit"
                  search
                  error={productErrors?.measurementUnit}
                  helperText={productHelps?.measurementUnit}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">
                  Sub-Measurement Unit
                </div>
                <Select
                  list={productSubMeasurementUnit}
                  value={productValues?.subMeasurementUnit}
                  handleChange={(e, value) =>
                    handleChange("subMeasurementUnit", value)
                  }
                  name="subMeasurementUnit"
                  search
                  error={productErrors?.subMeasurementUnit}
                  helperText={productHelps?.subMeasurementUnit}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <div className="txt-semi-bold fs14 light-grey">Formula</div>
                <CustomTextField
                  name="formula"
                  fullWidth
                  value={productValues?.formula}
                  error={productErrors?.formula}
                  helperText={productHelps?.formula}
                  handleChange={(e) =>
                    handleChange("formula", e?.target?.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="txt-semi-bold fs14 light-grey">
                  Brief Description of the Product
                </div>
                <CustomTextField
                  name="description"
                  fullWidth
                  value={productValues?.description}
                  error={productErrors?.description}
                  helperText={productHelps?.description}
                  handleChange={(e) =>
                    handleChange("description", e?.target?.value)
                  }
                  multiline
                />
              </Grid>
            </Grid>
            <div className="mt30">
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <div className="flex1-end">
                  <div className="flex-row-ali-cen">
                    <div className="mr10">
                      <CustomButton
                        text="Cancel"
                        textBtn
                        onClick={handleModalClose}
                        color={AppColors.red}
                      />
                    </div>
                    <div className="ml10">
                      <CustomButton text="Submit" onClick={handleSubmit} />
                    </div>
                  </div>
                </div>
              </Grid>
            </div>
          </div>
        </CustomModal>
        <CustomModal
          open={delModVisible}
          onClose={handleDelModClose}
          header="Delete"
          modal
          modalWidth={40}
        >
          <Typography className="txt-regular fs16 ml10">
            Are you sure want to delete this from list?
          </Typography>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="clinic-mod-btn-pos mb10 mt15">
              <div className="mr10">
                <CustomButton text="Cancel" onClick={handleDelModClose} />
              </div>
              <div className="ml10">
                <CustomButton redBtn text={"Confirm"} onClick={handleDelete} />
              </div>
            </div>
          </Grid>
        </CustomModal>
      </div>
    </Container>
  );
};

export default ProductComponent;
