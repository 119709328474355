import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Card, CardMedia, Grid, Pagination, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import payImg from "../../../../assets/images/png/pay.png";
import viewImg from "../../../../assets/images/png/view.png";
import CustomButton from "../../../../components/CustomButton";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import CustomTextField from "../../../../components/CustomTextField";
import Select from "../../../../components/Select/Select";
import {
  getPetBillingById,
  getProductsByClinic,
  updatePayment,
} from "../../../../redux/reducers/clinicSlice";
import { AppColors } from "../../../../util/AppColors";
import { PaymentTypeList } from "../../../../util/dropList";
import {
  getBorderColor,
  getPharmacyRecordBasedType,
  getTaxAndSubTotalAndTotal,
  getTreatmentRecordBasedType,
} from "../../../../util/function";
import { CardCss } from "../../../../util/object";
import Billing from "./Billing";

const initialValues = { amount: "", paymentMode: PaymentTypeList?.[0] };

const BillingHistory = ({
  isEditBill,
  setEditBill,
  petId,
  selectedBill,
  setSelectedBill,
}) => {
  const dispatch = useDispatch();
  const contentToPrint = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const itemsPerLoad = 5;
  const [filteredList, setFilteredList] = useState([]);
  const [invModVisible, setInvModVisible] = useState(false);
  const [payValues, setPayValues] = useState(initialValues);
  const user = localStorage.getItem("user");
  const profile = JSON.parse(user);
  const [modalData, setModalData] = useState();
  const [payModalVisible, setPayModalVisible] = useState(false);
  const defaultUrl = `?page=1&itemSize=${itemsPerLoad}`;
  const petBilling = useSelector((state) => state?.clinic?.petBilling);
  const clinicDet = useSelector((state) => state?.clinic?.details);
  const products = useSelector((state) => state?.clinic?.products);

  useEffect(() => {
    if (!isEditBill) {
      dispatch(getPetBillingById({ petId, url: defaultUrl }));
      setPage(1);
      dispatch(getProductsByClinic());
    }
  }, [isEditBill]);

  useEffect(() => {
    const filteredPayments = filterPayments(petBilling?.data, searchValue);
    setFilteredList(filteredPayments);
  }, [petBilling?.data, searchValue]);

  const filterPayments = (billing, searchText) => {
    const reqPayements = billing?.filter((pay) => pay?.petId === petId);
    if (!searchText) return reqPayements;

    return reqPayements?.filter((payment) => {
      const petNameMatch = payment?.petName
        ?.toLowerCase()
        .includes(searchText?.toLowerCase());
      const vetNameMatch = payment?.doctorName
        ?.toLowerCase()
        .includes(searchText?.toLowerCase());
      const paymentStatusMatch = payment?.status
        ?.toLowerCase()
        .includes(searchText?.toLowerCase());
      const issueMatch = payment?.issue
        ?.toLowerCase()
        .includes(searchText?.toLowerCase());
      return petNameMatch || vetNameMatch || paymentStatusMatch || issueMatch;
    });
  };

  const handleChangePage = (e, selectedPage) => {
    dispatch(
      getPetBillingById({
        petId,
        url: `?page=${selectedPage}&itemSize=${itemsPerLoad}`,
      })
    );
    setPage(selectedPage);
  };

  const handleInvModClose = () => {
    setSelectedBill(null);
    setInvModVisible(false);
  };

  const handlePrint = useReactToPrint({
    documentTitle: `${clinicDet?.name ?? ""}-${
      selectedBill?.invoiceNumbers ?? ""
    }`,
    removeAfterPrint: true,
  });

  const payModalOpen = (li) => {
    setModalData(li);
    setPayValues(initialValues);
    setPayModalVisible(!payModalVisible);
  };

  const handleChangeValues = (name, value) => {
    setPayValues({ ...payValues, [name]: value });
  };

  const payModalClose = (li) => {
    setModalData(li);
    setPayValues(initialValues);
    setPayModalVisible(!payModalVisible);
  };

  const finalPayment = () => {
    const data = {
      paymentId: modalData.paymentId,
      paymentMode: payValues?.paymentMode?.value,
      paymentAmount: payValues?.amount,
    };

    dispatch(updatePayment(data)).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        dispatch(getPetBillingById({ petId, url: defaultUrl }));
        setPage(1);
        setPayModalVisible(false);
        setPayValues(initialValues);
      }
    });
  };

  return (
    <>
      {isEditBill ? (
        <Billing bill={selectedBill} />
      ) : (
        <div className="scroll-80vh w100Per">
          <Grid container className="back-white">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="ph20">
              <div className="flex-center">
                <div className="w75Per">
                  <CustomTextField
                    placeholder="Search"
                    fullWidth
                    backgroundColor={AppColors.lightPink}
                    value={searchValue}
                    handleChange={(e) => setSearchValue(e?.target?.value)}
                  />
                </div>
              </div>
              <div className="pb100 w100Per">
                {filteredList?.length > 0 ? (
                  filteredList?.map((bill, ind) => (
                    <div className="flex-row-ali-cen mv10" key={ind + "bill"}>
                      <div className="w20Per">
                        <div className="back-img">
                          <div className="flex-center h100">
                            <div className="flex-column flex-center">
                              <Typography className="black2 fs10 font-bold">
                                {moment(new Date(bill?.createdDate)).format(
                                  "DD MMM"
                                )}
                              </Typography>
                              <Typography className="black2 fs10 font-bold mt5">
                                {moment(new Date(bill?.createdDate)).format(
                                  "YYYY"
                                )}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w60Per">
                        <Card sx={CardCss} className="inner-cards h110">
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className="mt10"
                            >
                              <div className="card-top-color card-top-blue-color" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <div className="ph10">
                                <div className="flex-row">
                                  <div className="flex-start">
                                    <div className="flex-column w10Per">
                                      <div className="h50">
                                        {bill?.doctorImage ? (
                                          <CardMedia
                                            image={bill?.doctorImage}
                                            className="img-h40"
                                          />
                                        ) : (
                                          <div className="flex-center img-hw40 back-gray2">
                                            <Typography className="font-bold fs30 white-color capitalize">
                                              {bill?.doctorName?.[0]}
                                            </Typography>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="flex-column">
                                      <div className="h50 ml30 w100Per">
                                        <Typography className="font-bold fs14 black capitalize">
                                          Dr. {bill?.doctorName}
                                        </Typography>
                                        {/* <Typography className="gray7 fs14 font-medium mt5 capitalize">
                                          {bill?.issue}
                                        </Typography> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex1-end">
                                    {bill?.status === "unpaid" ? (
                                      <img
                                        src={payImg}
                                        alt=""
                                        className="img-hw40 cursor"
                                        onClick={() => {
                                          setPayValues({
                                            ...payValues,
                                            amount: bill?.balanceDue ?? 0,
                                          });
                                          payModalOpen(bill);
                                        }}
                                      />
                                    ) : (
                                      <div className="img-hw40" />
                                    )}
                                    <img
                                      src={viewImg}
                                      alt=""
                                      className="img-hw40 cursor"
                                      onClick={() => {
                                        if (
                                          bill?.appointmentStatus ===
                                            "completed" ||
                                          bill?.status === "paid"
                                        ) {
                                          setSelectedBill(bill);
                                          return setInvModVisible(true);
                                        }
                                        if (
                                          bill?.appointmentStatus !==
                                            "completed" &&
                                          bill?.status !== "paid"
                                        ) {
                                          setSelectedBill(bill);
                                          return setEditBill(true);
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="flex-row-ali-cen mt5">
                                  <div className="flex-start">
                                    <Typography
                                      className={`txt-semi-bold fs12 ${
                                        bill?.balanceDue > 0
                                          ? "red5"
                                          : "blue-color"
                                      }`}
                                    >
                                      Balance Due:{" "}
                                      {Number(
                                        Number(
                                          getTaxAndSubTotalAndTotal(
                                            getTreatmentRecordBasedType(
                                              bill?.paymentDetails
                                            )
                                          )?.subTotal +
                                            getTaxAndSubTotalAndTotal(
                                              getPharmacyRecordBasedType(
                                                bill?.paymentDetails
                                              )
                                            )?.subTotal
                                        ) -
                                          (Number(bill?.amtPaid ?? 0) -
                                            Number(bill?.discount ?? 0))
                                      )?.toFixed(2)}
                                    </Typography>
                                  </div>
                                  <div className="flex1-end">
                                    <div
                                      className={`br5 ph10 pv5 ${
                                        bill?.appointmentType === "Physical"
                                          ? "card-con-blue-back"
                                          : "virtual-bg-color"
                                      }`}
                                    >
                                      <Typography className="header fs12 white-color">
                                        {bill?.appointmentType}
                                      </Typography>
                                    </div>
                                    <div
                                      className={`br5 ph10 pv5 ${
                                        bill?.status === "paid"
                                          ? "green-back2"
                                          : "org-back2"
                                      } ml10`}
                                    >
                                      <Typography
                                        className={`header fs12 ${
                                          bill?.status === "paid"
                                            ? "green2"
                                            : "red5"
                                        }`}
                                      >
                                        {bill?.status === "paid"
                                          ? "Paid"
                                          : "Unpaid"}
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Card>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-rec">No records available</div>
                )}
                {Math.ceil(petBilling?.totalRecords / itemsPerLoad) > 1 ? (
                  <div className="flex-end">
                    <Pagination
                      count={Math.ceil(petBilling?.totalRecords / itemsPerLoad)}
                      variant="outlined"
                      color="primary"
                      page={page}
                      onChange={handleChangePage}
                    />
                  </div>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </div>
      )}
      <CustomModal
        open={payModalVisible}
        onClose={payModalClose}
        header="Pay"
        rightModal
        modalWidth={30}
      >
        <Card
          sx={{
            borderRadius: 1,
            padding: 2,
            borderTopColor: `${getBorderColor(modalData?.status)}`,
          }}
          className="CustomCard-back-bill-payment"
        >
          <div className="maint">
            <div className="flex-row topt">
              <Grid item xs={3} sm={3} md={4} lg={4} xl={4}>
                <div className="flex-center">
                  {modalData?.petImage ? (
                    <CardMedia
                      image={modalData?.petImage}
                      className="CustomCard-img3-bill"
                    />
                  ) : (
                    <div className="flex-center h50img">
                      <Typography className="font-bold fs30 white-color capitalize">
                        {modalData?.petName?.[0]}
                      </Typography>
                    </div>
                  )}
                </div>
              </Grid>
              <div className="flex-row">
                <div className="flex-start">
                  <div className="flex-column">
                    <div className="flex-row">
                      <Typography
                        variant="body1"
                        className="mb10 font-bold fs14 capitalize"
                      >
                        {modalData?.petName}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={`ml5 capitalize font-medium fs14 ${
                          modalData?.gender === "male"
                            ? "card-blue-color"
                            : "card-rose-color"
                        }`}
                      >
                        {`(${modalData?.gender})`}
                      </Typography>
                    </div>
                    <Typography
                      variant="body2"
                      className="mb10 txt-regular card-gray-color fs12"
                    >
                      {modalData?.breed}
                    </Typography>
                  </div>
                </div>
              </div>
              <div />
            </div>
            <div>
              <div className="flex-row parentcontainer">
                <div className="flex-row  iconcontainer">
                  <AccountCircleOutlinedIcon sx={{ width: 25, height: 25 }} />
                  <Typography
                    variant="body1"
                    className="  font-bold fs14 capitalize flex-center h35"
                  >
                    {modalData?.userName ?? modalData?.vetName}
                  </Typography>
                </div>
                <div className="meeting-doctor">
                  Meeting Dr. {modalData?.doctorName}
                </div>
              </div>
              <div className="trdatenew">{modalData?.trDate}</div>
              <div
                className={
                  modalData?.balanceDue === 0 || modalData?.balanceDue === "Nil"
                    ? "balancedueblue"
                    : "balanceduered"
                }
              >
                Balance due: {Number(modalData?.balanceDue)?.toFixed(2)}
              </div>
              <div className="datecontainer"></div>
            </div>
          </div>
          <div
            className={`conttype ${
              modalData?.appointmentType === "Physical"
                ? "card-con-blue-back"
                : "virtual-bg-color"
            }`}
          >
            {modalData?.appointmentType}
          </div>
        </Card>
        <Grid container spacing={2} className="ph20">
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <CustomTextField
              label="Amount"
              name="amount"
              fullWidth
              handleChange={(e) =>
                handleChangeValues("amount", e?.target?.value)
              }
              value={payValues?.amount}
              labelTop
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className="font-medium fs14 black2">Payment Mode</div>
            <Select
              list={PaymentTypeList}
              value={payValues?.paymentMode}
              handleChange={(e, value) =>
                handleChangeValues("paymentMode", value)
              }
              name="paymentMode"
              search
            />
          </Grid>

          <div className="flex1-end mt20">
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <CustomButton text="Pay" onClick={finalPayment} />
            </Grid>
          </div>
        </Grid>
      </CustomModal>
      <CustomModal
        open={invModVisible}
        onClose={handleInvModClose}
        header=""
        modal
        modalWidth={70}
      >
        <div style={{ maxHeight: "80vh", overflowY: "auto" }}>
          <div ref={contentToPrint} className="bg">
            <div>
              <div className="flex-row-ali-cen mv20">
                <div className="w30Per">
                  {profile?.image ? (
                    <img src={profile?.image} className="h75ml30img" alt="" />
                  ) : (
                    <div className="flex-center h50img ml40">
                      <Typography className="font-bold fs30 white-color capitalize">
                        {profile?.name?.[0]}
                      </Typography>
                    </div>
                  )}
                </div>
                <div className="w40Per flex-center">
                  <div className="font-bold fs18 blue-color">
                    TREATMENT INVOICE
                  </div>
                </div>
                <div className="w30Per flex1-end">
                  <div className="flex-column">
                    <div className="font-bold fs12 blue-color">
                      Business Address:
                    </div>
                    <div className="header-right-text2">
                      {clinicDet?.address ?? ""}
                    </div>
                    {clinicDet?.taxId?.length > 0 ? (
                      <div className="flex-row-ali-cen">
                        <div className="font-bold fs12 blue-color">
                          CLINIC TAX ID:{" "}
                        </div>
                        <div className="header-right-text2 ml3">
                          {clinicDet?.taxId ?? ""}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {clinicDet?.mobile?.length > 0 ? (
                      <div className="flex-row-ali-cen">
                        <div className="font-bold fs12 blue-color">
                          Contact:{" "}
                        </div>
                        <div className="header-right-text2 ml3">
                          {clinicDet?.mobile ?? ""}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="line"></div>
              <div className="flex1-end mt10 ph30">
                <div className="flex-column">
                  <div className="flex-row-ali-cen">
                    <Typography className="font-bold fs14 gray4">
                      Payment Status
                    </Typography>
                    <Typography
                      className={`ml10 fs16 font-bold capitalize ${
                        selectedBill?.status === "paid"
                          ? "green2"
                          : selectedBill?.status === "unpaid"
                          ? "red2"
                          : "orange-color"
                      }`}
                    >
                      {selectedBill?.status}
                    </Typography>
                  </div>
                  <div className="flex-row-ali-cen">
                    <Typography className="font-bold fs14 gray4">
                      Total
                    </Typography>
                    <Typography className="black fs16 font-bold ml10">
                      Rs
                      {Number(
                        getTaxAndSubTotalAndTotal(
                          getTreatmentRecordBasedType(
                            selectedBill?.paymentDetails
                          )
                        )?.subTotal +
                          getTaxAndSubTotalAndTotal(
                            getPharmacyRecordBasedType(
                              selectedBill?.paymentDetails
                            )
                          )?.subTotal
                      )?.toFixed(2)}
                    </Typography>
                  </div>
                  <div className="flex-row-ali-cen">
                    <Typography className="font-bold fs14 gray4">
                      Amount Paid
                    </Typography>
                    <Typography className="green-color fs16 font-bold ml10">
                      Rs{" "}
                      {Number(selectedBill?.amtPaid) > 0
                        ? Number(selectedBill?.amtPaid)?.toFixed(2)
                        : Number(selectedBill?.amtPaid)}
                    </Typography>
                  </div>
                  <div className="flex-row-ali-cen">
                    <Typography className="font-bold fs14 gray4">
                      Balance Due
                    </Typography>
                    <Typography className="yellow-color fs16 font-bold ml10">
                      Rs{" "}
                      {Number(
                        Number(
                          getTaxAndSubTotalAndTotal(
                            getTreatmentRecordBasedType(
                              selectedBill?.paymentDetails
                            )
                          )?.subTotal +
                            getTaxAndSubTotalAndTotal(
                              getPharmacyRecordBasedType(
                                selectedBill?.paymentDetails
                              )
                            )?.subTotal
                        ) -
                          (Number(selectedBill?.amtPaid ?? 0) -
                            Number(selectedBill?.discount ?? 0))
                      )?.toFixed(2)}
                    </Typography>
                  </div>
                </div>
              </div>
              <div className="lower-container">
                <div className="box-container">
                  <div className="row-container">
                    <div>
                      <div className="colum-header">Pet Name</div>
                      <div className="colum-data">{selectedBill?.petName}</div>
                    </div>
                    <div>
                      <div className="colum-header">Parent Name</div>
                      <div className="colum-data capitalize">
                        {selectedBill?.userName}
                      </div>
                    </div>
                    <div>
                      <div className="colum-header">Invoice Number</div>
                      <div className="colum-data">
                        {selectedBill?.invoiceNumbers ?? ""}
                      </div>
                    </div>
                    <div>
                      <div className="colum-header">Invoice Date</div>
                      <div className="colum-data">
                        {moment(new Date(selectedBill?.createdDate)).format(
                          "DD MMM YYYY"
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="thin-line"></div>

                  <table className="custom-table">
                    <thead>
                      <tr>
                        <th>SERVICE / ITEM NAME</th>
                        <th>QTY</th>
                        <th>PRICE</th>
                        <th>DISCOUNT (Rs)</th>
                        <th>TAX</th>
                        <th>TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getTreatmentRecordBasedType(selectedBill?.paymentDetails)
                        ?.length > 0 &&
                        getTreatmentRecordBasedType(
                          selectedBill?.paymentDetails
                        )
                          ?.filter((pd) => pd?.type === "consultation")
                          ?.map((con) => (
                            <tr>
                              <td>
                                <div className="flex-row-ali-cen">
                                  Consultation ({con?.name}){" "}
                                  {selectedBill?.doctorName?.length > 0
                                    ? " - "
                                    : ""}
                                  <div className="font-bold fs12 black ml3">
                                    {selectedBill?.doctorName?.length > 0
                                      ? `Dr. ${selectedBill?.doctorName}`
                                      : ""}
                                  </div>
                                  {selectedBill?.registrationNo?.length > 0 ? (
                                    <div className="flex-row-ali-cen">
                                      <div className="font-regular fs12 black ml5">
                                        (
                                      </div>
                                      <div className="font-bold fs12 black ml2">
                                        RegNo:
                                      </div>
                                      <div className="font-regular fs12 black ml5 capitalize">
                                        {selectedBill?.registrationNo ??
                                          selectedBill?.registrationNo}{" "}
                                        )
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </td>
                              <td>1</td>
                              <td>Rs {Number(con?.price)?.toFixed(2)}</td>
                              <td>-</td>
                              <td>
                                {con?.tax > 0
                                  ? Number(con?.tax)?.toFixed(2)
                                  : "-"}
                              </td>
                              <td>Rs {Number(con?.total)?.toFixed(2)}</td>
                            </tr>
                          ))}

                      {getTreatmentRecordBasedType(selectedBill?.paymentDetails)
                        ?.length > 0 &&
                        getTreatmentRecordBasedType(
                          selectedBill?.paymentDetails
                        )
                          ?.filter((pd) => pd?.type !== "consultation")
                          ?.map((itm, i) => {
                            let prdct = null;
                            if (itm?.productId) {
                              prdct = products?.data?.find(
                                (prd) => prd?.productId === itm?.productId
                              );
                            }

                            return (
                              <tr key={i}>
                                <td>
                                  <div className="flex-column">
                                    {itm?.name}
                                    {prdct ? (
                                      <div className="flex-row-ali-cen mt3">
                                        <Typography className="txt-regular fs10 gray-color">
                                          Mfg. Date:{" "}
                                          {moment(
                                            prdct?.manufacturingDate instanceof
                                              Date
                                              ? prdct?.manufacturingDate
                                              : new Date()
                                          ).format("DD MMM YYYY")}
                                          {", "}
                                          Exp. Date:{" "}
                                          {moment(
                                            prdct?.expiryDate instanceof Date
                                              ? prdct?.expiryDate
                                              : new Date()
                                          ).format("DD MMM YYYY")}
                                          {prdct?.batchNumber?.length > 0
                                            ? `, Batch No.: ${prdct?.batchNumber}`
                                            : ""}
                                        </Typography>
                                      </div>
                                    ) : null}
                                  </div>
                                </td>
                                <td>{itm?.quantity}</td>
                                <td>Rs {Number(itm?.price)?.toFixed(2)}</td>
                                <td>-</td>
                                <td>
                                  {itm?.tax > 0
                                    ? Number(itm?.tax)?.toFixed(2)
                                    : "-"}
                                </td>
                                <td>Rs {Number(itm?.total)?.toFixed(2)}</td>
                              </tr>
                            );
                          })}

                      <tr>
                        <td colSpan="6">
                          <hr className="thin-line2" />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="12">
                          <div className="flex-row-between">
                            <div className="w65Per ali-itm-flx-end">
                              <div className="flex-column">
                                <div className="txt-semi-bold fs12 black">
                                  Disclaimer:
                                </div>
                                <Typography className="fs12 txt-regular gray-color mt5">
                                  {clinicDet?.disclaimer}
                                </Typography>
                              </div>
                            </div>
                            <div className="w35Per flex-end pr10">
                              <div className="flex-column w100Per">
                                <div className="flex-row-ali-cen mb5">
                                  <div className="w70Per">
                                    <div className="txtstyle">Discount</div>
                                  </div>
                                  <div className="w30Per flex-end">
                                    <div className="txtstyle">
                                      {selectedBill?.discount > 0
                                        ? `Rs ${Number(
                                            selectedBill?.discount
                                          )?.toFixed(2)}`
                                        : "-"}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-row-ali-cen mv5">
                                  <div className="w60Per">
                                    <div className="txtstyle">
                                      Treatment Subtotal
                                    </div>
                                  </div>
                                  <div className="w40Per flex-end">
                                    <div className="txtstyle">
                                      Rs{" "}
                                      {Number(
                                        getTaxAndSubTotalAndTotal(
                                          getTreatmentRecordBasedType(
                                            selectedBill?.paymentDetails
                                          )
                                        )?.subTotal
                                      )?.toFixed(2)}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-row-ali-cen mv5">
                                  <div className="w60Per">
                                    <div className="txtstyle">Tax</div>
                                  </div>
                                  <div className="w40Per flex-end">
                                    <div className="txtstyle">
                                      {getTaxAndSubTotalAndTotal(
                                        getTreatmentRecordBasedType(
                                          selectedBill?.paymentDetails
                                        )
                                      )?.tax > 0
                                        ? `Rs ${Number(
                                            getTaxAndSubTotalAndTotal(
                                              getTreatmentRecordBasedType(
                                                selectedBill?.paymentDetails
                                              )
                                            )?.tax
                                          )?.toFixed(2)}`
                                        : "-"}
                                    </div>
                                  </div>
                                </div>
                                <div className="balancecontainer ph5 mt10">
                                  <div className="flex-row-ali-cen w100Per">
                                    <div className="w60Per">
                                      <div className="txt-semi-bold fs14 blue-color">
                                        Total
                                      </div>
                                    </div>
                                    <div className="w40Per flex-end">
                                      <div className="txt-semi-bold fs14 blue-color">
                                        Rs{" "}
                                        {Number(
                                          getTaxAndSubTotalAndTotal(
                                            getTreatmentRecordBasedType(
                                              selectedBill?.paymentDetails
                                            )
                                          )?.subTotal
                                        )?.toFixed(2)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <div className="mb20" />
                    </tbody>
                  </table>
                  <div className="flex-row mv20 ph10">
                    <img
                      src={require("../../../../assets/images/jpg/WoofQrCode.jpeg")}
                      alt=""
                      className="img-h50"
                    />
                    <div className="blu-back wh50 flex-center">
                      <img
                        src={require("../../../../assets/images/png/VetInstantLogo.png")}
                        alt=""
                        className="img-h40"
                      />
                    </div>
                    <div className="flex-center">
                      <div className="font-bold fs14 ml20">
                        Scan to download our app
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-break" />
            <div>
              <div className="flex-row-ali-cen mv20">
                <div className="w30Per">
                  {profile?.image ? (
                    <img src={profile?.image} className="h75ml30img" alt="" />
                  ) : (
                    <div className="flex-center h50img ml40">
                      <Typography className="font-bold fs30 white-color capitalize">
                        {profile?.name?.[0]}
                      </Typography>
                    </div>
                  )}
                </div>
                <div className="w40Per flex-center">
                  <div className="font-bold fs18 blue-color">
                    PHARMACY INVOICE
                  </div>
                </div>
                <div className="w30Per flex1-end">
                  <div className="flex-column">
                    <div className="font-bold fs12 blue-color">
                      Business Address:
                    </div>
                    <div className="header-right-text2">
                      {clinicDet?.address ?? ""}
                    </div>
                    {clinicDet?.pharmacyTaxId?.length > 0 ? (
                      <div className="flex-row-ali-cen">
                        <div className="font-bold fs12 blue-color">
                          PHARMACY TAX ID:{" "}
                        </div>
                        <div className="header-right-text2 ml3">
                          {clinicDet?.pharmacyTaxId ?? ""}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {clinicDet?.mobile?.length > 0 ? (
                      <div className="flex-row-ali-cen">
                        <div className="font-bold fs12 blue-color">
                          Contact:{" "}
                        </div>
                        <div className="header-right-text2 ml3">
                          {clinicDet?.mobile ?? ""}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="line"></div>
              <div className="flex1-end mt10 ph30">
                <div className="flex-column">
                  <div className="flex-row-ali-cen">
                    <Typography className="font-bold fs14 gray4">
                      Payment Status
                    </Typography>
                    <Typography
                      className={`ml10 fs16 font-bold capitalize ${
                        selectedBill?.status === "paid"
                          ? "green2"
                          : selectedBill?.status === "unpaid"
                          ? "red2"
                          : "orange-color"
                      }`}
                    >
                      {selectedBill?.status}
                    </Typography>
                  </div>
                  <div className="flex-row-ali-cen">
                    <Typography className="font-bold fs14 gray4">
                      Total
                    </Typography>
                    <Typography className="black fs16 font-bold ml10">
                      Rs{" "}
                      {Number(
                        getTaxAndSubTotalAndTotal(
                          getTreatmentRecordBasedType(
                            selectedBill?.paymentDetails
                          )
                        )?.subTotal +
                          getTaxAndSubTotalAndTotal(
                            getPharmacyRecordBasedType(
                              selectedBill?.paymentDetails
                            )
                          )?.subTotal
                      )?.toFixed(2)}
                    </Typography>
                  </div>
                  <div className="flex-row-ali-cen">
                    <Typography className="font-bold fs14 gray4">
                      Amount Paid
                    </Typography>
                    <Typography className="green-color fs16 font-bold ml10">
                      Rs{" "}
                      {Number(selectedBill?.amtPaid) > 0
                        ? Number(selectedBill?.amtPaid)?.toFixed(2)
                        : Number(selectedBill?.amtPaid)}
                    </Typography>
                  </div>
                  <div className="flex-row-ali-cen">
                    <Typography className="font-bold fs14 gray4">
                      Balance Due
                    </Typography>
                    <Typography className="yellow-color fs16 font-bold ml10">
                      Rs{" "}
                      {Number(
                        Number(
                          getTaxAndSubTotalAndTotal(
                            getTreatmentRecordBasedType(
                              selectedBill?.paymentDetails
                            )
                          )?.subTotal +
                            getTaxAndSubTotalAndTotal(
                              getPharmacyRecordBasedType(
                                selectedBill?.paymentDetails
                              )
                            )?.subTotal
                        ) -
                          (Number(selectedBill?.amtPaid ?? 0) -
                            Number(selectedBill?.discount ?? 0))
                      )?.toFixed(2)}
                    </Typography>
                  </div>
                </div>
              </div>
              <div className="lower-container">
                <div className="box-container">
                  <div className="row-container">
                    <div>
                      <div className="colum-header">Pet Name</div>
                      <div className="colum-data">{selectedBill?.petName}</div>
                    </div>
                    <div>
                      <div className="colum-header">Parent Name</div>
                      <div className="colum-data capitalize">
                        {selectedBill?.userName}
                      </div>
                    </div>
                    <div>
                      <div className="colum-header">Invoice Number</div>
                      <div className="colum-data">
                        {selectedBill?.invoiceNumbers ?? ""}
                      </div>
                    </div>
                    <div>
                      <div className="colum-header">Invoice Date</div>
                      <div className="colum-data">
                        {moment(new Date(selectedBill?.createdDate)).format(
                          "DD MMM YYYY"
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="thin-line"></div>

                  <table className="custom-table">
                    <thead>
                      <tr>
                        <th>SERVICE / ITEM NAME</th>
                        <th>QTY</th>
                        <th>PRICE</th>
                        <th>DISCOUNT (Rs)</th>
                        <th>TAX</th>
                        <th>TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getPharmacyRecordBasedType(selectedBill?.paymentDetails)
                        ?.length > 0 &&
                        getPharmacyRecordBasedType(selectedBill?.paymentDetails)
                          ?.filter((pd) => pd?.type !== "consultation")
                          ?.map((itm, i) => {
                            let prdct = null;
                            if (itm?.productId) {
                              prdct = products?.data?.find(
                                (prd) => prd?.productId === itm?.productId
                              );
                            }

                            return (
                              <tr key={i}>
                                <td>
                                  <div className="flex-column">
                                    {itm?.name}
                                    {prdct ? (
                                      <div className="flex-row-ali-cen mt3">
                                        <Typography className="txt-regular fs10 gray-color">
                                          Mfg. Date:{" "}
                                          {moment(
                                            prdct?.manufacturingDate instanceof
                                              Date
                                              ? prdct?.manufacturingDate
                                              : new Date()
                                          ).format("DD MMM YYYY")}
                                          {", "}
                                          Exp. Date:{" "}
                                          {moment(
                                            prdct?.expiryDate instanceof Date
                                              ? prdct?.expiryDate
                                              : new Date()
                                          ).format("DD MMM YYYY")}
                                          {prdct?.batchNumber?.length > 0
                                            ? `, Batch No.: ${prdct?.batchNumber}`
                                            : ""}
                                        </Typography>
                                      </div>
                                    ) : null}
                                  </div>
                                </td>
                                <td>{itm?.quantity}</td>
                                <td>Rs {Number(itm?.price)?.toFixed(2)}</td>
                                <td>-</td>
                                <td>
                                  {itm?.tax > 0
                                    ? Number(itm?.tax)?.toFixed(2)
                                    : "-"}
                                </td>
                                <td>Rs {Number(itm?.total)?.toFixed(2)}</td>
                              </tr>
                            );
                          })}
                      <tr>
                        <td colSpan="6">
                          <hr className="thin-line2" />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="12">
                          <div className="flex-row-between">
                            <div className="w65Per ali-itm-flx-end">
                              <div className="flex-column">
                                <div className="txt-semi-bold fs12 black">
                                  Disclaimer:
                                </div>
                                <Typography className="fs12 txt-regular gray-color mt5">
                                  {clinicDet?.disclaimer}
                                </Typography>
                              </div>
                            </div>
                            <div className="w35Per flex-end pr10">
                              <div className="flex-column w100Per">
                                <div className="flex-row-ali-cen mb5">
                                  <div className="w70Per">
                                    <div className="txtstyle">Discount</div>
                                  </div>
                                  <div className="w30Per flex-end">
                                    <div className="txtstyle">
                                      {selectedBill?.discount > 0
                                        ? `Rs ${Number(
                                            selectedBill?.discount
                                          )?.toFixed(2)}`
                                        : "-"}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-row-ali-cen mv5">
                                  <div className="w60Per">
                                    <div className="txtstyle">
                                      Pharmacy Subtotal
                                    </div>
                                  </div>
                                  <div className="w40Per flex-end">
                                    <div className="txtstyle">
                                      Rs{" "}
                                      {Number(
                                        getTaxAndSubTotalAndTotal(
                                          getPharmacyRecordBasedType(
                                            selectedBill?.paymentDetails
                                          )
                                        )?.subTotal -
                                          getTaxAndSubTotalAndTotal(
                                            getPharmacyRecordBasedType(
                                              selectedBill?.paymentDetails
                                            )
                                          )?.tax
                                      )?.toFixed(2)}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-row-ali-cen mv5">
                                  <div className="w60Per">
                                    <div className="txtstyle">Tax</div>
                                  </div>
                                  <div className="w40Per flex-end">
                                    <div className="txtstyle">
                                      {getTaxAndSubTotalAndTotal(
                                        getPharmacyRecordBasedType(
                                          selectedBill?.paymentDetails
                                        )
                                      )?.tax > 0
                                        ? `Rs ${Number(
                                            getTaxAndSubTotalAndTotal(
                                              getPharmacyRecordBasedType(
                                                selectedBill?.paymentDetails
                                              )
                                            )?.tax
                                          )?.toFixed(2)}`
                                        : "-"}
                                    </div>
                                  </div>
                                </div>
                                <div className="balancecontainer ph5 mt10">
                                  <div className="flex-row-ali-cen w100Per">
                                    <div className="w60Per">
                                      <div className="txt-semi-bold fs14 blue-color">
                                        Total
                                      </div>
                                    </div>
                                    <div className="w40Per flex-end">
                                      <div className="txt-semi-bold fs14 blue-color">
                                        Rs{" "}
                                        {Number(
                                          getTaxAndSubTotalAndTotal(
                                            getPharmacyRecordBasedType(
                                              selectedBill?.paymentDetails
                                            )
                                          )?.subTotal
                                        )?.toFixed(2)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <div className="mb20" />
                    </tbody>
                  </table>
                  <div className="flex-row mv20 ph10">
                    <img
                      src={require("../../../../assets/images/jpg/WoofQrCode.jpeg")}
                      alt=""
                      className="img-h50"
                    />
                    <div className="blu-back wh50 flex-center">
                      <img
                        src={require("../../../../assets/images/png/VetInstantLogo.png")}
                        alt=""
                        className="img-h40"
                      />
                    </div>
                    <div className="flex-center">
                      <div className="font-bold fs14 ml20">
                        Scan to download our app
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="back-white"
          >
            <div className="flex-end">
              <div className="mr20 mv20">
                <CustomButton
                  text="Download"
                  onClick={() =>
                    handlePrint(null, () => contentToPrint.current)
                  }
                  smallBtn
                />
              </div>
            </div>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
};

export default BillingHistory;
