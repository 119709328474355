import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Custom3dotString from "../../../components/Custom3dotString";
import CustomButton from "../../../components/CustomButton";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CustomTextField from "../../../components/CustomTextField";
import CustomUpload from "../../../components/CustomUpload";
import { default as Select } from "../../../components/Select/Select";
import { chatgptApi } from "../../../redux/actions/api";
import {
  createStocks,
  deleteStockById,
  getPendingStocksByClinic,
  getStocksByClinic,
} from "../../../redux/reducers/clinicSlice";
import { hideLoader, showLoader } from "../../../redux/reducers/loaderSlice";
import { AppColors } from "../../../util/AppColors";
import { invetMsg } from "../../../util/chatgpt";
import {
  locationList,
  natureList,
  productCategoryList,
  productMeasurementUnit,
  productSubMeasurementUnit,
  yesNoValueList,
} from "../../../util/dropList";
import {
  convertDateYMD,
  convertDateYMDSlace,
  convertStringToObject,
  getExactNumber,
} from "../../../util/function";
import FilesWithCloseButton from "../ClinicPetDetails/PatientWorkbook/FilesWithCloseButton";

const requiredFieldList = [
  "category",
  "subCategory",
  "hsnSacCode",
  "name",
  "tradeName",
  "measurementUnit",
  "subMeasurementUnit",
  // "manufacturingDate",
  // "expiryDate",
  "location",
  "sellPrice",
  "subunitSellingPrice",
  // "administeringCharges",
  "quantity",
  "unitRate",
  "cgst",
  "sgst",
  "itemTotal",
];

const initStckVal = { supplierName: null, date: new Date(), list: [] };
const initialValues = {
  category: null,
  subCategory: "",
  name: "",
  tradeName: "",
  ingredient: "",
  nature: null,
  code: "",
  brand: "",
  measurementUnit: null,
  subMeasurementUnit: null,
  formula: "",
  batchNumber: "",
  manufacturingDate: null,
  expiryDate: null,
  location: null,

  sellPrice: "",
  subunitSellingPrice: "",
  administeringCharges: "",

  description: "",
};
const initialError = {
  category: false,
  subCategory: false,
  name: false,
  tradeName: false,
  ingredient: false,
  nature: false,
  code: false,
  brand: false,
  measurementUnit: false,
  subMeasurementUnit: false,
  formula: false,
  batchNumber: false,
  manufacturingDate: false,
  expiryDate: false,
  location: false,

  sellPrice: false,
  subunitSellingPrice: false,
  administeringCharges: false,

  description: false,
};

const initialHelp = {
  category: "",
  subCategory: "",
  name: "",
  tradeName: "",
  ingredient: "",
  nature: "",
  code: "",
  brand: "",
  measurementUnit: "",
  subMeasurementUnit: "",
  formula: "",
  batchNumber: "",
  manufacturingDate: "",
  expiryDate: "",
  location: "",

  sellPrice: "",
  subunitSellingPrice: "",
  administeringCharges: "",

  description: "",
};

const stckEntTblHdrs = [
  "icon",
  "hsnSacCode",
  "name",
  "quantity",
  "unitRate",
  "cgst",
  "sgst",
  "taxes",
  "itemTotal",
  "status",
];

const StockPendingTable = ({ tblGrayBack, setPenTblVsble, stckNmeExpan }) => {
  const dispatch = useDispatch();
  const [stckValue, setStckValue] = useState(initStckVal);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [invntUpdModVsble, setInvntUpdModVsble] = useState(false);
  const [inventFiles, setInventFiles] = useState([]);
  const [delModVsble, setDelModVsble] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [prdctPrevModVsble, setPrdctPrevModVsble] = useState(false);
  const [productErrors, setProductErrors] = useState(initialError);
  const [productHelps, setProductHelps] = useState(initialHelp);
  const [productValues, setProductValues] = useState(initialValues);
  const pendingStocks = useSelector((state) => state?.clinic?.pendingStocks);
  const defaultUrl = "?type=pending";

  useEffect(() => {
    dispatch(getPendingStocksByClinic(defaultUrl)).then((res) => {
      if (res?.payload?.data) {
        getTableList(res?.payload?.data);
      }
    });
  }, []);

  useEffect(() => {
    if (stckValue?.list?.length === 0 || !stckValue?.list)
      return setFilteredList([]);
    const reqList = stckValue?.list?.filter((li) =>
      li?.tradeName?.toLowerCase()?.includes(searchValue?.toLowerCase())
    );
    setFilteredList(reqList);
  }, [searchValue, stckValue?.list]);

  const getTableList = (pendingList = []) => {
    if (pendingList?.length === 0) {
      return setStckValue({ ...stckValue, list: [] });
    }
    const list = pendingList?.map((itm, i) => ({
      ...itm,
      name: itm?.tradeName,
      i,
      apiCalled: false,
      backEndApiCalled: false,
    }));
    setStckValue({ ...stckValue, list });
  };

  const handleApiCall = () => {
    const prdct = productValues?.product;
    dispatch(showLoader());
    const product = {
      barCodeNo: prdct?.barCodeNumber ?? "",
      batchNumber: prdct?.batchNumber ?? "",
      brand: prdct?.brand ?? "",
      category: prdct?.category?.value ?? prdct?.category ?? "",
      expiryDate: moment(new Date(prdct?.expiryDate ?? prdct?.expDate)).format(
        "YYYY-MM-DD"
      ),
      formula: prdct?.formula ?? "",
      mfgDate: moment(
        new Date(prdct?.mfgDate ?? prdct?.manufacturingDate ?? new Date())
      ).format("YYYY-MM-DD"),
      measurementUnit:
        prdct?.measurementUnit?.value ?? prdct?.measurementUnit ?? "",
      subMeasurementUnit:
        prdct?.subMeasurementUnit?.value ?? prdct?.subMeasurementUnit ?? "",
      prodcutName: prdct?.name ?? "",
      hsnSacCode: prdct?.hsnSacCode ?? "",
      tradeName: prdct?.tradeName ?? "",
      ingredient: prdct?.ingredient ?? "", //not receiving
      nature: prdct?.nature?.value ?? prdct?.nature ?? "",
      type: prdct?.type ?? "", //not receiving
      tax: Number(getExactNumber(prdct?.taxes ?? selectedRowData?.taxes) ?? 0), //not receiving
      administeringCharges: Number(prdct?.administeringCharges ?? 0), //not receiving
      subCategory: prdct?.subCategory ?? "",
      unitSellingPrice: Number(getExactNumber(prdct?.unitSellingPrice) ?? 0),
      subUnitSellingPrice: Number(
        getExactNumber(prdct?.subunitSellingPrice) ?? 0
      ),
      location: prdct?.location?.value ?? prdct?.location ?? "",
      active: true,
    };

    const data = {
      stocks: [
        {
          hsnSacCode: prdct?.hsnSacCode,
          tradeName: prdct?.tradeName ?? selectedRowData?.tradeName,
          quantity: Number(
            getExactNumber(prdct?.quantity ?? selectedRowData?.quantity) ?? 1
          ),
          unitRate: Number(
            getExactNumber(prdct?.unitRate ?? selectedRowData?.baseCost) ?? 0
          ),
          cgst: Number(
            getExactNumber(prdct?.cgst ?? selectedRowData?.cgst) ?? 0
          ),
          sgst: Number(
            getExactNumber(prdct?.sgst ?? selectedRowData?.sgst) ?? 0
          ),
          taxes: Number(
            getExactNumber(prdct?.taxes ?? selectedRowData?.taxes) ?? 0
          ),
          itemTotal: Number(
            getExactNumber(prdct?.itemTotal ?? selectedRowData?.itemTotal) ?? 0
          ),
          landedCost:
            Number(
              getExactNumber(
                prdct?.landedCost ?? selectedRowData?.landedCost
              ) ?? 0
            ) ?? 0,
          status: true,
          product,
          stockId: selectedRowData?.stockId,
        },
      ],
    };
    try {
      dispatch(createStocks(data)).then((res) => {
        if (res?.payload) {
          const reqList = stckValue?.list?.map((li, i) => {
            if (i === selectedRowData?.i) {
              return {
                ...li,
                apiCalled: true,
                backEndApiCalled: true,
                name: product?.tradeName,
              };
            }
            return li;
          });
          dispatch(getPendingStocksByClinic(defaultUrl));
          setStckValue({ ...stckValue, list: reqList });
          setInventFiles([]);
          setInvntUpdModVsble(false);
          setSelectedRowData(null);
          setPrdctPrevModVsble(false);
          setProductErrors(initialError);
          setProductHelps(initialHelp);
        }
      });
    } catch (err) {
      dispatch(hideLoader());
      console.log("Err");
    }
  };

  const handleUploadInventry = (data) => {
    setSelectedRowData(data);
    setInvntUpdModVsble(true);
  };

  const handleBack = () => {
    dispatch(getStocksByClinic(`?page=1&itemSize=10`));
    setPenTblVsble(false);
  };

  const handleInventImageRemove = (ind) => {
    const reqList = inventFiles
      ?.filter((fle, i) => i !== ind)
      ?.map((fle, i) => ({ ...fle, i }));
    setInventFiles(reqList);
  };

  const onInventoryUpload = () => {
    if (inventFiles?.length === 0 || !inventFiles) return;
    dispatch(showLoader());
    const payload = {
      model: "gpt-4o",
      messages: [
        invetMsg,
        {
          role: "user",
          content: inventFiles?.map((fle) => ({
            type: "image_url",
            image_url: {
              url: fle?.previewUrl,
            },
          })),
        },
      ],
    };

    chatgptApi
      .post("/v1/chat/completions", payload)
      .then((response) => {
        if (response?.status === 200) {
          const rawContent = response.data?.choices?.[0].message?.content;
          if (rawContent) {
            const prdct = convertStringToObject(rawContent);
            setPrdctPrevModVsble(true);
            setProductValues({
              ...productValues,
              apiResponse: JSON.stringify(prdct),
              product: {
                ...productValues,
                ...selectedRowData,
                ...prdct,
                hsnSacCode: selectedRowData?.hsnSacCode,
                category:
                  productCategoryList?.find(
                    (prd) => prd?.value === prdct?.["category" || "Category"]
                  ) ?? null,
                ingredient:
                  prdct?.["majorActiveIngredients" || "MajorActiveIngredients"],
                nature:
                  natureList?.find(
                    (nat) => nat?.value === prdct?.["nature" || "Nature"]
                  ) ?? null,
                code: prdct?.["barCodeNumber" || "BarCodeNumber"],
                measurementUnit:
                  productMeasurementUnit?.find(
                    (unit) =>
                      unit?.value ===
                      prdct?.["measurementUnit" || "MeasurementUnit"]
                  ) ?? null,
                submeasurementUnit:
                  productMeasurementUnit?.find(
                    (unit) =>
                      unit?.value ===
                      prdct?.["submeasurementUnit" || "SubmeasurementUnit"]
                  ) ?? null,
                manufacturingDate: new Date(
                  convertDateYMD(
                    prdct?.mfgDate ??
                      prdct?.["manufacturingDate" || "ManufacturingDate"]
                  ) ??
                    convertDateYMDSlace(
                      prdct?.mfgDate ??
                        prdct?.["manufacturingDate" || "ManufacturingDate"]
                    ) ??
                    new Date()
                ),
                expiryDate: new Date(
                  convertDateYMD(prdct?.["expiryDate" || "ExpiryDate"]) ??
                    convertDateYMDSlace(
                      prdct?.["expiryDate" || "ExpiryDate"]
                    ) ??
                    new Date()
                ),
                location:
                  locationList?.find(
                    (loc) => loc?.value === prdct?.["location" || "Location"]
                  ) ?? null,
                description: prdct?.["briefDescription" || "BriefDescription"],
                sellPrice: prdct?.["unitSellingPrice" || "UnitSellingPrice"],
                administeringCharges: prdct?.administeringCharges ?? "0",
              },
            });
            // handleApiCall(cleanedContent);
            dispatch(hideLoader());
          } else {
            dispatch(hideLoader());
            console.error("No content found in the response");
          }
        } else {
          dispatch(hideLoader());
          console.error("Error:", response.problem);
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        console.log("ERROR", error);
      });
  };

  const handleCloseInvntUpload = () => {
    setInvntUpdModVsble(false);
    setInventFiles([]);
  };

  const handleDeleteRow = (dta) => {
    setSelectedRowData(dta);
    setDelModVsble(true);
  };

  const handleCloseDelMod = () => {
    setDelModVsble(false);
    setSelectedRowData(null);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteStockById(selectedRowData?.stockId)).then((res) => {
      if (res?.payload) {
        dispatch(getPendingStocksByClinic(defaultUrl)).then((res) => {
          if (res?.payload?.data) {
            getTableList(res?.payload?.data);
          }
        });
        handleCloseDelMod();
      }
    });
  };

  const onUploadFile = (e) => {
    const fileList = e?.target?.files;
    let fileLists = inventFiles?.length > 0 ? [...inventFiles] : [];

    const readFiles = Array.from(fileList).map((file, i) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          fileLists = [
            ...fileLists,
            {
              file: file,
              previewUrl: reader.result,
              i,
            },
          ];
          resolve();
        };
        reader.onerror = () => reject(reader.error);
        reader.readAsDataURL(file);
      });
    });

    Promise.all(readFiles)
      .then(() => setInventFiles(fileLists))
      .catch((error) => console.error("Error reading files: ", error));
  };

  const onClosePrevMod = () => {
    setPrdctPrevModVsble(false);
    setSelectedRowData(null);
    setProductErrors(initialError);
    setProductHelps(initialHelp);
  };

  const handleChange = (name, value) => {
    setProductValues({
      ...productValues,
      product: { ...productValues?.product, [name]: value },
    });
    const reqValue = typeof value === "object" ? value?.value : value;
    if (reqValue?.length > 0) {
      setProductErrors({ ...productErrors, [name]: false });
      setProductHelps({ ...productHelps, [name]: "" });
    } else {
      setProductErrors({
        ...productErrors,
        [name]: requiredFieldList?.includes(name)
          ? name === "manufacturingDate" || name === "expiryDate"
            ? false
            : true
          : false,
      });
      setProductHelps({
        ...productHelps,
        [name]: requiredFieldList?.includes(name)
          ? name === "manufacturingDate" || name === "expiryDate"
            ? ""
            : "This field is required"
          : "",
      });
    }
  };

  const handleSubmit = async () => {
    let errObj = {};
    let hlpObj = {};
    requiredFieldList?.forEach((fld) => {
      const reqValue =
        typeof productValues?.product?.[fld] === "object"
          ? productValues?.product?.[fld]?.value
          : productValues?.product?.[fld];

      if (
        reqValue === undefined ||
        reqValue === null ||
        reqValue?.length === 0 ||
        reqValue === ""
      ) {
        errObj = {
          ...errObj,
          [fld]: requiredFieldList?.includes(fld)
            ? fld === "manufacturingDate" || fld === "expiryDate"
              ? false
              : true
            : false,
        };
        hlpObj = {
          ...hlpObj,
          [fld]: requiredFieldList?.includes(fld)
            ? fld === "manufacturingDate" || fld === "expiryDate"
              ? ""
              : "This field is required"
            : "",
        };
      } else {
        errObj = { ...errObj, [fld]: false };
        hlpObj = { ...hlpObj, [fld]: "" };
      }
    });
    setProductErrors({ ...productErrors, ...errObj });
    setProductHelps({ ...productHelps, ...hlpObj });
    if (Object.keys(errObj)?.some((val) => errObj?.[val] === true)) return;
    handleApiCall();
  };

  return (
    <>
      <div className="com-mar back-white pv10">
        <div className="flex-row-ali-cen w100Per">
          <div className="flex-center w100Per">
            <div className="flex-row-ali-cen">
              <div style={{ width: "600px" }}>
                <CustomTextField
                  placeholder="Search"
                  fullWidth
                  backgroundColor={AppColors.lightPink}
                  value={searchValue}
                  handleChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div style={{ position: "absolute", left: 70 }} onClick={handleBack}>
            <div className="flex-row-ali-cen cursor">
              <ArrowBackIcon
                sx={{ color: AppColors.appPrimary, width: 20, height: 20 }}
              />
              <Typography className="fs16 txt-semi-bold blue-color ml5">
                Back
              </Typography>
            </div>
          </div>
          <div className="pos-absolute r80">
            <Typography className="font-medium fs16">
              Total: {pendingStocks?.totalRecords}
            </Typography>
          </div>
        </div>
        <Box className="pb-40 ph20">
          <TableContainer sx={{ maxHeight: 400 }} className="mb20">
            <Table
              sx={{ minWidth: 200 }}
              aria-labelledby="tableTitle"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {stckEntTblHdrs?.map((hdr, i) => (
                    <TableCell
                      style={tblGrayBack}
                      className={`table-header-text`}
                    >
                      <div
                        style={{
                          width:
                            hdr === "icon"
                              ? 40
                              : hdr === "hsnSacCode" || hdr === "name"
                              ? 120
                              : hdr === "quantity" ||
                                hdr === "cgst" ||
                                hdr === "sgst"
                              ? 50
                              : hdr === "taxes" || hdr === "itemTotal"
                              ? 80
                              : hdr === "unitRate"
                              ? 100
                              : 70,
                        }}
                      >
                        {stckNmeExpan?.[hdr]}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredList?.map((li, ind) => {
                  return (
                    <TableRow>
                      {stckEntTblHdrs?.map((hdr, i) => (
                        <TableCell key={ind + i + "tr"} component="th">
                          <div
                            style={{
                              width:
                                hdr === "icon"
                                  ? 40
                                  : hdr === "hsnSacCode" || hdr === "name"
                                  ? 120
                                  : hdr === "quantity" ||
                                    hdr === "cgst" ||
                                    hdr === "sgst"
                                  ? 50
                                  : hdr === "taxes" || hdr === "itemTotal"
                                  ? 80
                                  : hdr === "unitRate"
                                  ? 100
                                  : 70,
                            }}
                          >
                            {i === 0 ? (
                              li?.backEndApiCalled ? (
                                <CheckCircleIcon
                                  sx={{ color: AppColors.green2 }}
                                />
                              ) : (
                                <PriorityHighIcon
                                  sx={{ color: AppColors.appYellow }}
                                />
                              )
                            ) : i === stckEntTblHdrs?.length - 1 ? (
                              <div className="flex-row-ali-cen">
                                <img
                                  src={require("../../../assets/images/png/delete.png")}
                                  className="imghw30 cursor"
                                  onClick={() => handleDeleteRow(li)}
                                />
                                {
                                  <img
                                    src={require("../../../assets/images/png/imgAttach.png")}
                                    className="imghw30 cursor ml20"
                                    onClick={() => handleUploadInventry(li)}
                                  />
                                }
                              </div>
                            ) : hdr === "name" ? (
                              <Custom3dotString
                                str={li?.[hdr]}
                                className="font-regular fs14"
                              />
                            ) : hdr === "taxes" || hdr === "itemTotal" ? (
                              Number(li?.[hdr])?.toFixed(2)
                            ) : (
                              li?.[hdr]
                            )}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>

      <CustomModal
        open={invntUpdModVsble}
        onClose={handleCloseInvntUpload}
        header="Inventory Upload"
        headerCenter
        modalWidth={50}
        modal
      >
        <Grid container spacing={2}>
          {inventFiles?.length ? (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mt20">
              <FilesWithCloseButton
                files={inventFiles}
                onClickFile={(i, fle) => handleInventImageRemove(i)}
                center
              />
            </Grid>
          ) : null}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CustomUpload
              onUploadFile={onUploadFile}
              multipleNew
              fileAccept="image/*"
              multiFiles
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="flex-center">
              <div>
                <CustomButton
                  text="Submit"
                  smallBtn
                  onClick={onInventoryUpload}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </CustomModal>
      <CustomModal
        open={delModVsble}
        onClose={handleCloseDelMod}
        header="Delete Product"
        modal
        modalWidth={40}
      >
        <Typography className="txt-regular fs16 ml10">
          Are you sure you want to Delete the selected Product?
        </Typography>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className="clinic-mod-btn-pos mb10 mt15">
            <div className="mr10">
              <CustomButton text="Yes" redBtn onClick={handleConfirmDelete} />
            </div>
            <div className="ml10">
              <CustomButton text="No" onClick={handleCloseDelMod} />
            </div>
          </div>
        </Grid>
      </CustomModal>

      <CustomModal
        open={prdctPrevModVsble}
        onClose={onClosePrevMod}
        header="Preview"
        headerCenter
        modalWidth={100}
        modal
      >
        <div className="ph20 scroll-80vh">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className="font-bold fs16 blue-color">
                Product Details
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Category <span className="red-color">*</span>
              </div>
              <Select
                list={productCategoryList}
                value={productValues?.product?.category}
                handleChange={(e, value) => handleChange("category", value)}
                name="category"
                search
                error={productErrors?.category}
                helperText={productHelps?.category}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Sub-Category <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="subCategory"
                fullWidth
                value={productValues?.product?.subCategory}
                error={productErrors?.subCategory}
                helperText={productHelps?.subCategory}
                handleChange={(e) =>
                  handleChange("subCategory", e?.target?.value)
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                HSN/SAC Code <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="hsnSacCode"
                fullWidth
                value={productValues?.product?.hsnSacCode}
                error={productErrors?.hsnSacCode}
                helperText={productHelps?.hsnSacCode}
                handleChange={(e) =>
                  handleChange("hsnSacCode", e?.target?.value)
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Name <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="name"
                fullWidth
                value={productValues?.product?.name}
                error={productErrors?.name}
                helperText={productHelps?.name}
                handleChange={(e) => handleChange("name", e?.target?.value)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Trade Name <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="tradeName"
                fullWidth
                value={productValues?.product?.tradeName}
                error={productErrors?.tradeName}
                helperText={productHelps?.tradeName}
                handleChange={(e) =>
                  handleChange("tradeName", e?.target?.value)
                }
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Major Active Ingredients
              </div>
              <CustomTextField
                name="ingredient"
                fullWidth
                value={productValues?.product?.ingredient}
                error={productErrors?.ingredient}
                helperText={productHelps?.ingredient}
                handleChange={(e) =>
                  handleChange("ingredient", e?.target?.value)
                }
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">Nature</div>
              <Select
                list={natureList}
                value={productValues?.nature}
                handleChange={(e, value) => handleChange("nature", value)}
                name="nature"
                search
                error={productErrors?.nature}
                helperText={productHelps?.nature}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Bar Code number
              </div>
              <CustomTextField
                name="code"
                fullWidth
                value={productValues?.product?.code}
                error={productErrors?.code}
                helperText={productHelps?.code}
                handleChange={(e) => handleChange("code", e?.target?.value)}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">Brand</div>
              <CustomTextField
                name="brand"
                fullWidth
                value={productValues?.product?.brand}
                error={productErrors?.brand}
                helperText={productHelps?.brand}
                handleChange={(e) => handleChange("brand", e?.target?.value)}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Measurement Unit <span className="red-color">*</span>
              </div>
              <Select
                list={productMeasurementUnit}
                value={productValues?.product?.measurementUnit}
                handleChange={(e, value) =>
                  handleChange("measurementUnit", value)
                }
                name="measurementUnit"
                search
                error={productErrors?.measurementUnit}
                helperText={productHelps?.measurementUnit}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Sub-Measurement Unit <span className="red-color">*</span>
              </div>
              <Select
                list={productSubMeasurementUnit}
                value={productValues?.product?.subMeasurementUnit}
                handleChange={(e, value) =>
                  handleChange("subMeasurementUnit", value)
                }
                name="subMeasurementUnit"
                search
                error={productErrors?.subMeasurementUnit}
                helperText={productHelps?.subMeasurementUnit}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">Formula</div>
              <CustomTextField
                name="formula"
                fullWidth
                value={productValues?.product?.formula}
                error={productErrors?.formula}
                helperText={productHelps?.formula}
                handleChange={(e) => handleChange("formula", e?.target?.value)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">Batch Number</div>
              <CustomTextField
                name="batchNumber"
                fullWidth
                value={productValues?.product?.batchNumber}
                error={productErrors?.batchNumber}
                helperText={productHelps?.batchNumber}
                handleChange={(e) =>
                  handleChange("batchNumber", e?.target?.value)
                }
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">Mfg Date</div>
              <CustomTextField
                fullWidth
                value={productValues?.product?.manufacturingDate}
                error={productErrors?.manufacturingDate}
                helperText={productHelps?.manufacturingDate}
                handleChange={(e) =>
                  handleChange("manufacturingDate", e?.target?.value)
                }
                mobileDate
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">Expiry Date</div>
              <CustomTextField
                fullWidth
                value={productValues?.product?.expiryDate}
                error={productErrors?.expiryDate}
                helperText={productHelps?.expiryDate}
                handleChange={(e) =>
                  handleChange("expiryDate", e?.target?.value)
                }
                mobileDate
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Location <span className="red-color">*</span>
              </div>
              <Select
                list={locationList}
                value={productValues?.product?.location}
                handleChange={(e, value) => handleChange("location", value)}
                name="location"
                search
                error={productErrors?.location}
                helperText={productHelps?.location}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="txt-semi-bold fs14 light-grey">
                Brief Description of the Product
              </div>
              <CustomTextField
                name="description"
                fullWidth
                value={productValues?.product?.description}
                error={productErrors?.description}
                helperText={productHelps?.description}
                handleChange={(e) =>
                  handleChange("description", e?.target?.value)
                }
                multiline
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className="font-bold fs16 blue-color">
                Price & Taxes
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Unit Selling Price (MRP) <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="sellPrice"
                fullWidth
                value={productValues?.product?.sellPrice}
                error={productErrors?.sellPrice}
                helperText={productHelps?.sellPrice}
                handleChange={(e) =>
                  handleChange("sellPrice", e?.target?.value)
                }
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Sub-Unit Selling Price <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="subunitSellingPrice"
                fullWidth
                value={productValues?.product?.subunitSellingPrice}
                error={productErrors?.subunitSellingPrice}
                helperText={productHelps?.subunitSellingPrice}
                handleChange={(e) =>
                  handleChange("subunitSellingPrice", e?.target?.value)
                }
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Administering Charges
              </div>
              <CustomTextField
                name="administeringCharges"
                fullWidth
                value={productValues?.product?.administeringCharges}
                error={productErrors?.administeringCharges}
                helperText={productHelps?.administeringCharges}
                handleChange={(e) =>
                  handleChange("administeringCharges", e?.target?.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className="font-bold fs16 blue-color">
                Stock Details
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Quantity <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="quantity"
                fullWidth
                value={productValues?.product?.quantity}
                error={productErrors?.quantity}
                helperText={productHelps?.quantity}
                handleChange={(e) => handleChange("quantity", e?.target?.value)}
                disabled
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Unit Rate <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="unitRate"
                fullWidth
                value={productValues?.product?.unitRate}
                error={productErrors?.unitRate}
                helperText={productHelps?.unitRate}
                handleChange={(e) => handleChange("unitRate", e?.target?.value)}
                disabled
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                CGST (%) <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="cgst"
                fullWidth
                value={productValues?.product?.cgst}
                error={productErrors?.cgst}
                helperText={productHelps?.cgst}
                handleChange={(e) => handleChange("cgst", e?.target?.value)}
                disabled
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                SGST (%) <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="sgst"
                fullWidth
                value={productValues?.product?.sgst}
                error={productErrors?.sgst}
                helperText={productHelps?.sgst}
                handleChange={(e) => handleChange("sgst", e?.target?.value)}
                disabled
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Item Total <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="itemTotal"
                fullWidth
                value={productValues?.product?.itemTotal}
                error={productErrors?.itemTotal}
                helperText={productHelps?.itemTotal}
                handleChange={(e) =>
                  handleChange("itemTotal", e?.target?.value)
                }
                disabled
              />
            </Grid>
          </Grid>

          <div className="mt30">
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <div className="flex1-end">
                <div className="flex-row-ali-cen">
                  <div className="mr10">
                    <CustomButton
                      text="Cancel"
                      textBtn
                      onClick={onClosePrevMod}
                      color={AppColors.red}
                    />
                  </div>
                  <div className="ml10">
                    <CustomButton text="Submit" onClick={handleSubmit} />
                  </div>
                </div>
              </div>
            </Grid>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default StockPendingTable;
