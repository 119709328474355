import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { storePetDetScreenDetails } from "../../../redux/reducers/mixedSlice";
import {
  getPetDetailsById,
  getPetsCompliantSummary,
} from "../../../redux/reducers/petSlice";
import CommonClinicPetDetails from "../../CommonScreens/CommonClinicPetDetails/CommonClinicPetDetails";
import AdmissionDetails from "./AdmissionDetails/AdmissionDetails";
import BillingHistory from "./Billing/BillingHistory";
import MedicalHistory from "./MedicalHistory/MedicalHistory";
import Overview from "./Overview/Overview";
import PatientWorkbook from "./PatientWorkbook/PatientWorkbook";

const ClinicPetDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { title, type, appointment } = location.state || {};
  const [isVideoCallEnabled, setVideoCallEnabled] = useState(false);
  const [scrollable, setScrollable] = useState(true);
  const [isEditBill, setEditBill] = useState(false);
  const [allergyModOpen, setAllergyModOpen] = useState(false);
  const [selectedBill, setSelectedBill] = useState(null);
  const [selectedTab, setSelectedTab] = useState("overView");
  const [inpatientId, setInpatientId] = useState(location?.state?.inpatientId);
  const apntDet = useSelector((state) => state?.pet?.complaintSummary?.data);
  const getPetData = apntDet?.medicalHistory;
  const getPereventiveData = apntDet?.prevetive;

  useEffect(() => {
    setSelectedBill(null);
    if (appointment?.appoinment?.appoimentId) {
      dispatch(getPetsCompliantSummary(appointment?.appoinment?.appoimentId));
    } else {
      dispatch(getPetDetailsById(appointment?.appoinment?.petId));
    }
    //for storing details to call socket
    dispatch(
      storePetDetScreenDetails({
        appoimentId: appointment?.appoinment?.appoimentId ?? null,
        petId: appointment?.appoinment?.appoimentId
          ? null
          : appointment?.appoinment?.petId,
      })
    );
  }, []);

  useEffect(() => {
    if (!location?.state?.selectedTab) return;
    if (location?.state?.selectedTab === "admissionDetails") {
      setSelectedTab(
        selectedTab === "admissionDetails" && !apntDet?.appointment?.inpatient
          ? "overView"
          : location?.state?.selectedTab
      );
    } else {
      setSelectedTab(location?.state?.selectedTab);
    }
  }, [location?.state?.selectedTab, apntDet?.appointment?.inpatient]);

  return (
    <CommonClinicPetDetails
      upcomingVisible={selectedTab === "overView"}
      isVideoCallEnabled={isVideoCallEnabled}
      setVideoCallEnabled={setVideoCallEnabled}
      appointment={appointment}
      scrollable={scrollable}
      setSelectedTab={setSelectedTab}
      setInpatientId={setInpatientId}
      allergyModOpen={allergyModOpen}
      setAllergyModOpen={setAllergyModOpen}
      selectedTab={selectedTab}
    >
      <Grid container className="ph2">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="back-white p10 bs-margin"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="flex-row">
                <div
                  className="ph10pv3"
                  onClick={() => setSelectedTab("overView")}
                >
                  <div
                    className={`flex-center cursor ${
                      selectedTab === "overView"
                        ? "pet-det-sel-tab"
                        : "pet-det-un-sel-tab"
                    }`}
                  >
                    Overview
                  </div>
                </div>
                <div
                  className="ph10pv3"
                  onClick={() => setSelectedTab("patientWorkbook")}
                >
                  <div
                    className={`flex-center cursor ${
                      selectedTab === "patientWorkbook"
                        ? "pet-det-sel-tab"
                        : "pet-det-un-sel-tab"
                    }`}
                  >
                    Patient Workbook
                  </div>
                </div>
                {apntDet?.appointment?.inpatient && (
                  <div
                    className="ph10pv3"
                    onClick={() => setSelectedTab("admissionDetails")}
                  >
                    <div
                      className={`flex-center cursor ${
                        selectedTab === "admissionDetails"
                          ? "pet-det-sel-tab"
                          : "pet-det-un-sel-tab"
                      }`}
                    >
                      Admission Details
                    </div>
                  </div>
                )}
                <div
                  className="ph10pv3"
                  onClick={() => {
                    setSelectedTab("billing");
                    setEditBill(false);
                  }}
                >
                  <div
                    className={`flex-center cursor ${
                      selectedTab === "billing"
                        ? "pet-det-sel-tab"
                        : "pet-det-un-sel-tab"
                    }`}
                  >
                    Billing
                  </div>
                </div>
                <div
                  className="ph10pv3"
                  onClick={() => {
                    setSelectedTab("history");
                    setEditBill(false);
                  }}
                >
                  <div
                    className={`flex-center cursor ${
                      selectedTab === "history"
                        ? "pet-det-sel-tab"
                        : "pet-det-un-sel-tab"
                    }`}
                  >
                    History
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {selectedTab === "overView" ? (
          <Overview
            getPetData={getPetData}
            getPereventiveData={getPereventiveData}
            appointment={appointment}
            title={title != null && title !== undefined ? title : type}
            isVideoCallEnabled={isVideoCallEnabled}
            setVideoCallEnabled={setVideoCallEnabled}
            allergyModOpen={allergyModOpen}
            setAllergyModOpen={setAllergyModOpen}
          />
        ) : null}
        {selectedTab === "patientWorkbook" ? (
          <PatientWorkbook
            appointment={appointment}
            setScrollable={setScrollable}
          />
        ) : null}
        {selectedTab === "admissionDetails" ? (
          <AdmissionDetails
            inpatientId={inpatientId}
            setSelectedTab={setSelectedTab}
            setEditBill={setEditBill}
            setSelectedBill={setSelectedBill}
          />
        ) : null}
        {selectedTab === "billing" ? (
          <BillingHistory
            isEditBill={isEditBill}
            setEditBill={setEditBill}
            petId={appointment?.appoinment?.petId}
            selectedBill={selectedBill}
            setSelectedBill={setSelectedBill}
          />
        ) : null}
        {selectedTab === "history" ? (
          <MedicalHistory petId={appointment?.appoinment?.petId} />
        ) : null}
      </Grid>
    </CommonClinicPetDetails>
  );
};

export default ClinicPetDetails;
