import { Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../components/CustomButton";
import CustomModal from "../../../components/CustomModal/CustomModal";
import Table from "../../../components/CustomTable";
import CustomTextField from "../../../components/CustomTextField";
import {
  createSupplier,
  deleteSupplier,
  getSupplier,
  updateSupplier,
} from "../../../redux/reducers/clinicSlice";
import { AppColors } from "../../../util/AppColors";

const tableHeaders = [
  "supplierName",
  "contactPerson",
  "email",
  "phoneNumber",
  "address",
  "extraStatus",
];

const nameExpan = {
  supplierName: "Supplier Name",
  contactPerson: "Contact Person",
  email: "Email",
  phone: "Phone Number",
  address: "Address",
};

const initValues = {
  supplierName: "",
  contactPerson: "",
  email: "",
  phone: "",
  address: "",
};

const initErrors = {
  supplierName: false,
  contactPerson: false,
  email: false,
  phone: false,
  address: false,
};

const Supplier = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [tableSuppliers, setTableSuppliers] = useState([]);
  const [supplierValues, setSupplierValues] = useState(initValues);
  const [supplierErrors, setSupplierErrors] = useState(initValues);
  const [supplierHelps, setSupplierHelps] = useState(initErrors);
  const [modVsble, setModVsble] = useState(false);
  const [delModVsble, setDelModVsble] = useState(false);
  const [supplierId, setSupplierId] = useState(null);
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const suppliers = useSelector((state) => state?.clinic?.suppliers);
  const defaultUrl = `?page=1&itemSize=${rowsPerPage}`;

  // useEffect(() => {
  //   dispatch(getSupplier(defaultUrl));
  // }, []);

  useEffect(() => {
    dispatch(
      getSupplier(`?page=${page}&itemSize=${rowsPerPage}&search=${searchValue}`)
    );
  }, [searchValue]);

  useEffect(() => {
    getSupplierTable();
  }, [suppliers]);

  const handleDelete = (item) => {
    setSupplierId(item?.supplierId);
    setDelModVsble(true);
  };

  const handleCloseDelMod = () => {
    setDelModVsble(false);
    setSupplierId(null);
  };

  const handleDeleteSupplier = () => {
    dispatch(deleteSupplier(supplierId)).then((res) => {
      if (res?.payload) {
        dispatch(getSupplier(defaultUrl));
        handleCloseDelMod();
      }
    });
  };

  const handleEdit = (item) => {
    setSupplierId(item?.supplierId);
    setSupplierValues({
      ...supplierValues,
      supplierName: item?.supplierName,
      contactPerson: item?.contactPersion,
      email: item?.email,
      phone: item?.phone,
      address: item?.address,
    });
    setModVsble(true);
  };

  const getSupplierTable = () => {
    const reqList = suppliers?.data?.map((sup) => ({
      supplierName: sup?.supplierName,
      contactPerson: sup?.contactPersion,
      email: sup?.email,
      phoneNumber: sup?.phone,
      address: sup?.address,
      extraStatus: (
        <div className="flex-row">
          <img
            src={require("../../../assets/images/png/delete.png")}
            alt=""
            className="imghw30 cursor"
            onClick={() => handleDelete(sup)}
          />

          <img
            src={require("../../../assets/images/png/editNew.png")}
            alt=""
            className="ml10 imghw30 cursor"
            onClick={() => handleEdit(sup)}
          />
        </div>
      ),
    }));
    setTableSuppliers(reqList);
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setPage(1);
    setTableSuppliers([]);
  };

  const handleCreateUpdate = () => {
    setModVsble(true);
  };

  const handleCloseMod = () => {
    setModVsble(false);
    setSupplierId(null);
    setSupplierValues(initValues);
    setSupplierHelps(initValues);
    setSupplierErrors(initErrors);
  };

  const handleSuccess = () => {
    setSupplierId(null);
    setSupplierValues(initValues);
    setSupplierHelps(initValues);
    setSupplierErrors(initErrors);
    dispatch(getSupplier(defaultUrl));
    setModVsble(false);
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setSupplierValues({ ...supplierValues, [name]: value });
    setSupplierErrors({
      ...supplierErrors,
      [name]: value === "" ? true : false,
    });
    setSupplierHelps({
      ...supplierHelps,
      [name]: value === "" ? `${nameExpan?.[name]} is required field` : "",
    });
  };

  const handleSave = () => {
    let errObj = {};
    let helpObj = {};
    Object.keys(supplierValues).forEach((key) => {
      errObj = {
        ...errObj,
        [key]: supplierValues?.[key] === "" ? true : false,
      };
      helpObj = {
        ...helpObj,
        [key]:
          supplierValues?.[key] === ""
            ? `${nameExpan?.[key]} is required field`
            : "",
      };
    });
    setSupplierHelps(helpObj);
    setSupplierErrors(errObj);
    if (Object.keys(errObj)?.some((val) => errObj?.[val] === true)) return;

    const data = {
      supplierName: supplierValues?.supplierName,
      email: supplierValues?.email,
      contactPersion: supplierValues?.contactPerson,
      phone: supplierValues?.phone,
      address: supplierValues?.address,
    };
    if (supplierId) {
      dispatch(updateSupplier({ id: supplierId, data })).then((res) => {
        if (res?.payload) handleSuccess();
      });
    } else {
      dispatch(createSupplier(data)).then((res) => {
        if (res?.payload) handleSuccess();
      });
    }
  };

  const handleChangePage = (e, selectedPage) => {
    setPage(selectedPage);
    setTableSuppliers([]);
    dispatch(
      getSupplier(
        `?page=${selectedPage}&itemSize=${rowsPerPage}${
          searchValue?.length > 0 ? `&search=${searchValue}` : ""
        }`
      )
    );
  };

  return (
    <Container maxWidth="xl">
      <div className="com-mar back-white pv10">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <div className="flex-row">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ width: "600px" }}
            >
              <CustomTextField
                placeholder="Search"
                fullWidth
                backgroundColor={AppColors.lightPink}
                value={searchValue}
                handleChange={handleSearch}
              />
            </Grid>
          </div>
          <div className="w5Per ml20">
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
              <div>
                <CustomButton
                  text="Create"
                  smallBtn
                  onClick={handleCreateUpdate}
                />
              </div>
            </Grid>
          </div>
          {/* <div className="pos-absolute r80">
            <Typography className="font-medium fs16">
              Total number of services: {services?.totalRecords}
            </Typography>
          </div> */}
        </Grid>

        <Table
          columns={tableHeaders}
          datas={tableSuppliers}
          page={page}
          rowsPerPage={rowsPerPage}
          totalRecords={suppliers?.totalRecords}
          handleChangePage={handleChangePage}
          grey
        />
      </div>
      <CustomModal
        open={modVsble}
        onClose={handleCloseMod}
        header="Supplier"
        modalWidth={30}
        rightModal
      >
        <Grid container spacing={2} className="ph20">
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="txt-semi-bold fs14 light-grey">
              Supplier Name <span className="red-color">*</span>
            </div>
            <CustomTextField
              name="supplierName"
              fullWidth
              value={supplierValues?.supplierName}
              error={supplierErrors?.supplierName}
              helperText={supplierHelps?.supplierName}
              handleChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="txt-semi-bold fs14 light-grey">
              Contact Person <span className="red-color">*</span>
            </div>
            <CustomTextField
              name="contactPerson"
              fullWidth
              value={supplierValues?.contactPerson}
              error={supplierErrors?.contactPerson}
              helperText={supplierHelps?.contactPerson}
              handleChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="txt-semi-bold fs14 light-grey">
              Email <span className="red-color">*</span>
            </div>
            <CustomTextField
              name="email"
              fullWidth
              value={supplierValues?.email}
              error={supplierErrors?.email}
              helperText={supplierHelps?.email}
              handleChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="txt-semi-bold fs14 light-grey">
              Phone Number <span className="red-color">*</span>
            </div>
            <CustomTextField
              name="phone"
              fullWidth
              value={supplierValues?.phone}
              error={supplierErrors?.phone}
              helperText={supplierHelps?.phone}
              handleChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey">
              Address <span className="red-color">*</span>
            </div>
            <CustomTextField
              name="address"
              fullWidth
              value={supplierValues?.address}
              error={supplierErrors?.address}
              helperText={supplierHelps?.address}
              handleChange={handleChange}
              multiline
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="flex1-end mt10">
              <div>
                <CustomButton
                  text={supplierId ? "Update" : "Submit"}
                  smallBtn
                  onClick={handleSave}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </CustomModal>
      <CustomModal
        open={delModVsble}
        onClose={handleCloseDelMod}
        header="Delete Supplier"
        modal
        modalWidth={40}
      >
        <Typography className="txt-regular fs16 ml10">
          Are you sure you want to delete the supplier?
        </Typography>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className="clinic-mod-btn-pos mb10 mt15">
            <div className="mr10">
              <CustomButton text="Yes" redBtn onClick={handleDeleteSupplier} />
            </div>
            <div className="ml10">
              <CustomButton text="No" onClick={handleCloseDelMod} />
            </div>
          </div>
        </Grid>
      </CustomModal>
    </Container>
  );
};

export default Supplier;
