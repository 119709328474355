import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { AppColors } from "../../../util/AppColors";
import { nameExpan } from "./tableHeaders";

const ReportTable = ({ columns, datas }) => {
  return (
    <Container maxWidth="xl">
      <Box className={"pb-40"}>
        <TableContainer sx={{ maxHeight: 350 }}>
          <Table
            sx={{ minWidth: 200 }}
            aria-labelledby="tableTitle"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {columns?.map((column, i) => (
                  <TableCell
                    key={i}
                    style={{
                      backgroundColor: AppColors.tableGrey,
                      color: AppColors.white,
                      textAlign: "left",
                    }}
                    className="table-header-text"
                  >
                    <div
                      style={{
                        minWidth:
                          column === "ingredient" ||
                          column === "composition" ||
                          column === "description"
                            ? 500
                            : 150,
                      }}
                    >
                      {nameExpan?.[column]}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {datas?.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={12}
                  style={{ textAlign: "center", height: 100 }}
                >
                  <div className="no-rec">No records available</div>
                </TableCell>
              </TableRow>
            )}
            <TableBody>
              {datas?.map((dt) => (
                <TableRow>
                  {columns?.map((column, i) => (
                    <TableCell
                      key={i}
                      component="th"
                      className="table-text-black capitalize"
                    >
                      <div
                        style={{
                          minWidth:
                            column === "ingredient" ||
                            column === "composition" ||
                            column === "description"
                              ? 500
                              : 150,
                        }}
                      >
                        {dt?.[column]}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default ReportTable;
