import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Card, CardMedia, Grid, Typography } from "@mui/material";
import { uniq } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import CustomCheckbox from "../../../components/CustomCheckbox";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CustomTextField from "../../../components/CustomTextField";
import Select from "../../../components/Select/Select";
import { getClinicHolidays } from "../../../redux/reducers/clinicSlice";
import { getVetHolidaysById } from "../../../redux/reducers/doctorSlice";
import { hideLoader, showLoader } from "../../../redux/reducers/loaderSlice";
import {
  getClinicPets,
  getSlotTime,
  petAppointment,
  petAppointmentImmediate,
} from "../../../redux/reducers/petSlice";
import { AppColors } from "../../../util/AppColors";
import {
  appointmentLaterList,
  appointmentList,
  serviceType,
  serviceTypeWOSurgery,
  typeAppointemnt,
  typeList,
  typeListNew,
} from "../../../util/dropList";
import { getAllDatesFromArray } from "../../../util/function";

const initialErrors = {
  typeOfAppointment: false,
  serviceType: false,
  consultationType: false,
  reason: false,
  appointmentOption: false,
  kennelNumber: false,
};
const initialHelpers = {
  typeOfAppointment: "",
  serviceType: "",
  consultationType: "",
  reason: "",
  appointmentOption: "",
  kennelNumber: "",
};

const initialValues = {
  typeOfAppointment: "Phone",
  serviceType: null,
  consultationType: "Virtual",
  reason: "",
  date: new Date(),
  appointmentOption: null,
  kennelNumber: "",
};

const initTimeObj = { times: [], ids: [] };

const VetBookAppointment = ({
  modalVisible,
  setModalBookVisible,
  selectVet,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [petId, setPetId] = useState(null);
  const [petDetails, setPetDetails] = useState(null);
  const [petValues, setPetValues] = useState(initialValues);
  const [searchText, setSearchText] = useState("");
  const [petErrors, setPetErrors] = useState(initialErrors);
  const [petHelpers, setPetHelpers] = useState(initialHelpers);
  const [selectTimeId, setSelectTimeId] = useState();
  const [selectTime, setSelectTime] = useState();
  const [selectTimeIdError, setSelectTimeIdError] = useState(false);
  const [petDetailsError, setPetDetailsError] = useState(false);
  const [holidayDates, setHolidayDates] = useState([]);
  const [timeObject, setTimeObject] = useState(initTimeObj);
  const defaultUrl = `?type=all&search=${searchText}`;
  const holidays = useSelector((state) => state?.doctor?.holidays);
  const pets = useSelector((state) => state.pet.clinicPets);
  const slotList = useSelector((state) => state.pet.slotList) || [];
  const clinicHolidays = useSelector((state) => state?.clinic?.holidays);
  const activeSlotList = slotList?.filter((status) => status?.status === "N");

  useEffect(() => {
    dispatch(getClinicHolidays());
  }, []);

  useEffect(() => {
    if (!petValues?.date || !petValues?.appointmentOption) {
      return;
    }
    if (petValues?.appointmentOption === "Later") {
      dispatch(getVetHolidaysById(selectVet?.doctorId));
      const data = {
        doctorId: selectVet?.doctorId,
        contType: petValues?.consultationType,
        date: moment(new Date(petValues?.date)).format("YYYY-MM-DD"),
      };
      dispatch(getSlotTime(data));
    }
  }, [
    selectVet?.doctorId,
    petValues?.date,
    petValues?.appointmentOption,
    petValues?.consultationType,
  ]);

  useEffect(() => {
    getClinicPetsData();
  }, [searchText]);

  useEffect(() => {
    getHolidayDateList();
  }, [holidays, clinicHolidays]);

  const getHolidayDateList = async () => {
    if (
      (holidays?.length === 0 || !holidays) &&
      (clinicHolidays?.length === 0 || !clinicHolidays)
    ) {
      return setHolidayDates([]);
    }
    const vetDates = (await getAllDatesFromArray(holidays)) ?? [];
    const cliHolidays =
      clinicHolidays?.map((hl) => {
        return hl?.date;
      }) ?? [];
    const mergedArray = vetDates?.concat(cliHolidays);
    setHolidayDates(uniq(mergedArray));
  };

  const getClinicPetsData = () => {
    dispatch(getClinicPets(defaultUrl));
  };

  const handleSelectTime = (time) => {
    setSelectTime(time?.time);
    setSelectTimeId(time?.slotTimeId);
    setSelectTimeIdError(false);
  };

  const handleReset = () => {
    setPetValues(initialValues);
    setPetErrors(initialErrors);
    setPetHelpers(initialHelpers);
    setSelectTime(null);
    setSelectTimeId(null);
    setPetDetails(null);
    setSearchText("");
    setSelectTimeIdError(false);
  };

  const modelOpen = () => {
    handleReset();
    setModalBookVisible(!modalVisible);
  };

  const handleSelect = (value, key) => {
    const reqObj = { ...petValues, [key]: value };
    if (key === "typeOfAppointment") {
      if (value === "Walk-in") {
        reqObj.consultationType = "Physical";
      }
    }
    setPetValues(reqObj);
    setPetErrors({
      ...petErrors,
      [key]: value?.toString()?.length > 0 ? false : true,
    });
    setPetHelpers({
      ...petHelpers,
      [key]: value?.toString().length > 0 ? "" : "Required Field",
    });
  };

  const handleValidation = () => {
    const errorList = [];
    const woErrorList = [];
    Object.keys(petValues).forEach(function (key, index) {
      if (petValues?.[key] === null || petValues?.[key] === "") {
        if (key === "kennelNumber") {
          if (
            petValues?.serviceType?.value !== "Surgery" ||
            petValues?.appointmentOption !== "Immediate"
          ) {
            return woErrorList.push(key);
          }
          errorList.push(key);
        }
        if (key === "date") {
          return woErrorList.push(key);
        }
        errorList.push(key);
      }
    });
    let errorObj = {};
    let errHelperObj = {};
    let correctObj = {};
    let helperObj = {};
    //set Error
    if (errorList?.length > 0) {
      errorList?.forEach((key) => {
        errorObj = { ...errorObj, [key]: true };
        errHelperObj = {
          ...errHelperObj,
          [key]: `Required Field`,
        };
      });
    }
    //Remove Error
    if (woErrorList?.length > 0) {
      woErrorList?.forEach((key) => {
        correctObj = { ...correctObj, [key]: false };
        helperObj = { ...helperObj, [key]: "" };
      });
    }
    setPetErrors({ ...petErrors, ...correctObj, ...errorObj });
    setPetHelpers({ ...petHelpers, ...helperObj, ...errHelperObj });
    return { errorList, woErrorList };
  };

  const petAppointmentCreate = async () => {
    const validation = handleValidation();
    if (validation?.errorList?.length > 0) return;
    if (petDetails === null || petDetails === undefined || petDetails === "") {
      setPetDetailsError(true);
    } else {
      if (petValues?.appointmentOption === "Immediate") {
        const data = {
          bookingType: petValues?.typeOfAppointment,
          consultationMode: petValues?.consultationType,
          serviceType: petValues?.serviceType?.value,
          reason: petValues?.reason,
          doctorId: selectVet?.doctorId,
          kennelNumber: petValues?.kennelNumber,
        };
        dispatch(showLoader());
        dispatch(petAppointmentImmediate({ petId, data })).then((res) => {
          if (res?.payload?.status === 200) {
            modelOpen();
            navigate("/dashboard");
          }
        });
        dispatch(hideLoader());
      } else {
        if (
          petValues?.serviceType?.value === "Surgery"
            ? timeObject?.times?.length !== 2
            : selectTimeId === null ||
              selectTimeId === undefined ||
              selectTimeId === ""
        ) {
          setSelectTimeIdError(true);
        } else {
          dispatch(showLoader());
          const data = {
            bookingType: petValues?.typeOfAppointment,
            consultationMode: petValues?.consultationType,
            serviceType: petValues?.serviceType?.value,
            reason: petValues?.reason,
            doctorId: selectVet?.doctorId,
            appointmentDate: moment(new Date(petValues?.date)).format(
              "YYYY-MM-DD"
            ),
            slotId: slotList?.[0]?.slotdayId,
          };
          if (petValues?.serviceType?.value === "Surgery") {
            const reqSmlId = timeObject?.times?.[0] <= timeObject?.times?.[1];
            const reqTimeObj = activeSlotList?.find(
              (slt) => slt?.slotTimeId === timeObject?.times?.[reqSmlId ? 0 : 1]
            );
            data.surgeryTimes = timeObject?.ids?.map((id) => {
              const timObj = activeSlotList?.find(
                (slt) => slt?.slotTimeId === id
              );
              return timObj?.time;
            });
            data.appoimentTime = reqTimeObj?.time;
            data.timeIds = timeObject?.ids;
          } else {
            data.appoimentTime = selectTime;
            data.timeId = selectTimeId;
          }

          dispatch(petAppointment({ petId, data })).then((res) => {
            if (res?.payload?.status === 200) {
              modelOpen();
              navigate("/dashboard");
            }
          });
          dispatch(hideLoader());
        }
      }
    }
  };

  const getSlotIdsBetween = (slots, id1, id2) => {
    const startId = Math.min(id1, id2);
    const endId = Math.max(id1, id2);
    return slots
      .filter(
        (slot) => slot?.slotTimeId >= startId && slot?.slotTimeId <= endId
      )
      .map((slot) => slot?.slotTimeId);
  };

  const handleSelectectedTimeIds = (time) => {
    const reqTimeIds = [...timeObject?.times, time?.slotTimeId];
    let ids = [];
    const reqList = {
      times: reqTimeIds?.length > 2 ? [time?.slotTimeId] : reqTimeIds,
    };
    if (reqList?.times?.length === 2) {
      ids = getSlotIdsBetween(activeSlotList, reqTimeIds?.[0], reqTimeIds?.[1]);
      reqList.ids = ids;
    } else {
      ids.push(time?.slotTimeId);
      reqList.ids = ids;
    }
    setTimeObject(reqList);
    setSelectTimeIdError(reqTimeIds?.length === 2 ? false : true);
  };

  return (
    <CustomModal
      open={modalVisible}
      onClose={modelOpen}
      header="Book an Appointment"
      rightModal
      modalWidth={30}
    >
      <Grid container spacing={2} className="ph20 scroll-80vh ">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card
                sx={{
                  borderRadius: 1,
                  padding: 2,
                  borderTopColor: AppColors.appPrimary,
                }}
                className="CustomCard-back-appointment-vet"
              >
                <div className="maint">
                  <div className="flex-row">
                    <Grid item xs={3}>
                      <div className="flex-center">
                        {selectVet?.image ? (
                          <CardMedia
                            image={selectVet?.image}
                            className="CustomCard-img3-bill"
                          />
                        ) : (
                          <div className="flex-center h50img">
                            <Typography className="font-bold fs30 white-color capitalize">
                              {selectVet?.name?.[0]}
                            </Typography>
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={9}>
                      <div className="flex-row">
                        <div className="flex-start">
                          <div className="flex-column">
                            <div className="flex-row">
                              <Typography
                                variant="body1"
                                className="mb5 font-bold fs14 capitalize"
                              >
                                Dr. {selectVet?.name}
                              </Typography>
                            </div>
                            <Typography
                              variant="body2"
                              className="mb10 txt-regular card-gray-color fs12"
                            >
                              {selectVet?.speciality}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {petDetails === null && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mb20">
            <CustomTextField
              name="reason"
              fullWidth
              search
              placeholder={"Pet Search"}
              backgroundColor={"##E2E2E2"}
              value={searchText}
              handleChange={(e) => setSearchText(e.target.value)}
            />
            {petDetailsError && (
              <Typography color="error" className="ml5 mt5 fs14">
                Pet is Requried
              </Typography>
            )}
          </Grid>
        )}

        {searchText && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {pets?.pets?.length > 0 &&
              pets?.pets?.map((petDet) => {
                const pet = petDet?.listOfDatas;
                return (
                  <Grid
                    container
                    spacing={1}
                    key={pet?.petId}
                    className="cursor"
                    onClick={() => {
                      setPetId(pet?.petId);
                      setPetDetails(pet);
                      setPetDetailsError(false);
                      setSearchText("");
                    }}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Card
                            sx={{
                              borderRadius: 1,
                              padding: 2,
                              borderTopColor: AppColors.appPrimary,
                            }}
                            className="CustomCard-back-appointment"
                          >
                            <div className="maint">
                              <div className="flex-row">
                                <Grid item xs={3}>
                                  <div className="flex-center">
                                    {pet?.petImage ? (
                                      <CardMedia
                                        image={pet?.petImage}
                                        className="CustomCard-img3-bill"
                                      />
                                    ) : (
                                      <div className="flex-center h50img">
                                        <Typography className="font-bold fs30 white-color capitalize">
                                          {pet?.petName?.[0]}
                                        </Typography>
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                                <Grid item xs={9}>
                                  <div className="flex-row">
                                    <div className="flex-start">
                                      <div className="flex-column">
                                        <div className="flex-row">
                                          <Typography
                                            variant="body1"
                                            className="mb10 font-bold fs14 capitalize"
                                          >
                                            {pet?.petName}
                                          </Typography>
                                          <Typography
                                            variant="body1"
                                            className={`ml5 font-medium capitalize fs14 ${
                                              pet.gender === "male"
                                                ? "card-blue-color"
                                                : "card-rose-color"
                                            }`}
                                          >
                                            ({pet.gender})
                                          </Typography>
                                        </div>
                                        <Typography
                                          variant="body2"
                                          className="mb10 txt-regular card-gray-color fs12"
                                        >
                                          {pet.breed}
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </div>
                              <div>
                                <div className="flex-row parentcontainer">
                                  <div className="flex-row iconcontainer">
                                    <AccountCircleOutlinedIcon
                                      sx={{ width: 25, height: 25 }}
                                    />
                                    <Typography className="font-bold fs14 capitalize flex-center h35">
                                      {pet.userName}
                                    </Typography>
                                  </div>
                                  <div className="meeting-doctor">
                                    {moment(pet?.dob).fromNow()} ({pet?.dob})
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        )}

        {petDetails && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Card
                  sx={{
                    borderRadius: 1,
                    padding: 2,
                    borderTopColor: AppColors.appPrimary,
                  }}
                  className="CustomCard-back-appointment"
                >
                  <div className="maint">
                    <div className="flex-row">
                      <Grid item xs={3}>
                        <div className="flex-center">
                          {petDetails?.petImage ? (
                            <CardMedia
                              image={petDetails.petImage}
                              className="CustomCard-img3-bill"
                            />
                          ) : (
                            <div className="flex-center h50img">
                              <Typography className="font-bold fs30 white-color capitalize">
                                {petDetails?.petName?.[0]}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </Grid>
                      <div className="flex-row">
                        <div className="flex-start">
                          <div className="flex-column">
                            <div className="flex-row">
                              <Typography
                                variant="body1"
                                className="mb10 font-bold fs14 capitalize"
                              >
                                {petDetails.petName}
                              </Typography>
                              <Typography
                                variant="body1"
                                className={`ml5 font-medium capitalize fs14 ${
                                  petDetails.gender === "male"
                                    ? "card-blue-color"
                                    : "card-rose-color"
                                }`}
                              >
                                ({petDetails.gender})
                              </Typography>
                            </div>
                            <Typography
                              variant="body2"
                              className="mb10 txt-regular card-gray-color fs12"
                            >
                              {petDetails.breed}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                    <div>
                      <div className="flex-row parentcontainer">
                        <div className="flex-row iconcontainer">
                          <AccountCircleOutlinedIcon
                            sx={{ width: 25, height: 25 }}
                          />
                          <Typography className="font-bold fs14 capitalize flex-center h35">
                            {petDetails.userName}
                          </Typography>
                        </div>
                        <div className="meeting-doctor">
                          {moment(petDetails?.dob).fromNow()} ({petDetails?.dob}
                          )
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <div className="txt-mont fs14">
            Booking Type <span className="red-color">*</span>
          </div>
          <Select
            list={typeAppointemnt}
            value={petValues.typeOfAppointment}
            handleChange={(e) =>
              handleSelect(e?.target?.value, "typeOfAppointment")
            }
            name="typeOfAppointment"
            select
            helperText={petHelpers?.typeOfAppointment}
            error={petErrors?.typeOfAppointment}
            labelTop
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <div className="txt-mont fs14">
            Consultation Mode <span className="red-color">*</span>
          </div>
          <Select
            list={
              petValues.typeOfAppointment === "Walk-in" ? typeListNew : typeList
            }
            value={
              petValues.typeOfAppointment === "Walk-in"
                ? "Physical"
                : petValues.consultationType
            }
            handleChange={(e) =>
              handleSelect(e?.target?.value, "consultationType")
            }
            name="consultationType"
            select
            helperText={petHelpers?.consultationType}
            error={petErrors?.consultationType}
            labelTop
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="txt-mont fs14">
            Service Type <span className="red-color">*</span>
          </div>
          <Select
            list={
              petValues?.consultationType === "Virtual"
                ? serviceTypeWOSurgery
                : serviceType
            }
            value={petValues.serviceType}
            handleChange={(e, value) => handleSelect(value, "serviceType")}
            search
            helperText={petHelpers?.serviceType}
            error={petErrors?.serviceType}
            labelTop
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="txt-mont fs14 ">
            Reason <span className="red-color">*</span>
          </div>
          <CustomTextField
            name="reason"
            fullWidth
            handleChange={(e) => handleSelect(e?.target?.value, "reason")}
            value={petValues?.reason}
            helperText={petHelpers?.reason}
            error={petErrors?.reason}
          />
        </Grid>
        {petValues?.serviceType?.value === "Surgery" &&
        petValues?.appointmentOption === "Immediate" ? (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-mont fs14 ">
              Kennel Number <span className="red-color">*</span>
            </div>
            <CustomTextField
              name="kennelNumber"
              fullWidth
              handleChange={(e) =>
                handleSelect(e?.target?.value, "kennelNumber")
              }
              value={petValues?.kennelNumber}
              helperText={petHelpers?.kennelNumber}
              error={petErrors?.kennelNumber}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mbminus">
          <div className="flex-row">
            <div className="txt-mont fs14 mt10">
              Appointment <span className="red-color">*</span> :
            </div>
            <div className="ml5">
              <CustomCheckbox
                radio
                value={petValues?.appointmentOption}
                onChange={(e) =>
                  handleSelect(e?.target?.value, "appointmentOption")
                }
                radios={
                  petValues.typeOfAppointment !== "Phone"
                    ? appointmentList
                    : appointmentLaterList
                }
              />
            </div>
          </div>
          {petErrors?.appointmentOption && (
            <Typography color="error" className="mt5 fs14">
              Appointment is Required
            </Typography>
          )}
        </Grid>

        {petValues?.appointmentOption === "Later" && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-mont fs14">
              Date <span className="red-color">*</span>
            </div>
            <div className="mb20">
              <CustomTextField
                name="date"
                value={petValues?.date}
                labelTop
                fullWidth
                handleChange={(e) => handleSelect(e?.target?.value, "date")}
                minDate={new Date()}
                mobileDateWithBatch
                markDateList={holidayDates ?? []}
              />
            </div>
          </Grid>
        )}

        {petValues?.appointmentOption === "Later" && petValues?.date && (
          <div className="flex-row-wrap1">
            {activeSlotList?.length > 0 &&
              activeSlotList?.map((item, i) => {
                return (
                  <div
                    key={item?.time + i}
                    onClick={() => {
                      petValues?.serviceType?.value === "Surgery"
                        ? handleSelectectedTimeIds(item)
                        : handleSelectTime(item);
                    }}
                    className={
                      (
                        petValues?.serviceType?.value === "Surgery"
                          ? timeObject?.ids?.includes(item?.slotTimeId)
                          : selectTimeId === item?.slotTimeId
                      )
                        ? "bgContainerSelected cursor"
                        : "bgContainerUnselected cursor"
                    }
                  >
                    {item?.time}
                  </div>
                );
              })}
          </div>
        )}
        {selectTimeIdError && (
          <Typography color="error" className="ml5 mt5 fs14">
            Time is Required
          </Typography>
        )}
      </Grid>

      <div className="flex1-end">
        <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
          <CustomButton text="Submit" onClick={petAppointmentCreate} />
        </Grid>
      </div>
    </CustomModal>
  );
};

export default VetBookAppointment;
