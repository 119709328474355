import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  Container,
  FormHelperText,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Custom3dotString from "../../../components/Custom3dotString";
import CustomButton from "../../../components/CustomButton";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CustomTextField from "../../../components/CustomTextField";
import CustomUpload from "../../../components/CustomUpload";
import { default as Select } from "../../../components/Select/Select";
import { chatgptApi } from "../../../redux/actions/api";
import {
  createStocks,
  getClinicUsers,
  getPendingStocksByClinic,
  getProductsByClinicWithFilter,
  getStocksByClinic,
  getSupplier,
  updateStockAdjust,
} from "../../../redux/reducers/clinicSlice";
import { hideLoader, showLoader } from "../../../redux/reducers/loaderSlice";
import { AppColors } from "../../../util/AppColors";
import { StockAdjustList } from "../../../util/arrayList";
import { invetMsg, stckEntMsg } from "../../../util/chatgpt";
import {
  locationList,
  natureList,
  productCategoryList,
  productMeasurementUnit,
  productSubMeasurementUnit,
  yesNoValueList,
} from "../../../util/dropList";
import {
  convertDateYMD,
  convertDateYMDSlace,
  convertStringToObject,
  getExactNumber,
  getTaxableValue,
  getTaxValue,
  getTotalValues,
} from "../../../util/function";
import FilesWithCloseButton from "../ClinicPetDetails/PatientWorkbook/FilesWithCloseButton";
import StockPendingTable from "./StockPendingTable";

const tableHeaders = [
  // "productCode",
  "tradeName",
  "category",
  "unitType",
  "quantity",
  "extraStatus",
];

const nameExpan = {
  supplierName: "Supplier Name",
  category: "Category",
  tradeName: "Product Name",
  batchNo: "Batch No",
  quantity: "Quantity",
  updatedQuantity: "Updated Quantity",
  location: "Location",
  productCode: "Product Code",
  unitType: "Unit Type",
  extraStatus: "",
  purchaseCost: "Purchase Cost",

  type: "Type",
  product: "Product",
  supplier: "Supplier",
  batchNumber: "Batch Number",
  remarks: "Remarks",
  customer: "Customer",
  invoiceNumber: "Invoice Number",
};

const initValues = {
  date: new Date(),
  supplierName: null,
  category: null,
  productName: null,
  batchNo: "",
  quantity: "",
  updatedQuantity: "",
  mfgDate: null,
  expiryDate: null,
  purchaseCost: "",
  location: "",
  remarks: "",
};

const tblGrayBack = {
  backgroundColor: AppColors.tableGrey,
  color: AppColors.white,
  textAlign: "left",
};

const initStckVal = { supplierName: null, date: new Date(), list: [] };

const stckEntTblHdrs = [
  "hsnSacCode",
  "name",
  "quantity",
  "samples",
  "unitRate",
  "discounts",
  "cgst",
  "sgst",
  "taxes",
  "itemTotal",
  "status",
];

const stckNmeExpan = {
  icon: "",
  hsnSacCode: "HSN/SAC Code",
  name: "Name",
  quantity: "Quantity",
  unitRate: "Unit Rate", //"Item Base Cost",
  cgst: "CGST %",
  sgst: "SGST %",
  taxes: "Taxes", //"Tax Amount",
  itemTotal: "Item Total", //"Item Total Cost",
  samples: "Samples",
  discounts: "Discounts",
};

const initAdjValues = {
  type: StockAdjustList?.[0],
  batchNumber: null,
  quantity: null,
  remarks: "",
  supplier: null,
  product: null,
  customer: null,
  invoiceNumber: "",
};
const initAdjErrs = {
  type: false,
  batchNumber: false,
  quantity: false,
  remarks: false,
  supplier: false,
  product: false,
  customer: false,
  invoiceNumber: false,
};
const initAdjHlps = {
  type: "",
  batchNumber: "",
  quantity: "",
  remarks: "",
  supplier: "",
  product: "",
  customer: "",
  invoiceNumber: "",
};

const requiredFieldList = [
  "category",
  "subCategory",
  "hsnSacCode",
  "name",
  "tradeName",
  "measurementUnit",
  "subMeasurementUnit",
  // "manufacturingDate",
  // "expiryDate",
  "location",
  "sellPrice",
  "subunitSellingPrice",
  // "administeringCharges",
  "taxApplicability",
  "tax",
  "quantity",
  "baseCost",
  "cgst",
  "sgst",
  "totalAmt",
];

const initialError = {
  category: false,
  subCategory: false,
  name: false,
  tradeName: false,
  ingredient: false,
  nature: false,
  code: false,
  brand: false,
  measurementUnit: false,
  subMeasurementUnit: false,
  formula: false,
  batchNumber: false,
  manufacturingDate: false,
  expiryDate: false,
  location: false,

  sellPrice: false,
  subunitSellingPrice: false,
  administeringCharges: false,
  taxApplicability: false,
  tax: false,

  description: false,
};

const initialHelp = {
  category: "",
  subCategory: "",
  name: "",
  tradeName: "",
  ingredient: "",
  nature: "",
  code: "",
  brand: "",
  measurementUnit: "",
  subMeasurementUnit: "",
  formula: "",
  batchNumber: "",
  manufacturingDate: "",
  expiryDate: "",
  location: "",

  sellPrice: "",
  subunitSellingPrice: "",
  administeringCharges: "",
  taxApplicability: "",
  tax: "",

  description: "",
};

const Stock = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [stockValues, setStockValues] = useState(initValues);
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const [supplierList, setSupplierList] = useState([]);
  const defaultUrl = `?page=1&itemSize=${rowsPerPage}`;
  const [groupedStocks, setGrouppedStocks] = useState(null);
  const [batchList, setBatchList] = useState(null);
  const [stcEntModVsble, setStcEntModVsble] = useState(false);
  const [stcEntFiles, setStcEntFiles] = useState([]);
  const [actStep, setActStep] = useState(1);
  const [stckValue, setStckValue] = useState(initStckVal);
  const [invntUpdModVsble, setInvntUpdModVsble] = useState(false);
  const [inventFiles, setInventFiles] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [stcEntApiCaled, setStcEntApiCaled] = useState(false);
  const [penTblVsble, setPenTblVsble] = useState(false);
  const [adjustModVsble, setAdjustModVsble] = useState(false);
  const [adjustValues, setAdjustValues] = useState(initAdjValues);
  const [adjustErrors, setAdjustErrors] = useState(initAdjErrs);
  const [adjustHelpers, setAdjustHelpers] = useState(initAdjHlps);
  const [productList, setProductList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [submitErr, setSubmitErr] = useState(false);
  const [stockEntrErr, setStockEntErr] = useState(false);
  const [prdctPrevModVsble, setPrdctPrevModVsble] = useState(false);
  const [productErrors, setProductErrors] = useState(initialError);
  const [productHelps, setProductHelps] = useState(initialHelp);
  const { suppliers, stocks, pendingStocks, users } = useSelector(
    (state) => state?.clinic
  );

  useEffect(() => {
    dispatch(getStocksByClinic(defaultUrl));
    dispatch(getPendingStocksByClinic(`?type=pending`));
    dispatch(getSupplier());
    dispatch(getClinicUsers());
  }, []);

  useEffect(() => {
    dispatch(
      getStocksByClinic(
        `?page=${page}&itemSize=${rowsPerPage}&search=${searchValue}`
      )
    );
  }, [searchValue]);

  useEffect(() => {
    if (stocks?.data?.length == 0 || !stocks?.data) return setProductList([]);
    const reqList = stocks?.data?.map((prd) => ({
      name: prd?.tradeName,
      value: prd?.tradeName,
    }));
    setProductList(reqList);
  }, [stocks]);

  useEffect(() => {
    const reqList = suppliers?.data
      ?.map((sup) => ({
        name: sup?.supplierName,
        value: sup?.supplierName,
        supplierId: sup?.supplierId,
      }))
      ?.sort((a, b) => a?.name?.localeCompare(b?.name));
    setSupplierList(reqList);
  }, [suppliers]);

  useEffect(() => {
    if (users?.length === 0 || !users) return setUserList([]);
    const reqList = users
      ?.filter((usr) => usr?.name?.length > 0)
      ?.map((sup) => ({ name: sup?.name, value: sup?.name, userId: sup?.id }))
      ?.sort((a, b) => a?.name?.localeCompare(b?.name));
    setUserList(reqList);
  }, [users]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setPage(1);
  };

  const handleCreateUpdate = () => {
    setStcEntModVsble(true);
  };

  const handleEditStock = (itm) => {
    // setStockId(itm?.stockId);
    const reqName = `${itm?.category},${itm?.productName}`;
    const reqBatchList = groupedStocks?.[reqName]?.map((li) => ({
      name: li?.batchNo,
      value: li?.batchNo,
    }));
    if (reqBatchList?.[0]) {
      const reqData = groupedStocks?.[reqName]?.[0];
      if (reqData?.category) {
        dispatch(getProductsByClinicWithFilter(`?search=${reqData?.category}`));
      }
      setStockValues({
        ...stockValues,
        date: new Date(reqData?.date),
        supplierName: reqData?.supplierName,
        category: reqData?.category,
        productName: reqData?.productName,
        batchNo: reqData?.batchNo,
        quantity: reqData?.quantity,
        mfgDate: new Date(reqData?.mfgDate),
        expiryDate: new Date(reqData?.expiryDate),
        location: reqData?.location,
        remarks: reqData?.remark,
      });
    }
    setBatchList(reqBatchList);
  };

  const TableDataRow = ({ data }) => {
    const [open, setOpen] = useState(false);

    return (
      <Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell style={{ width: 50 }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </IconButton>
          </TableCell>
          {tableHeaders?.map((th) => {
            return (
              <TableCell>
                {th === "extraStatus" ? (
                  <img
                    alt=""
                    src={require("../../../assets/images/png/editNew.png")}
                    className="cursor imghw30"
                    onClick={() => handleEditStock(data)}
                  />
                ) : th === "quantity" ? (
                  data?.stockBatch?.reduce((acc, obj) => acc + obj?.quantity, 0)
                ) : (
                  data?.[th]
                )}
              </TableCell>
            );
          })}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box style={{ background: "#D9D9D933" }}>
                <div className="ph20pv10 flex-row flex-row-wrap">
                  {data?.stockBatch?.length > 0 &&
                    data?.stockBatch?.map((li, i) => (
                      <div
                        className="w33Per flex-row mv10"
                        key={li?.batchId + `${i}`}
                      >
                        <div>
                          <Typography className="font-medium fs12 blue-color">
                            Batch No: {li?.batchNo}
                          </Typography>
                          <Typography className="font-medium fs12 black11">
                            Mfg Date: :{" "}
                            {moment(new Date(li?.mfgDate)).format("YYYY-MM-DD")}
                          </Typography>
                          <Typography className="font-medium fs12 black11">
                            Expiry Date:{" "}
                            {moment(new Date(li?.expiryDate)).format(
                              "YYYY-MM-DD"
                            )}
                          </Typography>
                          <Typography className="font-medium fs12 black11">
                            Quantity: {li?.quantity}
                          </Typography>
                          <Typography className="font-medium fs12 black11">
                            Unit Selling Price: {li?.unitSellingPrice}
                          </Typography>
                          <Typography className="font-medium fs12 black11">
                            Location: {li?.location}
                          </Typography>
                        </div>
                      </div>
                    ))}
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  const handleCloseStcEnt = () => {
    setStcEntModVsble(false);
    setStcEntFiles([]);
    setActStep(1);
    setSubmitErr(false);
    setStockEntErr(false);
  };

  const onEntFileUpload = (e) => {
    const fileList = e?.target?.files;
    let fileLists = stcEntFiles?.length > 0 ? [...stcEntFiles] : [];

    const readFiles = Array.from(fileList).map((file, i) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          fileLists = [
            ...fileLists,
            {
              file: file,
              previewUrl: reader.result,
              i,
            },
          ];
          resolve();
        };
        reader.onerror = () => reject(reader.error);
        reader.readAsDataURL(file);
      });
    });

    Promise.all(readFiles)
      .then(() => setStcEntFiles(fileLists))
      .catch((error) => console.error("Error reading files: ", error));
  };

  const handleImageRemove = (ind) => {
    const reqList = stcEntFiles
      ?.filter((fle, i) => i !== ind)
      ?.map((fle, i) => ({ ...fle, i }));
    setStcEntFiles(reqList);
  };

  const onStcEntUpload = () => {
    if (!stcEntFiles?.length === 0 || !stcEntFiles) return;
    dispatch(showLoader());
    const payload = {
      model: "gpt-4o",
      messages: [
        stckEntMsg,
        {
          role: "user",
          content: stcEntFiles?.map((fle) => ({
            type: "image_url",
            image_url: {
              url: fle?.previewUrl,
            },
          })),
        },
      ],
    };

    chatgptApi
      .post("/v1/chat/completions", payload)
      .then(async (response) => {
        if (response?.status === 200) {
          dispatch(hideLoader());
          const apiRes = response?.data?.choices?.[0]?.message?.content;
          if (apiRes) {
            let parsed = null;
            try {
              try {
                const cleanedText = apiRes
                  .replace("```json\n", "")
                  .replace("\n```", "");
                const unescapedText = cleanedText.replace(/\\"/g, '"'); // Add brackets around the items and remove the final comma if it exists
                parsed = JSON.parse(unescapedText); // Return the parsed object
              } catch (err) {
                // console.log("Chat GPT response getting error");
              }

              if (
                !parsed?.[
                  "Items" || "items" || "ItemDetails" || "HSN" || "ItemList"
                ]?.length > 0
              ) {
                return setStockEntErr(true);
              }
              let reqList = [];
              if (
                parsed?.[
                  "Items" || "items" || "ItemDetails" || "HSN" || "ItemList"
                ]?.length > 0
              ) {
                reqList = parsed?.[
                  "Items" || "items" || "ItemDetails" || "HSN" || "ItemList"
                ]?.map((itm, i) => {
                  return {
                    ...itm,
                    hsnSacCode: itm?.HSNSAC,
                    name: itm?.Name,
                    quantity: itm?.Quantity,
                    unitRate: itm?.Unitrate,
                    cgst: itm?.CGST,
                    sgst: itm?.SGST,
                    taxes: itm?.Taxes,
                    itemTotal: itm?.Itemtotal,
                    samples: itm?.Samples,
                    discounts: itm?.Discounts,
                    landedCost: itm?.landedCost ?? 0,
                    i,
                    apiCalled: false,
                    apiResponse: null,
                  };
                });
              }
              const reqObj = {
                ...stckValue,
                additionalCharges: getExactNumber(parsed?.Additionalcharges),
                address: parsed?.Address,
                billDate:
                  new Date(convertDateYMD(parsed?.["Billdate"])) ??
                  new Date(convertDateYMDSlace(parsed?.["Billdate"])),
                email: parsed?.Email,
                GSTIN: parsed?.GSTIN,
                grandtotal: parsed?.Grandtotal,
                invoiceNumber: parsed?.Invoicenumber,
                phone: parsed?.Phone,
                supplierName: parsed?.["Suppliername" || "supplierName"],
                totaltaxablevalue: parsed?.Totaltaxablevalue,
                totalTaxes: parsed?.Totaltaxes,
                list: reqList,
              };
              setStcEntFiles([]);
              setStckValue(reqObj);
              setActStep(2);
              setStockEntErr(false);
              dispatch(hideLoader());
            } catch (error) {
              console.error("Failed to parse JSON:", error);
            }
          } else {
            dispatch(hideLoader());
            console.error("No content found in the response");
          }
        } else {
          dispatch(hideLoader());
          console.error("Error:", response.problem);
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        console.log("ERROR", error);
      });
  };

  const onInventoryUpload = () => {
    if (inventFiles?.length === 0 || !inventFiles) return;
    dispatch(showLoader());
    const payload = {
      model: "gpt-4o",
      messages: [
        invetMsg,
        {
          role: "user",
          content: inventFiles?.map((fle) => ({
            type: "image_url",
            image_url: {
              url: fle?.previewUrl,
            },
          })),
        },
      ],
    };

    chatgptApi
      .post("/v1/chat/completions", payload)
      .then((response) => {
        setActStep(2);
        if (response?.status === 200) {
          dispatch(showLoader());
          const rawContent = response.data?.choices?.[0].message?.content;
          if (rawContent) {
            const prdct = convertStringToObject(rawContent);

            const reqList = stckValue?.list?.map((li, i) => {
              if (i === selectedRowData?.i) {
                return {
                  ...li,
                  name: prdct?.tradeName ?? li?.name,
                  apiCalled: true,
                  apiResponse: JSON.stringify(prdct),
                  product: {
                    ...li,
                    ...prdct,
                    tax: prdct?.tax ?? li?.tax ?? null,
                    hsnSacCode: li?.HSNSAC,
                    category:
                      productCategoryList?.find(
                        (prd) =>
                          prd?.value === prdct?.["category" || "Category"]
                      ) ?? null,
                    ingredient:
                      prdct?.[
                        "majorActiveIngredients" || "MajorActiveIngredients"
                      ],
                    nature:
                      natureList?.find(
                        (nat) => nat?.value === prdct?.["nature" || "Nature"]
                      ) ?? null,
                    code: prdct?.["barCodeNumber" || "BarCodeNumber"],
                    measurementUnit:
                      productMeasurementUnit?.find(
                        (unit) =>
                          unit?.value ===
                          prdct?.["measurementUnit" || "MeasurementUnit"]
                      ) ?? null,
                    submeasurementUnit:
                      productMeasurementUnit?.find(
                        (unit) =>
                          unit?.value ===
                          prdct?.["submeasurementUnit" || "SubmeasurementUnit"]
                      ) ?? null,
                    manufacturingDate: new Date(
                      convertDateYMD(
                        prdct?.mfgDate ??
                          prdct?.["manufacturingDate" || "ManufacturingDate"]
                      ) ??
                        convertDateYMDSlace(
                          prdct?.mfgDate ??
                            prdct?.["manufacturingDate" || "ManufacturingDate"]
                        ) ??
                        new Date()
                    ),
                    expiryDate: new Date(
                      convertDateYMD(prdct?.["expiryDate" || "ExpiryDate"]) ??
                        convertDateYMDSlace(
                          prdct?.["expiryDate" || "ExpiryDate"]
                        ) ??
                        new Date()
                    ),
                    location:
                      locationList?.find(
                        (loc) =>
                          loc?.value === prdct?.["location" || "Location"]
                      ) ?? null,
                    description:
                      prdct?.["briefDescription" || "BriefDescription"],
                    sellPrice:
                      prdct?.["unitSellingPrice" || "UnitSellingPrice"],
                    administeringCharges: prdct?.administeringCharges ?? "0",
                    taxApplicability: yesNoValueList?.find(
                      (yn) => yn?.value === prdct?.taxApplicability
                    ),
                    tax: prdct?.tax,
                  },
                };
              }
              return li;
            });
            setStckValue({ ...stckValue, list: reqList });
            setInventFiles([]);
            setInvntUpdModVsble(false);
            setPrdctPrevModVsble(true);
            // setSelectedRowData(null);
            dispatch(hideLoader());
          } else {
            dispatch(hideLoader());
            console.error("No content found in the response");
          }
        } else {
          dispatch(hideLoader());
          console.error("Error:", response.problem);
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        console.log("ERROR", error);
      });
  };

  const handleChangeStckValue = (name, value) => {
    setStckValue({ ...stckValue, [name]: value });
  };

  const handleChangeTableValue = (name, value, ind) => {
    const reqList = stckValue?.list?.map((li, i) => {
      if (i === ind) {
        const reqObj = { ...li, [name]: value };
        if (name === "sgst") reqObj.cgst = value;
        if (name === "cgst") reqObj.sgst = value;
        return reqObj;
      }
      return li;
    });
    setStckValue({ ...stckValue, list: reqList });
  };

  const handleChangeAdditionalCharge = (name, value) => {
    setStckValue({ ...stckValue, [name]: value });
  };

  const handleDeleteRow = (ind) => {
    const reqList = stckValue?.list
      ?.filter((li, i) => i !== ind)
      ?.map((li, i) => ({ ...li, i }));
    setStckValue({ ...stckValue, list: reqList });
  };

  const handleCloseInvntUpload = () => {
    setInvntUpdModVsble(false);
    setInventFiles([]);
  };

  const handleUploadInventry = (data) => {
    setSelectedRowData(data);
    setInvntUpdModVsble(true);
  };

  const handleEditRow = (data) => {
    setSelectedRowData(data);
  };

  const onUploadFile = (e) => {
    const fileList = e?.target?.files;
    let fileLists = inventFiles?.length > 0 ? [...inventFiles] : [];

    const readFiles = Array.from(fileList).map((file, i) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          fileLists = [
            ...fileLists,
            {
              file: file,
              previewUrl: reader.result,
              i,
            },
          ];
          resolve();
        };
        reader.onerror = () => reject(reader.error);
        reader.readAsDataURL(file);
      });
    });

    Promise.all(readFiles)
      .then(() => setInventFiles(fileLists))
      .catch((error) => console.error("Error reading files: ", error));
  };

  const handleInventImageRemove = (ind) => {
    const reqList = inventFiles
      ?.filter((fle, i) => i !== ind)
      ?.map((fle, i) => ({ ...fle, i }));
    setInventFiles(reqList);
  };

  const onStcApiCall = (isSubmit = false) => {
    if (stckValue?.list?.length === 0 || !stckValue?.list) return;
    // if (isSubmit && stckValue?.list?.some((li) => !li?.apiCalled)) {
    //   return setSubmitErr(true);
    // }
    dispatch(showLoader());
    let stocks = [];
    if (stckValue?.list?.length > 0) {
      stocks = stckValue?.list?.map((itm) => {
        // let product = null;
        // if (itm?.apiCalled && itm?.apiResponse) {
        //   const prd = JSON.parse(itm?.apiResponse);
        //   product = {
        //     barCodeNo: itm?.product?.barCodeNumber ?? "",
        //     batchNumber: itm?.product?.batchNumber ?? "",
        //     brand: itm?.product?.brand ?? "",
        //     category:
        //       itm?.product?.category?.value ?? itm?.product?.category ?? "",
        //     expiryDate: moment(
        //       new Date(itm?.product?.expiryDate ?? itm?.product?.expDate)
        //     ).format("YYYY-MM-DD"),
        //     formula: itm?.product?.formula ?? "",
        //     mfgDate: moment(
        //       new Date(itm?.product?.mfgDate ?? itm?.product?.manufacturingDate)
        //     ).format("YYYY-MM-DD"),
        //     measurementUnit:
        //       itm?.product?.measurementUnit?.value ??
        //       itm?.product?.measurementUnit ??
        //       "",
        //     subMeasurementUnit:
        //       itm?.product?.subMeasurementUnit?.value ??
        //       itm?.product?.subMeasurementUnit ??
        //       "",
        //     prodcutName: itm?.product?.name ?? "",
        //     hsnSacCode: itm?.product?.hsnSacCode ?? "",
        //     tradeName: itm?.product?.tradeName ?? "",
        //     ingredient: itm?.product?.ingredient ?? "", //not receiving
        //     nature:
        //       itm?.product?.natue?.value ??
        //       itm?.product?.natue ??
        //       prd?.nature ??
        //       "",
        //     type: prd?.type ?? "", //not receiving
        //     tax: Number(
        //       getExactNumber(itm?.product?.tax ?? prd?.tax ?? itm?.taxAmt) ?? 0
        //     ), //not receiving
        //     administeringCharges: Number(
        //       itm?.product?.administeringCharges ??
        //         prd?.administeringCharges ??
        //         0
        //     ), //not receiving
        //     subCategory: itm?.product?.subCategory ?? prd?.subCategory ?? "",
        //     unitSellingPrice: Number(
        //       getExactNumber(
        //         itm?.product?.unitSellingPrice ?? prd?.unitSellingPrice
        //       ) ?? 0
        //     ),
        //     subUnitSellingPrice: Number(
        //       getExactNumber(
        //         itm?.product?.subunitSellingPrice ?? prd?.subunitSellingPrice
        //       ) ?? 0
        //     ),
        //     location:
        //       itm?.product?.location?.value ??
        //       itm?.product?.location ??
        //       prd?.location ??
        //       "",
        //     active: true,
        //   };
        // }
        const taxableValue = getTaxableValue({
          quantity: getExactNumber(itm?.quantity),
          unitRate: getExactNumber(itm?.unitRate),
          discount: getExactNumber(itm?.discounts ?? 0),
        });
        const taxes = getTaxValue({
          taxableValue,
          cgst: getExactNumber(itm?.cgst),
          sgst: getExactNumber(itm?.sgst),
        });
        const itemTotal = taxableValue + taxes;
        return {
          hsnSacCode: itm?.hsnSacCode ?? itm?.HSNSAC,
          tradeName: itm?.name ?? itm?.Name,
          quantity:
            Number(
              Number(getExactNumber(itm?.quantity)) +
                Number(getExactNumber(itm?.samples ?? 0))
            ) ?? 1,
          unitRate: Number(getExactNumber(itm?.unitRate) ?? 0),
          cgst: Number(getExactNumber(itm?.cgst ?? itm?.CGST) ?? 0),
          sgst: Number(getExactNumber(itm?.sgst ?? itm?.SGST) ?? 0),
          taxes: Number(taxes ?? 0),
          itemTotal: Number(itemTotal ?? 0),
          landedCost: 0,
          status: false,
          // status: product !== null,
          product: null,
        };
      });
    }
    // console.log("stckValue", stckValue);

    const data = {
      supplierName: stckValue?.supplierName,
      date: moment().format("YYYY-MM-DD"),
      email: stckValue?.email,
      phone: stckValue?.phone,
      address: stckValue?.address,
      GSTIN: stckValue?.GSTIN,
      billDate: moment(new Date(stckValue?.billDate)).format("YYYY-MM-DD"),
      invoiceNumber: stckValue?.invoiceNumber,
      totalTaxableValue: Number(
        getTotalValues({
          list: stckValue?.list,
          additionalCharges: stckValue?.additionalCharges,
        })?.taxableValues ?? 0
      ),
      totalTaxes: Number(
        getTotalValues({
          list: stckValue?.list,
          additionalCharges: stckValue?.additionalCharges,
        })?.taxes ?? 0
      ),
      additionalCharges: Number(stckValue?.additionalCharges ?? 0),
      grandTotal: Number(
        getTotalValues({
          list: stckValue?.list,
          additionalCharges: stckValue?.additionalCharges,
        })?.grandTotal ?? 0
      ),
      stocks,
    };
    try {
      dispatch(createStocks(data)).then((res) => {
        if (res?.payload) {
          dispatch(getStocksByClinic(defaultUrl));
          dispatch(getPendingStocksByClinic(`?type=pending`));
          setStcEntModVsble(false);
          setStckValue(initStckVal);
          setActStep(1);
          setSubmitErr(false);
        }
      });
    } catch (err) {
      dispatch(hideLoader());
      console.log("Err");
    }
  };

  const handleAdjust = () => {
    setAdjustModVsble(true);
  };

  const handleCloseAdjMod = () => {
    setAdjustModVsble(false);
    setAdjustValues(initAdjValues);
    setAdjustErrors(initAdjErrs);
    setAdjustHelpers(initAdjHlps);
  };

  const handleChangeAdjustValue = (name, value) => {
    const reqValue = typeof value === "object" ? value?.value : value;
    setAdjustValues({ ...adjustValues, [name]: value });
    setAdjustErrors({
      ...adjustErrors,
      [name]:
        reqValue?.length > 0
          ? false
          : adjustValues?.type?.value !== "Other Adjustments" &&
            name === "remarks"
          ? false
          : name === "invoiceNumber"
          ? false
          : true,
    });
    setAdjustHelpers({
      ...adjustHelpers,
      [name]:
        reqValue?.length > 0
          ? ""
          : adjustValues?.type?.value !== "Other Adjustments" &&
            name === "remarks"
          ? ""
          : name === "invoiceNumber"
          ? ""
          : `${nameExpan[name]} is Required`,
    });
  };

  const getBatchNumbersByProduct = (tradeName) => {
    const reqPrd = stocks?.data?.find((stc) => stc?.tradeName === tradeName);
    if (reqPrd) {
      const reqList = reqPrd?.stockBatch?.map((btc) => ({
        name: btc?.batchNo,
        value: btc?.batchNo,
      }));
      return reqList;
    }
    return [];
  };

  const handleValidation = () => {
    const errList = {};
    const hlpList = {};
    Object.keys(adjustValues).forEach(function (key) {
      const reqValue =
        typeof adjustValues[key] === "object"
          ? adjustValues[key]?.value ?? null
          : adjustValues[key];
      if (
        key === "quantity" &&
        adjustValues?.type?.value !== "Other Adjustments" &&
        Number(reqValue) < 1
      ) {
        errList[key] = true;
        hlpList[key] = "Quantity must be Positive number";
        return;
      }
      if (reqValue?.length === 0 || reqValue === null) {
        if (key === "invoiceNumber") {
          errList[key] = false;
          hlpList[key] = "";
          return;
        }
        if (
          key === "remarks" &&
          adjustValues?.type?.value !== "Other Adjustments"
        ) {
          errList[key] = false;
          hlpList[key] = "";
          return;
        }
        if (
          key === "supplier" &&
          adjustValues?.type?.value !== "Return on Purchase"
        ) {
          errList[key] = false;
          hlpList[key] = "";
          return;
        }
        if (
          key === "customer" &&
          adjustValues?.type?.value !== "Return on Sales"
        ) {
          errList[key] = false;
          hlpList[key] = "";
          return;
        }
        errList[key] = true;
        hlpList[key] = `${nameExpan[key]} is Required`;
      }
    });
    setAdjustHelpers({ ...adjustHelpers, ...hlpList });
    setAdjustErrors({ ...adjustErrors, ...errList });
    return Object.values(errList).some((item) => item);
  };

  const handleStockAdjust = () => {
    const validation = handleValidation();
    if (validation) return;
    const data = {
      type: adjustValues?.type?.value,
      quantity:
        adjustValues?.type?.value === "Return on Purchase"
          ? Number(adjustValues?.quantity) * -1
          : Number(adjustValues?.quantity),
      batchNumber: adjustValues?.batchNumber?.value,
      productName: adjustValues?.product?.value,
      remarks: adjustValues?.remarks,
    };
    if (adjustValues?.type?.value !== "Other Adjustments") {
      data.invoiceNumber = adjustValues?.invoiceNumber;
    }
    if (adjustValues?.type?.value === "Return on Purchase") {
      data.supplierName = adjustValues?.supplier?.name;
      data.supplierId = adjustValues?.supplier?.supplierId;
    }
    if (adjustValues?.type?.value === "Return on Sales") {
      data.customerName = adjustValues?.customer?.name;
      data.userId = adjustValues?.customer?.userId;
    }
    dispatch(updateStockAdjust(data)).then((res) => {
      if (res?.payload) {
        dispatch(getStocksByClinic(defaultUrl));
        handleCloseAdjMod();
      }
    });
  };

  const onClosePrevMod = () => {
    setPrdctPrevModVsble(false);
    setSelectedRowData(null);
    setProductErrors(initialError);
    setProductHelps(initialHelp);
  };

  const handleChange = (name, value) => {
    const reqList = stckValue?.list?.map((li, i) => {
      if (i === selectedRowData?.i) {
        return { ...li, product: { ...li?.product, [name]: value } };
      }
      return li;
    });
    setStckValue({ ...stckValue, list: reqList });
    const reqValue = typeof value === "object" ? value?.value : value;
    if (reqValue?.length > 0) {
      setProductErrors({ ...productErrors, [name]: false });
      setProductHelps({ ...productHelps, [name]: "" });
    } else {
      setProductErrors({
        ...productErrors,
        [name]: requiredFieldList?.includes(name)
          ? name === "manufacturingDate" || name === "expiryDate"
            ? false
            : true
          : false,
      });
      setProductHelps({
        ...productHelps,
        [name]: requiredFieldList?.includes(name)
          ? name === "manufacturingDate" || name === "expiryDate"
            ? ""
            : "This field is required"
          : "",
      });
    }
  };

  const getRowValues = () => {
    return stckValue?.list?.find((li, i) => i === selectedRowData?.i);
  };

  const handleSubmit = async () => {
    let errObj = {};
    let hlpObj = {};
    requiredFieldList?.forEach((fld) => {
      const reqValue =
        typeof getRowValues()?.product?.[fld] === "object"
          ? getRowValues()?.product?.[fld]?.value
          : getRowValues()?.product?.[fld];

      if (
        reqValue === undefined ||
        reqValue === null ||
        reqValue?.length === 0 ||
        reqValue === ""
      ) {
        errObj = {
          ...errObj,
          [fld]: requiredFieldList?.includes(fld)
            ? fld === "manufacturingDate" || fld === "expiryDate"
              ? false
              : true
            : false,
        };
        hlpObj = {
          ...hlpObj,
          [fld]: requiredFieldList?.includes(fld)
            ? fld === "manufacturingDate" || fld === "expiryDate"
              ? ""
              : "This field is required"
            : "",
        };
        if (fld === "tax") {
          errObj = {
            ...errObj,
            [fld]:
              getRowValues()?.product?.taxApplicability === "No" ? false : true,
          };
          hlpObj = {
            ...hlpObj,
            [fld]:
              getRowValues()?.product?.taxApplicability === "No"
                ? ""
                : "This field is required",
          };
        }
      } else {
        errObj = { ...errObj, [fld]: false };
        hlpObj = { ...hlpObj, [fld]: "" };
      }
    });
    setProductErrors({ ...productErrors, ...errObj });
    setProductHelps({ ...productHelps, ...hlpObj });
    if (Object.keys(errObj)?.some((val) => errObj?.[val] === true)) return;
    setSelectedRowData(null);
    setPrdctPrevModVsble(false);
    setProductErrors(initialError);
    setProductHelps(initialHelp);
  };

  const handleAdd = () => {
    setStckValue({
      ...stckValue,
      list: [
        ...stckValue?.list,
        { i: stckValue?.list?.length, apiCalled: false, apiResponse: null },
      ],
    });
  };

  return (
    <>
      <Container maxWidth="xl">
        {penTblVsble ? (
          <StockPendingTable
            setPenTblVsble={setPenTblVsble}
            stckEntTblHdrs={stckEntTblHdrs}
            tblGrayBack={tblGrayBack}
            stckNmeExpan={stckNmeExpan}
          />
        ) : (
          <div className="com-mar back-white pv10">
            <div className="flex-row-ali-cen w100Per">
              <div className="flex-center w100Per">
                <div className="flex-row-ali-cen">
                  <div style={{ width: "600px" }}>
                    <CustomTextField
                      placeholder="Search"
                      fullWidth
                      backgroundColor={AppColors.lightPink}
                      value={searchValue}
                      handleChange={handleSearch}
                    />
                  </div>
                  <div className="ml20">
                    <CustomButton
                      text="Create"
                      smallBtn
                      onClick={handleCreateUpdate}
                    />
                  </div>
                  <div className="ml20">
                    <CustomButton
                      text="Adjust"
                      smallBtn
                      onClick={handleAdjust}
                    />
                  </div>
                </div>
              </div>
              <div style={{ position: "absolute", right: 70 }}>
                <Typography
                  className="fs14 txt-semi-bold orange-color cursor"
                  onClick={() => setPenTblVsble(true)}
                >
                  Pending {pendingStocks?.totalRecords}
                </Typography>
              </div>
            </div>

            <Box className="pb-40 ph20">
              <TableContainer sx={{ maxHeight: 400 }}>
                <Table aria-label="collapsible table" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell style={tblGrayBack} />
                      {tableHeaders?.map((th) => (
                        <TableCell style={tblGrayBack}>
                          {nameExpan?.[th]}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stocks?.data?.length > 0 &&
                      stocks?.data?.map((stock, i) => (
                        <TableDataRow
                          key={stock?.stockId + `${i}`}
                          data={stock}
                        />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>
        )}
      </Container>

      <CustomModal
        open={stcEntModVsble}
        onClose={handleCloseStcEnt}
        header="Stock Entry"
        headerCenter
        modalWidth={actStep === 1 ? 40 : 90}
        modal
      >
        <div className="scroll-90vh">
          <Grid container spacing={0} className="pb20">
            {actStep === 1 ? (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="flex-row-ali-cen flex-center">
                  <Typography className="txt-semi-bold fs16 black">
                    Step {actStep}:
                  </Typography>
                  <Typography className="ml5 font-medium fs16 black">
                    Upload a clear picture of your supplier invoice
                  </Typography>
                </div>
              </Grid>
            ) : null}
            {actStep === 1 ? (
              <>
                {stcEntFiles?.length > 0 ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="mt20"
                  >
                    <FilesWithCloseButton
                      files={stcEntFiles}
                      onClickFile={(i, fle) => handleImageRemove(i)}
                      center
                    />
                  </Grid>
                ) : null}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="p20"
                >
                  <CustomUpload
                    onUploadFile={onEntFileUpload}
                    multipleNew
                    fileAccept="image/*,.pdf"
                    multiFiles
                  />
                </Grid>
              </>
            ) : (
              <>
                <div className="flex-row-ali-cen w100Per flex-center mv20">
                  <Typography className="txt-semi-bold gray7 fs14">
                    Supplier Name:
                  </Typography>
                  <Typography className="font-medium gray7 fs14 ml5">
                    {stckValue?.supplierName}
                  </Typography>

                  <Typography className="txt-semi-bold gray7 fs14 ml30">
                    Date:
                  </Typography>
                  <div className="w20Per ml10">
                    <CustomTextField
                      name="date"
                      fullWidth
                      handleChange={(e) =>
                        handleChangeStckValue("date", e?.target?.value)
                      }
                      value={stckValue?.billDate ?? stckValue?.date}
                      mobileDate
                      disabled
                    />
                  </div>
                </div>
                <TableContainer sx={{ maxHeight: 350 }} className="mb20">
                  <Table
                    sx={{ minWidth: 200 }}
                    aria-labelledby="tableTitle"
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow>
                        {stckEntTblHdrs?.map((hdr, i) => (
                          <TableCell
                            style={tblGrayBack}
                            className={`table-header-text`}
                          >
                            <div
                              style={{
                                width:
                                  i === 0
                                    ? 200
                                    : i === stckEntTblHdrs?.length - 1
                                    ? 70
                                    : 130,
                              }}
                            >
                              {stckNmeExpan?.[hdr]}
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stckValue?.list?.map((li, ind) => {
                        const taxableValue = getTaxableValue({
                          quantity: getExactNumber(li?.quantity),
                          unitRate: getExactNumber(li?.unitRate),
                          discount: getExactNumber(li?.discounts ?? 0),
                        });
                        const taxes = getTaxValue({
                          taxableValue,
                          cgst: getExactNumber(li?.cgst),
                          sgst: getExactNumber(li?.sgst),
                        });
                        const itemTotal = taxableValue + taxes;
                        return (
                          <TableRow>
                            {stckEntTblHdrs?.map((hdr, i) => (
                              <TableCell
                                key={ind + `${i}` + "tr"}
                                component="th"
                              >
                                <div
                                  style={{
                                    width:
                                      i === 0
                                        ? 200
                                        : i === stckEntTblHdrs?.length - 1
                                        ? 70
                                        : 130,
                                  }}
                                >
                                  {i === stckEntTblHdrs?.length - 1 ? (
                                    <div className="flex-row-ali-cen">
                                      <img
                                        src={require("../../../assets/images/png/delete.png")}
                                        className="imghw30 cursor"
                                        onClick={() => handleDeleteRow(ind)}
                                      />
                                      {/* {stcEntApiCaled ? (
                                        selectedRowData?.i === ind ? (
                                          <img
                                            src={require("../../../assets/images/png/imgAttach.png")}
                                            className="imghw30 cursor ml20"
                                            onClick={() =>
                                              handleUploadInventry(li)
                                            }
                                          />
                                        ) : (
                                          <img
                                            src={require("../../../assets/images/png/edit.png")}
                                            className="imghw30 cursor ml20"
                                            onClick={() => handleEditRow(li)}
                                          />
                                        )
                                      ) : (
                                        <img
                                          src={require("../../../assets/images/png/imgAttach.png")}
                                          className="imghw30 cursor ml20"
                                          onClick={() => {
                                            if (li?.apiCalled) return;
                                            handleUploadInventry(li);
                                          }}
                                        />
                                      )} */}
                                    </div>
                                  ) : li?.apiCalled ? (
                                    hdr === "name" ? (
                                      <Custom3dotString
                                        str={li?.[hdr]}
                                        className="font-regular fs14"
                                        length={13}
                                      />
                                    ) : (
                                      li?.product?.[hdr] ?? li?.[hdr]
                                    )
                                  ) : (
                                    <CustomTextField
                                      name={hdr}
                                      fullWidth
                                      handleChange={(e) =>
                                        handleChangeTableValue(
                                          hdr,
                                          e?.target?.value,
                                          ind
                                        )
                                      }
                                      value={
                                        hdr === "taxes"
                                          ? Number(taxes)?.toFixed(2)
                                          : hdr === "itemTotal"
                                          ? Number(itemTotal)?.toFixed(2)
                                          : li?.[hdr]
                                      }
                                      disabled={
                                        hdr === "taxes" || hdr === "itemTotal"
                                      }
                                      type={
                                        hdr === "hsnSacCode" || hdr === "name"
                                          ? "string"
                                          : "number"
                                      }
                                    />
                                  )}
                                </div>
                              </TableCell>
                            ))}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div>
                  <CustomButton text="Add" smallBtn onClick={handleAdd} />
                </div>
                <div className="flex1-end pr50">
                  <div className="w25Per">
                    <div className="flex-row-ali-cen">
                      <div className="flex-start">
                        <div className="font-medium fs14 black">
                          Total Taxable Value
                        </div>
                      </div>
                      <div className="flex1-end">
                        <div className="font-bold fs14 black">
                          {Number(
                            getTotalValues({
                              list: stckValue?.list,
                              additionalCharges: stckValue?.additionalCharges,
                            })?.taxableValues
                          )?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                    <div className="flex-row-ali-cen mt10">
                      <div className="flex-start">
                        <div className="font-medium fs14 black">
                          Total taxes
                        </div>
                      </div>
                      <div className="flex1-end">
                        <div className="font-medium fs14 black">
                          {Number(
                            getTotalValues({
                              list: stckValue?.list,
                              additionalCharges: stckValue?.additionalCharges,
                            })?.taxes
                          )?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                    <div className="flex-row-ali-cen mt10">
                      <div className="flex-start">
                        <div className="font-medium fs14 black">
                          Additional Charges
                        </div>
                      </div>
                      <div className="flex1-end">
                        <div className="w70Per">
                          <CustomTextField
                            fullWidth
                            handleChange={(e) =>
                              handleChangeAdditionalCharge(
                                "additionalCharges",
                                e?.target?.value
                              )
                            }
                            value={stckValue?.additionalCharges}
                            type="number"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex-row-ali-cen mt10">
                      <div className="flex-start">
                        <div className="font-medium fs14 black">
                          Grand Total
                        </div>
                      </div>
                      <div className="flex1-end">
                        <div className="font-bold fs14 black">
                          {Number(
                            getTotalValues({
                              list: stckValue?.list,
                              additionalCharges: stckValue?.additionalCharges,
                            })?.grandTotal
                          )?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {stcEntApiCaled ? (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography className="flex-center mb20 txt-regular green3 fs14">
                      Product mapping successful. Click on the save button to
                      update the inventory.
                    </Typography>
                  </Grid>
                ) : null}
              </>
            )}

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {actStep === 1 ? (
                stockEntrErr ? (
                  <div className="flex-center mb10">
                    <FormHelperText error>
                      Unable to process the request. Kindly upload a clear
                      picture and try again.
                    </FormHelperText>
                  </div>
                ) : null
              ) : submitErr ? (
                <div className="flex-center mb10">
                  <FormHelperText error>
                    Please upload images against each product to complete the
                    process or skip to continue later.
                  </FormHelperText>
                </div>
              ) : null}

              <div className="flex-center">
                <div className="flex-row">
                  <CustomButton
                    text="Submit"
                    smallBtn
                    onClick={() => {
                      actStep === 1 ? onStcEntUpload() : onStcApiCall(true);
                    }}
                  />
                  {/* {actStep === 2 ? (
                  <div className="ml20">
                    <CustomButton
                      text="Skip"
                      smallBtn
                      onClick={() => onStcApiCall(false)}
                      yellowBtn
                    />
                  </div>
                ) : null} */}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </CustomModal>

      <CustomModal
        open={adjustModVsble}
        onClose={handleCloseAdjMod}
        header="Stock Adjustment"
        modalWidth={30}
        rightModal
      >
        <div className="scroll-90vh">
          <Grid container spacing={2} className="ph20">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="txt-semi-bold fs14 light-grey">
                Type <span className="red-color">*</span>
              </div>
              <Select
                list={StockAdjustList}
                handleChange={(e, value) =>
                  handleChangeAdjustValue("type", value)
                }
                value={adjustValues?.type}
                helperText={adjustHelpers?.type}
                error={adjustErrors?.type}
                search
              />
            </Grid>
            {adjustValues?.type?.value === "Return on Purchase" ? (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="txt-semi-bold fs14 light-grey">
                  Supplier <span className="red-color">*</span>
                </div>
                <Select
                  list={supplierList}
                  handleChange={(e, value) =>
                    handleChangeAdjustValue("supplier", value)
                  }
                  value={adjustValues?.supplier}
                  helperText={adjustHelpers?.supplier}
                  error={adjustErrors?.supplier}
                  search
                />
              </Grid>
            ) : null}
            {adjustValues?.type?.value === "Return on Sales" ? (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="txt-semi-bold fs14 light-grey">
                  Customer <span className="red-color">*</span>
                </div>
                <Select
                  list={userList}
                  handleChange={(e, value) =>
                    handleChangeAdjustValue("customer", value)
                  }
                  value={adjustValues?.customer}
                  helperText={adjustHelpers?.customer}
                  error={adjustErrors?.customer}
                  search
                />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="txt-semi-bold fs14 light-grey">
                Product <span className="red-color">*</span>
              </div>
              <Select
                list={productList}
                handleChange={(e, value) =>
                  handleChangeAdjustValue("product", value)
                }
                value={adjustValues?.product}
                helperText={adjustHelpers?.product}
                error={adjustErrors?.product}
                search
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="txt-semi-bold fs14 light-grey">
                Batch Number <span className="red-color">*</span>
              </div>
              <Select
                list={getBatchNumbersByProduct(adjustValues?.product?.value)}
                handleChange={(e, value) =>
                  handleChangeAdjustValue("batchNumber", value)
                }
                value={adjustValues?.batchNumber}
                helperText={adjustHelpers?.batchNumber}
                error={adjustErrors?.batchNumber}
                search
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="txt-semi-bold fs14 light-grey">
                Quantity <span className="red-color">*</span>
                {adjustValues?.type?.value === "Other Adjustments" ? (
                  <Tooltip
                    title="Enter a positive value to increase 
                         the quantity or enter a negative 
                         value to decrease the quantity"
                    className="ml5"
                  >
                    <InfoOutlinedIcon
                      sx={{ width: 15, height: 15, color: AppColors.red }}
                    />
                  </Tooltip>
                ) : null}
              </div>

              <CustomTextField
                handleChange={(e) =>
                  handleChangeAdjustValue("quantity", e?.target?.value)
                }
                value={adjustValues?.quantity}
                helperText={adjustHelpers?.quantity}
                error={adjustErrors?.quantity}
                fullWidth
                type="number"
              />
            </Grid>
            {adjustValues?.type?.value !== "Other Adjustments" ? (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="txt-semi-bold fs14 light-grey">
                  Invoice Number
                </div>
                <CustomTextField
                  handleChange={(e) =>
                    handleChangeAdjustValue("invoiceNumber", e?.target?.value)
                  }
                  value={adjustValues?.invoiceNumber}
                  helperText={adjustHelpers?.invoiceNumber}
                  error={adjustErrors?.invoiceNumber}
                  fullWidth
                />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="txt-semi-bold fs14 light-grey">
                Remarks
                {adjustValues?.type?.value === "Other Adjustments" ? (
                  <span className="red-color"> *</span>
                ) : null}
              </div>
              <CustomTextField
                handleChange={(e) =>
                  handleChangeAdjustValue("remarks", e?.target?.value)
                }
                value={adjustValues?.remarks}
                helperText={adjustHelpers?.remarks}
                error={adjustErrors?.remarks}
                fullWidth
                rows={3}
                multiline
                required={adjustValues?.type?.value === "Other Adjustments"}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="flex1-end mt10">
                <div>
                  <CustomButton
                    text="Submit"
                    smallBtn
                    onClick={handleStockAdjust}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </CustomModal>

      <CustomModal
        open={invntUpdModVsble}
        onClose={handleCloseInvntUpload}
        header="Inventory Upload"
        headerCenter
        modalWidth={50}
        modal
      >
        <Grid container spacing={2}>
          {inventFiles?.length ? (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mt20">
              <FilesWithCloseButton
                files={inventFiles}
                onClickFile={(i, fle) => handleInventImageRemove(i)}
                center
              />
            </Grid>
          ) : null}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CustomUpload
              onUploadFile={onUploadFile}
              multipleNew
              fileAccept="image/*"
              multiFiles
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="flex-center">
              <div>
                <CustomButton
                  text="Submit"
                  smallBtn
                  onClick={onInventoryUpload}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </CustomModal>

      <CustomModal
        open={prdctPrevModVsble}
        onClose={onClosePrevMod}
        header="Preview"
        headerCenter
        modalWidth={100}
        modal
      >
        <div className="ph20 scroll-80vh">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className="font-bold fs16 blue-color">
                Product Details
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Category <span className="red-color">*</span>
              </div>
              <Select
                list={productCategoryList}
                value={getRowValues()?.product?.category}
                handleChange={(e, value) => handleChange("category", value)}
                name="category"
                search
                error={productErrors?.category}
                helperText={productHelps?.category}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Sub-Category <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="subCategory"
                fullWidth
                value={getRowValues()?.product?.subCategory}
                error={productErrors?.subCategory}
                helperText={productHelps?.subCategory}
                handleChange={(e) =>
                  handleChange("subCategory", e?.target?.value)
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                HSN/SAC Code <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="hsnSacCode"
                fullWidth
                value={getRowValues()?.product?.hsnSacCode}
                error={productErrors?.hsnSacCode}
                helperText={productHelps?.hsnSacCode}
                handleChange={(e) =>
                  handleChange("hsnSacCode", e?.target?.value)
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Name <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="name"
                fullWidth
                value={getRowValues()?.product?.name}
                error={productErrors?.name}
                helperText={productHelps?.name}
                handleChange={(e) => handleChange("name", e?.target?.value)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Trade Name <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="tradeName"
                fullWidth
                value={getRowValues()?.product?.tradeName}
                error={productErrors?.tradeName}
                helperText={productHelps?.tradeName}
                handleChange={(e) =>
                  handleChange("tradeName", e?.target?.value)
                }
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Major Active Ingredients
              </div>
              <CustomTextField
                name="ingredient"
                fullWidth
                value={getRowValues()?.product?.ingredient}
                error={productErrors?.ingredient}
                helperText={productHelps?.ingredient}
                handleChange={(e) =>
                  handleChange("ingredient", e?.target?.value)
                }
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">Nature</div>
              <Select
                list={natureList}
                value={getRowValues()?.nature}
                handleChange={(e, value) => handleChange("nature", value)}
                name="nature"
                search
                error={productErrors?.nature}
                helperText={productHelps?.nature}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Bar Code number
              </div>
              <CustomTextField
                name="code"
                fullWidth
                value={getRowValues()?.product?.code}
                error={productErrors?.code}
                helperText={productHelps?.code}
                handleChange={(e) => handleChange("code", e?.target?.value)}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">Brand</div>
              <CustomTextField
                name="brand"
                fullWidth
                value={getRowValues()?.product?.brand}
                error={productErrors?.brand}
                helperText={productHelps?.brand}
                handleChange={(e) => handleChange("brand", e?.target?.value)}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Measurement Unit <span className="red-color">*</span>
              </div>
              <Select
                list={productMeasurementUnit}
                value={getRowValues()?.product?.measurementUnit}
                handleChange={(e, value) =>
                  handleChange("measurementUnit", value)
                }
                name="measurementUnit"
                search
                error={productErrors?.measurementUnit}
                helperText={productHelps?.measurementUnit}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Sub-Measurement Unit <span className="red-color">*</span>
              </div>
              <Select
                list={productSubMeasurementUnit}
                value={getRowValues()?.product?.subMeasurementUnit}
                handleChange={(e, value) =>
                  handleChange("subMeasurementUnit", value)
                }
                name="subMeasurementUnit"
                search
                error={productErrors?.subMeasurementUnit}
                helperText={productHelps?.subMeasurementUnit}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">Formula</div>
              <CustomTextField
                name="formula"
                fullWidth
                value={getRowValues()?.product?.formula}
                error={productErrors?.formula}
                helperText={productHelps?.formula}
                handleChange={(e) => handleChange("formula", e?.target?.value)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">Batch Number</div>
              <CustomTextField
                name="batchNumber"
                fullWidth
                value={getRowValues()?.product?.batchNumber}
                error={productErrors?.batchNumber}
                helperText={productHelps?.batchNumber}
                handleChange={(e) =>
                  handleChange("batchNumber", e?.target?.value)
                }
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">Mfg Date</div>
              <CustomTextField
                fullWidth
                value={getRowValues()?.product?.manufacturingDate}
                error={productErrors?.manufacturingDate}
                helperText={productHelps?.manufacturingDate}
                handleChange={(e) =>
                  handleChange("manufacturingDate", e?.target?.value)
                }
                mobileDate
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">Expiry Date</div>
              <CustomTextField
                fullWidth
                value={getRowValues()?.product?.expiryDate}
                error={productErrors?.expiryDate}
                helperText={productHelps?.expiryDate}
                handleChange={(e) =>
                  handleChange("expiryDate", e?.target?.value)
                }
                mobileDate
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Location <span className="red-color">*</span>
              </div>
              <Select
                list={locationList}
                value={getRowValues()?.product?.location}
                handleChange={(e, value) => handleChange("location", value)}
                name="location"
                search
                error={productErrors?.location}
                helperText={productHelps?.location}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="txt-semi-bold fs14 light-grey">
                Brief Description of the Product
              </div>
              <CustomTextField
                name="description"
                fullWidth
                value={getRowValues()?.product?.description}
                error={productErrors?.description}
                helperText={productHelps?.description}
                handleChange={(e) =>
                  handleChange("description", e?.target?.value)
                }
                multiline
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className="font-bold fs16 blue-color">
                Price & Taxes
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Unit Selling Price (MRP) <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="sellPrice"
                fullWidth
                value={getRowValues()?.product?.sellPrice}
                error={productErrors?.sellPrice}
                helperText={productHelps?.sellPrice}
                handleChange={(e) =>
                  handleChange("sellPrice", e?.target?.value)
                }
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Sub-Unit Selling Price <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="subunitSellingPrice"
                fullWidth
                value={getRowValues()?.product?.subunitSellingPrice}
                error={productErrors?.subunitSellingPrice}
                helperText={productHelps?.subunitSellingPrice}
                handleChange={(e) =>
                  handleChange("subunitSellingPrice", e?.target?.value)
                }
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Administering Charges
              </div>
              <CustomTextField
                name="administeringCharges"
                fullWidth
                value={getRowValues()?.product?.administeringCharges}
                error={productErrors?.administeringCharges}
                helperText={productHelps?.administeringCharges}
                handleChange={(e) =>
                  handleChange("administeringCharges", e?.target?.value)
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Tax applicability <span className="red-color">*</span>
              </div>
              <Select
                list={yesNoValueList}
                value={getRowValues()?.product?.taxApplicability}
                handleChange={(e) =>
                  handleChange("taxApplicability", e?.target?.value)
                }
                name="taxApplicability"
                select
                error={productErrors?.taxApplicability}
                helperText={productHelps?.taxApplicability}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Tax % <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="tax"
                fullWidth
                value={
                  getRowValues()?.product?.taxApplicability === "No"
                    ? "-"
                    : getRowValues()?.product?.tax
                }
                error={
                  getRowValues()?.product?.taxApplicability === "No"
                    ? false
                    : productErrors?.tax
                }
                helperText={
                  getRowValues()?.product?.taxApplicability === "No"
                    ? ""
                    : productHelps?.tax
                }
                handleChange={(e) => handleChange("tax", e?.target?.value)}
                disabled={getRowValues()?.product?.taxApplicability === "No"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className="font-bold fs16 blue-color">
                Stock Details
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Quantity <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="quantity"
                fullWidth
                value={getRowValues()?.product?.quantity}
                error={productErrors?.quantity}
                helperText={productHelps?.quantity}
                handleChange={(e) => handleChange("quantity", e?.target?.value)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Item Base Cost <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="baseCost"
                fullWidth
                value={getRowValues()?.product?.baseCost}
                error={productErrors?.baseCost}
                helperText={productHelps?.baseCost}
                handleChange={(e) => handleChange("baseCost", e?.target?.value)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                CGST (%) <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="cgst"
                fullWidth
                value={getRowValues()?.product?.cgst}
                error={productErrors?.cgst}
                helperText={productHelps?.cgst}
                handleChange={(e) => handleChange("cgst", e?.target?.value)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                SGST (%) <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="sgst"
                fullWidth
                value={getRowValues()?.product?.sgst}
                error={productErrors?.sgst}
                helperText={productHelps?.sgst}
                handleChange={(e) => handleChange("sgst", e?.target?.value)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <div className="txt-semi-bold fs14 light-grey">
                Item Total Cost <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="totalAmt"
                fullWidth
                value={getRowValues()?.product?.totalAmt}
                error={productErrors?.totalAmt}
                helperText={productHelps?.totalAmt}
                handleChange={(e) => handleChange("totalAmt", e?.target?.value)}
              />
            </Grid>
          </Grid>

          <div className="mt30">
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <div className="flex1-end">
                <div className="flex-row-ali-cen">
                  <div className="mr10">
                    <CustomButton
                      text="Cancel"
                      textBtn
                      onClick={onClosePrevMod}
                      color={AppColors.red}
                    />
                  </div>
                  <div className="ml10">
                    <CustomButton text="Submit" onClick={handleSubmit} />
                  </div>
                </div>
              </div>
            </Grid>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default Stock;
