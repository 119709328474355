import { initSlot } from "./object";

export const dayList = [
  { id: 1, name: "Monday", shortName: "M", value: "mon", isSelected: false },
  {
    id: 2,
    name: "Tuesday",
    shortName: "T",
    value: "tue",
    isSelected: false,
  },
  {
    id: 3,
    name: "Wednesday",
    shortName: "W",
    value: "wen",
    isSelected: false,
  },
  {
    id: 4,
    name: "Thursday",
    shortName: "T",
    value: "thu",
    isSelected: false,
  },
  { id: 5, name: "Friday", shortName: "F", value: "fri", isSelected: false },
  {
    id: 6,
    name: "Saturday",
    shortName: "S",
    value: "sat",
    isSelected: false,
  },
  { id: 7, name: "Sunday", shortName: "S", value: "sun", isSelected: false },
];

export const conditionList = [
  { id: 1, name: "Ears", value: "Ears" },
  { id: 2, name: "Eye", value: "Eyes" },
  { id: 3, name: "Mouth", value: "Mouth" },
  { id: 4, name: "Gastrointestinal", value: "Gastrointestinal" },
  { id: 5, name: "Limping or mobility", value: "Mobility" },
  { id: 6, name: "Respiratory", value: "Respiratory" },
  { id: 7, name: "Skin & coat", value: "Skin" },
  { id: 8, name: "Urinary", value: "renalUrinary" },
  { id: 9, name: "Emergency", value: "Emergency" },
  { id: 10, name: "General", value: "General" },
];

export const clinicFilterList = [
  { name: "Clinic Name", value: "name" },
  { name: "Location", value: "location" },
];

export const scheduleList = [
  { name: "Schedule", value: "Pending" },
  { name: "Mark as Complete", value: "Completed" },
];

export const statusList = [
  { name: "All", value: "All" },
  { name: "Pending", value: "Pending" },
  { name: "Completed", value: "Completed" },
  { name: "Overdue", value: "Overdue" },
];

export const LocationList = [
  { name: "Adyar", value: "Adyar" },
  { name: "Guindy", value: "Guindy" },
];

export const profileSettingsDays = [
  {
    i: 0,
    name: "Sunday",
    value: "sun",
    isSelected: false,
    startTime: new Date(),
    endTime: new Date(),
    duration: "15",
  },
  {
    i: 1,
    name: "Monday",
    value: "mon",
    isSelected: false,
    startTime: new Date(),
    endTime: new Date(),
    duration: "15",
  },
  {
    i: 2,
    name: "Tuesday",
    value: "tue",
    isSelected: false,
    startTime: new Date(),
    endTime: new Date(),
    duration: "15",
  },
  {
    i: 3,
    name: "Wednesday",
    value: "wen",
    isSelected: false,
    startTime: new Date(),
    endTime: new Date(),
    duration: "15",
  },
  {
    i: 4,
    name: "Thursday",
    value: "thu",
    isSelected: false,
    startTime: new Date(),
    endTime: new Date(),
    duration: "15",
  },
  {
    i: 5,
    name: "Friday",
    value: "fri",
    isSelected: false,
    startTime: new Date(),
    endTime: new Date(),
    duration: "15",
  },
  {
    i: 6,
    name: "Saturday",
    value: "sat",
    isSelected: false,
    startTime: new Date(),
    endTime: new Date(),
    duration: "15",
  },
];

export const roleList = [
  { id: 1, name: "Admin", value: "admin", isSelected: false },
  { id: 2, name: "Billing", value: "billing", isSelected: false },
  { id: 3, name: "Front Desk", value: "frontdesk", isSelected: false },
  { id: 4, name: "Management", value: "management", isSelected: false },
  { id: 5, name: "Pharmacy", value: "pharmacy", isSelected: false },
  { id: 6, name: "Veterinarian", value: "veterinarian", isSelected: false },
];

export const newRoleList = [
  // {
  //   id: 4,
  //   name: "Clinic Admin",
  //   value: "admin",
  //   isSelected: false,
  // },
  { name: "Assistant Veterinarian", value: "Assistant Veterinarian" },
  { name: "Senior Veterinarian", value: "Senior Veterinarian" },
  { name: "Specialist", value: "Specialist" },
];

export const NewRefundList = [
  { id: 1, name: "Yes", value: "Y" },
  { id: 2, name: "No", value: "N" },
];

export const PrepaidPostpaidList = [
  { id: 1, name: "Prepaid", value: "prepaid" },
  { id: 2, name: "Postpaid", value: "postpaid" },
];

export const PercentageZeroList = [{ id: 1, name: "0", value: "0" }];

export const Percentage50and100List = [
  { id: 1, name: "50", value: "50" },
  { id: 2, name: "100", value: "100" },
];

export const ConsultationList = [
  { id: 1, name: "Virtual", value: "Virtual", isSelected: false },
  { id: 2, name: "Physical", value: "Physical", isSelected: false },
  { id: 3, name: "HomeVisit", value: "HomeVisit", isSelected: false },
];

// export const productCategoryList = [
//   { name: "Medication", value: "Medication" },
//   { name: "Supplement", value: "Supplement" },
//   { name: "Vaccination", value: "Vaccines" },
//   { name: "Deworming", value: "Deworming" },
//   { name: "Flea/Tick Treatment", value: "Flea/tick treament" },
// ];

export const preventiveTypes = [
  { name: "Deworming", value: "deworming" },
  { name: "Flea/Tick Treatment", value: "fleaTreatment" },
  { name: "Vaccination", value: "vaccination" },
];

export const serviceCategoryList = [
  { name: "Diagnostics", value: "diagnostics" },
];

export const unitTypeList = [
  { id: 1, name: "Box", value: "Box", isSelected: false },
  { id: 2, name: "Bottle", value: "Bottle", isSelected: false },
  { id: 3, name: "Ointment", value: "Ointment", isSelected: false },
  { id: 4, name: "Pack", value: "Pack", isSelected: false },
  { id: 5, name: "Piece", value: "Piece", isSelected: false },
  { id: 6, name: "Strip", value: "Strip", isSelected: false },
  { id: 7, name: "Tablet", value: "Tablet", isSelected: false },
];

export const stockTransition = [
  { id: 1, name: "Stock Entry", value: "Stock Entry", isSelected: false },
  {
    id: 2,
    name: "Stock transcation",
    value: "Stock transcation",
    isSelected: false,
  },
];

export const issueList = [
  { id: 1, name: "Ears", value: "Ears" },
  { id: 2, name: "Eye", value: "Eyes" },
  { id: 3, name: "Mouth", value: "Mouth" },
  { id: 4, name: "Gastrointestinal", value: "Gastrointestinal" },
  { id: 5, name: "Limping", value: "Limping" },
  { id: 6, name: "Breathing", value: "Breathing" },
  { id: 7, name: "Skin", value: "Skin" },
  { id: 8, name: "Urine", value: "Urine" },
  { id: 9, name: "Emergency", value: "Emergency" },
  { id: 10, name: "General", value: "General" },
];

export const frequencyList = [
  { name: "1-1-1", value: "1-1-1" },
  { name: "1-0-1", value: "1-0-1" },
  { name: "1-1-0", value: "1-1-0" },
  { name: "1-0-0", value: "1-0-0" },
  { name: "0-1-0", value: "0-1-0" },
  { name: "0-0-1", value: "0-0-1" },
  { name: "0-1-1", value: "0-1-1" },
];

export const frequncyCheckList = [
  { name: "Morning", value: "morning" },
  { name: "Afternoon", value: "afternoon" },
  { name: "Night", value: "night" },
  { name: "Custom", value: "Custom" },
];

export const weekArray = [
  {
    day: "Sunday",
    value: "sunday",
    slots: [initSlot],
    isSelected: false,
  },
  {
    day: "Monday",
    value: "monday",
    slots: [initSlot],
    isSelected: false,
  },
  {
    day: "Tuesday",
    value: "tuesday",
    slots: [initSlot],
    isSelected: false,
  },
  {
    day: "Wednesday",
    value: "wednesday",
    slots: [initSlot],
    isSelected: false,
  },
  {
    day: "Thursday",
    value: "thursday",
    slots: [initSlot],
    isSelected: false,
  },
  {
    day: "Friday",
    value: "friday",
    slots: [initSlot],
    isSelected: false,
  },
  {
    day: "Saturday",
    value: "saturday",
    slots: [initSlot],
    isSelected: false,
  },
];

export const StockAdjustList = [
  { name: "Other Adjustments", value: "Other Adjustments" },
  { name: "Return on Purchase", value: "Return on Purchase" },
  { name: "Return on Sales", value: "Return on Sales" },
];

export const stockReportTypes = [
  { name: "Stock Summary", value: "Stock Summary" },
  { name: "Stock Adjustment", value: "Stock Adjustment" },
  { name: "Stock Consumption", value: "Stock Consumption" },
  { name: "Supplier Purchase", value: "Supplier Purchase" },
];
