import TodayIcon from "@mui/icons-material/Today";
import { Grid, Typography } from "@mui/material";
import { uniq } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CustomTextField from "../../../components/CustomTextField";
import Select from "../../../components/Select/Select";
import {
  doctorSlotTimeByDisable,
  getClinicHolidays,
  getSlotById,
} from "../../../redux/reducers/clinicSlice";
import { hideLoader, showLoader } from "../../../redux/reducers/loaderSlice";
import {
  getAllDatesFromArray,
  getTodayWithYMDFormat,
  getUpcoming7Dates,
  getUpcomingSlotsWithoutBook,
} from "../../../util/function";
import VetAndUpcomingAppointments from "../VetAndUpcomingAppointments/VetAndUpcomingAppointments";
import "./ManageSlot.css";

const ManageSlot = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const slotData = useSelector((state) => state.clinic.getSlot);
  const [selectedDayId, setSelectedDayId] = useState(null);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [selectedType, setSelectedType] = useState("today");
  const [selectMode, setSelectMode] = useState("all");
  const [weekDateList, setWeekDateList] = useState([]);
  const [modVsble, setModVsble] = useState(false);
  const [selectedDayDetails, setSelectedDayDetails] = useState(null);
  const holidays = useSelector((state) => state?.doctor?.holidays);
  const [holidayDates, setHolidayDates] = useState([]);
  const clinicHolidays = useSelector((state) => state?.clinic?.holidays);

  useEffect(() => {
    const dateList = getUpcoming7Dates();
    setWeekDateList(dateList);
    dispatch(getClinicHolidays());
  }, []);

  useEffect(() => {
    getSlotData();
  }, [selectMode, selectedType, params?.vetId]);

  useEffect(() => {
    getHolidayDateList();
  }, [holidays, clinicHolidays]);

  const getHolidayDateList = async () => {
    if (
      (holidays?.length === 0 || !holidays) &&
      (clinicHolidays?.length === 0 || !clinicHolidays)
    ) {
      return setHolidayDates([]);
    }
    const vetDates = (await getAllDatesFromArray(holidays)) ?? [];

    const cliHolidays =
      clinicHolidays?.map((hl) => {
        return hl?.date;
      }) ?? [];
    const mergedArray = vetDates?.concat(cliHolidays);
    setHolidayDates(uniq(mergedArray));
  };

  const getSlotsDetailsByType = (typ) => {
    const reqList = [];
    weekDateList?.filter((wl) => {
      const slot = slotData?.find(
        (slot) =>
          slot.type === typ &&
          moment(new Date(wl)).format("dddd").toLowerCase() === slot?.day
      );

      if (slot) {
        const reqItm = { ...slot, slotDate: wl };
        if (moment(new Date(wl)).format("YYYY-MM-DD") === slot?.slotDate) {
          reqItm.slotTime = slot?.slotTime?.filter(
            (slt) => slt?.status === "Y"
          );
        }
        reqList.push(reqItm);
      }
    });
    return reqList;
  };

  const handleSelectedSlot = (slot) => {
    if (!selectedDayId) setSelectedDayId(slot?.slotdayId);
    if (
      slot?.slotdayId ===
      (selectedDayId !== null ? selectedDayId : slot?.slotdayId)
    ) {
      if (
        selectedSlots?.length > 0 &&
        selectedSlots?.[0]?.status !== slot?.status
      )
        return;

      const prevSlot = selectedSlots?.find(
        (ss) => ss?.slotTimeId === slot?.slotTimeId
      );
      if (prevSlot) {
        const reqSlots = selectedSlots?.filter(
          (sts) => sts?.slotTimeId !== slot?.slotTimeId
        );
        if (reqSlots?.length === 0) setSelectedDayId(null);
        return setSelectedSlots(reqSlots);
      }
      setSelectedSlots([...selectedSlots, slot]);
    }
  };

  const getSlotData = async () => {
    if (selectedType === "today") {
      const date = getTodayWithYMDFormat();
      const url = `?filter=${selectMode}&type=${selectedType}&date=${date}`;
      const vetId = params?.vetId;
      const metaData = { vetId, url };
      await dispatch(getSlotById(metaData));
    } else {
      const url = `?filter=${selectMode}&type=${selectedType}`;
      const vetId = params?.vetId;
      const metaData = { vetId, url };
      await dispatch(getSlotById(metaData));
    }
  };

  const handleSelectType = async (type) => {
    setSelectedType(type);
  };

  const handleSelectMode = async (mode) => {
    setSelectMode(mode.target.value);
    dispatch(showLoader());
    if (selectedType === "today") {
      const date = getTodayWithYMDFormat();
      const url = `?filter=${selectMode}&type=${selectedType}&date=${date}`;
      const vetId = params?.vetId;
      const metaData = { vetId, url };
      dispatch(getSlotById(metaData));
    } else {
      const url = `?filter=${selectMode}&type=${selectedType}`;
      const vetId = params?.vetId;
      const metaData = { vetId, url };
      await dispatch(getSlotById(metaData));
    }
    dispatch(hideLoader());
  };

  const getFirstThreeLetters = (day) => {
    return day.substring(0, 3);
  };

  const handleEdit = (slot) => {
    setSelectedDayDetails(slot);
    setModVsble(true);
  };

  const CommonRow = ({ slot, key, type }) => {
    let times = slot?.slotTime;
    const slotDate = moment(new Date(slot?.slotDate)).format("YYYY-MM-DD");
    if (slotDate === getTodayWithYMDFormat()) {
      times = getUpcomingSlotsWithoutBook(slot?.slotTime);
    }
    if (holidayDates?.includes(slotDate)) times = [];

    return times?.length > 0 ? (
      <div className="flex-row w100Per mt10 mh40" key={key + type}>
        <div className="w15Per flex-start-center">
          <Typography className="txt-regular fs12 table-black">
            {moment(new Date(slot?.slotDate)).format("MMM DD")}
          </Typography>
        </div>

        <div className="w15Per flex-start-center">
          <Typography className="txt-regular fs12 table-black capitalize">
            {getFirstThreeLetters(slot?.day)}
          </Typography>
        </div>
        <div className="flex-start-center w65Per">
          <div className="flex-row scrollx w1200px">
            {times?.length > 0 &&
              times?.map((st, i) => (
                <Typography
                  key={i + st?.time}
                  className={`txt-regular table-time-black ${
                    i !== 0 ? "ml10" : ""
                  } ${
                    st?.status === "Y"
                      ? "table-time-disabled"
                      : "table-time-back"
                  }`}
                >
                  {st?.time}
                </Typography>
              ))}
          </div>
        </div>
        <div className="w5Per">
          <div className="flex-row-ali-cen ml5">
            {times?.length > 0 && (
              <img
                src={require("../../../assets/images/png/edit.png")}
                alt=""
                className="imghw30 cursor"
                onClick={() => handleEdit(slot)}
              />
            )}
          </div>
        </div>
      </div>
    ) : null;
  };

  const handleCloseMod = () => {
    setModVsble(false);
  };

  const handleUpdateAvailableStatus = async () => {
    if (selectedSlots?.length === 0) return;
    const timeIds = selectedSlots?.map((slts) => slts?.slotTimeId);

    dispatch(doctorSlotTimeByDisable({ timeIds })).then((res) => {
      if (res?.payload) {
        getSlotData();
        setSelectedDayId(null);
        setSelectedSlots([]);
        setSelectedDayDetails(null);
        setModVsble(false);
      }
    });
  };

  return (
    <>
      <VetAndUpcomingAppointments
        vetId={location?.state?.vetId}
        active="manageSlot"
      >
        <Grid container className="ph2">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="back-white p10"
          >
            <div className="flex-row">
              <div className="flex-start">
                <div className="flex-center">
                  <Typography
                    className={`font-bold fs14 cursor day-type-box ${
                      selectedType === "today" ? "black" : "gray2"
                    }`}
                    onClick={() => handleSelectType("today")}
                  >
                    Today
                  </Typography>
                  <Typography
                    className={`font-bold fs14 cursor day-type-box ${
                      selectedType === "week" ? "black" : "gray2"
                    }`}
                    onClick={() => handleSelectType("week")}
                  >
                    Week
                  </Typography>
                </div>
              </div>
              <div className="flex1-center">
                <div className="flex-row">
                  <div className="flex-center">
                    <Typography className="text-bold mh20">
                      {selectedType === "today"
                        ? moment(new Date()).format("DD MMM YYYY")
                        : `${moment(new Date()).format("DD MMM")} - ${moment(
                            new Date(
                              new Date().setDate(new Date().getDate() + 6)
                            )
                          ).format("DD MMM YYYY")}`}
                    </Typography>
                  </div>
                </div>
              </div>
              <div className="w20Per">
                <Select
                  list={[
                    { name: "All", value: "all" },
                    { name: "Virtual", value: "virtual" },
                    { name: "Physical", value: "physical" },
                    { name: "Home Visit", value: "home" },
                  ]}
                  value={selectMode ?? "All"}
                  name={"All"}
                  handleChange={(e) => handleSelectMode(e)}
                  select
                />
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="mv3 back-white"
          >
            <div className="p20 scroll-80vh">
              {selectMode === "virtual" || selectMode === "all" ? (
                <>
                  <Typography className="text-bold">Virtual</Typography>
                  <div className="flex-row mt10">
                    <div className="w15Per">
                      <Typography className="txt-regular fs14 table-gray">
                        Date
                      </Typography>
                    </div>
                    <div className="w15Per">
                      <Typography className="txt-regular fs14 table-gray">
                        Day
                      </Typography>
                    </div>
                    <div className="w70Per">
                      <Typography className="txt-regular fs14 table-gray">
                        Slots
                      </Typography>
                    </div>
                  </div>

                  <div className="table-gary-bar mv5 " />
                  {getSlotsDetailsByType("virtual")?.length > 0
                    ? getSlotsDetailsByType("virtual")?.map((slot, i) => (
                        <CommonRow slot={slot} type="Virtual" key={i} />
                      ))
                    : null}
                </>
              ) : null}

              {selectMode === "physical" || selectMode === "all" ? (
                <>
                  <Typography
                    className={`text-bold ${
                      selectMode === "all" ? "mt20" : ""
                    }`}
                  >
                    Physical
                  </Typography>
                  <div className="flex-row mt10">
                    <div className="w15Per">
                      <Typography className="txt-regular fs14 table-gray">
                        Date
                      </Typography>
                    </div>
                    <div className="w15Per">
                      <Typography className="txt-regular fs14 table-gray">
                        Day
                      </Typography>
                    </div>
                    <div className="w70Per">
                      <Typography className="txt-regular fs14 table-gray">
                        Slots
                      </Typography>
                    </div>
                  </div>

                  <div className="table-gary-bar mv5 " />
                  {getSlotsDetailsByType("physical")?.length > 0
                    ? getSlotsDetailsByType("physical")?.map((slot, i) => (
                        <CommonRow slot={slot} type="Physical" key={i} />
                      ))
                    : null}
                </>
              ) : null}

              {selectMode === "home" || selectMode === "all" ? (
                <>
                  <Typography
                    className={`text-bold ${
                      selectMode === "all" ? "mt20" : ""
                    }`}
                  >
                    Home Visit
                  </Typography>
                  <div className="flex-row mt10">
                    <div className="w15Per">
                      <Typography className="txt-regular fs14 table-gray">
                        Date
                      </Typography>
                    </div>
                    <div className="w15Per">
                      <Typography className="txt-regular fs14 table-gray">
                        Day
                      </Typography>
                    </div>
                    <div className="w70Per">
                      <Typography className="txt-regular fs14 table-gray">
                        Slots
                      </Typography>
                    </div>
                  </div>

                  <div className="table-gary-bar mv5 " />
                  {getSlotsDetailsByType("home")?.length > 0
                    ? getSlotsDetailsByType("home")?.map((slot, i) => (
                        <CommonRow slot={slot} type="home" key={i} />
                      ))
                    : null}
                </>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </VetAndUpcomingAppointments>
      <CustomModal
        open={modVsble}
        modalWidth={30}
        header="Manage Unavailability"
        rightModal
        onClose={handleCloseMod}
      >
        <div className="ph20 scroll-80vh">
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="flex-row-ali-cen">
                <Typography className="fs14 txt-semi-bold black">
                  Consultation Type:
                </Typography>
                <Typography className="fs14 font-medium black ml5 capitalize">
                  {selectedDayDetails?.type}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mv10">
              <div className="txt-semi-bold fs14 mark-balck mb10">Date</div>
              <CustomTextField
                fullWidth
                value={moment(new Date(selectedDayDetails?.slotDate)).format(
                  "ddd, DD MMM YYYY"
                )}
                startIcon
                inputIcon={<TodayIcon />}
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className="txt-semi-bold fs14 mark-balck">
                Slots
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="flex-row-wrap1 w100Per">
                {(moment(new Date(selectedDayDetails?.slotDate)).format(
                  "YYYY-MM-DD"
                ) === getTodayWithYMDFormat()
                  ? getUpcomingSlotsWithoutBook(selectedDayDetails?.slotTime)
                  : selectedDayDetails?.slotTime?.filter(
                      (tm) => tm?.book !== "Y"
                    )
                )?.map((st, i) => (
                  <Typography
                    key={i + st?.time}
                    className={`txt-regular table-time-black cursor flex-center ${
                      selectedSlots?.includes(st)
                        ? "table-time-selected2"
                        : st?.status === "Y"
                        ? "table-time-disabled2"
                        : "table-time-back2"
                    }`}
                    onClick={() => handleSelectedSlot(st)}
                  >
                    {st?.time}
                  </Typography>
                ))}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="flex1-end mt20">
                <Typography
                  className={`font-bold fs16 cursor ${
                    selectedSlots?.[0]?.status === "Y" ? "green2" : "red10"
                  }`}
                  onClick={handleUpdateAvailableStatus}
                >
                  {selectedSlots?.[0]?.status === "Y"
                    ? "Mark as Available"
                    : "Mark as Unavailable"}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
};

export default ManageSlot;
