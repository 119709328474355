import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import {
  Box,
  Card,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Custom3dotString from "../../components/Custom3dotString";
import CustomButton from "../../components/CustomButton";
import Checkbox from "../../components/CustomCheckbox";
import CustomModal from "../../components/CustomModal/CustomModal";
import CustomTextField from "../../components/CustomTextField";
import CustomUpload from "../../components/CustomUpload";
import Select from "../../components/Select/Select";
import {
  getClinicUsers,
  registerClinicUser,
} from "../../redux/reducers/clinicSlice";
import {
  createUserPetByUserId,
  getPetBreedsByType,
  getPetColorsByType,
  getPetTypes,
} from "../../redux/reducers/petSlice";
import { AppColors } from "../../util/AppColors";
import { genderList, NeuteredList } from "../../util/dropList";
import { ErrorStrings } from "../../util/ErrorString";
import { reduceYearsAndMonthsToStartOfMonth } from "../../util/function";
import generatePass from "../../util/randomPassword";

const initValues = {
  petName: "",
  parentName: "",
  userMobile: "",
  email: "",
  password: "",
  rfid: "",
  breed: null,
  petType: null,
  color: null,
  gender: null,
  neutered: null,
  ageType: "Year",
  dob: null,
  image: null,
};

const initErrors = {
  petName: "",
  dob: "",
  breed: "",
  gender: "",
  neutered: "",
  weight: "",
  parentName: "",
  userMobile: "",
  email: "",
  password: "",
  color: "",
  petType: "",
  rfId: "",
};

const CreateClinicPet = ({
  createModVisible,
  onCloseCreateMod,
  userDetails,
}) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [activeStep, setActiveStep] = useState(userDetails ? 1 : 0);
  const [petValues, setPetValues] = useState(initValues);
  const [showPassword, setShowPassword] = useState(false);
  const [isAutoGenPass, setAutoGenPass] = useState(false);
  const [selectedUser, setSelectedUser] = useState(
    userDetails ? userDetails : null
  );
  const [errors, setErrors] = useState(initErrors);
  const [petColorList, setPetColorList] = useState([]);
  const [breedList, setBreedList] = useState([]);
  const [prtTypeList, setPetTypeList] = useState([]);
  const [filtredCliUsers, setFilteredClinicUsers] = useState([]);
  const users = useSelector((state) => state?.clinic?.users);
  const petDetails = useSelector((state) => state?.pet?.petDetails);

  useEffect(() => {
    dispatch(getPetTypes()).then((res) => {
      if (res?.payload) {
        const reqList = res?.payload?.map((sp) => ({ name: sp, value: sp }));
        setPetTypeList(reqList);
      }
    });
    dispatch(getClinicUsers(searchValue));
  }, []);

  useEffect(() => {
    getFilteredUserList();
  }, [searchValue, users]);

  const getFilteredUserList = () => {
    const reqList = users?.filter((usr) => {
      if (
        usr?.name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        usr?.email?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        usr?.mobile?.toLowerCase().includes(searchValue?.toLowerCase())
      ) {
        return usr;
      }
    });

    setFilteredClinicUsers(reqList ?? []);
  };

  const handleSelectedUser = (user) => {
    setSelectedUser(user);
    setActiveStep(2);
  };

  const passValidation = (value) => {
    if (value === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: ErrorStrings.emptyPass,
      }));
      return;
    }
    if (value?.length < 6) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: ErrorStrings.inValidPass,
      }));
      return;
    }
    setErrors((prevErrors) => ({ ...prevErrors, password: false }));
  };

  const handleInputChange = (e, field) => {
    setPetValues({ ...petValues, [field]: e?.target?.value });
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
    if (field === "password") {
      passValidation(e?.target?.value);
    }
  };

  const handleSelectChange = (value, field) => {
    setPetValues({ ...petValues, [field]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
    if (field === "petType") {
      dispatch(getPetBreedsByType(value?.value)).then((res) => {
        if (res?.payload) {
          const reqList = res?.payload?.map((bd) => ({
            ...bd,
            name: bd?.breed,
            value: bd?.breed,
          }));
          setBreedList(reqList);
        }
      });
      dispatch(getPetColorsByType(value?.value)).then((res) => {
        if (res?.payload) {
          const reqList = res?.payload?.map((cl) => ({
            ...cl,
            name: cl?.color,
            value: cl?.color,
          }));
          setPetColorList(reqList);
        }
      });
    }
  };

  const handleClickShowPassword = (value) => {
    setShowPassword(!value);
  };

  const handleAutoGenPassword = () => {
    setAutoGenPass(!isAutoGenPass);
    if (!isAutoGenPass) {
      const pass = generatePass();
      setPetValues({ ...petValues, password: pass });
    } else {
      setPetValues({ ...petValues, password: "" });
    }
  };

  const onUploadFile = (e) => {
    const reader = new FileReader();
    const file = e?.target?.files[0];
    reader.onloadend = () => {
      setPetValues({
        ...petValues,
        image: { file: e.target.files[0], previewUrl: reader.result },
      });
    };
    reader.readAsDataURL(file);
  };

  const validateParentFields = () => {
    const newErrors = {};
    if (!petValues?.parentName) newErrors.parentName = "This field is required";
    if (!petValues?.userMobile) newErrors.userMobile = "This field is required";
    // if (!petValues?.email) {
    //   newErrors.email = "This field is required";
    // } else
    if (petValues?.email?.length > 0 && !petValues?.email.includes("@")) {
      newErrors.email = "Invalid email format";
    }
    if (petValues?.password === "") {
      newErrors.password = ErrorStrings.emptyPass;
    }
    if (petValues?.password !== "" && petValues?.password?.length < 6) {
      newErrors.password = ErrorStrings.inValidPass;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateFields = () => {
    const newErrors = {};
    if (!petValues?.petName) newErrors.petName = "This field is required";
    if (!petValues?.dob) newErrors.dob = "This field is required";
    if (!petValues?.breed) newErrors.breed = "This field is required";
    if (!petValues?.gender) newErrors.gender = "This field is required";
    if (!petValues?.neutered) newErrors.neutered = "This field is required";
    if (!petValues?.weight) newErrors.weight = "This field is required";
    if (!petValues?.color) newErrors.color = "This field is required";
    if (!petValues?.petType) newErrors.petType = "This field is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (selectedUser) return setActiveStep(activeStep + 1);
    if (!validateParentFields()) return;
    const data = new FormData();
    data.append("userName", petValues?.parentName);
    data.append("userMobile", petValues?.userMobile);
    data.append("email", petValues?.email);
    data.append("password", petValues?.password);

    dispatch(registerClinicUser(data)).then((res) => {
      if (res?.payload?.status === 201) {
        setSelectedUser(res?.payload?.data);
        setActiveStep(activeStep + 1);
      }
    });
  };

  const createPet = async () => {
    if (!validateFields()) return;
    const data = new FormData();
    data.append("petName", petValues?.petName);
    data.append("petType", petValues?.petType?.value);
    data.append("breed", petValues?.breed?.value);
    data.append("weight", petValues?.weight);
    data.append("reproductiveStatus", petValues?.neutered);
    data.append("gender", petValues?.gender);
    data.append(
      "dob",
      reduceYearsAndMonthsToStartOfMonth({
        years: petValues?.ageType === "Year" ? Number(petValues?.dob) : 0,
        months: petValues?.ageType === "Month" ? Number(petValues?.dob) : 0,
      })
    );
    data.append("color", petValues?.color?.value);
    if (petValues?.image?.file) data.append("petImage", petValues?.image?.file);
    if (petValues?.rfid?.length > 0) data.append("rfid", petValues?.rfid);

    dispatch(
      createUserPetByUserId({
        userId: selectedUser?.id ?? userDetails?.id ?? petDetails?.userId,
        data,
      })
    ).then((res) => {
      if (res?.payload) {
        onCloseMod("petCreated");
        setSelectedUser(null);
      }
    });
  };

  const onCloseMod = (str) => {
    onCloseCreateMod(str ?? null);
    setActiveStep(0);
    setSelectedUser(null);
    setSearchValue("");
    setPetValues(initValues);
    setErrors(initErrors);
  };

  return (
    <CustomModal
      open={createModVisible}
      onClose={onCloseMod}
      header="Create"
      rightModal
      modalWidth={30}
    >
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep}>
          {["1", "2"].map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>Step {label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === 0 ? (
          <div className="scroll-70vh ph20 mt10">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomTextField
                  fullWidth
                  search
                  value={searchValue}
                  placeholder="Search Parent"
                  handleChange={(e) => setSearchValue(e?.target?.value)}
                  backgroundColor={AppColors.gray6}
                />
              </Grid>

              {searchValue?.length > 2 && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {filtredCliUsers?.length > 0 &&
                    filtredCliUsers?.map((usr) => {
                      return (
                        <Grid
                          container
                          spacing={1}
                          key={usr?.id}
                          className="cursor"
                          onClick={() => handleSelectedUser(usr)}
                        >
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Card
                                  sx={{
                                    borderRadius: 1,
                                    padding: 2,
                                    borderTopColor: AppColors.appPrimary,
                                  }}
                                  className="CustomCard-back-appointment"
                                >
                                  <div className="maint">
                                    <div className="flex-row">
                                      <Grid item>
                                        <div className="flex-row">
                                          <div className="flex-start">
                                            <div className="flex-column">
                                              <div className="flex-row">
                                                <Typography className="mb10 font-bold fs14 capitalize">
                                                  {usr?.name}
                                                </Typography>
                                              </div>
                                              <Typography className="mb10 txt-regular card-gray-color fs12">
                                                {usr?.email}
                                              </Typography>
                                              <Typography className="mb10 txt-regular card-gray-color fs12">
                                                {usr?.mobile}
                                              </Typography>
                                            </div>
                                          </div>
                                        </div>
                                      </Grid>
                                    </div>
                                  </div>
                                </Card>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography className="gray13 fs14 txt-semi-bold flex-center">
                  (or)
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <CustomTextField
                  label="Parent Name"
                  name="parentName"
                  fullWidth
                  handleChange={(e) => handleInputChange(e, "parentName")}
                  value={petValues?.parentName}
                  error={!!errors.parentName}
                  helperText={errors.parentName}
                  labelTop
                  mandatoryField
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <CustomTextField
                  label="Contact Number"
                  name="userMobile"
                  fullWidth
                  handleChange={(e) => handleInputChange(e, "userMobile")}
                  value={petValues?.userMobile}
                  error={!!errors.userMobile}
                  helperText={errors.userMobile}
                  labelTop
                  mandatoryField
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <CustomTextField
                  label="Email"
                  name="email"
                  fullWidth
                  handleChange={(e) => handleInputChange(e, "email")}
                  value={petValues?.email}
                  error={!!errors.email}
                  helperText={errors.email}
                  labelTop
                  // mandatoryField
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <CustomTextField
                  label="Password"
                  name="password"
                  fullWidth
                  handleChange={(e) => handleInputChange(e, "password")}
                  value={petValues?.password}
                  error={!!errors.password}
                  helperText={errors.password}
                  showPassword={showPassword}
                  handleClickShowPassword={handleClickShowPassword}
                  labelTop
                  password
                  mandatoryField
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Checkbox
                  label="Auto-Generate Password"
                  checked={isAutoGenPass}
                  onChange={handleAutoGenPassword}
                />
              </Grid>
            </Grid>
          </div>
        ) : (
          <div className="scroll-80vh ph20 mt10">
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="mb30"
              >
                <div className="dashed-card mv20 p20">
                  <div className="flex-row-ali-cen">
                    <AccountCircleOutlinedIcon sx={{ width: 25, height: 25 }} />
                    <div className="flex-center">
                      <Custom3dotString
                        str={
                          selectedUser?.name ??
                          userDetails?.userName ??
                          petDetails?.userName
                        }
                        className="font-bold fs12 flex-center ml10"
                        length={30}
                      />
                    </div>
                  </div>
                  <div className="flex-row-ali-cen mt10">
                    <MailIcon
                      sx={{
                        color: AppColors.appPrimary,
                        width: 25,
                        height: 25,
                      }}
                    />
                    <div className="flex-center">
                      <Typography className="txt-regular card-gray-color fs14 ml10 ovrflw-wrp-anywre">
                        {selectedUser?.email ??
                          userDetails?.userEmail ??
                          petDetails?.userEmail}
                      </Typography>
                    </div>
                  </div>
                  <div className="flex-row-ali-cen mt10">
                    <CallIcon
                      sx={{
                        color: AppColors.appPrimary,
                        width: 25,
                        height: 25,
                      }}
                    />
                    <div className="flex-center">
                      <Typography
                        variant="body2"
                        className="txt-regular card-gray-color fs14 ml10"
                      >
                        {selectedUser?.mobile ??
                          userDetails?.userMobile ??
                          petDetails?.userMobile}
                      </Typography>
                    </div>
                  </div>
                </div>
                <CustomUpload
                  uploadText="Profile Picture"
                  onUploadFile={onUploadFile}
                  value={petValues?.image?.previewUrl}
                  profileImg
                  imageHeight={140}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="txt-mont fs14">
                  Name <span className="red-color">*</span>
                </div>
                <CustomTextField
                  name="petName"
                  fullWidth
                  handleChange={(e) => handleInputChange(e, "petName")}
                  value={petValues?.petName}
                  error={!!errors.petName}
                  helperText={errors.petName}
                  labelTop
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <div className="txt-mont fs14">
                  Pet Type <span className="red-color">*</span>
                </div>
                <Select
                  list={prtTypeList}
                  value={petValues?.petType}
                  handleChange={(e, value) =>
                    handleSelectChange(value, "petType")
                  }
                  name="petType"
                  search
                  error={!!errors.petType}
                  helperText={errors.petType}
                  labelTop
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="txt-mont fs14">
                  Age in {petValues?.ageType}{" "}
                  <span className="red-color">*</span>
                </div>
                <CustomTextField
                  name="dob"
                  fullWidth
                  handleChange={(e) => handleInputChange(e, "dob")}
                  value={petValues?.dob}
                  error={!!errors.dob}
                  helperText={errors.dob}
                  labelTop
                  type="number"
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <div className="txt-mont fs14">
                  Age Type <span className="red-color">*</span>
                </div>
                <Select
                  list={[
                    { name: "Month", value: "Month" },
                    { name: "Year", value: "Year" },
                  ]}
                  value={petValues?.ageType}
                  handleChange={(e) =>
                    handleSelectChange(e?.target?.value, "ageType")
                  }
                  name="ageType"
                  select
                  error={!!errors.ageType}
                  helperText={errors.ageType}
                  labelTop
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <div className="txt-mont fs14">
                  Breed <span className="red-color">*</span>
                </div>
                <Select
                  list={breedList}
                  value={petValues?.breed}
                  handleChange={(e, value) =>
                    handleSelectChange(value, "breed")
                  }
                  name="breed"
                  search
                  error={!!errors.breed}
                  helperText={errors.breed}
                  labelTop
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <div className="txt-mont fs14">
                  Color <span className="red-color">*</span>
                </div>
                <Select
                  list={petColorList}
                  value={petValues?.color}
                  handleChange={(e, value) =>
                    handleSelectChange(value, "color")
                  }
                  name="color"
                  error={!!errors.color}
                  helperText={errors.color}
                  search
                  labelTop
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <div className="txt-mont fs14">
                  Gender <span className="red-color">*</span>
                </div>
                <Select
                  list={genderList}
                  value={petValues?.gender}
                  handleChange={(e) =>
                    handleSelectChange(e?.target?.value, "gender")
                  }
                  select
                  error={!!errors.gender}
                  helperText={errors.gender}
                  labelTop
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <div className="txt-mont fs14">
                  Reproductive Status <span className="red-color">*</span>
                </div>
                <Select
                  list={NeuteredList}
                  value={petValues?.neutered}
                  handleChange={(e) =>
                    handleSelectChange(e?.target?.value, "neutered")
                  }
                  error={!!errors.neutered}
                  helperText={errors.neutered}
                  select
                  labelTop
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <CustomTextField
                  name="weight"
                  value={petValues?.weight}
                  fullWidth
                  handleChange={(e) => handleInputChange(e, "weight")}
                  label={"Weight"}
                  error={!!errors.weight}
                  helperText={errors.weight}
                  suffix={petValues?.weight !== "" ? "Kg" : ""}
                  labelTop
                  mandatoryField
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomTextField
                  label="RFID"
                  name="rfid"
                  fullWidth
                  handleChange={(e) => handleInputChange(e, "rfid")}
                  value={petValues?.rfid}
                  labelTop
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="flex1-end">
                  <div>
                    <CustomButton
                      text="Register"
                      onClick={createPet}
                      smallBtn
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        )}

        <Fragment>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            {activeStep === 0 && (
              <div className="flex1-end">
                <div>
                  <CustomButton text="Next" smallBtn onClick={handleNext} />
                </div>
              </div>
            )}
          </Box>
        </Fragment>
      </Box>
    </CustomModal>
  );
};

export default CreateClinicPet;
