import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CallIcon from "@mui/icons-material/Call";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import EditIcon from "@mui/icons-material/Edit";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import MailIcon from "@mui/icons-material/Mail";
import { Grid, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ChangePasswordIcon } from "../../../assets/images/svg/change-password.svg";
import Custom3dotString from "../../../components/Custom3dotString";
import CustomButton from "../../../components/CustomButton";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CustomSwitch from "../../../components/CustomSwitch";
import CustomTextField from "../../../components/CustomTextField";
import CustomUpload from "../../../components/CustomUpload";
import Select from "../../../components/Select/Select";
import { forgotPassword } from "../../../redux/reducers/authSlice";
import { createNewChat } from "../../../redux/reducers/chatSlice";
import {
  getRecentTransactionsPayment,
  getVetDetailsById,
  getVetUpcomingAppointmentsById,
} from "../../../redux/reducers/clinicSlice";
import {
  updateVetBlockStatus,
  updateVetByClinic,
} from "../../../redux/reducers/vetSlice";
import { AppColors } from "../../../util/AppColors";
import { newRoleList } from "../../../util/arrayList";
import { specialtyList, typeList } from "../../../util/dropList";
import CommonPaymentUpdateModal from "../../Hospital/CommonPaymentUpdateModal/CommonPaymentUpdateModal";
import VetBookAppointment from "../../Hospital/VetCalendar/VetBookAppointment";

const initialValues = {
  type: "Vet",
  salutation: "Mr",
  name: "",
  speciality: "",
  contactNumber: "",
  conType: [],
  email: "",
  password: "",
  role: "",
  registrationNo: "",
  image: { file: null, previewUrl: null },
};

const initialError = {
  salutation: false,
  name: false,
  speciality: false,
  conType: false,
  contactNumber: false,
  email: false,
  password: false,
  role: false,
};

const initialHelp = {
  salutation: "",
  name: "",
  speciality: "",
  conType: "",
  contactNumber: "",
  email: "",
  password: "",
  role: "",
};

const nameExpan = {
  salutation: "Salutation",
  name: "Name",
  speciality: "Speciality",
  conType: "Consultation Type",
  contactNumber: "Contact Number",
  email: "Email",
  password: "Password",
  role: "role",
};

const VetAndUpcomingAppointments = ({
  children,
  vetId,
  left = true,
  active,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const [conModVisible, setConModVisible] = useState(false);
  const [modalBookVisible, setModalBookVisible] = useState(false);
  const [profileEditModVisible, setProfileEditModVisible] = useState(false);
  const vetDetails = useSelector((state) => state?.clinic?.vet);
  const [profileValues, setProfileValues] = useState(initialValues);
  const [profileHelps, setProfileHelps] = useState(initialHelp);
  const [profileError, setProfileError] = useState(initialError);
  const upcomingAppointments = useSelector(
    (state) => state?.clinic?.vetUpcomingAppointments
  );
  const payment = useSelector((state) => state.clinic.transactions);
  const getPayment =
    payment ??
    []
      .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
      .slice(0, 10);

  useEffect(() => {
    if (vetId) {
      dispatch(getVetDetailsById(vetId));
      dispatch(getVetUpcomingAppointmentsById(vetId));
    }
    dispatch(getRecentTransactionsPayment("?filter=all&type=resent"));
  }, []);

  const bookAppointment = (li) => {
    setModalBookVisible(true);
  };

  const handleManageSlot = () => {
    navigate(`/manage-slot/${vetId}/4`, { state: { vetId } });
  };

  const updateVetStatus = async () => {
    const apiRes = await dispatch(
      updateVetBlockStatus({
        vetId: vetId ?? vetDetails?.doctorId,
        active: vetDetails?.active === "N" ? "Y" : "N",
      })
    );
    if (apiRes?.payload) dispatch(getVetDetailsById(vetId));
  };

  const conModalOpen = () => {
    setConModVisible(!conModVisible);
  };

  const onApiSuccess = () => {
    dispatch(getVetDetailsById(vetId));
    setConModVisible(!conModVisible);
  };

  const handleEditProfile = () => {
    setProfileValues({
      ...profileValues,
      type: vetDetails?.doctorId ? "Vet" : "Other",
      name: vetDetails?.name,
      speciality: vetDetails?.speciality,
      conType: vetDetails?.consulationType,
      email: vetDetails?.email,
      role: vetDetails?.role,
      salutation: vetDetails?.salutation,
      contactNumber: vetDetails?.mobile,
      registrationNo: vetDetails?.registrationNo ?? "",
      image: { file: null, previewUrl: vetDetails?.image },
    });
    setProfileEditModVisible(true);
  };

  const onEditProfileModClose = () => {
    setProfileEditModVisible(!profileEditModVisible);
  };

  const handleChange = (name, value) => {
    if (name === "conType") {
      setProfileValues({
        ...profileValues,
        conType: typeof value === "string" ? value.split(",") : value,
      });
      return;
    }
    setProfileValues({
      ...profileValues,
      [name]:
        name === "name"
          ? value?.charAt(0).toUpperCase() + value?.slice(1)
          : value,
    });
    if (value === "") {
      setProfileError({ ...profileError, [name]: true });
      setProfileHelps({
        ...profileHelps,
        [name]: `${nameExpan?.[name]} Required Field`,
      });
    }
    if (value !== "") {
      setProfileError({ ...profileError, [name]: false });
      setProfileHelps({ ...profileHelps, [name]: "" });
    }
  };

  const validateField = (value) => {
    if (Array?.isArray(value)) {
      return value?.length > 0;
    } else {
      return typeof value === "string" && value.trim() !== "";
    }
  };

  const handleUpdate = async () => {
    const nameValid = validateField(profileValues?.name);
    const emailValid = validateField(profileValues?.email);
    const specialityValid = validateField(profileValues?.speciality);
    const conTypeValid = validateField(profileValues?.conType);
    const roleValid = validateField(profileValues?.role);
    if (
      !nameValid || !emailValid || profileValues?.type === "Vet"
        ? !specialityValid || !conTypeValid
        : !roleValid
    ) {
      const errors = { name: !nameValid, email: !emailValid };
      const helps = {
        name: nameValid ? "" : "This Field is required",
        email: emailValid ? "" : "Invalid email format",
      };
      if (profileValues?.type === "Vet") {
        errors.speciality = !specialityValid;
        errors.conType = !conTypeValid;
        helps.speciality = specialityValid ? "" : "This Field is required";
        helps.conType = conTypeValid ? "" : "This Field is required";
      } else {
        errors.role = !roleValid;
        helps.role = roleValid ? "" : "This Field is required";
      }
      setProfileError(errors);
      setProfileHelps(helps);
      return;
    }

    const form = new FormData();
    form.append("name", profileValues.name);
    form.append("email", profileValues.email);
    if (profileValues?.type === "Vet") {
      form.append(
        "salutation",
        profileValues?.type === "Vet" ? "Dr." : profileValues?.salutation
      );
      form.append("speciality", profileValues.speciality);
      form.append("consulationType", profileValues.conType);
      form.append("mobile", profileValues?.contactNumber);
      form.append("role", profileValues?.role);
      if (profileValues?.registrationNo !== "") {
        form.append("registrationNo", profileValues?.registrationNo ?? "");
      }
      if (profileValues?.image?.file) {
        form.append("image", profileValues?.image?.file);
      }
    } else {
      form.append("role", profileValues?.role);
    }

    let apiRes = null;
    if (profileValues?.type === "Vet") {
      apiRes = await dispatch(updateVetByClinic({ vetId, form }));
    }
    if (apiRes?.payload) {
      dispatch(getVetDetailsById(vetId));
      setProfileEditModVisible(false);
      setProfileValues(initialValues);
    }
  };

  const onUploadFile = (e) => {
    const reader = new FileReader();
    const file = e?.target?.files[0];
    reader.onloadend = () => {
      setProfileValues({
        ...profileValues,
        image: { file: e.target.files[0], previewUrl: reader.result },
      });
    };
    reader.readAsDataURL(file);
  };

  const handleChat = () => {
    dispatch(createNewChat(vetId)).then((res) => {
      if (res?.payload) {
        navigate("/chat", { state: { chatId: res?.payload?.chatId } });
      }
    });
  };

  const handleSave = async () => {
    dispatch(forgotPassword({ emailId: vetDetails?.email }));
  };

  const getTimeExceed = (apntTime) => {
    return (
      new Date(new Date(apntTime).setHours(new Date(apntTime).getHours() + 1)) <
      new Date()
    );
  };

  return (
    <>
      <Grid container className="back-white ph20">
        {left ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={2.5}
            lg={2.5}
            xl={2.5}
            className="back-white border-rt"
            style={{ height: "100vh" }}
          >
            <Grid container className="mv20 ph20">
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                {vetDetails?.image ? (
                  <img src={vetDetails?.image} alt="" className="img2" />
                ) : (
                  <div className="flex-center h100img brd-r6">
                    <Typography className="font-bold fs30 white-color capitalize">
                      {vetDetails?.name?.[0]}
                    </Typography>
                  </div>
                )}
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8} className="ph10">
                <Typography className="font-bold capitalize">
                  Dr. {vetDetails?.name}
                </Typography>
                <Typography className="mb10 txt-regular card-gray-color fs12 capitalize">
                  {vetDetails?.speciality}
                </Typography>
                <div className="flex-row">
                  <div className="flex-center">
                    <Typography
                      className={`mb10 font-bold fs12 ${
                        vetDetails?.active === "Y" ? "blue-color" : "red-color3"
                      }`}
                    >
                      {vetDetails?.active === "Y" ? "Available" : "On Leave"}
                    </Typography>
                    <div className="ml10">
                      <CustomSwitch
                        value={vetDetails?.active === "Y" ? true : false}
                        onChange={updateVetStatus}
                        greenToGray
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-row">
                  {vetDetails?.consulationType?.length > 0 &&
                    vetDetails?.consulationType?.map((ct, i) => (
                      <div
                        key={ct}
                        className={`txt-regular white-color fs8 card-consultation ${
                          ct === "Physical"
                            ? "card-con-blue-back"
                            : ct === "Virtual"
                            ? "virtual-bg-color"
                            : "card-con-gray-back"
                        } ${i !== 0 ? "ml5" : ""}`}
                      >
                        {ct === "Home" ? "Home Visit" : ct}
                      </div>
                    ))}
                </div>
              </Grid>
              <div className="dashed-card mv20 p20">
                <div className="flex-row">
                  <MailIcon
                    sx={{
                      color: AppColors.appPrimary,
                      width: 30,
                      height: 30,
                    }}
                  />
                  <div className="flex-center">
                    <Typography className="txt-regular card-gray-color fs14 ml10 ovrflw-wrp-anywre">
                      {vetDetails?.email}
                    </Typography>
                  </div>
                </div>
                <div className="flex-row mt10">
                  <CallIcon
                    sx={{
                      color: AppColors.appPrimary,
                      width: 30,
                      height: 30,
                    }}
                  />
                  <div className="flex-center">
                    <Typography className="txt-regular card-gray-color fs14 ml10">
                      {vetDetails?.mobile}
                    </Typography>
                  </div>
                </div>
              </div>
              {role !== "ROLE_DOCTOR" && (
                <div className="mb10">
                  <CustomButton
                    text="Chat"
                    leftIcon
                    startIcon={<ChatOutlinedIcon />}
                    onClick={handleChat}
                  />
                </div>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography className="text-bold mb10 fs14">
                  Shortcuts
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="flex-row mv5 cursor">
                  <EditIcon
                    sx={{ width: 30, height: 30, color: AppColors.gray2 }}
                  />
                  <div className="ml10 flex-center" onClick={handleEditProfile}>
                    <Typography className="txt-regular fs14">
                      Edit Profile
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="flex-row mv5 cursor">
                  <CalendarMonthOutlinedIcon
                    sx={{
                      width: 30,
                      height: 30,
                      color:
                        active === "vetCalendar"
                          ? AppColors?.appPrimary
                          : AppColors.gray2,
                    }}
                  />
                  <div
                    className="ml10 flex-center"
                    onClick={() => {
                      navigate("/vet-calendar", {
                        state: { vet: { doctorId: vetId } },
                      });
                    }}
                  >
                    <Typography
                      className={`txt-regular fs14 ${
                        active === "vetCalendar" ? "blue-color" : ""
                      }`}
                    >
                      My calendar
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  className="flex-row mv5 cursor"
                  onClick={() => {
                    navigate("/manage-availability", { state: { vetId } });
                  }}
                >
                  <InsertInvitationIcon
                    sx={{
                      width: 30,
                      height: 30,
                      color:
                        active === "manageAvailability"
                          ? AppColors?.appPrimary
                          : AppColors.gray2,
                    }}
                  />
                  <div className="ml10 flex-center">
                    <Typography
                      className={`txt-regular fs14 ${
                        active === "manageAvailability" ? "blue-color" : ""
                      }`}
                    >
                      Manage Availability
                    </Typography>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="flex-row mv5 cursor" onClick={handleManageSlot}>
                  <EditCalendarOutlinedIcon
                    sx={{
                      width: 30,
                      height: 30,
                      color:
                        active === "manageSlot"
                          ? AppColors?.appPrimary
                          : AppColors.gray2,
                    }}
                  />
                  <div className="ml10 flex-center">
                    <Typography
                      className={`txt-regular fs14 ${
                        active === "manageSlot" ? "blue-color" : ""
                      }`}
                    >
                      Manage Slots
                    </Typography>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="flex-row mv5 cursor">
                  <CalendarMonthOutlinedIcon
                    sx={{ width: 30, height: 30, color: AppColors.gray2 }}
                  />
                  <div className="ml10 flex-center" onClick={bookAppointment}>
                    <Typography className="txt-regular fs14">
                      Book an appointment
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="flex-row mv5 cursor">
                  <ChangePasswordIcon
                    style={{
                      width: 30,
                      height: 30,
                      fill: AppColors.gray2,
                    }}
                  />
                  <div className="ml10 flex-center" onClick={handleSave}>
                    <Typography className="txt-regular fs14">
                      Reset Password
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        <Grid
          item
          xs={12}
          sm={12}
          md={left ? 7 : 9}
          lg={left ? 7 : 9}
          xl={left ? 7 : 9}
          className="border-rt"
        >
          {children}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={left ? 2.5 : 3}
          lg={left ? 2.5 : 3}
          xl={left ? 2.5 : 3}
          className="back-white"
        >
          <Grid container className={`mv20 ph20 ${!left ? "scroll-90vh" : ""}`}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {!left ? (
                <Typography className="text-bold mb10 fs14">
                  Recent Transactions
                </Typography>
              ) : (
                <Typography className="text-bold mb10 fs14">
                  Upcoming Appointments
                </Typography>
              )}
            </Grid>
            {left ? (
              <>
                <div
                  className="w100Per"
                  style={{
                    height: window.innerHeight / 2 - 80,
                    overflowY: "scroll",
                  }}
                >
                  {upcomingAppointments?.length > 0 ? (
                    upcomingAppointments?.map((upAp, i) => {
                      const aptmnt = upAp?.appoinment;

                      return (
                        <div
                          className="mv10 upcom-card cursor"
                          key={i + aptmnt?.appoimentId}
                          onClick={() => {
                            navigate(`/clinic-pet-details`, {
                              state: {
                                appointment: { appoinment: aptmnt },
                              },
                            });
                          }}
                        >
                          <div className="flex-row-ali-cen">
                            <div className="w25Per">
                              {aptmnt?.petImage ? (
                                <div>
                                  <img
                                    src={aptmnt?.petImage}
                                    alt=""
                                    className="pet-img"
                                  />
                                </div>
                              ) : (
                                <div className="flex-center h55w45img">
                                  <Typography className="font-bold fs30 white-color capitalize">
                                    {aptmnt?.petName?.[0]}
                                  </Typography>
                                </div>
                              )}
                            </div>
                            <div className="w75Per">
                              <div className="flex-row mv3">
                                <div className="flex-center">
                                  <Typography className="font-bold fs14 capitalize">
                                    {aptmnt?.petName}
                                  </Typography>
                                  <Typography
                                    className={`ml5 font-medium fs14 capitalize ${
                                      aptmnt?.gender === "male"
                                        ? "card-blue-color"
                                        : "card-rose-color"
                                    }`}
                                  >
                                    {`(${aptmnt?.gender})`}
                                  </Typography>
                                </div>
                              </div>
                              <div className="flex-row mv3">
                                <div className="flex-center">
                                  <div className="gray-dot" />
                                  <Typography
                                    className={`ml3 font-medium fs12 capitalize ${
                                      upAp?.appoinmentType === "Physical" ||
                                      "Virtual"
                                        ? "blue3"
                                        : upAp?.appoinmentType === "Vaccination"
                                        ? "card-green-color"
                                        : ""
                                    }`}
                                  >
                                    {`${
                                      upAp?.appoinmentType === "Physical" ||
                                      "Virtual"
                                        ? "Consultation"
                                        : ""
                                    }`}
                                    {/* {`${
                                    upAp?.appoinmentType === "Physical" ||
                                    "Virtual"
                                      ? "Consultation"
                                      : ""
                                  } | ${upAp?.problemType}${
                                    upAp?.problems?.[0]
                                      ? ` - ${upAp?.problems?.[0]}`
                                      : ""
                                  }`} */}
                                  </Typography>
                                </div>
                              </div>
                              <div className="flex-row mv3">
                                <div className="flex-center">
                                  <div className="gray-dot" />
                                  <Typography className="ml3 txt-regular card-gray2 fs12">
                                    {aptmnt?.appoimentTime}
                                  </Typography>
                                </div>
                                <div className="flex1-end">
                                  <Typography
                                    className={`txt-regular white-color fs12 upcom-app-box ${
                                      aptmnt?.appoinmentType === "Physical"
                                        ? "card-con-blue-back"
                                        : "virtual-bg-color"
                                    }`}
                                  >
                                    {aptmnt?.appoinmentType}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </div>
                          {getTimeExceed(aptmnt?.appoinmentdateTime) ? (
                            <Typography className="red-color fs10 font-medium">
                              Unfinished
                            </Typography>
                          ) : null}
                        </div>
                      );
                    })
                  ) : (
                    <div className="no-rec">No records available</div>
                  )}
                </div>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography className="text-bold mb10 fs14">
                    Past Appointments
                  </Typography>
                </Grid>
                <div
                  className="w100Per"
                  style={{
                    height: window.innerHeight / 2 - 80,
                    overflowY: "scroll",
                  }}
                ></div>
              </>
            ) : (
              <>
                {getPayment?.length > 0 ? (
                  getPayment?.map((pay, i) => {
                    return (
                      <div
                        className="mv10 upcom-card-wo-back"
                        key={pay?.paymentId}
                      >
                        <div className="flex-row flex-center">
                          <div className="flex-start">
                            {pay?.petImage ? (
                              <img
                                src={pay?.petImage}
                                alt=""
                                className="pet-card-img"
                              />
                            ) : (
                              <div className="flex-center h50img">
                                <Typography className="font-bold fs30 white-color capitalize">
                                  {pay?.petName?.[0]}
                                </Typography>
                              </div>
                            )}
                            <div className="flex-column ml10 spa-around">
                              <div className="flex-center">
                                <Custom3dotString
                                  str={pay?.petName}
                                  className="font-bold fs14"
                                  length={5}
                                />
                                <Typography
                                  className={`ml5 font-medium fs14 capitalize ${
                                    pay?.gender === "male"
                                      ? "card-blue-color"
                                      : "card-rose-color"
                                  }`}
                                >
                                  {`(${pay?.gender})`}
                                </Typography>
                              </div>
                              <div
                                style={{
                                  color: "#75808A",
                                  fontSize: "12px",
                                }}
                              >
                                {moment(pay?.createdDate).format("DD MMM YYYY")}
                              </div>
                            </div>
                          </div>
                          <div className="flex1-end">
                            <div className="paymentstatuspaid">
                              Rs{" "}
                              {Number(pay?.totalAmt) > 0
                                ? Number(pay?.totalAmt).toFixed(2)
                                : Number(pay?.totalAmt)}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="no-reco">No records available</div>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <CommonPaymentUpdateModal
        open={conModVisible}
        onClose={conModalOpen}
        onApiSuccess={onApiSuccess}
        vet={vetDetails}
      />

      <VetBookAppointment
        modalVisible={modalBookVisible}
        setModalBookVisible={setModalBookVisible}
        selectVet={vetDetails}
      />

      <CustomModal
        open={profileEditModVisible}
        onClose={onEditProfileModClose}
        header="Edit Profile"
        rightModal
        modalWidth={30}
      >
        <Grid container spacing={1} className="ph20">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mb30">
            <CustomUpload
              uploadText="Profile Picture"
              onUploadFile={onUploadFile}
              value={profileValues?.image?.previewUrl}
              profileImg
              imageHeight={140}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className="txt-mont fs14 fw-600 ">
              Name <span className="red-color"> *</span>
            </div>
            <CustomTextField
              name="name"
              fullWidth
              handleChange={(e) => handleChange("name", e?.target?.value)}
              value={profileValues?.name}
              helperText={profileHelps?.name}
              error={profileError?.name}
              labelTop
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className="txt-mont fs14 fw-600">
              Role <span className="red-color"> *</span>
            </div>
            <Select
              list={newRoleList}
              value={profileValues?.role}
              handleChange={(e) => handleChange("role", e?.target?.value)}
              select
              error={profileError?.role}
              helperText={profileHelps?.role}
              labelTop
            />
          </Grid>

          {profileValues?.type === "Vet" ? (
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <div className="txt-mont fs14 fw-600 ">
                Speciality <span className="red-color"> *</span>
              </div>
              <Select
                list={specialtyList}
                value={profileValues?.speciality}
                handleChange={(e) =>
                  handleChange("speciality", e?.target?.value)
                }
                name="speciality"
                select
                error={profileError?.speciality}
                helperText={profileHelps?.speciality}
              />
            </Grid>
          ) : null}
          {profileValues?.type === "Vet" ? (
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <div className="txt-mont fs14 fw-600 ">
                Consultation Type <span className="red-color"> *</span>
              </div>

              <Select
                list={typeList}
                value={profileValues?.conType}
                handleChange={(e) => handleChange("conType", e?.target?.value)}
                multiSelectTagCheck
                error={profileHelps?.conType}
                helperText={profileError?.conType}
              />
            </Grid>
          ) : null}

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-mont fs14 fw-600 ">
              Email <span className="red-color"> *</span>
            </div>
            <CustomTextField
              name="email"
              fullWidth
              handleChange={(e) => handleChange("email", e?.target?.value)}
              value={profileValues?.email}
              helperText={profileHelps?.email}
              error={profileError?.email}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="txt-mont fs14 fw-600 ">Phone Number</div>
            <CustomTextField
              value={profileValues?.contactNumber}
              handleChange={(e) =>
                handleChange("contactNumber", e?.target?.value)
              }
              name="Contact Number"
              error={profileHelps?.contactNumber}
              fullWidth
              helperText={profileError?.contactNumber}
              labelTop
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="txt-mont fs14 fw-600 ">Registration No.</div>

            <CustomTextField
              value={profileValues?.registrationNo}
              handleChange={(e) =>
                handleChange("registrationNo", e?.target?.value)
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="flex1-end">
              <div className="w20Per">
                <CustomButton text="Update" onClick={handleUpdate} />
              </div>
            </div>
          </Grid>
        </Grid>
      </CustomModal>
    </>
  );
};

export default VetAndUpcomingAppointments;
