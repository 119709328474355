import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Typography } from "@mui/material";
import { saveAs } from "file-saver";
import { capitalize, uniq } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import CustomButton from "../../../components/CustomButton";
import CustomCheckbox from "../../../components/CustomCheckbox";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CustomTextField from "../../../components/CustomTextField";
import CustomSelect from "../../../components/Select/Select";
import {
  getAllClinicPaymentList,
  getClinicAppointemnts,
  getClinicStocks,
  getProductsByClinic,
  getServices,
  getStockAdjust,
  getSupplier,
} from "../../../redux/reducers/clinicSlice";
import { getClinicPets } from "../../../redux/reducers/petSlice";
import { AppColors } from "../../../util/AppColors";
import { StockAdjustList, stockReportTypes } from "../../../util/arrayList";
import {
  genderListAll,
  NeuteredList,
  payReportFilter,
  petAppntTypeList,
  prdctServceList,
  stockExpireDateList,
  stockQuantityList,
  typeList,
} from "../../../util/dropList";
import { getAge } from "../../../util/getAge";
import ReportTable from "./ReportTable";
import {
  appointmentHeaders,
  nameExpan,
  paymentsHeaders,
  petsHeaders,
  productHeaders,
  serviceHeaders,
  stockAdjustHeaders,
  stockConsumptionHeaders,
  stockSummaryHeaders,
  stockSupplierHeaders,
} from "./tableHeaders";

const reprtTabs = [
  "Pets",
  "Appointments",
  "Billing",
  "Inventory",
  "Stock",
  "Revenue",
];

const initFilter = {
  rangeFilter: "All",
  type: null,
  category: null,
  subCategory: "",

  species: null,
  breed: null,
  gender: null,
  reproductiveStatus: null,

  vetName: null,
  consultationType: null,

  petName: null,
  status: null,

  expDate: null,
  quantity: null,
  hsnCode: null,
  stcRepType: stockReportTypes?.[0],

  customer: null,
  supplier: null,
  startDate: null,
  endDate: null,
};

const dateRanges = ["All", "Week", "Month", "Year"];

const DownloadReports = () => {
  const dispatch = useDispatch();
  const [repType, setRepType] = useState(null);
  const [filterValues, setFilterValues] = useState(initFilter);
  const [editColModVsble, setEditColModVsble] = useState(false);
  const [activeTableHeaders, setActiveTableHeaders] = useState([]);
  const [crntTblHeders, setCrntTblHeders] = useState();
  const [prevModVsble, setPrevModVsble] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [breedList, setBreedList] = useState([]);
  const [speciesList, setSpeciesList] = useState([]);
  const [vetList, setVetList] = useState([]);
  const [petList, setPetList] = useState([]);
  const [stockCatList, setCatList] = useState([]);
  const [hsnCodes, setHsnCodes] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const products = useSelector((state) => state?.clinic?.products?.data);
  const services = useSelector((state) => state?.clinic?.services?.data);
  const pets = useSelector((state) => state?.pet?.clinicPets);
  const appointments = useSelector(
    (state) => state?.clinic?.clinicAppointments
  );
  const payments = useSelector((state) => state?.clinic?.paymentList);
  const stockSales = useSelector((state) => state?.clinic?.stockSales);
  // const clinicStocks = useSelector((state) => state?.clinic?.clinicStocks);
  const { clinicStocks, stockAdjust, suppliers } = useSelector(
    (state) => state?.clinic
  );
  const defPaymntUrl = "?filter=all&type=all";

  useEffect(() => {
    if (repType === "Pets") {
      getTableData({
        list: pets?.pets,
        reportType: "Pets",
        species: filterValues?.species?.value,
        breed: filterValues?.breed?.value,
        gender: filterValues?.gender?.value,
        reproductiveStatus: filterValues?.reproductiveStatus?.value,
      });
    }
    if (repType === "Appointments") {
      getTableData({
        list: appointments,
        reportType: "Appointments",
        vetName: filterValues?.vetName?.value,
        consultationType: filterValues?.consultationType?.value,
        categType: filterValues?.category?.value,
        rangeFilter: filterValues?.rangeFilter,
      });
    }
    if (repType === "Inventory") {
      if (filterValues?.type?.value === "Product") {
        if (
          filterValues?.category !== null ||
          filterValues?.subCategory?.length > 0
        ) {
          getTableData({
            list: products,
            reportType: "Inventory",
            typeFilter: "Product",
          });
        } else {
          dispatch(getProductsByClinic()).then((res) => {
            if (res?.payload) {
              const prdcts = res?.payload?.data;
              getTableData({
                list: products,
                reportType: "Inventory",
                typeFilter: "Product",
              });
              setCrntTblHeders(productHeaders);
              setActiveTableHeaders(
                productHeaders?.map((pd, i) => ({
                  name: pd,
                  isSelected: true,
                  i,
                }))
              );
              getCategoryList(prdcts?.length > 0 ? prdcts : []);
            }
          });
        }
      }
      if (filterValues?.type?.value === "Service") {
        if (
          filterValues?.category !== null ||
          filterValues?.subCategory?.length > 0
        ) {
          getTableData({
            list: services,
            reportType: "Inventory",
            typeFilter: "Service",
          });
        } else {
          dispatch(getServices()).then((res) => {
            if (res?.payload) {
              const servics = res?.payload?.data;
              getTableData({
                list: servics,
                reportType: "Inventory",
                typeFilter: "Service",
              });
              setCrntTblHeders(serviceHeaders);
              setActiveTableHeaders(
                serviceHeaders?.map((pd, i) => ({
                  name: pd,
                  isSelected: true,
                  i,
                }))
              );
              getCategoryList(servics?.length > 0 ? servics : []);
            }
          });
        }
      }
    }
    if (repType === "Billing") {
      getTableData({
        list: payments,
        vetName: filterValues?.vetName?.value,
        petName: filterValues?.petName?.value,
        consultationType: filterValues?.consultationType?.value,
        paymentStatus: filterValues?.status?.value,
        rangeFilter: filterValues?.rangeFilter,
      });
    }
    if (repType === "Stock") {
      //to setting table headers
      setCrntTblHeders(
        filterValues?.stcRepType?.value === "Stock Summary"
          ? stockSummaryHeaders
          : filterValues?.stcRepType?.value === "Stock Adjustment"
          ? stockAdjustHeaders
          : filterValues?.stcRepType?.value === "Stock Consumption"
          ? stockConsumptionHeaders
          : stockSupplierHeaders
      );
      //filter table records based on filters
      if (filterValues?.stcRepType?.value === "Stock Summary") {
        getTableData({
          list: getClinicStockList(clinicStocks?.data),
          categType: filterValues?.category?.value,
          expiryDate: filterValues?.expDate?.value,
          quantity: filterValues?.quantity?.value,
          hsnCode: filterValues?.hsnCode?.value,
          stockRepType: filterValues?.stcRepType?.value,
        });
      }
      if (filterValues?.stcRepType?.value === "Stock Adjustment") {
        // if (stockAdjust?.data?.length === 0 || !stockAdjust?.data) {
        dispatch(getStockAdjust()).then((res) => {
          if (res?.payload) {
            const list = res?.payload?.data;
            const customers = list
              ?.filter((li) => li?.customerName?.length > 0)
              ?.map((li) => {
                return li?.customerName?.toLowerCase();
              });
            const uniqList = uniq(customers);
            setCustomerList(
              uniqList?.map((ul) => ({
                name: capitalize(ul),
                value: capitalize(ul),
              }))
            );
            const supplierList = list
              ?.filter((li) => li?.supplierName?.length > 0)
              ?.map((li) => {
                return li?.supplierName?.toLowerCase();
              });
            const uniqSuppList = uniq(supplierList);
            setSupplierList(
              uniqSuppList?.map((ul) => ({
                name: capitalize(ul),
                value: capitalize(ul),
              }))
            );
            getTableData({
              list,
              stcType: filterValues?.type?.value,
              customer: filterValues?.customer?.value,
              supplier: filterValues?.supplier?.value,
              startDate: filterValues?.startDate,
              endDate: filterValues?.endDate,
              stockRepType: filterValues?.stcRepType?.value,
            });
          }
        });
        // } else {
        //   const customers = stockAdjust?.data
        //     ?.filter((li) => li?.customerName?.length > 0)
        //     ?.map((li) => {
        //       return li?.customerName?.toLowerCase();
        //     });
        //   const uniqList = uniq(customers);
        //   setCustomerList(
        //     uniqList?.map((ul) => ({
        //       name: capitalize(ul),
        //       value: capitalize(ul),
        //     }))
        //   );
        //   const supplierList = stockAdjust?.data
        //     ?.filter((li) => li?.supplierName?.length > 0)
        //     ?.map((li) => {
        //       return li?.supplierName?.toLowerCase();
        //     });
        //   const uniqSuppList = uniq(supplierList);
        //   setSupplierList(
        //     uniqSuppList?.map((ul) => ({
        //       name: capitalize(ul),
        //       value: capitalize(ul),
        //     }))
        //   );
        //   getTableData({
        //     list: stockAdjust?.data,
        //     stcType: filterValues?.type?.value,
        //     customer: filterValues?.customer?.value,
        //     supplier: filterValues?.supplier?.value,
        //     startDate: filterValues?.startDate,
        //     endDate: filterValues?.endDate,
        //     stockRepType: filterValues?.stcRepType?.value,
        //   });
        // }
      }
      if (filterValues?.stcRepType?.value === "Stock Consumption") {
        // const list = [{}];
        getTableData({
          list: getClinicStockList(clinicStocks?.data),
          categType: filterValues?.category?.value,
          expiryDate: filterValues?.expDate?.value,
          quantity: filterValues?.quantity?.value,
          hsnCode: filterValues?.hsnCode?.value,
          stockRepType: filterValues?.stcRepType?.value,
        });
      }
      if (filterValues?.stcRepType?.value === "Supplier Purchase") {
        // if (suppliers?.data?.length === 0 || !suppliers?.data) {
        dispatch(getSupplier()).then((res) => {
          const reqList = getRequiredSupplierList(res?.payload?.data);
          getTableData({
            list: reqList,
            supplier: filterValues?.supplier?.value,
            startDate: filterValues?.startDate,
            endDate: filterValues?.endDate,
            stockRepType: filterValues?.stcRepType?.value,
          });
          const reqSuppliers = res?.payload?.data
            ?.filter((li) => li?.supplierName?.length > 0)
            ?.map((li) => {
              return li?.supplierName?.toLowerCase();
            });
          const uniqSuppliers = uniq(reqSuppliers);
          const reqSupplierList = uniqSuppliers?.map((sup) => ({
            name: capitalize(sup),
            value: capitalize(sup),
          }));
          setSupplierList(reqSupplierList);
        });
        // } else {
        //   const reqList = getRequiredSupplierList(suppliers?.data);
        //   getTableData({
        //     list: reqList,
        //     supplier: filterValues?.supplier?.value,
        //     startDate: filterValues?.startDate,
        //     endDate: filterValues?.endDate,
        //     stockRepType: filterValues?.stcRepType?.value,
        //   });
        // }
      }
    }
  }, [filterValues]);

  const getRequiredSupplierList = (list) => {
    if (list?.length === 0 || !list) return [];
    const reqList = [];
    list?.forEach((li) => {
      li?.supplierDetails?.forEach((supDet) => {
        reqList.push({
          supplierName: li?.supplierName,
          gst: supDet?.gstinoruin,
          billDate: supDet?.billDate,
          invoiceNumber: supDet?.invoiceNumber,
          totalTaxableValue: supDet?.totalTaxableValue,
          totalTaxAmount: supDet?.totalTaxAmount,
          totalAdditionalCharges: supDet?.totalAdditionalCharges,
          grandTotal: supDet?.grandTotal,
        });
      });
    });
    return reqList;
  };

  const getClinicStockList = (list) => {
    if (list?.length === 0 || !list) return [];
    const reqList = [];
    list?.forEach((li) => {
      li?.stockBatch?.forEach((btch) => {
        reqList.push({
          product: li?.tradeName,
          category: li?.category,
          hsnSacCode: li?.hsnSacCode,
          batch: btch?.batchNo,
          mfgDate: btch?.mfgDate,
          expiryDate: btch?.expiryDate,
          quantity: btch?.quantity,
          location: btch?.location,
        });
      });
    });
    return reqList;
  };

  const getCategoryList = (list) => {
    const catList = list
      ?.filter((prd) => prd?.category !== null)
      ?.map((prd) => {
        return prd?.category;
      });
    const uniqCatList = uniq(catList);
    if (uniqCatList?.length > 0) {
      const reqList = uniqCatList?.map((cat) => ({ name: cat, value: cat }));
      setCategoryList(reqList);
    } else {
      setCategoryList([]);
    }
  };

  const handleSelRepType = (typ) => {
    setRepType(typ);
    if (typ === "Pets") {
      setFilterValues(initFilter);
      setCrntTblHeders(petsHeaders);
      setActiveTableHeaders(
        petsHeaders?.map((pd, i) => ({ name: pd, isSelected: true, i }))
      );
      dispatch(getClinicPets(`?type=all`)).then((res) => {
        if (res?.payload) {
          const petList = res?.payload?.data;
          const species = [];
          const breeds = [];
          getTableData({ list: petList, reportType: "Pets" });
          if (petList?.length > 0) {
            petList?.forEach((pt) => {
              const pet = pt?.listOfDatas;
              species.push(pet?.petType?.toLowerCase());
              breeds.push(pet?.breed?.toLowerCase());
            });
          }
          setSpeciesList(
            uniq(species)
              ?.map((sp) => ({ name: capitalize(sp), value: capitalize(sp) }))
              ?.sort((a, b) => a?.name.localeCompare(b?.name))
          );
          setBreedList(
            uniq(breeds)
              ?.map((sp) => ({ name: capitalize(sp), value: capitalize(sp) }))
              ?.sort((a, b) => a?.name.localeCompare(b?.name))
          );
        }
      });
    }
    if (typ === "Appointments") {
      setFilterValues(initFilter);
      setCrntTblHeders(appointmentHeaders);
      setActiveTableHeaders(
        appointmentHeaders?.map((pd, i) => ({ name: pd, isSelected: true, i }))
      );
      dispatch(getClinicAppointemnts()).then((res) => {
        if (res?.payload) {
          const apnts = res?.payload;
          getTableData({
            list: apnts,
            reportType: "Appointments",
          });
          const vetList = apnts
            ?.filter((apnt) => apnt?.doctorName !== "" && apnt?.doctorName)
            ?.map((apnt) => {
              return apnt?.doctorName;
            });
          setVetList(uniq(vetList)?.map((vet) => ({ name: vet, value: vet })));
        }
      });
    }
    if (typ === "Inventory") {
      //to set header and selected type
      setFilterValues({ ...filterValues, type: prdctServceList?.[0] });
      setCrntTblHeders(productHeaders);
      setActiveTableHeaders(
        productHeaders?.map((pd, i) => ({ name: pd, isSelected: true, i }))
      );
      // for calling product list api
      dispatch(getProductsByClinic()).then((res) => {
        if (res?.payload) {
          const prducts = res?.payload?.data;
          getTableData({
            list: prducts,
            reportType: "Inventory",
            typeFilter: "Product",
          });
          getCategoryList(prducts?.length > 0 ? prducts : []);
        }
      });
    }
    if (typ === "Stock") {
      setFilterValues(initFilter);
      setCrntTblHeders(stockSummaryHeaders);
      setActiveTableHeaders(
        stockSummaryHeaders?.map((pd, i) => ({ name: pd, isSelected: true, i }))
      );
      dispatch(getClinicStocks()).then((res) => {
        if (res?.payload) {
          const stockList = res?.payload?.data;
          getTableData({
            list: getClinicStockList(stockList),
            reportType: "Stock",
            stockRepType: filterValues?.stcRepType?.value,
          });
          const catries = stockList?.map((stc) => {
            return stc?.category?.toLowerCase();
          });
          setCatList(uniq(catries)?.map((cat) => ({ name: cat, value: cat })));
          const reqHsnCodes = stockList?.map((stc) => {
            return stc?.hsnSacCode;
          });
          setHsnCodes(
            uniq(reqHsnCodes)?.map((cat) => ({
              name: capitalize(cat),
              value: capitalize(cat),
            }))
          );
        }
      });
    }
    if (typ === "Billing") {
      setFilterValues(initFilter);
      setCrntTblHeders(paymentsHeaders);
      setActiveTableHeaders(
        paymentsHeaders?.map((pd, i) => ({ name: pd, isSelected: true, i }))
      );
      dispatch(getAllClinicPaymentList(defPaymntUrl)).then((res) => {
        if (res?.payload) {
          const payList = res?.payload;
          getTableData({ list: payList, reportType: "Billing" });
          const vetList = payList
            ?.filter((pay) => pay?.doctorName !== "" && pay?.doctorName)
            ?.map((pay) => {
              return pay?.doctorName;
            });
          setVetList(uniq(vetList)?.map((vet) => ({ name: vet, value: vet })));
          const pets = payList
            ?.filter((pay) => pay?.petName !== "" && pay?.petName)
            ?.map((pay) => {
              return pay?.petName;
            });
          setPetList(uniq(pets)?.map((pet) => ({ name: pet, value: pet })));
        }
      });
    }
  };

  function filterApntList(
    list = [],
    vetName = null,
    consultationType = null,
    categType = null,
    rangeFilter = "All"
  ) {
    if (list?.length === 0 || !list) return [];
    if (!vetName && !consultationType && !categType && rangeFilter === "All") {
      return list;
    }
    const today = new Date();
    const oneWeekBefore = new Date(today);
    oneWeekBefore.setDate(today.getDate() - 6);
    oneWeekBefore.setHours(0, 0, 0, 0);

    const oneMonthBefore = new Date(today);
    oneMonthBefore.setMonth(today.getMonth() - 1);
    oneMonthBefore.setHours(0, 0, 0, 0);

    const oneYearBefore = new Date(today);
    oneYearBefore.setFullYear(today.getFullYear() - 1);
    oneYearBefore.setHours(0, 0, 0, 0);

    const reqList = list?.filter((li) => {
      const matchesVetName = vetName
        ? li?.doctorName?.toLowerCase() === vetName?.toLowerCase()
        : true;
      const matchesConsultationType = consultationType
        ? li?.appoinmentType === consultationType
        : true;
      const matchesCategry = categType
        ? categType === "Inpatient"
          ? li?.inpatient
          : !li?.inpatient
        : true;

      //for checking date range
      const credDate = new Date(
        new Date(li?.appoinmentDate).setHours(23, 59, 59)
      );

      const matchesRangeFilter = (() => {
        switch (rangeFilter) {
          case "Week":
            return credDate >= oneWeekBefore;
          case "Month":
            return credDate >= oneMonthBefore;
          case "Year":
            return credDate >= oneYearBefore;
          default:
            return true;
        }
      })();
      return (
        matchesVetName &&
        matchesConsultationType &&
        matchesCategry &&
        matchesRangeFilter
      );
    });
    return reqList;
  }

  function filterPetList(
    list = [],
    species = null,
    breed = null,
    gender = null,
    reproductiveStatus = null
  ) {
    if (list?.length === 0 || !list) return [];
    if (!species && !breed && !gender && !reproductiveStatus) return list;
    return list?.filter((li) => {
      const pet = li?.listOfDatas;
      const matchesSpecies = species
        ? pet?.petType?.toLowerCase() === species?.toLowerCase()
        : true;
      const matchesBreed = breed
        ? pet?.breed?.toLowerCase() === breed?.toLowerCase()
        : true;
      const matchesGender = gender
        ? gender === "all"
          ? true
          : pet?.gender?.toLowerCase() === gender?.toLowerCase()
        : true;
      const matchesReprdctStat = reproductiveStatus
        ? pet.reproductiveStatus?.toLowerCase() ===
          reproductiveStatus?.toLowerCase()
        : true;
      return (
        matchesSpecies && matchesBreed && matchesGender && matchesReprdctStat
      );
    });
  }

  function filterBillingList(
    list = [],
    vetName = null,
    petName = null,
    consultationType = null,
    paymentStatus = null,
    rangeFilter = "All"
  ) {
    if (list?.length === 0 || !list) return [];
    if (
      !vetName &&
      !petName &&
      !consultationType &&
      !paymentStatus &&
      rangeFilter === "All"
    ) {
      return list;
    }

    const today = new Date();
    const oneWeekBefore = new Date(today);
    oneWeekBefore.setDate(today.getDate() - 6);
    oneWeekBefore.setHours(0, 0, 0, 0);

    const oneMonthBefore = new Date(today);
    oneMonthBefore.setMonth(today.getMonth() - 1);
    oneMonthBefore.setHours(0, 0, 0, 0);

    const oneYearBefore = new Date(today);
    oneYearBefore.setFullYear(today.getFullYear() - 1);
    oneYearBefore.setHours(0, 0, 0, 0);

    return list?.filter((li) => {
      const matchesVetName = vetName
        ? li?.doctorName?.toLowerCase() === vetName?.toLowerCase()
        : true;
      const matchesPetName = petName
        ? li?.petName?.toLowerCase() === petName?.toLowerCase()
        : true;
      const matchesConTyp = consultationType
        ? li?.appointmentType?.toLowerCase() === consultationType?.toLowerCase()
        : true;
      const matchesPayStat = paymentStatus
        ? paymentStatus === "all"
          ? true
          : li?.status?.toLowerCase() === paymentStatus?.toLowerCase()
        : true;
      //for checking date range
      const credDate = new Date(new Date(li?.createdDate).setHours(23, 59, 59));

      const matchesRangeFilter = (() => {
        switch (rangeFilter) {
          case "Week":
            return credDate >= oneWeekBefore;
          case "Month":
            return credDate >= oneMonthBefore;
          case "Year":
            return credDate >= oneYearBefore;
          default:
            return true;
        }
      })();

      return (
        matchesVetName &&
        matchesPetName &&
        matchesConTyp &&
        matchesPayStat &&
        matchesRangeFilter
      );
    });
  }

  function filterStockList(
    list = [],
    categType = null,
    expiryDate = null,
    quantity = null,
    hsnCode = null
  ) {
    if (list?.length === 0 || !list) return [];
    if (!categType && !expiryDate && !quantity && !hsnCode) return list;
    const today = new Date();
    const days =
      expiryDate === "Less than 30 days"
        ? 30
        : expiryDate === "Less than 90 days"
        ? 90
        : expiryDate === "Less than 180 days"
        ? 180
        : 365;
    return list?.filter((li) => {
      const targetDate = new Date(today.getTime() + days * 24 * 60 * 60 * 1000);
      const calExpiryDate = new Date(li?.expiryDate);
      const matchesCategory = categType
        ? li?.category?.toLowerCase() === categType?.toLowerCase()
        : true;
      const expiryDateMatch = expiryDate ? calExpiryDate < targetDate : true;
      const quantityMatch = quantity
        ? quantity === "Less than 50"
          ? li?.quantity < 50
          : li?.quantity < 10
        : true;
      const hsnCodeMatch = hsnCode
        ? li?.hsnSacCode?.toLowerCase() === hsnCode?.toLowerCase()
        : true;
      return (
        matchesCategory && expiryDateMatch && quantityMatch && hsnCodeMatch
      );
    });
  }

  function filterStockAdjustments(
    list = [],
    stcType = null,
    customer = null,
    supplier = null,
    startDate = null,
    endDate = null
  ) {
    if (list?.length === 0 || !list) return [];
    if (!stcType && !customer && !supplier && !startDate && !endDate)
      return list;
    return list?.filter((li) => {
      const reqStartDate = new Date(new Date(startDate).setHours(0, 0, 0, 0));
      const reqEndDate = new Date(new Date(endDate).setHours(23, 59, 59, 999));
      const stcTypeMatches = stcType
        ? li?.type?.toLowerCase() === stcType?.toLowerCase()
        : true;
      const customerMatches = customer
        ? li?.customerName?.toLowerCase() === customer?.toLowerCase()
        : true;
      const supplierMatches = supplier
        ? li?.supplierName?.toLowerCase() === supplier?.toLowerCase()
        : true;
      const dateRangeMatches =
        startDate && endDate
          ? reqStartDate <= new Date(li?.createdDate) &&
            reqEndDate >= new Date(li?.createdDate)
          : true;
      return (
        stcTypeMatches && customerMatches && supplierMatches && dateRangeMatches
      );
    });
  }

  function filterSupplierPurchase(
    list = [],
    supplier = null,
    startDate = null,
    endDate = null
  ) {
    if (list?.length === 0 || !list) return [];
    if (!supplier && !startDate && !endDate) return list;
    return list?.filter((li) => {
      const reqStartDate = new Date(new Date(startDate).setHours(0, 0, 0, 0));
      const reqEndDate = new Date(new Date(endDate).setHours(23, 59, 59, 999));
      const supplierMatches = supplier
        ? li?.supplierName?.toLowerCase() === supplier?.toLowerCase()
        : true;
      const dateRangeMatches =
        startDate && endDate
          ? reqStartDate <= new Date(li?.billDate) &&
            reqEndDate >= new Date(li?.billDate)
          : true;
      return supplierMatches && dateRangeMatches;
    });
  }

  function filterCategoryAndSubCategory(
    list = [],
    category = null,
    subCategory = null
  ) {
    if (list?.length === 0 || !list) return [];
    if (!category && subCategory?.length === 0) return list;
    return list?.filter((li) => {
      const matchesCategory = category ? li.category === category : true;
      const matchesSubCategory = subCategory
        ? li?.subCategory?.toLowerCase()?.includes(subCategory?.toLowerCase())
        : true;
      return matchesCategory && matchesSubCategory;
    });
  }

  const getTableData = async ({
    list = [],
    reportType = null,
    typeFilter = null,
    categType = null,
    subCategory = null,
    species = null,
    breed = null,
    gender = null,
    reproductiveStatus = null,
    vetName = null,
    consultationType = null,
    petName = null,
    paymentStatus = null,
    rangeFilter = "All",
    expiryDate = null,
    quantity = null,
    hsnCode = null,
    stockRepType = null,
    stcType = null,
    customer = null,
    supplier = null,
    startDate = null,
    endDate = null,
  }) => {
    if ((reportType ?? repType) === "Pets") {
      const reqPets = await filterPetList(
        list,
        species,
        breed,
        gender,
        reproductiveStatus
      );
      const reqList = reqPets?.map((pet, i) => {
        const petDet = pet?.listOfDatas;
        return {
          name: petDet?.petName,
          parentName: petDet?.userName,
          species: petDet?.petType,
          breed: petDet?.breed,
          gender: petDet?.gender,
          reproductiveStatus: petDet?.reproductiveStatus,
          petAge: getAge(petDet?.dob, true),
          color: petDet?.color,
          weight: petDet?.weight,
          rfid: petDet?.rfid,
        };
      });
      setTableData(reqList);
    }
    if ((reportType ?? repType) === "Appointments") {
      const reqApnts = await filterApntList(
        list,
        vetName,
        consultationType,
        categType,
        rangeFilter
      );
      const reqList = reqApnts?.map((apnt, i) => {
        return {
          apntPetName: apnt?.petName,
          parentName: apnt?.userName,
          vetName: apnt?.doctorName,
          consultationType: apnt?.appoinmentType,
          category: apnt?.inpatient ? "Inpatient" : "Outpatient",
          apntDateAndTime: `${moment(apnt?.appoinmentDate).format(
            "DD MMM YYYY"
          )} ${apnt?.appoimentTime}`,
          serviceType: apnt?.serviceType,
        };
      });
      setTableData(reqList);
    }
    if ((reportType ?? repType) === "Inventory") {
      if ((typeFilter ?? filterValues?.type?.value) === "Product") {
        const reqPrdcts = await filterCategoryAndSubCategory(
          list ?? products,
          categType ?? filterValues?.category?.value,
          subCategory ?? filterValues?.subCategory
        )?.map((prd) => ({
          ...prd,
          productName: prd?.name,
          unitSellingPrice: prd?.sellPrice,
        }));
        setTableData(reqPrdcts);
      }
      if ((typeFilter ?? filterValues?.type?.value) === "Service") {
        const reqServices = await filterCategoryAndSubCategory(
          list ?? services,
          categType ?? filterValues?.category?.value,
          subCategory ?? filterValues?.subCategory
        )?.map((ser) => ({ ...ser, sellingPrice: ser?.servicePrice }));
        setTableData(reqServices);
      }
    }
    if ((reportType ?? repType) === "Billing") {
      const reqBills = await filterBillingList(
        list,
        vetName,
        petName,
        consultationType,
        paymentStatus,
        rangeFilter
      );
      const reqList = reqBills?.map((bil, i) => {
        return {
          payPetName: bil?.petName,
          vetName: bil?.doctorName,
          balanceDue:
            Number(bil?.balanceDue) > 0
              ? `Rs ${Number(bil?.balanceDue).toFixed(2)}`
              : "Nil",
          consultationType: bil?.appointmentType,
          paymentStatus: bil?.status === "paid" ? "Paid" : "Unpaid",
          trDate: moment(bil?.createdDate).format("DD MMM YYYY"),
        };
      });
      setTableData(reqList);
    }
    if ((reportType ?? repType) === "Stock") {
      if (stockRepType === "Stock Summary") {
        const reqStocks = await filterStockList(
          list,
          categType,
          expiryDate,
          quantity,
          hsnCode
        );
        setTableData(reqStocks);
      }
      if (stockRepType === "Stock Adjustment") {
        const reqAdjustments = await filterStockAdjustments(
          list,
          stcType,
          customer,
          supplier,
          startDate,
          endDate
        );
        const reqTable = reqAdjustments?.map((ad) => ({
          ...ad,
          date: moment(new Date(ad?.createdDate)).format("DD MMM YYYY"),
          by: ad?.createdBy,
          customer: ad?.customerName,
          supplier: ad?.supplierName,
          invoiceNo: ad?.invoiceNumber, // not reecive in table
          product: ad?.productName,
          batchNo: ad?.batchNumber,
        }));
        setTableData(reqTable);
      }
      if (stockRepType === "Supplier Purchase") {
        const reqSupplierPurchases = await filterSupplierPurchase(
          list,
          supplier,
          startDate,
          endDate
        );
        setTableData(reqSupplierPurchases);
      }
    }
  };

  const getImgeBasedType = (typ) => {
    return typ === "Pets"
      ? require("../../../assets/images/svg/pets.svg").default
      : typ === "Appointments"
      ? require("../../../assets/images/svg/appointments.svg").default
      : typ === "Inventory"
      ? require("../../../assets/images/svg/inventory.svg").default
      : typ === "Stock"
      ? require("../../../assets/images/svg/stock.svg").default
      : typ === "Billing"
      ? require("../../../assets/images/svg/billing.svg").default
      : require("../../../assets/images/svg/revenue.svg").default;
  };

  const handleChange = (name, value) => {
    const reqObj = { ...filterValues, [name]: value };
    if (name === "type") {
      reqObj.category = null;
      reqObj.subCategory = null;
    }
    if (name === "category") {
      reqObj.subCategory = null;
    }
    setFilterValues(reqObj);
  };

  const handleEditColumn = () => {
    setEditColModVsble(true);
  };

  const onEditModClose = () => {
    setEditColModVsble(false);
  };

  const handleSelcetdColumn = (selected, ind) => {
    const reqList = activeTableHeaders?.map((act, i) => {
      if (i === ind) return { ...act, isSelected: selected };
      return act;
    });
    setActiveTableHeaders(reqList);
  };

  const handleSaveTableColumn = () => {
    const reqList = activeTableHeaders
      ?.filter((act) => act?.isSelected)
      ?.map((act) => {
        return act?.name;
      });
    setCrntTblHeders(reqList);
    setEditColModVsble(false);
  };

  const onClosePrevMod = () => {
    setPrevModVsble(false);
  };

  const handleDownload = () => {
    const reqTableList = tableData?.map((dta) => {
      const obj = {};
      crntTblHeders?.forEach((hd) => {
        const newHeader = nameExpan[hd] || hd;
        obj[newHeader] = dta?.[hd];
      });
      return obj;
    });

    // Define the sheet data
    const worksheet = XLSX.utils.json_to_sheet(reqTableList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook and trigger a download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(
      data,
      `${
        repType === "Inventory"
          ? `${repType}_${
              filterValues?.type?.value === "Product" ? "product" : "service"
            }`
          : repType
      }.xlsx`
    );
  };

  const handleResetFilter = () => {
    setFilterValues({ ...initFilter, stcRepType: filterValues?.stcRepType });
  };

  const handleSelectedDateRange = (value) => {
    setFilterValues({ ...filterValues, rangeFilter: value });
  };

  const renderDateRangeFilterByName = (name) => {
    return (
      <Typography
        className={`font-bold fs14 cursor day-type-box ${
          filterValues?.rangeFilter === name ? "black" : "gray2"
        }`}
        onClick={() => handleSelectedDateRange(name)}
        key={name}
      >
        {name}
      </Typography>
    );
  };

  return (
    <>
      <div className="flex-row-between-align-center mt30">
        {reprtTabs?.map((rprt) => (
          <div
            key={rprt}
            className="w15Per flex-column mh5 cursor flex-center"
            onClick={() => handleSelRepType(rprt)}
          >
            <div
              className={`flex-center brdr-rd5-h120 ${
                repType === rprt ? "report-selctd-bodr" : "report-unsel-bodr"
              }`}
            >
              <img alt="" src={getImgeBasedType(rprt)} className="imghw30" />
            </div>
            <Typography className="font-medium fs12 black12 mt5">
              {rprt}
            </Typography>
          </div>
        ))}
      </div>
      {repType ? (
        <div>
          <Typography className="blue-color fs16 font-bold mb10 mt20">
            Filters
          </Typography>

          <div className="flex-row">
            {repType === "Pets" ? (
              <>
                <div className="flex-column w15Per">
                  <div className="font-medium fs14 black2">Breed</div>
                  <CustomSelect
                    list={breedList}
                    name="breed"
                    value={filterValues?.breed}
                    handleChange={(e, value) => handleChange("breed", value)}
                    search
                  />
                </div>
                <div className="flex-column ml15 w15Per">
                  <div className="font-medium fs14 black2">Gender</div>
                  <CustomSelect
                    list={genderListAll}
                    name="gender"
                    value={filterValues?.gender}
                    handleChange={(e, value) => handleChange("gender", value)}
                    search
                  />
                </div>
                <div className="flex-column ml15 w15Per">
                  <div className="font-medium fs14 black2">
                    Reproductive Status
                  </div>
                  <CustomSelect
                    list={NeuteredList}
                    name="reproductiveStatus"
                    value={filterValues?.reproductiveStatus}
                    handleChange={(e, value) =>
                      handleChange("reproductiveStatus", value)
                    }
                    search
                  />
                </div>
                <div className="flex-column ml15 w15Per">
                  <div className="font-medium fs14 black2">Species</div>
                  <CustomSelect
                    list={speciesList}
                    name="species"
                    value={filterValues?.species}
                    handleChange={(e, value) => handleChange("species", value)}
                    search
                  />
                </div>
              </>
            ) : null}
            {repType === "Appointments" || repType === "Billing" ? (
              <div className="flex-column w15Per">
                <div className="font-medium fs14 black2">Vet Name</div>
                <CustomSelect
                  list={vetList}
                  name="vetName"
                  value={filterValues?.vetName}
                  handleChange={(e, value) => handleChange("vetName", value)}
                  search
                />
              </div>
            ) : null}
            {repType === "Billing" ? (
              <div className="flex-column ml15 w15Per">
                <div className="font-medium fs14 black2">Pet Name</div>
                <CustomSelect
                  list={petList}
                  name="petName"
                  value={filterValues?.petName}
                  handleChange={(e, value) => handleChange("petName", value)}
                  search
                />
              </div>
            ) : null}
            {repType === "Appointments" || repType === "Billing" ? (
              <div className="flex-column ml15 w15Per">
                <div className="font-medium fs14 black2">Consultation Type</div>
                <CustomSelect
                  list={typeList}
                  name="consultationType"
                  value={filterValues?.consultationType}
                  handleChange={(e, value) =>
                    handleChange("consultationType", value)
                  }
                  search
                />
              </div>
            ) : null}
            {repType === "Appointments" ? (
              <div className="flex-column ml15 w15Per">
                <div className="font-medium fs14 black2">Category</div>
                <CustomSelect
                  list={petAppntTypeList}
                  name="category"
                  value={filterValues?.category}
                  handleChange={(e, value) => handleChange("category", value)}
                  search
                />
              </div>
            ) : null}
            {repType === "Inventory" ? (
              <>
                <div className="flex-column w15Per">
                  <div className="font-medium fs14 black2">Type</div>
                  <CustomSelect
                    list={prdctServceList}
                    name="type"
                    value={filterValues?.type}
                    handleChange={(e, value) => handleChange("type", value)}
                    search
                  />
                </div>
                <div className="flex-column ml15 w15Per">
                  <div className="font-medium fs14 black2">Category</div>
                  <CustomSelect
                    list={categoryList}
                    name="category"
                    value={filterValues?.category}
                    handleChange={(e, value) => handleChange("category", value)}
                    search
                  />
                </div>
                <div className="flex-column ml20 w15Per">
                  <div className="font-medium fs14 black2">Sub Category</div>
                  <CustomTextField
                    name="subCategory"
                    fullWidth
                    handleChange={(e) =>
                      handleChange("subCategory", e?.target?.value)
                    }
                    value={filterValues?.subCategory}
                  />
                </div>
              </>
            ) : null}
            {repType === "Billing" ? (
              <div className="flex-column ml15 w15Per">
                <div className="font-medium fs14 black2">Status</div>
                <CustomSelect
                  list={payReportFilter}
                  name="status"
                  value={filterValues?.status}
                  handleChange={(e, value) => handleChange("status", value)}
                  search
                />
              </div>
            ) : null}
            {repType === "Stock" ? (
              filterValues?.stcRepType?.value === "Stock Summary" ? (
                <>
                  <div className="flex-column w15Per">
                    <div className="font-medium fs14 black2">Category</div>
                    <CustomSelect
                      list={stockCatList}
                      name="category"
                      value={filterValues?.category}
                      handleChange={(e, value) =>
                        handleChange("category", value)
                      }
                      search
                    />
                  </div>
                  <div className="flex-column ml20 w15Per">
                    <div className="font-medium fs14 black2">Expiry Date</div>
                    <CustomSelect
                      list={stockExpireDateList}
                      name="expDate"
                      value={filterValues?.expDate}
                      handleChange={(e, value) =>
                        handleChange("expDate", value)
                      }
                      search
                    />
                  </div>
                  <div className="flex-column ml20 w15Per">
                    <div className="font-medium fs14 black2">Quantity</div>
                    <CustomSelect
                      list={stockQuantityList}
                      name="quantity"
                      value={filterValues?.quantity}
                      handleChange={(e, value) =>
                        handleChange("quantity", value)
                      }
                      search
                    />
                  </div>
                  <div className="flex-column ml20 w15Per">
                    <div className="font-medium fs14 black2">HSN/SAC Code</div>
                    <CustomSelect
                      list={hsnCodes}
                      name="hsnCode"
                      value={filterValues?.hsnCode}
                      handleChange={(e, value) =>
                        handleChange("hsnCode", value)
                      }
                      search
                    />
                  </div>
                </>
              ) : (
                <>
                  {filterValues?.stcRepType?.value === "Stock Adjustment" ? (
                    <div className="flex-column w15Per">
                      <div className="font-medium fs14 black2">Type</div>
                      <CustomSelect
                        list={
                          filterValues?.stcRepType?.value === "Stock Adjustment"
                            ? StockAdjustList
                            : prdctServceList
                        }
                        name="type"
                        value={filterValues?.type}
                        handleChange={(e, value) => handleChange("type", value)}
                        search
                      />
                    </div>
                  ) : null}
                  {filterValues?.stcRepType?.value === "Stock Consumption" ? (
                    <div className="flex-column w15Per">
                      <div className="font-medium fs14 black2">
                        HSN/SAC Code
                      </div>
                      <CustomSelect
                        list={
                          filterValues?.stcRepType?.value === "Stock Adjustment"
                            ? StockAdjustList
                            : prdctServceList
                        }
                        name="type"
                        value={filterValues?.type}
                        handleChange={(e, value) => handleChange("type", value)}
                        search
                      />
                    </div>
                  ) : null}
                  {filterValues?.stcRepType?.value === "Stock Adjustment" ||
                  filterValues?.stcRepType?.value === "Stock Consumption" ? (
                    <div className="flex-column w15Per ml20">
                      <div className="font-medium fs14 black2">Customer</div>
                      <CustomSelect
                        list={customerList}
                        name="customer"
                        value={filterValues?.customer}
                        handleChange={(e, value) =>
                          handleChange("customer", value)
                        }
                        search
                      />
                    </div>
                  ) : null}
                  {filterValues?.stcRepType?.value === "Stock Adjustment" ||
                  filterValues?.stcRepType?.value === "Supplier Purchase" ? (
                    <div
                      className={`flex-column w15Per ${
                        filterValues?.stcRepType?.value === "Supplier Purchase"
                          ? ""
                          : "ml20"
                      }`}
                    >
                      <div className="font-medium fs14 black2">Supplier</div>
                      <CustomSelect
                        list={supplierList}
                        name="supplier"
                        value={filterValues?.supplier}
                        handleChange={(e, value) =>
                          handleChange("supplier", value)
                        }
                        search
                      />
                    </div>
                  ) : null}
                  {filterValues?.stcRepType?.value === "Stock Consumption" ? (
                    <div className="flex-column w15Per ml20">
                      <div className="font-medium fs14 black2">Vet Name</div>
                      <CustomSelect
                        list={prdctServceList}
                        name="type"
                        value={filterValues?.type}
                        handleChange={(e, value) => handleChange("type", value)}
                        search
                      />
                    </div>
                  ) : null}
                  <div className="flex-column w15Per ml20">
                    <div className="font-medium fs14 black2">Start Date</div>
                    <CustomTextField
                      fullWidth
                      handleChange={(e) =>
                        handleChange("startDate", e?.target?.value)
                      }
                      value={filterValues?.startDate}
                      mobileDate
                    />
                  </div>
                  <div className="flex-column w15Per ml20">
                    <div className="font-medium fs14 black2">End Date</div>
                    <CustomTextField
                      fullWidth
                      handleChange={(e) =>
                        handleChange("endDate", e?.target?.value)
                      }
                      value={filterValues?.endDate}
                      mobileDate
                    />
                  </div>
                </>
              )
            ) : null}

            {repType === "Appointments" || repType === "Billing" ? (
              <div className="flex1-end">
                {dateRanges?.map((dr) => renderDateRangeFilterByName(dr))}
              </div>
            ) : null}

            {repType === "Stock" ? (
              <div className="flex1-end">
                <div className="flex-column w200">
                  <div className="font-medium fs14 black2">Report Type</div>
                  <CustomSelect
                    list={stockReportTypes}
                    name="stcRepType"
                    value={filterValues?.stcRepType}
                    handleChange={(e, value) =>
                      handleChange("stcRepType", value)
                    }
                    search
                  />
                </div>
              </div>
            ) : null}
          </div>

          <div className="flex-row-ali-cen mt5">
            <Typography className="gray15 fs16 font-bold">
              Total {tableData?.length}
            </Typography>
            <div
              className="flex-row-ali-cen ml50 cursor"
              onClick={handleEditColumn}
            >
              <EditOutlinedIcon
                sx={{
                  color: AppColors.appPrimary,
                  width: 20,
                  height: 20,
                }}
              />
              <Typography className="blue-color fs16 font-medium ml5">
                Edit columns ({crntTblHeders?.length})
              </Typography>
            </div>
            <Typography
              className="red-color fs16 font-medium cursor ml20"
              onClick={handleResetFilter}
            >
              Reset
            </Typography>
          </div>
          <div className="m20Min">
            <div className="mt10">
              <ReportTable
                datas={
                  tableData?.length > 0
                    ? filterValues?.type?.value === "Product"
                      ? tableData?.slice(0, 1)
                      : tableData?.slice(0, 2)
                    : []
                }
                columns={crntTblHeders}
                incWidth={filterValues?.type?.value === "Product"}
              />
            </div>
          </div>
          <div className="flex1-end">
            <div>
              <CustomButton
                text="Preview"
                smallBtn
                whiteBtn
                onClick={() => setPrevModVsble(true)}
              />
            </div>
            <div className="ml10">
              <CustomButton text="Download" smallBtn onClick={handleDownload} />
            </div>
          </div>
        </div>
      ) : null}
      <CustomModal
        open={editColModVsble}
        onClose={onEditModClose}
        header="Columns"
        modal
        modalWidth={25}
      >
        <div className="ph20 scroll-70vh">
          {activeTableHeaders?.map((hr) => (
            <div className="flex-row" key={hr?.i}>
              <CustomCheckbox
                label={nameExpan?.[hr?.name]}
                checked={hr?.isSelected}
                onChange={() => handleSelcetdColumn(!hr?.isSelected, hr?.i)}
              />
            </div>
          ))}
          <div className="flex-end mb10">
            <div>
              <CustomButton
                text="Save"
                smallBtn
                onClick={handleSaveTableColumn}
              />
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={prevModVsble}
        onClose={onClosePrevMod}
        header="Preview"
        modal
        modalWidth={95}
      >
        <ReportTable
          datas={tableData?.length > 0 ? tableData : []}
          columns={crntTblHeders}
        />
      </CustomModal>
    </>
  );
};

export default DownloadReports;
